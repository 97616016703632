import { TaskTypes, ValidDisplayMethodology, AnalysisStatus } from './entities';

export enum EVENTS {
  LOAD_VIEW = 'Load View',
  SIGNUP = 'Signup',
  CREATE_FIELD_ANALYSIS = 'Create Field Analysis',
  CREATE_ANALYSIS = 'Create Analysis',
  CREATE_TASK = 'Create Task',
  COMPLETE_TASK = 'Complete Task',
  COMPLETE_ANALYSIS = 'Complete Analysis',
  UPDATE_ANALYSIS_STATUS = 'Update Analysis Status',
  DOWNLOAD_REPORT_DOCX = 'Download Report Docx',
  SAVE_REPORT_PDF = 'Save Report PDF',
}

export type EventNames = `${EVENTS}`;

export type EventPayloads = {
  [EVENTS.LOAD_VIEW]: {
    View: string;
  };
  [EVENTS.SIGNUP]: {
    Username: string;
  };
  [EVENTS.CREATE_FIELD_ANALYSIS]: {
    'Analysis Created DateTime': string;
    'Incident Occurred DateTime': string;
  };
  [EVENTS.CREATE_ANALYSIS]: {
    'Analysis Methodology': ValidDisplayMethodology;
    'Analysis Name'?: string;
    'Group UUID'?: string;
  };
  [EVENTS.CREATE_TASK]: {
    'Analysis UUID': string;
    'Analysis ID'?: number;
    'Task Type': TaskTypes;
    'Task Name': string;
  };
  [EVENTS.COMPLETE_TASK]: {
    'Analysis UUID': string;
    'Analysis Name': string;
    'Analysis ID'?: number;
    'Task Type': TaskTypes;
    'Task Name': string;
    Completed: boolean;
  };
  [EVENTS.COMPLETE_ANALYSIS]: {
    'Analysis Methodology': ValidDisplayMethodology;
    'Analysis Name': string;
  };
  [EVENTS.UPDATE_ANALYSIS_STATUS]: {
    'Analysis Methodology': ValidDisplayMethodology;
    'Analysis Name': string;
    'Analysis Status': AnalysisStatus;
  };
  [EVENTS.DOWNLOAD_REPORT_DOCX]: {
    'Analysis UUID': string;
  };
  [EVENTS.SAVE_REPORT_PDF]: {
    'Analysis UUID': string;
  };
};
