import React from 'react';
import cx from 'classnames';
import { mapValues } from 'lodash';

import shortid from 'shortid';
import { CaretDown16, CaretRight16 } from '@carbon/icons-react';
import { Table } from '../ReportCard';
import { nodeIconForType, nodeColorForType, labelForType, isMultiWordType } from '../../hooks';

import styles from '../../styles/report.module.css';

export const RootCauses = ({ notes, data, nodes = [], allNotesExpanded = false }) => {
  const notesArray = notes || [];
  // build a map: { "0250063a-f782-484a-9499-65526e470676": false }
  // ...for use in tracking visibility of root cause rows
  const rootCauseStateMap = React.useCallback(
    (expanded = false) => {
      if (data.length > 0) {
        return data.reduce((map, obj) => {
          map[obj.oid] = expanded;
          return map;
        }, {});
      }

      return {};
    },
    [data],
  );

  const [rowVisibilityState, setRowVisibilityState] = React.useState(rootCauseStateMap);

  React.useEffect(() => {
    setRowVisibilityState(rootCauseStateMap(allNotesExpanded));
  }, [allNotesExpanded, rootCauseStateMap]);

  if (data.length < 1) {
    return <p>Root causes have not been identified on your tree.</p>;
  }

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Root Cause</Table.HeaderCell>
            <Table.HeaderCell>Corrective Action</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map(e => {
            if (!e) return null;

            const NodeIcon = nodeIconForType({ type: e.type });
            const nodeColor = nodeColorForType({ type: e.type });
            const associatedNotes = notesArray.filter(n => {
              return n.nodeUuid === e.oid;
            });

            const toggleExpandRow = () => {
              if (associatedNotes.length > 0) {
                return setRowVisibilityState(state => ({
                  ...mapValues(state, () => false),
                  [e.oid]: !state[e.oid],
                }));
              }

              return null;
            };

            const rowIsExpanded = rowVisibilityState[e.oid];
            const associatedCorrectiveActions = nodes.filter(n => {
              return n.parent === e.oid && n.type === 'correctiveAction';
            });

            return (
              <>
                <Table.Row key={shortid()} onClick={toggleExpandRow} pointerOnHover={associatedNotes.length > 0}>
                  <Table.Cell
                    width={isMultiWordType(e.type) ? '195px' : '100px'}
                    style={{ paddingLeft: '1.2rem', paddingRight: '0.8rem' }}
                  >
                    <div className={styles['rootCauseTypeIconWrapper']}>
                      <NodeIcon fill={nodeColor} width={18} height={18} />
                      <span className={cx(styles['rootCauseTypeLabel'], 'fw6')}>{labelForType({ type: e.type })}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{e.text}</Table.Cell>
                  <Table.Cell>
                    {associatedCorrectiveActions.length > 0 ? (
                      <ol>
                        {associatedCorrectiveActions.map(action => (
                          <li className="pv1" key={action.oid}>
                            {action.text}
                          </li>
                        ))}
                      </ol>
                    ) : (
                      <span className="fg--black-50">–</span>
                    )}
                  </Table.Cell>
                  <Table.Cell width="40px" align="right">
                    <span className="fs-s mono">{associatedNotes.length}</span>
                  </Table.Cell>
                  <Table.Cell width="30px" padding="0" align="center" className="collapseIcon">
                    <div className="pa3">
                      {associatedNotes.length > 0 ? (
                        rowIsExpanded ? (
                          <CaretDown16 className="fill--black-90" />
                        ) : (
                          <CaretRight16 className="fill--black-30" />
                        )
                      ) : null}
                    </div>
                  </Table.Cell>
                </Table.Row>
                {rowIsExpanded
                  ? associatedNotes.map(n => {
                      return (
                        <Table.Row className={styles['rootCauseNoteRow']} key={shortid()}>
                          <Table.Cell colSpan="5" padding="0" className={styles['rootCauseNote']} background="#f7f7f7">
                            <Table border={false}>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell
                                    className="pl5"
                                    width="150px"
                                    style={{
                                      maxWidth: 150,
                                      paddingRight: '1rem',
                                    }}
                                  >
                                    <div
                                      className={cx('flex items-center justify-end', styles['rootCauseNoteTypeLabel'])}
                                    >
                                      <span>{n.noteType}</span>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p className="fs-xs ma0 pl2">{n.text}</p>
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                  : null}
              </>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
