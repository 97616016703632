import { Add20, Copy16, Launch16 } from '@carbon/icons-react';
import cx from 'classnames';
import { Button, Checkbox } from 'Buttons';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { createNewWebhook, changeWebhookTestMode, getAllWebhooks, initDispatchedWS } from 'Sync';
import { Map } from 'immutable';
import { Page } from './Page';

enum WebhookTypes {
  tasks = 'tasks',
  trees = 'trees',
  newTree = 'newTree',
}

type WebhookType = keyof typeof WebhookTypes;

type Webhook<S extends WebhookType> = {
  key: string;
  createdAt: string;
  testMode: boolean;
  webhookType: WebhookType;
};

type RCAsWebhook = Webhook<'trees'>;
type TasksWebhook = Webhook<'tasks'>;
type CreateRCAWebhook = Webhook<'newTree'>;

export const Webhooks = ({ state, dispatch }: { state: any; dispatch: any }) => {
  const [isInitializing, setIsInitializing] = React.useState(true);
  // const [webhookIdentifier, setWebhookIdentifier] = useState<string | null>(null);
  // const [webhookKey, setWebhookKey] = useState<string | null>(null);
  // const [isCreating, setIsCreating] = useState(false);
  // const [createdAt, setCreatedAt] = useState(null);
  const [fetchedWebhooks, setFetchedWebhooks] = useState(false);
  const [loading, setLoading] = useState(true);
  const socket = state.get('ws');
  const token = state.get('token');
  const user = state.getIn(['organization', 'user'], {});
  // const [webhooks, setWebhooks] = useState([]);
  const webhooks = state.get('webhooks', []);

  const handleCopyToClipboard = (uuid: string, type: string) => {
    dispatch({
      type: 'COPY_TO_CLIPBOARD',
      value: `${process.env.REACT_APP_BASE_URL.replace('#', '')}webhook/v1/${type}/${uuid}`,
      message: 'Copied webhook URL successfully',
    });
  };

  const fetchWebhooks = async () => {
    setLoading(true);

    getAllWebhooks(token)
      .then(hooks => {
        dispatch({
          type: 'SET_WEBHOOKS',
          data: hooks,
        });
      })
      .catch(error => console.error('failed to fetch webhooks on mount', error))
      .finally(() => {
        setFetchedWebhooks(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token && !fetchedWebhooks) {
      fetchWebhooks();
    }
  }, [token, webhooks, fetchedWebhooks, setLoading, setFetchedWebhooks]);

  React.useLayoutEffect(() => {
    if (socket) {
      // view is rendering for the first time
      // ...fetch groups and flip initialization flag
      if (isInitializing) {
        // make sure we don't have any holes in store
        dispatch({ type: 'GET_ORGANIZATION' });
        // dispatch(Map({ type: 'LIST_GROUPS', username: user.get('username') }));
        return setIsInitializing(false);
      }
    } else {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          return dispatch({ type: 'SET_URL', url: '/' });
        }

        if (isInitializing) {
          // make sure we don't have any holes in store
          dispatch({ type: 'GET_ORGANIZATION' });
          return setIsInitializing(false);
        }
      });
    }
  }, [socket, isInitializing, user]);
  // const isOrgAdmin = state.getIn(['users', user.get('username'), 'roles'], []).includes('ADMIN');

  const RCAWebhooks = ({ data }: { data: RCAsWebhook[] }) => {
    const [isCreating, setIsCreating] = useState(false);
    // const [webhookKey, setWebhookKey] = useState<string | null>(null);
    // const [createdAt, setCreatedAt] = useState(null);

    const createWebhook = async () => {
      setIsCreating(true);

      createNewWebhook({ token, type: 'trees' })
        .then(data => {
          dispatch({
            type: 'ADD_WEBHOOK',
            data,
          });
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: 'Successfully created webhook',
              style: 'SUCCESS',
            }),
          );
        })
        .catch();

      setIsCreating(false);
      // .then(() => {
      // setIsCreating(false)
      // dispatch(
      //   Map({
      //     type: 'SHOW_TOAST',
      //     message: 'Webhook successfully created.',
      //   }),
      // );
      // })
      // .catch(e => {
      //   console.error(e);
      //   dispatch(
      //     Map({
      //       type: 'SHOW_TOAST',
      //       message: 'Failed to create webhook. See console.',
      //       style: 'FAILURE',
      //     }),
      //   );
      // })
      // .finally(() => setIsCreating(false));

      // if (webhook && webhook.key) {
      //   // setWebhookIdentifier(webhook.key.slice(0, 2));
      //   // setWebhookKey(webhook.key);
      //   // setCreatedAt(webhook.createdAt);
      // }

      // delay(() => setIsCreating(false), 300);
    };

    // const getWebhooks = async () => {
    //   try {
    //     const webhook = await getAllWebhooks(token);

    //     if (webhook && webhook[0]) {
    //       setWebhookIdentifier(webhook[0].key.slice(0, 2));
    //       setWebhookKey(webhook[0].key);
    //       setCreatedAt(webhook[0].createdAt);
    //     }
    //   } catch (error) {
    //     console.error('Failed to fetch webhooks for user', error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    if (data.length < 1) {
      return (
        <Button
          className="dib mw-3"
          disabled={isCreating}
          theme="primary"
          small={false}
          id="new-webhook"
          icon={<Add20 fill="white" />}
          onClick={createWebhook}
          text="Create RCA Webhook"
        />
      );
    }

    return (
      <>
        {data.map(webhook => {
          const onCopyWebhook = () => handleCopyToClipboard(webhook.key, 'trees');
          return (
            <div key={webhook.key} className="bg-near-white ph2 pv3 flex items-center justify-evenly mb2">
              <div className={cx('w-100 flex items-center justify-evenly')}>
                <p className="pl2 ta-left fs-xs mv0">{format(new Date(webhook.createdAt), 'MM/d/uuuu')}</p>

                <p className="mv0 fs-s mv0 ph3 truncate-l">
                  {process.env.NODE_ENV === 'development'
                    ? `${process.env.REACT_APP_BASE_URL.replace('/#', '')}/webhook/v1/trees/`
                    : null}
                  {isCreating ? '....' : `${webhook.key.slice(0, 8)}****`}
                </p>

                <div className="flex items-stretch justify-even ml-auto">
                  <button onClick={onCopyWebhook} className="fs-s ph2 bg-transparent flex items-center justify-center">
                    <Copy16 fill="currentColor" className="ml1 mr2" width={12} />
                    <span className="i18n pre">Copy URL</span>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const CreateTreeWebhooks = ({ data }: { data: Array<CreateRCAWebhook> }) => {
    const [isCreating, setIsCreating] = useState(false);
    // const [isTestMode, setIsTestMode] = useState(data[0].testMode || false);
    const targetWebhook = data[0];

    const handleSetTestmode = (enabled: boolean) => {
      const webhook = data[0];
      // setIsTestMode(enabled);

      changeWebhookTestMode({ token, webhookUuid: data[0].key, enabled })
        .then(() => {
          dispatch({
            type: 'UPDATE_WEBHOOK',
            webhookUuid: webhook.key,
            testMode: enabled,
          });
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: 'Successfully updated webhook',
              style: 'SUCCESS',
            }),
          );

          fetchWebhooks();
        })
        .catch();
    };

    const createWebhook = () => {
      setIsCreating(true);

      createNewWebhook({ token, type: 'newTree' })
        .then(data => {
          dispatch({
            type: 'ADD_WEBHOOK',
            data,
          });
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: 'Successfully created webhook',
              style: 'SUCCESS',
            }),
          );
        })
        .catch();

      setIsCreating(false);
    };

    if (data.length < 1) {
      return (
        <Button
          className="dib mw-3"
          disabled={isCreating}
          theme="primary"
          small={false}
          id="new-webhook"
          icon={<Add20 fill="white" />}
          onClick={createWebhook}
          text="Create New Tree Webhook"
        />
      );
    }

    return (
      <div className="flex flex-column">
        {data.map(webhook => {
          const onCopyWebhook = () => handleCopyToClipboard(webhook.key, 'newTree');
          return (
            <>
              <div key={webhook.key} className="bg-near-white ph2 pv3 flex items-center justify-evenly mb2">
                <div className={cx('w-100 flex items-center justify-evenly')}>
                  <p className="pl2 ta-left fs-xs mv0">{format(new Date(webhook.createdAt), 'MM/d/uuuu')}</p>

                  <p className="mv0 fs-s mv0 ph3 truncate-l">
                    {process.env.NODE_ENV === 'development'
                      ? `${process.env.REACT_APP_BASE_URL.replace('/#', '')}/webhook/v1/newTree`
                      : null}
                    {isCreating ? '....' : `${webhook.key.slice(0, 8)}****`}
                  </p>

                  <div className="flex items-stretch justify-even ml-auto">
                    <button
                      onClick={onCopyWebhook}
                      className="fs-s ph2 bg-transparent flex items-center justify-center"
                    >
                      <Copy16 fill="currentColor" className="ml1 mr2" width={12} />
                      <span className="i18n pre">Copy URL</span>
                    </button>
                  </div>
                </div>
              </div>
              <Checkbox
                id="enable-testmode"
                className="mt2"
                text="Enable testmode"
                onClick={e => {
                  handleSetTestmode(e);
                }}
                description="Prevents any data from being created"
                value={targetWebhook.testMode}
              />
            </>
          );
        })}
      </div>
    );
  };

  const TaskWebhooks = ({ data }: { data: TasksWebhook[] }) => {
    const [isCreating, setIsCreating] = useState(false);
    // const [webhookKey, setWebhookKey] = useState<string | null>(null);
    // const [createdAt, setCreatedAt] = useState(null);

    const createWebhook = () => {
      setIsCreating(true);

      createNewWebhook({ token, type: 'tasks' })
        .then(data => {
          dispatch({
            type: 'ADD_WEBHOOK',
            data,
          });
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: 'Successfully created webhook',
              style: 'SUCCESS',
            }),
          );
        })
        .catch();

      setIsCreating(false);
      // return createNewWebhook({ token, type: 'tasks' })
      //   .then(x => {
      //     console.info('webhook created:', x);
      //   })
      //   .catch(e => console.error(e))
      //   .finally(() => setIsCreating(false));

      // if (webhook && webhook.key) {
      //   // setWebhookIdentifier(webhook.key.slice(0, 2));
      //   // setWebhookKey(webhook.key);
      //   // setCreatedAt(webhook.createdAt);
      // }

      // delay(() => setIsCreating(false), 300);
    };

    // const getWebhooks = async () => {
    //   try {
    //     const webhook = await getAllWebhooks(token);

    //     if (webhook && webhook[0]) {
    //       setWebhookIdentifier(webhook[0].key.slice(0, 2));
    //       setWebhookKey(webhook[0].key);
    //       setCreatedAt(webhook[0].createdAt);
    //     }
    //   } catch (error) {
    //     console.error('Failed to fetch webhooks for user', error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    if (data.length < 1) {
      return (
        <Button
          className="dib mw-3"
          disabled={isCreating}
          theme="primary"
          small={false}
          id="new-webhook"
          icon={<Add20 fill="white" />}
          onClick={createWebhook}
          text="Create Task Webhook"
        />
      );
    }

    return (
      <>
        {data.map(webhook => {
          const onCopyWebhook = () => handleCopyToClipboard(webhook.key, 'tasks');
          return (
            <div key={webhook.key} className="bg-near-white ph2 pv3 flex items-center justify-evenly mb2">
              <div className={cx('w-100 flex items-center justify-evenly')}>
                <p className="pl2 ta-left fs-xs mv0">{format(new Date(webhook.createdAt), 'MM/d/uuuu')}</p>

                <p className="mv0 fs-s mv0 ph3 truncate-l">
                  {process.env.NODE_ENV === 'development'
                    ? `${process.env.REACT_APP_BASE_URL.replace('/#', '')}/webhook/v1/tasks/`
                    : null}
                  {isCreating ? '....' : `${webhook.key.slice(0, 8)}****`}
                </p>

                <div className="flex items-stretch justify-even ml-auto">
                  <button onClick={onCopyWebhook} className="fs-s ph2 bg-transparent flex items-center justify-center">
                    <Copy16 fill="currentColor" className="ml1 mr2" width={12} />
                    <span className="i18n pre">Copy URL</span>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const taskWebhooks = webhooks.filter(hook => hook.webhookType === 'tasks');
  const rcaWebhooks = webhooks.filter(hook => hook.webhookType === 'trees');
  const createTreeWebhooks = webhooks.filter(hook => hook.webhookType === 'newTree');

  return (
    <Page
      title={state.getIn(['organization', 'companyName'])}
      // className={computedStyles}
      state={state}
      dispatch={dispatch}
      loading={loading && !fetchedWebhooks}
    >
      <div className="w-75 content">
        <div className="flex flex-column">
          <header className="flex items-start flex-column">
            <div className="flex flex-column items-start w-100">
              <h1 className="f2 fw7 mv0">Webhooks</h1>
              <a
                className="flex items-center link f6 blue pv1 ph1"
                target="_blank"
                rel="nofollow"
                href="https://easyrca.com/learn?faq=reporting#power-bi"
              >
                <Launch16 color="blue" className="mr1" />
                Finish configuring
              </a>
            </div>

            <p className="f5 fw4">
              Your organization can use the RCA and task webhooks to bring your EasyRCA data into Power BI for more
              detailed analytics.
              <br />
              You can use the New Tree webhook to create new RCAs directly from your CMMS system.
            </p>
            <p className="f5 fw4">
              For assistance getting set up, contact{' '}
              <a className="link blue underline fw5" href="mailto:support@reliability.com">
                support@reliability.com
              </a>
            </p>
          </header>

          <div className="w-50 mb3">
            <h2>RCA Webhooks</h2>
            <RCAWebhooks data={rcaWebhooks} />
          </div>
          <div className="w-50 mb3">
            <h2>Task Webhooks</h2>
            <TaskWebhooks data={taskWebhooks} />
          </div>

          <div className="w-50">
            <h2>New Tree Webhook</h2>
            <CreateTreeWebhooks data={createTreeWebhooks} />
          </div>
        </div>
      </div>
    </Page>
  );
};
