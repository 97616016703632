import React from 'react';
import shortid from 'shortid';
import { Lightbox } from '../Lightbox';
import { useNodeContentForType } from '../../hooks';

import styles from './styles.module.css';

const AttachmentHeader = ({ data }) => {
  return (
    <div className={styles.AttachmentHeader}>
      <div className={styles.attachmentHeaderMeta}>
        <span className={styles.attachmentFilename}>{data['data-meta'].filename}</span>
        <span className={styles.attachmentType}>({data['data-meta'].contentType})</span>
      </div>
    </div>
  );
};

const AttachmentFooter = ({ data }) => {
  const nodeContent = useNodeContentForType({
    type: data['data-meta'].node ? data['data-meta'].node.type : '',
  });

  if (nodeContent) {
    return (
      <div className={styles.AttachmentFooter}>
        <div className="flex-column align-center justify-start">
          <span className={styles.attachmentColumnLabel}>
            <nodeContent.icon
              style={{
                marginRight: 3,
                width: 18,
                height: 18,
                position: 'relative',
                top: 4,
              }}
            />
            {nodeContent.title}
          </span>
          <p className="pt3 ma0">{data['data-meta'].node ? data['data-meta'].node.text : 'N/A'}</p>
        </div>
      </div>
    );
  }

  return null;
};

export const AttachmentGallery = ({ files }) => {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const gotoPrevious = () => currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);

  const gotoNext = () => currentImageIndex + 1 < files.length && setCurrentImageIndex(currentImageIndex + 1);
  const imgObj = files[currentImageIndex];

  return (
    <div className={styles.Gallery}>
      {files.map((file, i) => (
        <div key={shortid()} className={styles.GalleryItem}>
          {file && (
            <button
              onClick={() => {
                setCurrentImageIndex(i);
                setIsOpen(true);
              }}
            >
              <img className={styles.GalleryItemImage} src={file.src} alt={file.alt} loading={file.loading} />
            </button>
          )}
        </div>
      ))}
      <Lightbox
        isOpen={isOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        onClose={() => setIsOpen(false)}
        images={files}
        currentIndex={currentImageIndex}
        renderFooter={() => <AttachmentFooter data={imgObj} />}
        renderHeader={() => <AttachmentHeader data={imgObj} />}
        renderPrevButton={() => null}
        renderNextButton={() => null}
        renderImageOverlay={() => null}
        /* Add styling */
        /* Handle closing */
        /* Use single or double click to zoom */
        singleClickToZoom
        /* react-spring config for open/close animation */
        pageTransitionConfig={{
          from: { /*transform: 'scale(0.75)',*/ opacity: 0 },
          enter: { /*transform: 'scale(1)',*/ opacity: 1 },
          leave: { /*transform: 'scale(0.75)', */ opacity: 0 },
          config: { mass: 0.5, tension: 320, friction: 32 },
        }}
      />
    </div>
  );
};

AttachmentGallery.displayName = 'AttachmentGallery';
