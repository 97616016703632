import React, { useState, useRef, useLayoutEffect } from 'react';
import { List, Map } from 'immutable';

export const NodeInputPopover = ({ state, dispatch }) => {
  const type = state.getIn(['tree', 'view', 'selected', 'type']);
  const coords = state.getIn(['tree', 'view', 'selected', 'coordinates']);
  const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
  const elem = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());

  const [text, setText] = useState(elem.get('text', ''));

  const [containerStyle, setContainerStyle] = useState({ display: 'none' });

  const ref = useRef(null);

  const onKeyDown = e => {
    const key = e.key;
    switch (key) {
      case 'Enter':
        dispatch(Map({ type: 'UPDATE_TEXT', path, text }));
        dispatch(Map({ type: 'UNSELECT' }));
        dispatch(Map({ type: 'CREATE_CHILD', path }));
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'Tab':
        dispatch(Map({ type: 'UPDATE_TEXT', path, text }));
        dispatch(Map({ type: 'UNSELECT' }));
        dispatch(Map({ type: 'CREATE_CHILD', path: path.skipLast(2) }));
        e.preventDefault();
        e.stopPropagation();
        break;
      default:
        // no-op
        break;
    }
  };

  useLayoutEffect(() => {
    let id;
    if (type === 'DOUBLECLICK' && coords) {
      const wDefault = 257;
      const hDefault = 235;
      const x1 = coords.get('x1');
      const y1 = coords.get('y1');
      const w = coords.get('w');
      const h = coords.get('h');
      const scale = Math.max(w / 250, h / 200);
      const xPad = 20;
      const yPad = 50;

      const wActual = wDefault - 2 * xPad;
      const hActual = hDefault - 2 * yPad;

      setContainerStyle({
        left: x1 - 3 + xPad * scale,
        top: y1 + yPad * scale,
        width: `${wActual}px`,
        height: `${hActual}px`,
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      });
      id = requestAnimationFrame(() => ref.current.focus());
    } else {
      setContainerStyle({ display: 'none' });
    }

    return () => {
      cancelAnimationFrame(id);
    };
  }, [coords, setContainerStyle, type]);

  const onChange = e => {
    const text = e.target.value.substring(0, 110);
    setText(text);
  };

  return (
    <div style={containerStyle} className={'z-2 absolute'}>
      <textarea
        key={path}
        ref={ref}
        value={text}
        onChange={onChange}
        onFocus={() => {
          setText(elem.get('text'));
          dispatch(Map({ type: 'CLEAR_TEXT', path }));
        }}
        onBlur={() => {
          dispatch(Map({ type: 'UPDATE_TEXT', path, text }));
          dispatch(Map({ type: 'UNSELECT' }));
        }}
        onKeyDown={onKeyDown}
        style={{ resize: 'none' }}
        className="h-100 w-100 outline-0 bg-transparent bn"
      />
    </div>
  );
};
