import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { List, Map } from 'immutable';
import { InProgress20, ErrorFilled24, Login20 } from '@carbon/icons-react';
import { Input } from './TextInputs';
import { Button } from './Buttons';

import logoSrc from './svgs/logo-v2.svg';
import { sendForgotPasswordRequest } from './Sync';

const salutations = List([
  "Let's get back to finding some roots.",
  "Let's get back to controlling the fix.",
  "Let's get you back to being proactive.",
  "Let's get you back to finding some ROI.",
  "Let's get back to PROACTing like a boss.", // Sebastian wanted this one
]);

const randomSalutation = () => salutations.get((Math.random() * salutations.size) | 0);

export const ForgotPassword = ({ state, dispatch }) => {
  const [model, setModel] = useState(
    Map({
      salutation: randomSalutation(),
      username: '',
    }),
  );
  const error = model.get('error');
  const submitting = model.get('submitting');
  const username = model.get('username');

  async function resetPassword(username, callback) {
    const results = await sendForgotPasswordRequest(username);
    if (!results.error) {
      setModel(model.set('username', ''));
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          duration: 8000,
          message: 'Reset instructions sent to the email provided.',
        }),
      );
    } else {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          duration: 8000,
          message: results.error,
        }),
      );
    }
  }
  const onSubmit = (e, callback) => {
    if (!username) {
      setModel(m => m.set('error', 'Your email is invalid.'));
    }
    resetPassword(username, (err, ws) => {
      if (err) {
        return err;
      } else {
        return ws;
      }
    });

    e.preventDefault();
  };
  return (
    <div className="h-100 w-100 flex flex-column justify-center items-center bg-grid pb4">
      <div className="ba bw2 pa4 b--navy bg-white mb4" style={{ width: '400px' }}>
        <div className="mb4">
          <img alt="easy rca logo" src={logoSrc} className="db" style={{ width: '200px' }} />
        </div>
        <div className="f3">Forgot Password</div>
        <div className="f5 black-60 mt1 mb4 lh-title">{model.get('salutation')}</div>
        <div
          className={classNames('bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red', {
            dn: !error,
            flex: error,
          })}
        >
          <ErrorFilled24 className="fill--red mr2" />
          <div className="flex-auto">{error}</div>
        </div>
        <div className="pt3">
          <form onSubmit={onSubmit} className="w-100 db flex flex-column">
            <Input
              autoFocus
              type="email"
              className="mb3"
              placeholder="Email Address"
              onChange={e => setModel(model.set('username', e.target.value))}
              value={model.get('username', '')}
            />
            <Button
              text="Submit"
              disabled={submitting}
              icon={
                submitting ? (
                  <InProgress20 className="spin white fill--white" />
                ) : (
                  <Login20 className="fill--white white" />
                )
              }
            />
          </form>
        </div>
      </div>
      <Link className="black underline link" to="/login">
        Back to Login
      </Link>
    </div>
  );
};
