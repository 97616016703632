import React, { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { Add24, CaretDown24, View20, ViewOff20, WarningFilled20 } from '@carbon/icons-react';
import { validate } from 'email-validator';
import Fuse from 'fuse.js';
import { Avatar } from './Nav';

const textCanvas = document.createElement('canvas');
export const getTextWidth = (text, px) => {
  const context = textCanvas.getContext('2d');
  context.font = `${px}px "IBM Plex Sans"`;
  const metrics = context.measureText(text);
  return Math.ceil(metrics.width) + 3;
};

const InputError = ({ text }) => {
  return <div className={classNames('mt1 red f6', { dn: !text })}>{text}</div>;
};

const noop = e => null;

export const Input = ({
  title,
  helper = '',
  autoFocus = false,
  type = 'text',
  placeholder = '',
  inverse = false,
  disabled = false,
  value,
  error = '',
  className = '',
  onChange,
  onFocus = noop,
  onBlur = noop,
  onKeyDown = noop,
  onKeyPress = noop,
  capitalize = false,
  ...props
}) => {
  const [model, setModel] = useState(Map({ focus: autoFocus }));
  const focused = model.get('focus');
  const errored = !!error;

  return (
    <div className={classNames('w-100', className)}>
      <div
        className={classNames('f5 mb1 select-none ttc', {
          'black-30': disabled,
          'black-80': !disabled && !inverse,
          white: (!disabled && inverse) || (disabled && inverse),
        })}
      >
        {title}
      </div>
      <div
        className={classNames('f6 mb1', {
          'black-20': disabled,
          'black-60': !disabled && !inverse,
          'white-80': (!disabled && inverse) || (disabled && inverse),
        })}
      >
        {helper}
      </div>
      <div className={'relative'}>
        <input
          className={classNames('bw1 pa2 w-100 bb', {
            'bg-black-10': disabled,
            'bg-black-05': !errored && !props.light,
            'bg-white': props.light && !disabled,
            'bg-white-20 white': inverse && !disabled,
            'bg-white-20 white o-50': inverse && disabled,
            'b--red': errored,
            'b--black-10 black-30': disabled && !inverse,
            'b--black-20': !disabled && !errored && !focused && !inverse,
            'b--white-70': (!disabled || disabled) && !errored && !focused && inverse,
            'b--blue': focused && !errored && !disabled,
            ttc: capitalize,
          })}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value ? value : ''}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={e => {
            setModel(model.set('focus', true));
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={e => {
            setModel(model.delete('focus'));
            if (onBlur) {
              onBlur(e);
            }
          }}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          {...props}
        />
        <div
          className={classNames('fill--red absolute right-0 pa2 pr2 z-1', {
            dib: errored,
            dn: !errored,
          })}
        >
          <WarningFilled20 />
        </div>
      </div>
      <InputError text={error} />
    </div>
  );
};

const UserAutocompleteOption = ({ state, dispatch, username, onClick, selected }) => {
  const [model, setModel] = useState(Map());

  const fullName = state.getIn(['users', username, 'fullName'], username);

  return (
    <div
      className={classNames('pa2 flex items-center trans w-100 br bl bb b--white-20', {
        'o-50': selected,
        'pointer o-50': model.get('hover'),
      })}
      onMouseEnter={() => setModel(model.set('hover', true))}
      onMouseLeave={() => setModel(model.delete('hover'))}
      onClick={e => onClick(username)}
    >
      <Avatar state={state} dispatch={dispatch} username={username} height={40} />
      <div className={'ml2 f6 white'}>
        <div className={'fw7'}>{fullName}</div>
        <div>{username}</div>
      </div>
    </div>
  );
};
export const AutocompleteInput = ({ state, dispatch, onSelect, value, onChange, internalOnly, ...props }) => {
  const users = state.get('users', Map());
  const fuseAutocomplete = useMemo(
    () =>
      new Fuse(
        users
          .valueSeq()
          .map(u => {
            const email = u.get('email');
            const username = u.get('username');
            const fullName = u.get('fullName');
            return { fullName, username, email };
          })
          .toJS(),
        {
          includeScore: false,
          keys: ['username', 'email', 'fullName'],
        },
      ),
    [users],
  );

  const userSearch = useCallback(
    user => List(fuseAutocomplete.search(user).map(x => x.item.username)).sort(),
    [fuseAutocomplete],
  );

  return (
    <div className={'relative'}>
      <Input
        {...props}
        onChange={e => {
          onChange(e);
        }}
        value={value}
      />
      <div
        className={classNames('absolute left-0 w-100 ba z-1 bg-black', {
          dn: !value,
        })}
        style={{ top: '100%' }}
      >
        {userSearch(value).map(u => (
          <UserAutocompleteOption
            state={state}
            dispatch={dispatch}
            key={u}
            username={u}
            onClick={text => {
              onSelect(text);
            }}
          />
        ))}
        {!internalOnly && value && validate(value) ? (
          <div
            className={classNames('pa2 flex items-center trans w-100 br bl bb b--white-20 hover pointer dim')}
            onClick={() => {
              onSelect(value);
            }}
          >
            <div style={{ height: '40px', width: '40px' }} className={'flex justify-center items-center'}>
              <div
                style={{ height: '34px', width: '34px' }}
                className={'bg-blue white fill--white flex justify-center items-center'}
              >
                <Add24 />
              </div>
            </div>
            <div className={'ml2 f6 white'}>
              <div className={'fw7'}>{value}</div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export const PasswordInput = ({ className, error, ...props }) => {
  const [model, setModel] = useState(Map());

  const showPassword = model.get('showPassword');

  return (
    <div className={classNames(className, 'w-100')}>
      <div className={'relative'}>
        <Input type={showPassword ? ' text' : 'password'} error={error} {...props} />
        <div
          className={classNames('absolute right-0 top-0 pa2 z-1', {
            dn: error,
            dib: !error,
          })}
          onMouseDown={() => setModel(model.set('showPassword', true))}
          onMouseUp={() => setModel(model.delete('showPassword'))}
        >
          {showPassword ? <ViewOff20 /> : <View20 />}
        </div>
      </div>
    </div>
  );
};

export const Textarea = ({
  title,
  helper = '',
  placeholder = '',
  onKeyDown = noop,
  error = '',
  value,
  autoFocus = false,
  className = '',
  inverse = false,
  disabled = false,
  onFocus = noop,
  onBlur = noop,
  onChange,
  ...props
}) => {
  const [model, setModel] = useState(Map({ focus: autoFocus }));
  const focused = model.get('focus');
  const errored = !!error;

  return (
    <div className={classNames(className, 'w-100')}>
      <div
        className={classNames('f5 mb1', {
          'black-30': disabled,
          'black-80': !disabled && !inverse,
          white: (!disabled && inverse) || (disabled && inverse),
        })}
      >
        {title}
      </div>
      <div
        className={classNames('f6 mb1', {
          'black-20': disabled,
          'black-60': !disabled && !inverse,
          'white-80': (!disabled && inverse) || (disabled && inverse),
        })}
      >
        {helper}
      </div>
      <div className={'relative'}>
        <textarea
          className={classNames('bw1 bt-0 br-0 bl-0 pa2 w-100 outline-0', {
            'bg-black-10': disabled,
            'bg-black-05': !errored && !props.light,
            'bg-white': props.light && !disabled,
            'bg-white-20 white': inverse && !disabled,
            'bg-white-20 white o-50': inverse && disabled,
            'b--red': errored,
            'b--black-10 black-30': disabled && !inverse,
            'b--black-20': !disabled && !errored && !focused && !inverse,
            'b--white-70': (!disabled || disabled) && !errored && !focused && inverse,
            'b--blue': focused && !errored && !disabled,
          })}
          style={{ resize: 'none' }}
          rows={4}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value ? value : ''}
          placeholder={placeholder}
          onChange={e => onChange(e)}
          onFocus={e => {
            setModel(model.set('focus', true));
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={e => {
            setModel(model.delete('focus'));
            if (onBlur) {
              onBlur(e);
            }
          }}
          onKeyDown={onKeyDown}
          {...props}
        />
        <div
          className={classNames('fill--red absolute right-0 pa2 pr2 z-1', {
            dib: errored,
            dn: !errored,
          })}
        >
          <WarningFilled20 />
        </div>
      </div>
      <InputError text={error} />
    </div>
  );
};

export const Select = ({
  options,
  disabled = false,
  title,
  value,
  onChange,
  inverse = false,
  className = '',
  inputClassName = '',
  style = {},
}) => {
  return (
    <div className={classNames(className)} style={style}>
      <div
        className={classNames('i18n f5 mb1', {
          'black-30': disabled && !inverse,
          'black-80': !disabled && !inverse,
          // white: (disabled && inverse) || (!disabled && inverse),
        })}
      >
        {title}
      </div>
      <div
        className={classNames('bb ph2 pv1 flex items-center relative', {
          'bg-white-20 white b--white-70': inverse,
          'o-50': disabled,
          'black bg-black-05 b--black-20': !inverse,
        })}
      >
        <div className={'flex-auto'}>{value}</div>
        <div className={''}>
          <CaretDown24
            className={classNames({
              'dark-gray fill--gray': !disabled,
              'light-gray fill--light-gray': disabled,
            })}
          />
        </div>
        <select
          disabled={disabled}
          value={value}
          onChange={onChange}
          className={classNames(
            'top-0 left-0 right-0 bottom-0 z-1 bg-trans absolute w-100 o-0',
            { pointer: !disabled, 'not-allowed': disabled },
            inputClassName,
          )}
        >
          {options.map((o, index) => (
            <option key={`${o}_${index}`} value={o}>
              {o}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
