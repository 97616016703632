import React, { useEffect, useState, useReducer, useRef } from 'react';
import classNames from 'classnames';
import { List, Map, Set } from 'immutable';
import { ErrorFilled24, WarningAltFilled24 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { validate } from 'email-validator';
import { trackEvent } from 'containers';
import { EVENTS } from '@interfaces';
import logoSrc from './svgs/logo.svg';
import { getStrength, triggerCode, createOrganization, storeToken, convertToMember } from './Sync';
import { Button } from './Buttons';
import { Input, PasswordInput } from './TextInputs';
import { Separator } from './Separator';
import msSigninSrc from './svgs/ms-signin.svg';

const backgroundChoices = ['bg-hex', 'bg-square', 'bg-grid', 'bg-cross', 'bg-grate', 'bg-shapes'];

export const randomBackgroundClass = () => {
  return backgroundChoices[(Math.random() * backgroundChoices.length) | 0];
};

const getInitialStateFromSearch = () => {
  const s = window.location.search.substring(1);
  return s ? Map(s.split('&').map(x => x.split('='))).map(v => decodeURIComponent(v)) : Map();
};

const EmailAndPassword = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const didMountRef = useRef();
  const inviteUUID = state.get('inviteUUID', '');
  const fullName = model.get('fullName', '');
  const phone = model.get('phone', '');
  const email = model.get('email', '');
  const password = model.get('password', '');
  const confirm = model.get('confirm', '');
  const error = model.get('error', '');
  const warning = model.get('warning', '');

  useEffect(() => {
    if (!didMountRef.current) {
      const urlParams = new URLSearchParams(window.location.search);
      const firstName = urlParams.get('firstName');
      const lastName = urlParams.get('lastName');
      const phone = urlParams.get('phone');
      let newModel = model;
      if (firstName && lastName) {
        newModel = newModel.set('fullName', `${firstName} ${lastName}`);
      } else if (firstName) {
        newModel = newModel.set('fullName', firstName);
      } else if (lastName) {
        newModel = newModel.set('fullName', lastName);
      }
      if (phone) {
        newModel = newModel.set('phone', phone);
      }
      setModel(newModel);
      didMountRef.current = true;
    }
  }, [model]);

  const isSubmittable =
    email && fullName && validate(email) && password && confirm && password.length > 7 && password === confirm;

  const onError = err => setModel(model.set('error', err));

  const onSubmit = () => {
    if (inviteUUID && password && password === confirm && password.length > 7) {
      dispatch(
        Map({
          type: 'CONVERT_TO_MEMBER',
          fullName,
          password,
          inviteUUID,
          onError,
        }),
      );
    } else if (isSubmittable) {
      dispatch(Map({ type: 'SET_USER_INFO', email, password, fullName, phone }));
    } else {
      onError(
        'All fields are required. Email address must be valid. Passwords must match and longer that 7 characters.',
      );
    }
  };

  return (
    <>
      <div className="ba bw2 b--navy pa4 ">
        <div className="mb4">
          <img alt="easy rca logo" src={logoSrc} className="db" style={{ width: '200px' }} />
        </div>

        <div className="f3">{inviteUUID ? 'Activate Account' : 'Create Account'}</div>
        <div className="f5 black-60 mb2 lh-copy">
          {inviteUUID ? 'This will create your password and add you to your organization.' : "Let's get you started."}
        </div>
        <div
          className={classNames('bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red', {
            dn: !error,
            flex: error,
          })}
        >
          <ErrorFilled24 className="fill--red w3 mr2" />
          <div className="flex-auto">{error}</div>
        </div>
        <div
          className={classNames('bl bw2 b--gold pa2 mb2 items-center justify-between gold fw6 bg-washed-yellow', {
            dn: !warning,
            flex: warning,
          })}
        >
          <WarningAltFilled24 className="fill--gold mr2" />
          <div className="flex-auto">{warning}</div>
        </div>

        <button
          className="db mt4"
          style={{ padding: 0, backgroundColor: 'transparent' }}
          onClick={() => {
            window.location = `${window.location.origin}/microsoft/login`;
          }}
        >
          <img src={msSigninSrc} />
        </button>

        <Separator className="mt3" />

        <div className="pt3">
          <form
            className="w-100 db flex flex-column"
            onSubmit={e => {
              onSubmit();
              e.preventDefault();
            }}
          >
            <Input
              placeholder="Full Name"
              autoFocus
              onChange={e => setModel(model.set('fullName', e.target.value))}
              className="mb2"
              capitalize
              value={fullName}
            />
            {!inviteUUID ? (
              <Input
                type="email"
                className="mb2"
                placeholder="Email Address"
                onChange={e => setModel(model.set('email', e.target.value))}
                value={email}
              />
            ) : (
              <div />
            )}
            <Input
              placeholder="Phone Number"
              onChange={e => setModel(model.set('phone', e.target.value))}
              className="mb2"
              value={phone}
            />
            <PasswordInput
              className="mb2"
              placeholder="Password"
              onChange={e => setModel(model.set('password', e.target.value))}
              value={password}
              onBlur={() => {
                if (password) {
                  getStrength(password, (err, res) => {
                    if (res) {
                      const {
                        feedback: { suggestions },
                      } = res;
                      if (suggestions.length > 0) {
                        setModel(model.set('warning', suggestions.join(' ')));
                        return;
                      }
                    }
                    setModel(model.delete('warning'));
                  });
                }
              }}
            />
            <PasswordInput
              placeholder="Confirm Password"
              onChange={e => setModel(model.set('confirm', e.target.value))}
              value={confirm}
              error={password !== confirm ? 'Passwords do not match' : null}
            />
            <Button className="mt4 fill--white white" text={inviteUUID ? 'Set Password' : 'Continue'} />
          </form>
          <Link
            className={classNames('blue underline link mt4', {
              dn: inviteUUID,
              db: !inviteUUID,
            })}
            to="/"
          >
            Login here if you have an account.
          </Link>
        </div>
      </div>
    </>
  );
};

const TeamMembers = ({ dispatch }) => {
  const [model, setModel] = useState(Map({ members: List(['']) }));
  const error = model.get('error');

  const onSubmit = () => {
    dispatch(
      Map({
        type: 'SET_MEMBERS',
        members: Set(model.get('members').filter(validate)),
      }),
    );
  };

  return (
    <div className="ba bw2 b--navy pa4">
      <div className="mb4">
        <img alt="easy rca logo" src={logoSrc} className="db" style={{ width: '200px' }} />
      </div>

      <div className="f3">Create Team</div>
      <div className="f5 black-60 mt1 mb4 lh-title">We&apos;ll invite them and add them to your organization.</div>

      <div
        className={classNames('bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red', {
          dn: !error,
          flex: error,
        })}
      >
        <ErrorFilled24 className="fill--red w3 mr2" />
        <div className="flex-auto">{error}</div>
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {model.get('members').map((v, i) => (
          <Input
            key={`m_${i}`}
            autoFocus={model.get('members').size - 1 === i}
            type="email"
            className="mb2 mt3"
            placeholder="Team Member"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setModel(model.update('members', m => m.push('')));
                e.preventDefault();
              }
            }}
            onChange={e => setModel(model.setIn(['members', i], e.target.value))}
            value={model.getIn(['members', i], '')}
          />
        ))}
        <div className="mt3 blue link pointer dim" onClick={() => setModel(model.update('members', m => m.push('')))}>
          + Add Another
        </div>
        <Button className="mt4 fill--white white" text="Invite" type="submit" />
        <div className="mt3 underline blue link pointer dim" onClick={onSubmit}>
          Skip this step
        </div>
        <div className="mt3 underline blue link pointer dim" onClick={() => dispatch(Map({ type: 'CANCEL_MEMBERS' }))}>
          Go Back
        </div>
      </form>
    </div>
  );
};

const Organization = ({ dispatch }) => {
  const [model, setModel] = useState(Map());
  const error = model.get('error');
  const companyName = model.get('companyName', '');

  const onSubmit = () => {
    if (companyName) {
      dispatch(Map({ type: 'SET_ORGANIZATION', organization: Map({ companyName }) }));
    } else {
      setModel(model.set('error', 'This field cannot be blank. Please enter a name for your institiution.'));
    }
  };

  return (
    <div className="ba bw2 b--navy pa4">
      <div className="mb4">
        <img alt="easy rca logo" src={logoSrc} className="db" style={{ width: '200px' }} />
      </div>

      <div className="f3">Create Organization</div>
      <div className="f5 black-60 mb4 lh-copy">We&apos;ll let you add others next.</div>
      <div
        className={classNames('bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red', {
          dn: !error,
          flex: error,
        })}
      >
        <ErrorFilled24 className="fill--red w3 mr2" />
        <div className="flex-auto">{error}</div>
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Input
          autoFocus
          type="text"
          className="mb2"
          placeholder="Company Name"
          onChange={e => setModel(model.set('companyName', e.target.value))}
          value={companyName}
        />
        <Button className="mt4" text="Create Organization" type="submit" />
        <div
          className="mt3 underline blue link pointer dim"
          onClick={() => dispatch(Map({ type: 'CANCEL_ORGANIZATION' }))}
        >
          Go Back
        </div>
      </form>
    </div>
  );
};

const VerificationCode = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const email = state.get('email');
  const error = state.get('createAccountError') || model.get('error', '');
  const code = model.get('code', '');

  useEffect(() => {
    triggerCode(email, err => {
      if (err) {
        if (err.includes('already exists')) {
          setModel(m =>
            m.set(
              'error',
              'Unable to send your email verification: email already exists. Please verify your email address is valid or contact support@reliability.com.',
            ),
          );
        } else {
          setModel(m =>
            m.set(
              'error',
              'Unable to send your email verification. Please verify your email address is valid or contact support@reliability.com.',
            ),
          );
        }
      }
    });
  }, [email, setModel]);

  const onClick = () => {
    setModel(m => m.set('verifying', true));
    dispatch(
      Map({
        type: 'SEND_INFO',
        code,
        onError: error => {
          dispatch(Map({ type: 'SET_INFO_ERROR', error }));
          setModel(m => m.delete('verifying'));
        },
      }),
    );
  };

  return (
    <div className="w-100 mw6 pb3">
      <div className="mb4">
        <img alt="easy rca logo" src={logoSrc} className="db" style={{ width: '200px' }} />
      </div>

      <div className="f3 mb1">Last step: Verify your Email</div>
      <div className="f5 black-60 mb4 lh-copy">We sent a code via email. Paste that here.</div>

      <div
        className={classNames('bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red', {
          dn: !error,
          flex: error,
        })}
      >
        <ErrorFilled24 className="fill--red w3 mr2" />
        <div className="flex-auto">{error}</div>
      </div>
      <div>
        <Input
          autoFocus={false}
          type="text"
          className="mb2"
          placeholder="Verification Code"
          onChange={e => setModel(model.set('code', e.target.value))}
          value={code}
        />
        <div className="mt4 f6 lh-copy fw3">
          By clicking below, you are indicating that you have read the&nbsp;
          <a
            className="blue fw4"
            href="https://reliability-dot-com.s3.amazonaws.com/EasyRCA_EULA_Agreement+July+2020.pdf"
          >
            End-user License Agreement
          </a>
          &nbsp;and agree.
        </div>
        <Button
          disabled={model.get('verifying')}
          className="mt3"
          id="submit-new-account"
          text="Finish and Agree"
          onClick={onClick}
        />
        <div className="mt3 underline blue link pointer dim" onClick={() => dispatch(Map({ type: 'CANCEL_CODE' }))}>
          Go Back
        </div>
      </div>
    </div>
  );
};

const formReducer = (state, action) => {
  const type = action.get('type');

  switch (type) {
    case 'EMAIL_AND_PASSWORD':
      return state.merge(action.delete('type'));

    case 'SET_USER_INFO':
      return state.merge(action.delete('type')).set('view', 'ORG');

    case 'SET_ORGANIZATION':
      return state.set('companyName', action.getIn(['organization', 'companyName'])).set('view', 'MEMBERS');

    case 'CONVERT_TO_MEMBER': {
      const inviteUUID = action.get('inviteUUID');
      const fullName = action.get('fullName');
      const password = action.get('password');
      const onError = action.get('onError');

      convertToMember(inviteUUID, fullName, password, (err, res) => {
        if (err) {
          onError(err);
        } else {
          if (!res) {
            onError('Your user account has been deactivated or this invitation has expired.');
          } else {
            storeToken(res.username, res.token);
            // adios
            window.location.replace('/');
          }
        }
      });
      return state;
    }

    case 'SET_MEMBERS':
      return state.set('members', action.get('members')).set('view', 'code');

    case 'SET_INFO_ERROR':
      return state.set('createAccountError', action.get('error'));

    case 'SEND_INFO': {
      const email = state.get('email');
      const password = state.get('password');
      const fullName = state.get('fullName');
      const phone = state.get('phone');
      const companyName = state.get('companyName');
      const members = state.get('members');
      const code = action.get('code');
      const onError = action.get('onError');
      createOrganization(email, fullName, phone, password, companyName, members, code, (err, loginToken) => {
        if (err) {
          onError(err);
        } else {
          storeToken(email, loginToken.token);
          trackEvent(EVENTS.SIGNUP, { Username: email });
          window.location.replace('/');
        }
      });
      return state;
    }

    case 'CANCEL_ORGANIZATION':
      return state.set('view', 'USER');

    case 'CANCEL_MEMBERS':
      return state.set('view', 'ORG');

    case 'CANCEL_CODE':
      return state.set('view', 'MEMBERS');

    default:
      return state;
  }
};

export const CreateAccount = () => {
  const [formState, formDispatch] = useReducer(formReducer, Map(getInitialStateFromSearch().set('view', 'USER')));
  const view = formState.get('view');

  return (
    <div className={classNames('h-100 w-100 flex justify-center items-center bg-grid pb4')}>
      <div className="bg-white mb4" style={{ width: '400px' }}>
        {view === 'USER' ? (
          <EmailAndPassword state={formState} dispatch={formDispatch} />
        ) : view === 'ORG' ? (
          <Organization state={formState} dispatch={formDispatch} />
        ) : view === 'MEMBERS' ? (
          <TeamMembers state={formState} dispatch={formDispatch} />
        ) : (
          <VerificationCode state={formState} dispatch={formDispatch} />
        )}
      </div>
    </div>
  );
};
