import React from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { animated, config, useSpring } from '@react-spring/web';

import shortid from 'shortid';
import styles from './styles.module.css';

export const Overlay: React.FC<{
  onClick?: () => void;
  visible?: boolean;
  duration?: number;
}> = ({ onClick }) => {
  const overlaySpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.stiff,
  });

  const ref = useDetectClickOutside({
    triggerKeys: ['Escape'],
    disableClick: true,
    onTriggered: () => {
      if (onClick) {
        return onClick();
      }
    },
  });

  return <animated.div ref={ref} key={shortid()} className={styles['overlay']} style={overlaySpring} />;
};
