// NB: These are case sensitive. A variation in case is a new word.
// Keep them in alphabetical order.
export const i18nStrings = [
  'Add to Template Library',
  'Add Child',
  'Add New Member',
  'All',
  'Analysis',
  'Available Users',
  'Analysis Assistant',
  'Appearance Options',
  'Archive',
  'Assign Admin access',
  'Attachments',
  'Class',
  'Code',
  'Complete',
  'Connect',
  'Copy',
  'Customer Impact',
  'Cut',
  'Decrease Font Size',
  'Delete',
  'Department',
  'Disable Admin access',
  'Download Image',
  'Edit User',
  'Equipment',
  'Environmental Impact',
  'Event Information',
  'Facility',
  'Frequency per Year',
  'Group',
  'Grant a license',
  'Hide',
  'In Process',
  'Increase Font Size',
  'Injury Report',
  'Insert Template',
  'Labor/Time Cost',
  'Left-to-right',
  'Mark Not True',
  'Mark True',
  'Mark Unverified',
  'Mark Verified',
  'Members',
  'N/A',
  'No Problem Statement',
  'Organize',
  'Pending',
  'Paste',
  'Production/Schedule Cost',
  'Property/Equipment Cost',
  'Quick Launch RCA',
  'Really delete?',
  'Redo',
  'Remove from Template Library',
  'Remove user license',
  'Resend Invitation Email',
  'Review',
  'Send this user a password reset email',
  'Safety Impact',
  'Successfully set status to',
  'Summary of Findings',
  'Site',
  'Top-to-bottom',
  'Toggle Grid',
  'Undo',
  'Untitled',
  'View Attachments',
  'View-only access not allowed for this tree.',
  'View Report',
];
