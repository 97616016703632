import React from 'react';
import { List } from 'immutable';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

type AvatarProps = {
  name: string;
  height?: number;
  fontSize?: number;
  disableTooltip?: boolean;
  borderRadius?: string;
};

const colors = List([
  'darkRed',
  'orange',
  'purple',
  'rebeccaPurple',
  'mediumSeaGreen',
  'green',
  'navy',
  'cornflowerBlue',
  'mediumslateblue',
]);

function getHash(s: string) {
  let hashInt = 0;
  let i;
  let chr: number | null = null;

  if (!s || !s.length) {
    return null;
  }

  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hashInt = (hashInt << 5) - hashInt + chr;
    hashInt |= 0;
  }

  return hashInt;
}

function nameToLetters(name: string) {
  if (!name || name === 'null null' || !name.split) {
    return '..';
  }

  return name
    .split(' ')
    .map(x => x[0])
    .join('')
    .toUpperCase()
    .substring(0, 3);
}

function nameToColor(name: string) {
  if (!name || name === 'null null') return 'dimgray';

  const hash = getHash(name) || 0;
  const modulo = hash % colors.size;
  const color = colors.get(modulo);

  return color;
}

export const Avatar: React.FC<AvatarProps> = ({
  name,
  height = 36,
  fontSize = 16,
  borderRadius = '2px',
  disableTooltip = false,
}) => {
  const styles = React.useMemo(
    () => ({
      height: `${height}px`,
      width: `${height}px`,
      minWidth: `${height}px`,
      fontSize: `${fontSize}px`,
      backgroundColor: `${nameToColor(name)}`,
      borderRadius,
      overflow: 'hidden',
    }),
    [height, fontSize, name],
  );

  React.useEffect(() => {
    if (disableTooltip) return;

    ReactTooltip.rebuild();
  }, [disableTooltip]);

  return (
    <div data-tip={name} data-place="top" data-class="tooltip-zindex-fix" data-delay-show={250} style={styles}>
      <div className={cx('h-100 w-100 white flex justify-center items-center fw7')}>{nameToLetters(name)}</div>
    </div>
  );
};
