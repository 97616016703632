import React, { CSSProperties } from 'react';
import tinycolor from 'tinycolor2';
import cx from 'classnames';

import styles from './styles.module.css';

export const Chip: React.FC<{
  label: string;
  className?: string;
  color?: string;
  borderWidth?: number;
  margin?: number;
  fontSize?: string;
  style?: CSSProperties;
}> = ({ label, className, style, color, margin, fontSize }) => {
  const selectedColor = color || 'black';
  const colorVariableString = `var(--${selectedColor})`;
  const subtleColor = tinycolor(selectedColor).setAlpha(0.6).toString();
  return (
    <div
      className={cx(styles.Chip, className)}
      style={{
        border: `${1}px solid ${subtleColor}`,
        color: colorVariableString,
        margin: margin || 0,
        fontSize: fontSize || '0.7rem',
        fontWeight: 600,
        letterSpacing: '-0.1px',
        ...style,
      }}
    >
      {label}
    </div>
  );
};
