import { Dispatch, useState } from 'react';
import { Map } from 'immutable';
import { Link, useLocation } from 'react-router-dom';
import { CaretDown16, CaretUp16 } from '@carbon/icons-react';
import { useDetectClickOutside } from 'react-detect-click-outside';

export const CreateRCAButton = ({
  dispatch,
  allowAiOptions = false,
  ...props
}: {
  allowAiOptions?: boolean;
  dispatch: Dispatch<Map<string, unknown> | Record<string, unknown>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setIsOpen(false);
    },
  });

  const navigateToGenerateRcaTurbo = () => {
    window.location.href = `/v2/generate-rca-turbo?from=${location.pathname}`;
  };

  return (
    <div className="relative" ref={ref}>
      <button
        onClick={() => setIsOpen(true)}
        className="btn ph3 pv2 f5 flex items-center justify-center bg-blue b--transparent hover-bg-dark-blue white br0"
        style={{ minHeight: 42 }}
      >
        Create RCA
        <div className="ml2">
          {isOpen ? (
            <CaretUp16 width={16} height={16} fill="white" color="white" />
          ) : (
            <CaretDown16 width={16} height={16} fill="white" color="white" />
          )}
        </div>
      </button>
      {isOpen ? (
        <div
          className="shadow-2 absolute left-0 bg-white z-100 br1 overflow-hidden"
          style={{ top: '120%', width: '150%' }}
        >
          <Link
            to="/new-analysis/type"
            className="hover-bg-light-gray black no-underline bg-transparent w-100 pv2 ph3 bb b--black-10 flex items-center justify-start"
          >
            RCA Wizard
          </Link>
          <Link
            to="/templates"
            className="hover-bg-light-gray black no-underline bg-transparent w-100 pv2 ph3 bb b--black-10 flex items-center justify-start"
          >
            Templates
          </Link>
          <button
            onClick={() => dispatch(Map({ type: 'NEW_TREE', tree: Map({ methodology: 'PROACT' }) }))}
            className="hover-bg-light-gray bg-transparent w-100 pv2 ph3 bb b--black-10 flex items-center justify-start"
          >
            PROACT
          </button>
          <button
            onClick={() => dispatch(Map({ type: 'NEW_TREE', tree: Map({ methodology: 'FISHBONE' }) }))}
            className="hover-bg-light-gray bg-transparent w-100 pv2 ph3 bb b--black-10 flex items-center justify-start"
          >
            Fishbone
          </button>
          <button
            onClick={() => dispatch(Map({ type: 'NEW_TREE', tree: Map({ methodology: '5WHYS' }) }))}
            className="hover-bg-light-gray bg-transparent w-100 pv2 ph3 flex items-center justify-start"
          >
            5 Why's
          </button>
          {allowAiOptions && (
            <button
              onClick={navigateToGenerateRcaTurbo}
              className="hover-bg-light-gray bg-transparent w-100 pv2 ph3 bt b--black-10 flex items-center justify-start"
            >
              Generate RCA Turbo
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};
