import { DispatchableAction } from '@interfaces';
import React from 'react';

type MessageHandlers = {
  [messageType: string]: (msg: MessageEvent['data']) => void;
};

type SocketContextValue = {
  ws?: WebSocket;
  send: (message: DispatchableAction) => void;
  close: () => void;
  status: number;
  addMessageHandlers: (handlers: MessageHandlers) => void;
  isConnected: boolean;
};

const SocketContext = React.createContext<SocketContextValue | null>(null);

type SocketProviderProps = {
  username?: string | null;
  token?: string | null;
  url?: string;
  rootEventHandlers?: {
    open?: (event: WebSocketEventMap['open']) => void;
    close?: (event: WebSocketEventMap['close']) => void;
    error?: (event: WebSocketEventMap['error']) => void;
  };
  messageHandlers?: [{ message: string; handler: (event: WebSocketEventMap['message']) => void }];
};

const getEndpoint = async () => {
  try {
    const response = await fetch('/ws-endpoint', {
      method: 'GET',
    });
    const defaultUrl = `wss://${window.location.hostname}/ws`;

    // production, use default socketUrl
    if (response.status === 204) {
      return defaultUrl;
    }

    // request succeeded, return url
    if (response.status === 200) {
      const url = await response.text();
      return url;
    }

    return defaultUrl;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to get socket endpoint');
  }
};

const startHeartbeat = ({ socket }: { socket?: WebSocket; interval?: number }) => {
  if (!socket) {
    console.info('[startHeartbeat]: no socket provided');
    return;
  }

  const pingServiceId = setInterval(() => {
    if (socket.readyState === WebSocket.OPEN) {
      return socket.send(JSON.stringify({ type: 'ONLINE' }));
    }

    return clearInterval(pingServiceId);
  }, 20000);
};

const createSocketConnection = async ({
  token,
  username,
  setIsConnected,
}: {
  token?: string;
  username: string;
  setIsConnected: (s: boolean) => void;
}) => {
  if (!username) {
    throw new Error('[SocketContext]: createSocketConnection called without username');
  }
  if (!token) {
    throw new Error('[SocketContext]: createSocketConnection without authorization');
  }

  try {
    const url = await getEndpoint();
    const socket = new WebSocket(`${url}?username=${encodeURIComponent(username)}&token=${encodeURIComponent(token)}`);
    const operationTimer = setTimeout(() => {
      throw new Error('createSocketConnection timed out');
    }, 8000);

    socket?.addEventListener(
      'open',
      () => {
        console.info('[SocketContext]: socket opened successfully; clearing timeout');
        setIsConnected(true);
        clearTimeout(operationTimer);

        // handle first 'HELLO' message to begin pinging backend
        socket?.addEventListener(
          'message',
          message => {
            try {
              const payload = JSON.parse(message.data);
              // @TODO move expanding set of known message handlers outside of socket context
              // @TODO (cont) EXPIRED_USER and BAD_TOKEN responses could both be set up as part of AuthContext

              if (payload.type === 'HELLO') {
                startHeartbeat({ socket });
              } else if (payload.type === 'EXPIRED_USER') {
                setIsConnected(false);
                socket?.close();

                // @TODO remove use of window.location here
                // @TODO (cont) socket context should be free of concerns regarding what happens in response to messages
                // @ts-ignore
                window.location.replace(expiredRedirectURL); // See index.html
              } else if (payload.type === 'BAD_TOKEN') {
                setIsConnected(false);
                socket?.close();

                // @TODO remove use of window.location here
                // @TODO (cont) socket context should be free of concerns regarding what happens in response to messages
                window.location.replace('/');
              }
            } catch (error) {
              console.error('[SocketContext]: heartbeat handler failed to parse message', error);
            }
          },
          { once: true },
        );
      },
      { once: true },
    );

    socket?.addEventListener(
      'error',
      err => {
        setIsConnected(false);
        console.info('[SocketContext]: connection attempt failed; clearing timeout', err);
        clearTimeout(operationTimer);
        socket?.close();
      },
      { once: true },
    );

    socket?.addEventListener(
      'close',
      () => {
        setIsConnected(false);
      },
      { once: true },
    );

    return socket;
  } catch (error) {
    console.error(error);
  }
};

export const SocketProvider: React.FC<SocketProviderProps> = ({ children, rootEventHandlers = {}, ...props }) => {
  const [socket, setSocket] = React.useState<WebSocket | undefined>();
  const [isConnected, setIsConnected] = React.useState(false);

  const handleCloseSocket = React.useCallback(
    (code?: number, reason?: string) => {
      if (socket) {
        setIsConnected(false);
        return socket.close(code || 1000, reason || 'manually closing socket');
      }

      return;
    },
    [socket],
  );

  const rootMessageHandler = React.useCallback((msg, handlers: MessageHandlers) => {
    try {
      const parsedBody = JSON.parse(msg.data);
      const targetEvents = Object.keys(handlers);

      if (!targetEvents.includes(parsedBody.type)) {
        // @NOTE we need handling for { error: 'xxx' }  messages
        // don't print anything to console if the message type is 'ONLINE'
        if (parsedBody.type !== 'ONLINE') {
          // console.info('No handler found for action', {
          //   action: parsedBody,
          //   type: parsedBody.type,
          // });
        }

        return;
      }

      return handlers[parsedBody.type](parsedBody);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const addMessageHandlers = React.useCallback(
    (handlers: MessageHandlers) => {
      if (!socket) {
        return console.info('[SocketContext]: No connected socket to attach received handlers to', handlers);
      }

      try {
        socket.removeEventListener('message', msg => rootMessageHandler(msg, handlers));

        socket.addEventListener('message', msg => rootMessageHandler(msg, handlers));
      } catch (error) {
        console.info('[SocketContext]: failed to add message handlers for', handlers);
        console.error(error);
      }
    },
    [socket, rootMessageHandler],
  );

  React.useEffect(() => {
    async function handleCreateSocket() {
      if (!props.username) {
        console.info('[SocketContext]: no username provided; skipping socket creation');
        return;
      }

      if (!props.token) {
        console.info('[SocketContext]: no token provided; skipping socket creation');
        return;
      }

      // if (socket?.readyState === WebSocket.OPEN) {
      //   console.info(
      //     '[SocketContext]: socket already connected; closing and recreating',
      //   );
      //   setIsConnected(false);
      //   socket.close(
      //     1000,
      //     'username or token changed; reinitializing connection',
      //   );
      // }

      if (socket && socket?.readyState === WebSocket.OPEN) {
        console.info('[SocketContext]: socket already connected; refusing to open new connection');
        return;
      }

      const connection = await createSocketConnection({
        username: props.username,
        token: props.token,
        setIsConnected,
      });

      if (connection) {
        setSocket(connection);

        /**
         * Root event handling
         * attach any handlers received via rootEventHandlers prop
         */
        if (Object.keys(rootEventHandlers).length) {
          Object.keys(rootEventHandlers).forEach(eventType => {
            connection.addEventListener(eventType, e => rootEventHandlers[eventType](e));
          });
        }
      }

      if (connection?.readyState === WebSocket.OPEN) {
        setIsConnected(true);
      }
    }

    if (!socket) {
      handleCreateSocket();
    }

    // clean up connection on unmounted / re-run
    return () => {
      console.info('[SocketContext]: handleCreateSocket effect cleaning up...');
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.token, props.username]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const send = React.useCallback(
    function send(message: DispatchableAction) {
      if (!socket || !socket.OPEN) {
        // console.info(
        //   '[SocketContext]: attempted to dispatch the following, but socket is not ready',
        //   message,
        // );

        return;
      }

      // console.info(`[SocketContext]: ${message.type}`);

      try {
        const payload = JSON.stringify(message);

        console.info(`[${message.type}]:`, payload);

        socket.send(payload);
      } catch (error) {
        console.error(`[SocketContext]: failed to send: ${JSON.stringify(message)}`, error);
      }
    },
    [socket],
  );

  const value = React.useMemo(
    () => ({
      ws: socket,
      send,
      close: handleCloseSocket,
      addMessageHandlers,
      status: socket?.readyState || 0,
      isConnected,
    }),
    [socket, send, isConnected, addMessageHandlers, handleCloseSocket],
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export const useSocket = () => {
  const context = React.useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return context as SocketContextValue;
};
