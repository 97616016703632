import React, { ReactElement } from 'react';
import cx from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { StylingProps } from '@interfaces';

import { PopoverProvider, usePopover } from './PopoverContext';
import * as PopoverContent from './content';

import styles from './styles.module.css';

interface PopoverCompoundMembers {
  Content: React.FC<StylingProps & PopoverContentProps>;
  Trigger: React.FC<StylingProps>;
}

type ContentTypes = 'new-form' | 'edit-form' | 'confirmation' | 'unstyled';

type PopoverContentProps =
  | {
      type?: ContentTypes;
      title?: never;
      subtitle?: never;
      message?: never;
    }
  | {
      type: 'new-form';
      onSubmit: (v?: string | number) => void;
      inputType?: string;
      title?: string;
      subtitle?: string;
    }
  | {
      type: 'edit-form';
      title?: string;
      subtitle?: string;
      onSubmit: (v?: string | number) => void;
      inputType?: string;
      initialValue?: number | string;
    }
  | {
      type: 'confirmation';
      message?: string;
      warning?: string;
      title?: string;
    };

const Content: React.FC<PopoverContent.CommonContentProps & PopoverContentProps> = ({
  type = 'unstyled',
  ...props
}) => {
  const { isVisible } = usePopover();

  switch (type) {
    case 'confirmation':
      return <PopoverContent.Confirmation isVisible={isVisible} {...props} />;
    case 'edit-form':
      return <PopoverContent.EditForm isVisible={isVisible} {...props} />;
    case 'new-form':
      return <PopoverContent.NewForm isVisible={isVisible} {...props} />;
    case 'unstyled':
    default:
      return <PopoverContent.Unstyled isVisible={isVisible} {...props} />;
  }
};

const Trigger: React.FC<StylingProps> = ({ children, className, ...props }) => {
  const { toggle, onToggle } = usePopover();

  const handleToggle = () => {
    onToggle();
    return toggle();
  };

  if (React.isValidElement(children)) {
    const RenderedTrigger = React.cloneElement(children as ReactElement, {
      onClick: handleToggle,
      ...props,
    });

    return RenderedTrigger;
  }

  return (
    <button onClick={handleToggle} className={cx(styles.popoverTrigger, className)} {...props}>
      {children}
    </button>
  );
};

const ClickDetector: React.FC<
  StylingProps & {
    onTriggered?: () => void;
  }
> = ({ children, style, className, onTriggered, ...props }) => {
  const { hide } = usePopover();
  const detectClicksRef = useDetectClickOutside({
    onTriggered: () => {
      console.warn('popover click detector triggered...');
      if (onTriggered) {
        onTriggered();
      }

      return hide();
    },
  });

  return (
    <div ref={detectClicksRef} style={style} className={className} {...props}>
      {children}
    </div>
  );
};

const Popover: React.FC<
  StylingProps & {
    onDismissed?: () => void;
    onToggle?: () => void;
  }
> &
  PopoverCompoundMembers = ({ children, style, width, height, onDismissed, onToggle, className, ...props }) => {
  return (
    <PopoverProvider onToggle={onToggle}>
      <ClickDetector
        onTriggered={onDismissed}
        style={{ width: width || 'auto', height: height || 'auto', ...style }}
        className={cx(styles.popoverContainer, className)}
        {...props}
      >
        {children}
      </ClickDetector>
    </PopoverProvider>
  );
};

Popover.Content = Content;
Popover.Trigger = Trigger;

export { Popover };
