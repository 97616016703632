import cx from 'classnames';
import { FC } from 'react';

type CalloutProps = {
  type: 'info' | 'warn' | 'error' | 'success';
  className?: string;
};

export const Callout: FC<CalloutProps> = ({ type = 'info', children, className }) => {
  return (
    <div
      className={cx(
        'flex items-center justify-start pv3 ph3 black ml-auto mr-auto br1 mv2',
        { 'bg--powder-blue': type === 'info' },
        className,
      )}
      style={{ columnGap: 10 }}
    >
      <div className="w-100 flex items-center justify-evenly">{children}</div>
    </div>
  );
};
