import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

type ToolbarProps = {
  renderItems: (p: { isLoading?: boolean } & Record<string, unknown>) => JSX.Element;
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  offset?: number;
  fixed?: boolean;
};

export const Toolbar: React.FC<ToolbarProps> = ({
  title,
  subtitle,
  isLoading = false,
  fixed = false,
  offset = 0,
  renderItems: Items,
}) => {
  return (
    <div className={cx(styles['toolbar'], { [styles['fixed']]: fixed })} style={{ top: offset }}>
      <div className={cx(styles['content'], { [styles['fixedContent']]: fixed })}>
        <div className={styles['header']}>
          <h1 className={cx(styles['heading'], 'i18n')}>{title}</h1>
          {subtitle ? <h5 className={cx(styles['subheading'], 'i18n')}>{subtitle}</h5> : null}
        </div>
        <div className={styles['filters']}>
          <Items isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};
