import React, { MouseEventHandler, useRef, useState } from 'react';

type UseBlobDownload = {
  datatype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' | 'text/plain';
  filename: string;
  blob: string;
  onError?: (error?: any) => void;
  onSave?: () => void;
};

export function useBlobDownload({ datatype, filename, blob, onError, onSave }: UseBlobDownload) {
  React.useEffect(() => {
    if (!document || !window || !blob) return;

    const url = `data:${datatype};base64,${blob}`;

    fetch(url)
      .then(x => x.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');

        downloadLink.setAttribute('href', blobUrl);
        downloadLink.setAttribute('download', filename);
        downloadLink.style.display = 'none';

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        window.URL.revokeObjectURL(blobUrl);
      })
      .then(() => {
        if (onSave) {
          onSave();
        }
      })
      .catch(e => {
        console.error('failed to download blob', e);

        if (onError) {
          onError(e);
        }
      });

    return () => {};
  }, [document, window, blob]);
}
