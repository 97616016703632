import React from 'react';
import cx from 'classnames';
import { ValidObject } from '@interfaces';
import { TableInstance } from 'react-table';
import shortid from 'shortid';

type TableFiltersProps<D extends ValidObject> = Pick<TableInstance<D>, 'columns'>;

export const TableFilters = <DataObj extends ValidObject>(props: TableFiltersProps<DataObj>) => {
  const filterableColumns = React.useMemo(
    () => props.columns?.filter(c => c.Filter !== undefined && c.canFilter),
    [props.columns],
  );

  return (
    <div className="w-50 flex items-stretch justify-start">
      {filterableColumns?.map(column => (
        <div key={column.id || shortid()} className={cx('w-100 flex flex-column justify-center items-center')}>
          {/* @ts-ignore */}
          {column.render('Filter')}
        </div>
      ))}
    </div>
  );
};
