import React, { useMemo, useState } from 'react';
import { useAuth, useDashboard, useOrganization } from 'containers';
import { compareAsc, compareDesc } from 'date-fns';
import { useRouteMatch, useLocation } from 'react-router';
import { AvatarGroup, DataTable } from 'components';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import { toTitleCase } from 'utils';

import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { Flyout, FlyoutProps } from './Flyout';

export const DashboardTasksFlyout: React.FC<FlyoutProps> = props => {
  const { token } = useAuth();
  const {
    selectedEquipment,
    selectedFacility,
    selectedGroup,
    selectedOwner,
    selectedPeriod,
    tasks,
    fetchDashboardTasksData,
  } = useDashboard();
  const [hasFetched, setHasFetched] = useState(false);
  const { users } = useOrganization();
  const { params } = useRouteMatch<{ type: string }>();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const viewingOverdue =
    query.has('overdue') && Boolean(query.get('overdue')) !== false;
  const taskType: unknown =
    params.type === 'verifications' ? 'VERIFICATION' : 'CORRECTIVE_ACTION';

  React.useEffect(() => {
    if (!token || hasFetched) return;

    fetchDashboardTasksData({
      token,
      equipment: selectedEquipment,
      facility: selectedFacility,
      groupUuid: selectedGroup,
      owner: selectedOwner,
      periodMonths: selectedPeriod.interval,
      overdue: query.has('overdue'),
      upcoming: query.has('upcoming'),
      taskType: taskType as string,
      gt2Weeks: query.has('gt2Weeks'),
      lt2Weeks: query.has('lt2Weeks'),
    });

    if (tasks) {
      setHasFetched(true);
    }
  }, [
    token,
    taskType,
    hasFetched,
    setHasFetched,
    tasks,
    query,
    params,
    selectedGroup,
    selectedPeriod,
    selectedOwner,
    selectedFacility,
    selectedEquipment,
  ]);

  const assignees = React.useMemo(() => {
    const list = Object.values(users);

    return list.map(user => {
      return {
        value: user.email.toLowerCase(),
        label: user.fullName,
        extraLabel: user.email.toLowerCase(),
        name: user.fullName,
        id: shortid(),
      };
    });
  }, [users]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const memoizedColumns = useMemo(() => {
    return [
      {
        id: 'analysis',
        Header: 'Analysis',
        accessor: 'treeTitle',
        sortType: (rowA, rowB, columnId) => {
          const a = rowA.values[columnId].toLowerCase();
          const b = rowB.values[columnId].toLowerCase();

          return a < b ? -1 : 1;
        },
        Cell: ({ value, row }) => {
          const href = `/tree/${row.original.treeUuid}`;

          return (
            <Link className="underline black fs-s" to={href}>
              {value || 'Untitled Analysis'}
            </Link>
          );
        },
      },
      {
        id: 'task',
        Header: 'Task',
        accessor: 'title',
        Cell: ({ value }) => {
          return <span className="fs-s">{value}</span>;
        },
      },
      {
        id: 'assignee',
        Header: 'Assignee',
        filter: (rows, _, filterValue) => {
          if (!filterValue) return rows;

          const results = rows.filter(row =>
            row.values.assignee.includes(filterValue),
          );

          return results;
        },
        Filter: table => (
          <DataTable.SelectFilter
            options={assignees}
            isSearchable
            isClearable
            placeholder="Assignee"
            {...table}
          />
        ),
        Cell: ({ value }) => {
          if (value.length) {
            const userObjects = value.map(s => {
              return {
                username: users[s]?.username,
                fullName: users[s]?.fullName,
              };
            });

            const usernames = userObjects.map(user =>
              user.fullName ? user.fullName : user.username,
            );

            return <AvatarGroup usernames={usernames[0] ? usernames : value} />;
          }

          return null;
        },
        accessor: 'doers',
      },
      {
        id: 'due-date',
        Header: 'Due Date',
        sortType: (a, b, id, descending) => {
          if (descending) {
            return compareDesc(new Date(b.values[id]), new Date(a.values[id]));
          }

          return compareAsc(new Date(a.values[id]), new Date(b.values[id]));
        },
        accessor: task => format(task.deadline, 'MM/dd/yyyy'),
      },
    ];
  }, []);

  return (
    <Flyout
      title={`${viewingOverdue ? 'Overdue' : 'Upcoming'} ${toTitleCase(
        params.type,
      )}`}
      maxWidth={800}
      {...props}
    >
      <div className="h-100 overflow-y-scroll">
        <div className="flex flex-column">
          {tasks && tasks.length > 0 ? (
            <DataTable
              data={tasks}
              globalFiltering
              filterable
              pagination
              sortable
              initialSort={[{ id: 'due-date', desc: true }]}
              columns={memoizedColumns}
            />
          ) : null}
        </div>
      </div>
    </Flyout>
  );
};
