// @ts-nocheck
import { i18nStrings } from './i18n_words';

export const _ = (strings, text) => {
  if (strings && strings.length > 0) {
    if (strings[text]) {
      return strings[text];
    } else {
      console.warn(`i18n: string "${text}" does not exist, please add to i18nStrings.`);
    }
  }

  return text;
};

export const initI18n = callback => {
  const doTranslate = callback => {
    const words = i18nStrings.map(word => ({ t: 1, w: word }));

    Weglot.translate({ words: words, languageTo: Weglot.getCurrentLang() }, xlated => {
      const strings = {};
      i18nStrings.forEach((word, i) => {
        strings[word] = xlated[i];
      });

      callback(strings);
    });
  };

  if (process.env.REACT_APP_WEGLOT_KEY) {
    if (Weglot.initialized) {
      // Already initialized
      doTranslate(callback);
      return;
    }

    Weglot.initialize({
      api_key: `${process.env.REACT_APP_WEGLOT_KEY}`,
      //hide_switcher: true,
      whitelist: [{ value: '.i18n' }],
      excluded_blocks: [{ value: '.no-i18n' }],
      //cache: true,
      switchers: [
        {
          style: {
            full_name: true,
            with_name: true,
            is_dropdown: true,
            with_flags: true,
            //flag_type: 'circle',
            invert_flags: false,
          },
          // Move switcher somewhere in the page
          location: {
            target: '.i18nSwitcher',
            sibling: null,
          },
        },
      ],
    });

    Weglot.on('initialized', () => {
      doTranslate(callback);
    });

    Weglot.on('languageChanged', (newLang, prevLang) => {
      console.log(`I18n lagnuage switched from ${prevLang} to ${newLang}.`);
      doTranslate(callback);
    });
  }
};
