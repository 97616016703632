import React from 'react';
import cx from 'classnames';
import { StylingProps } from '@interfaces';

import { Unstyled } from './Unstyled';
import styles from './styles.module.css';
import type { CommonContentProps } from './types';

type ConfirmationPopoverProps = {
  message?: string;
  warning?: string;
  title?: string;
};

export const Confirmation: React.FC<StylingProps & CommonContentProps & ConfirmationPopoverProps> = ({
  children,
  ...props
}) => {
  return (
    <Unstyled className={cx(styles.confirmationContent, styles.solidContentBorder)} {...props}>
      <div className={styles.confirmationMessage}>
        {props.title ? <p className={styles.confirmationTitle}>{props.title}</p> : null}
        {props.message ? (
          <p className={styles.newFormContentSubtitle}>
            {props.message}
            {props.warning ? <span className={styles.confirmationWarning}> {props.warning}</span> : null}
          </p>
        ) : null}
      </div>
      <div className={styles.footerContent}>{children}</div>
    </Unstyled>
  );
};
