import stringify from 'csv-stringify/lib/browser';
import { List } from 'immutable';
const organizationColumns = {
  companyName: 'Company Name',
  username: 'Username',
  email: 'Email',
  fullName: 'Name',
  roles: 'Roles',
  licenses: 'Company Licenses',
  organizationCreatedAt: 'Organization Start',
  userCreatedAt: 'User Start',
  id: 'Id',
  treeCount: '# of Trees',
};

const csvFromState = state => {
  const organizations = state.get('organizations').reduce((acc, x) => acc.concat(x), List());

  const results = organizations
    .map(x =>
      x
        .update('organizationCreatedAt', oca => new Date(oca).toLocaleString())
        .update('userCreatedAt', uca => new Date(uca).toLocaleString())
        .update('roles', rs => List(rs).sort().join(';'))
        .set('treeCount', state.getIn(['counts', x.get('email')])),
    )
    .toJS();

  return new Promise((resolve, reject) =>
    stringify(
      results,
      {
        header: true,
        columns: organizationColumns,
      },
      (err, str) => {
        if (err) {
          reject(err);
        } else {
          resolve(str);
        }
      },
    ),
  );
};

export const copyCSVToClipboard = async state => {
  const csv = await csvFromState(state);
  await navigator.clipboard.writeText(csv);
};
