import React from 'react';
import { _ } from 'utils';

import { MethodologySelect, MethodologySelectProps } from './MethodologySelect';

export const QuickLaunchSelect = (props: MethodologySelectProps) => {
  const styles = React.useRef({
    valueContainer: provided => ({
      ...provided,
      paddingRight: 16,
    }),
    placeholder: provided => ({
      ...provided,
      color: 'var(--blue)',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'var(--blue)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: provided => ({
      ...provided,
      height: '100%',
      borderRadius: 0,
      width: 200,
      borderColor: 'var(--blue)',
    }),
    ...props.styles,
  }).current;

  return (
    <MethodologySelect
      openMenuOnFocus
      closeMenuOnSelect={false}
      isClearable={false}
      isSearchable={false}
      tabSelectsValue={false}
      controlShouldRenderValue={false}
      onChange={props.onChange}
      styles={styles}
      // @ts-ignore
      placeholder={`${_(props.i18n, 'Quick Launch RCA')}`}
      {...props}
    />
  );
};
