import React, { useEffect, useState } from 'react';
import { List, OrderedSet, Map, Range } from 'immutable';
import { _ } from 'utils';
import cx from 'classnames';
import { Link, Route, Redirect } from 'react-router-dom';
import { format as formatDate, setHours, setMinutes, parseISO } from 'date-fns';

import { Avatar, DeepSelect, GroupsSelect, OrgMembersSelect } from 'components';
import { Close16 } from '@carbon/icons-react';
import shortid from 'shortid';
import { calculateFailureCost, toUSD } from 'utils';
import { CustomField, CustomFieldMap, User } from '@interfaces';
import { reduce } from 'lodash';
import { Page } from './Page';
import { Button } from './Buttons';
import { Select, Input, Textarea } from './TextInputs';
import { getAvailableCustomFields, initDispatchedWS } from './Sync';

const HeatmapSquare = ({ score, active, onHover, onClick, selected }) => {
  return (
    <div
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      onClick={onClick}
      style={{ color: active || selected ? 'white' : 'rgba(255,255,255,0.4)' }}
      className={cx('relative h3 w3 pointer ma1 b--white flex justify-center items-center fw7', {
        'b--dashed ba': active,
        'b--solid ba bw1': selected,
      })}
    >
      {score}
    </div>
  );
};

const Heatmap = ({ value, onSelect, xAxis, yAxis }) => {
  const [coords, setCoords] = useState(Map());
  const activeRow = coords.get('y');
  const activeCol = coords.get('x');
  const selectedRow = value.get('y');
  const selectedCol = value.get('x');

  return (
    <div className="dib">
      <div className="relative dib w-100 h-100">
        <div className="absolute w-100 tc left-0" style={{ bottom: '-1rem' }}>
          <div className="dib ph2 pv1 bg-black white">{xAxis}</div>
        </div>
        <div className="absolute" style={{ top: '50%', left: '-3rem' }}>
          <div className="ph2 pv1 bg-black white w-100 rotate-270">{yAxis}</div>
        </div>
        <div
          className="flex justify-center items-center bl bb bw2"
          onMouseLeave={() => setCoords(Map())}
          style={{
            background:
              'linear-gradient(225deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
          }}
        >
          {Range(1, 6).map(col => (
            <div key={`c${col}`}>
              {Range(1, 6)
                .reverse()
                .map(row => (
                  <HeatmapSquare
                    key={`r${row}`}
                    score={col * row}
                    // row={row}
                    // column={col}
                    onHover={isHover => {
                      setCoords(isHover ? Map({ y: row, x: col }) : Map());
                    }}
                    onClick={() => {
                      onSelect(coords);
                    }}
                    selected={selectedRow === row && selectedCol === col}
                    active={
                      (activeRow === row && col === 1) ||
                      (activeCol === col && row === 1) ||
                      (activeRow === row && activeCol === col) ||
                      (selectedRow === row && col === 1) ||
                      (selectedCol === col && row === 1)
                    }
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const RecommendedMethodology = ({ probability, impact }) => {
  const score = probability * impact;

  if (score > 9) {
    return (
      <>
        These issues are severe. EasyRCA recommends a full <span className="fw7">PROACT</span> root cause analysis team
        be chartered.
      </>
    );
  } else if (score > 4) {
    return (
      <>
        EasyRCA recommends a <span className="fw7">PROACT</span> logic tree. What triggered this analysis may occur
        again. It presents a sizable issue or high frequency work stoppage that may yield substantial return on
        investment.
      </>
    );
  } else if (score > 2) {
    return (
      <>
        EasyRCA recommends a <span className="fw7">Fishbone</span> analysis. Key staff should spend a few hours
        assessing the problem beyond the surface level, looking for issues and finding the major problems that triggered
        the analysis.
      </>
    );
  } else {
    return (
      <>
        EasyRCA recommends a <span className="fw7">5 Whys</span> analysis. The cost and likelihood of an incident may
        warrant analysis, but not enough for a large team or significant work.
      </>
    );
  }
};

const methodologyToString = Map({
  PROACT: 'PROACT',
  FISHBONE: 'Fishbone',
  '5WHYS': '5 Whys',
});

const stringToMethodology = Map({
  PROACT: 'PROACT',
  Fishbone: 'FISHBONE',
  '5 Whys': '5WHYS',
});

// const likelihoods = List([
//   'Very low',
//   'Low',
//   'Possible',
//   'Likely',
//   'Very likely',
// ]);

// const consequences = List([
//   'Very low cost',
//   'Low cost',
//   'Moderate costs',
//   'High costs',
//   'Very high costs',
// ]);
const MethodologyType = ({ form, onChange, state }) => {
  const impact = form.get('impact', 1);
  const probability = form.get('probability', 1);
  const risk = Map({ x: impact, y: probability });
  const score = probability * impact;
  const [showRiskAssessment, setShowRiskAssessment] = React.useState(false);
  const [recommendation, setRecommendation] = React.useState('PROACT');
  const templateOptions = state
    .get('trees', Map())
    .filter(t => t.get('isTemplate'))
    .sortBy(x => (x.get('title') || 'Untitled Analysis').toLowerCase());

  React.useEffect(() => {
    if (score > 9) {
      return setRecommendation('PROACT');
    } else if (score > 4) {
      return setRecommendation('PROACT');
    } else if (score > 2) {
      return setRecommendation('Fishbone');
    }

    return setRecommendation('5 Whys');
  }, [score]);

  return (
    <div>
      <Select
        className={cx({ 'o-20': showRiskAssessment })}
        title="Methodology"
        value={methodologyToString.get(form.get('methodology', 'PROACT'))}
        options={List(['PROACT', 'Fishbone', '5 Whys'])}
        onChange={e => {
          onChange(form.set('methodology', stringToMethodology.get(e.target.value)).delete('template'));
        }}
      />
      <div className="mb3 pv2 type-recommendation">
        <div>
          <div className="flex items-center justify-start mb2">
            <p className="i18n fs-s mr1">Can&#8216;t decide which methodology to use?</p>
            <button
              className="i18n b--blue ba br1 unstyled fg--blue pa1 ph2 ml-auto"
              // buttonClasses={['br1']}
              // theme="secondary"
              // small
              onClick={() => setShowRiskAssessment(show => !show)}
            >
              {!showRiskAssessment ? 'Take a risk assessment.' : 'Close assessment.'}
            </button>
          </div>
          {showRiskAssessment ? (
            <div className="mb4">
              <div className="f6 gray mb3 flex items-center mb2">
                Click a square to define your impact and probability.
              </div>
              <div className="flex flex-column items-start justify-start">
                <Heatmap
                  value={risk}
                  onSelect={coord => onChange(form.set('impact', coord.get('x')).set('probability', coord.get('y')))}
                  xAxis="Impact"
                  yAxis="Probability"
                />
                <p className="pt4 fs-s">
                  <RecommendedMethodology probability={form.get('probability')} impact={form.get('impact')} />
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={cx('flex items-center justify-start', {
            // 'opacity--50':
            //   recommendation !== 'PROACT' &&
            //   form.get('methodology', 'PROACT') !== 'PROACT',
          })}
        >
          <Select
            className={cx('w-100', {
              'o-20': showRiskAssessment,
            })}
            disabled={recommendation !== 'PROACT' && form.get('methodology', 'PROACT') !== 'PROACT'}
            title="Use Internal Template"
            value={templateOptions.getIn([form.get('template'), 'title'])}
            options={List(['']).concat(templateOptions.valueSeq().map(x => x.get('title') || 'Untitled Analysis'))}
            onChange={e => {
              if (e.target.selectedIndex === 0) {
                onChange(form.delete('template').delete('methodology'));
                return;
              }
              const templateTreeUuid = templateOptions.keySeq().get(e.target.selectedIndex - 1);
              const methodology = state.getIn(['trees', templateTreeUuid, 'methodology']);
              onChange(form.set('template', templateTreeUuid).set('methodology', methodology));
            }}
          />
          {/* <input
            disabled={
              recommendation !== 'PROACT' &&
              form.get('methodology', 'PROACT') !== 'PROACT'
            }
            type="checkbox"
            onClick={() => form.set('useInternalTemplate', true)}
            name="useInternalTemplate"
            id="useInternalTemplate"
          />
          <label className={cx('pl2')} htmlFor="useInternalTemplate">
            Use internal template
          </label> */}
        </div>
        {recommendation !== 'PROACT' && form.get('methodology', 'PROACT') !== 'PROACT' && (
          <div className="pv1">
            <em className="fs-xxs fg--red">Templates are only available for PROACT Analyses</em>
          </div>
        )}

        {/* <div className="f6 mt2 mb3 lh-title" style={{ height: '80px' }}>
          <RecommendedMethodology probability={probability} impact={impact} />
        </div> */}
      </div>
      {/* <div className="mb3">
        <Slider
          title="Probability"
          value={probability}
          options={likelihoods}
          onChange={v => onChange(form.set('probability', v))}
        />
      </div>
      <div className="mb3">
        <Slider
          title="Impact"
          value={impact}
          options={consequences}
          onChange={v => onChange(form.set('impact', v))}
        />
      </div> */}
    </div>
  );
};

const AnalysisInput = ({ autoFocus = false, title, k, form, onChange, type = 'text' }) => {
  return (
    <div className="i18n mb2">
      <Input
        autoFocus={autoFocus}
        title={title}
        value={form.get(k)}
        onChange={e => onChange(form.set(k, e.target.value))}
        type={type}
      />
    </div>
  );
};

const Timing = ({ form, onChange }) => {
  return (
    <div className="mb3">
      <div className="mb2">
        <AnalysisInput title="Event Occurred Date" k="eventDate" form={form} onChange={onChange} type="date" />
      </div>
      <div className="mb2">
        <AnalysisInput title="Event Occurred Time" k="eventTime" form={form} onChange={onChange} type="time" />
      </div>
      <div className="mb2">
        <AnalysisInput title="Analysis Start Date" k="analysisStart" form={form} onChange={onChange} type="date" />
      </div>
      <div className="mb2">
        <AnalysisInput title="Expected Completion" k="expectedCompletion" form={form} onChange={onChange} type="date" />
      </div>
    </div>
  );
};

const NewAnalysisCard = ({ children }) => {
  return (
    <div className="pb4" style={{ width: '35rem' }}>
      {children}
    </div>
  );
};

const NewAnalysisStep = ({ state, text, url, ...props }) => {
  return (
    <Link to={url} className={cx('no-underline black dim', props.className)}>
      <div
        className={cx('i18n f4 mb2 pv1', {
          blue: state.get('url') === url,
        })}
      >
        {text}
      </div>
    </Link>
  );
};

const wizardDefaults = Map({
  methodology: 'PROACT',
  canView: true,
  analysisStart: formatDate(new Date(), 'uuuu-MM-d'),
  analysisCompleted: null,
  groupUuid: '',
  injuries: '',
  safetyImpact: '',
  environmentalImpact: '',
  customerImpact: '',
  productionCost: '',
  propertyCost: '',
  laborCost: '',
  frequency: '',
  title: '',
  description: '',
  facility: null,
  equipment: null,
  customValues: Map({}),
});

const tryParseISO = s => {
  try {
    return parseISO(s).getTime();
  } catch (e) {
    return null;
  }
};

const eventDateAndTime = (eventDate, eventTime) => {
  const date = tryParseISO(eventDate);
  if (date) {
    if (eventTime) {
      const [hr, min] = eventTime.split(':');
      return setMinutes(setHours(date, hr | 0), min | 0).getTime();
    } else {
      return date;
    }
  }
};

const createNewTree = (state, form, members) => {
  const analysisStart = tryParseISO(form.get('analysisStart'));
  const expectedCompletion = tryParseISO(form.get('expectedCompletion'));
  const event = eventDateAndTime(form.get('eventDate'), form.get('eventTime'));

  const treeMembers = OrderedSet(
    form.get('shareAll') ? state.get('users', Map()).keySeq() : members.map(m => m.username),
    // : form.get('members', List()),
  );

  const templateUuid = form.get('template');

  const tree = Map({
    methodology: form.get('methodology'),
    members: treeMembers,
    title: form.get('title'),
    groupUuid: form.get('groupUuid'),
    description: form.get('description'),
    injuries: form.get('injuries'),
    safetyImpact: form.get('safetyImpact'),
    environmentalImpact: form.get('environmentalImpact'),
    customerImpact: form.get('customerImpact'),
    productionCost: form.get('productionCost'),
    propertyCost: form.get('propertyCost'),
    laborCost: form.get('laborCost'),
    frequency: form.get('frequency'),
    canView: form.get('canView'),
    status: 'PENDING',
    startAt: analysisStart,
    eventAt: event,
    expectedAt: expectedCompletion,
    customValues: form.get('customValues', {}),
    facility: form.get('facility', null),
    equipment: form.get('equipment', null),
  });

  return Map({ tree, templateUuid, events: List([]) });
};

const CustomFieldsCard = ({
  onFieldValueChange,
  customFields,
  customValues,
}: {
  token: string;
  dispatch: (...a: Array<any>) => void;
  customFields: Map<keyof CustomFieldMap, CustomFieldMap[keyof CustomFieldMap]>;
  customValues: Map<string, string>;
  onFieldValueChange: (p: { fieldUuid: string; value: string }) => void;
}) => {
  const fields = Object.values(customFields.toJS ? customFields.toJS() : customFields);
  const fieldsList = (Object.values(fields) as Array<CustomField>).sort((a, b) => a.index - b.index);

  return (
    <div className="pb4" style={{ width: '35rem' }}>
      <h1>Custom Fields</h1>
      {fieldsList.map(field => {
        const fieldLabel = `field-${field.uuid}`;
        const fieldValue = customValues.get(field.uuid) ?? '';

        return (
          <div key={field.uuid} className="flex flex-column mb3">
            <label className="mb1" htmlFor={fieldLabel}>
              {field.label}
            </label>
            <input
              type="text"
              name={fieldLabel}
              defaultValue={fieldValue}
              className="bw1 pa2 w-100 bb bg-black-05 b--black-20"
              onChange={e => onFieldValueChange({ fieldUuid: field.uuid, value: e.target.value })}
            />
          </div>
        );
      })}
    </div>
  );
};

export const NewAnalysisWizard = ({ state, dispatch }) => {
  const ws = state.get('ws');
  const [form, setForm] = useState(wizardDefaults);
  const members = state.get('users').toJS();
  const orgMembers = Object.values(members) as User[];
  // immutable merging is obnoxious; using this for selected members instead
  const [teamMembers, setTeamMembers] = React.useState([]);
  const groups = state.get('groups', List());
  const i18n = state.get('i18n');
  const token = state.get('token', null);

  useEffect(() => {
    if (!token) {
      console.info('no token');
    }

    console.info('form', form.toJS());
  }, [form, token]);

  const handleAddMember = React.useCallback(
    member => {
      if (!dispatch) {
        console.error('Dispatch is unavailable. Cannot handle adding members.');
      }

      if (teamMembers.find(m => m.username === member.username)) {
        return dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: `${member.username} has already been added`,
            duration: 2500,
            style: 'WARNING',
          }),
        );
      }

      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: `Added ${member.username}`,
          duration: 2500,
          style: 'SUCCESS',
        }),
      );

      return setTeamMembers(m => [...m, member]);
    },
    [dispatch, teamMembers],
  );

  const handleRemoveMember = React.useCallback(
    member => {
      setTeamMembers(m => m.filter(u => u.username !== member.username));

      if (dispatch) {
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: `Removed ${member.username}`,
            duration: 2500,
            style: 'SUCCESS',
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!ws) {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          dispatch(Map({ type: 'SET_URL', url: '/' }));
        }
      });
    } else {
      dispatch(Map({ type: 'LIST' }));
      dispatch(Map({ type: 'LIST_GROUPS' }));
      dispatch(Map({ type: 'GET_ORGANIZATION' }));
    }
  }, [ws, dispatch]);

  // const equipmentNames = state
  //   .getIn(
  //     ['organization', 'user', 'customNames', 'equipment'],
  //     Map({
  //       class: 'Class',
  //       code: 'Code',
  //       type: 'Type',
  //     }),
  //   )
  //   .toJS();

  // const facilityNames = state
  //   .getIn(
  //     ['organization', 'user', 'customNames', 'facility'],
  //     Map({
  //       department: 'Department',
  //       location: 'Location',
  //       site: 'Site',
  //     }),
  //   )
  //   .toJS();

  const groupSelectValue = React.useMemo(() => {
    if (form.get('groupUuid') && groups.size > 0) {
      const groupObject = groups.toJS().find(g => g.groupUuid === form.get('groupUuid'));

      if (!groupObject) return null;

      return {
        label: groupObject.name,
        value: groupObject.groupUuid,
      };
    }

    return null;
  }, [groups, form]);

  useEffect(() => {
    if (!token) return;

    getAvailableCustomFields({ token })
      .then(data => {
        const initialCustomValues = reduce(
          Object.keys(data),
          (obj, key) => ({
            ...obj,
            [key]: '',
          }),
          {},
        );

        // const fieldUuids =
        setForm(f => f.set('customValues', Map(initialCustomValues)));
        dispatch(
          Map({
            type: 'GET_CUSTOM_FIELDS',
            payload: Map(data),
          }),
        );
      })
      .catch(error => console.info('custom fields error:', error));
  }, [token]);

  const customFields = state.get('customFields', Map());

  const totalCost = calculateFailureCost({
    frequency: Number(form.get('frequency')),
    laborCost: form.get('laborCost'),
    productionCost: form.get('productionCost'),
    propertyCost: form.get('propertyCost'),
  }).total;

  useEffect(() => {
    console.info('customValues', form.get('customValues'));
  }, [form]);

  // const handleChangeFacility = p => {
  //   setForm(s =>
  //     s.merge({
  //       facilityLocationUuid: p.location?.value,
  //       facilitySiteUuid: p.site?.value,
  //       facilityDepartmentUuid: p.department?.value,
  //       facilityLocation: p.location?.label,
  //       facilitySite: p.site?.label,
  //       facilityDepartment: p.department?.label,
  //     }),
  //   );
  // };

  // const handleChangeEquipment = p =>
  //   setForm(s => {
  //     return s.merge({
  //       equipmentTypeUuid: p.type?.value,
  //       equipmentClassUuid: p.class?.value,
  //       equipmentCodeUuid: p.code?.value,
  //       equipmentType: p.type?.label,
  //       equipmentClass: p.class?.label,
  //       equipmentCode: p.code?.label,
  //     });
  //   });

  // const facilitiesArray = facilities && facilities.toJS ? facilities.toJS() : [];
  // const equipmentsArray = equipments && equipments.toJS ? equipments.toJS() : [];

  return (
    <Page title="New Analysis Wizard" state={state} dispatch={dispatch}>
      <div className="flex flex-row">
        <div className="w-100 mw5">
          <NewAnalysisStep state={state} text="Analysis Type" url="/new-analysis/type" />
          <NewAnalysisStep state={state} text="Event Information" url="/new-analysis/event-information" />
          <NewAnalysisStep state={state} text="Equipment" url="/new-analysis/equipment" />
          {customFields.size > 0 ? (
            <NewAnalysisStep className="fadeIn" state={state} text="Custom Fields" url="/new-analysis/custom-fields" />
          ) : null}
          <NewAnalysisStep state={state} text="Costs" url="/new-analysis/costs" />
          <NewAnalysisStep state={state} text="Team" url="/new-analysis/team" />
          <Button
            text="Launch Analysis"
            onClick={() => dispatch(createNewTree(state, form, teamMembers).set('type', 'NEW_TREE'))}
            className="i18n dib mt4"
            theme="secondary"
            small
          />
        </div>
        <div className="flex flex-column">
          <Redirect from="/new-analysis" to="/new-analysis/type" />
          <Route path="/new-analysis/type">
            <NewAnalysisCard>
              {/*@ts-ignore*/}
              <MethodologyType state={state} dispatch={dispatch} form={form} onChange={setForm} />
            </NewAnalysisCard>
            <div>
              <Link to="/new-analysis/event-information">
                <Button text="Next" className="i18n dib" />
              </Link>
            </div>
          </Route>
          <Route path="/new-analysis/event-information">
            <NewAnalysisCard>
              <div className="mb3">
                <AnalysisInput title="Title" form={form} k="title" onChange={setForm} />
              </div>
              <div className="mb3">
                Group
                <label className="i18n db mb1" htmlFor="groupUuid"></label>
                <GroupsSelect
                  name="groupUuid"
                  id="groupUuid"
                  data={groups.toJS()}
                  value={groupSelectValue}
                  onChange={({ value }, { action }) => {
                    if (action === 'clear') {
                      return setForm(form.set('groupUuid', ''));
                    } else if (action === 'select-option') {
                      return setForm(form.set('groupUuid', value));
                    }
                  }}
                />
              </div>
              <div className="mb2">
                <Textarea
                  className="i18n"
                  title="Problem Statement"
                  value={form.get('description')}
                  onChange={e => setForm(form.set('description', e.target.value))}
                />
              </div>
              <Timing form={form} onChange={setForm} />
            </NewAnalysisCard>
            <div>
              <Link to="/new-analysis/equipment">
                <Button text="Next" className="i18n dib" />
              </Link>
            </div>
          </Route>
          <Route path="/new-analysis/equipment">
            <NewAnalysisCard>
              <div className="flex flex-column mb3">
                <label className="f5 mb1 black-80" htmlFor="facility">
                  Facility
                </label>
                <DeepSelect
                  token={token}
                  theme="light"
                  type="facilities"
                  name="facility"
                  placeholder="Search by facility name..."
                  inputId="facility"
                  defaultValue={form.get('facility', null)}
                  defaultInputValue={form.get('facility', null)?.namePath.join(' ')}
                  onChange={(value, meta) => {
                    if (meta.action === 'clear') {
                      return setForm(s => s.merge({ facility: null }));
                    } else if (meta.action === 'select-option') {
                      // more immutable typings fun below

                      // @ts-expect-error
                      return setForm(s => s.merge({ facility: value.object }));
                    }
                  }}
                />
              </div>
              <div className="flex flex-column">
                <label className="f5 mb1 black-80" htmlFor="equipment">
                  Equipment
                </label>
                <DeepSelect
                  token={token}
                  theme="light"
                  type="equipment"
                  inputId="equipment"
                  name="equipment"
                  placeholder="Search by equipment name..."
                  defaultValue={form.get('equipment', null)}
                  defaultInputValue={form.get('equipment', null)?.namePath.join(' ')}
                  onChange={(value, meta) => {
                    if (meta.action === 'clear') {
                      return setForm(s => s.merge({ equipment: null }));
                    } else if (meta.action === 'select-option') {
                      // more immutable typings fun below

                      // @ts-expect-error
                      return setForm(s => s.merge({ equipment: value.object }));
                    }
                  }}
                />
              </div>
            </NewAnalysisCard>
            <div>
              <Link to={customFields.size > 0 ? '/new-analysis/custom-fields' : '/new-analysis/costs'}>
                <Button text="Next" className="i18n dib" />
              </Link>
            </div>
          </Route>
          <Route path="/new-analysis/custom-fields">
            {customFields.size < 1 ? (
              <Redirect from="/new-analysis/custom-fields" to="/new-analysis/costs" />
            ) : (
              <>
                <CustomFieldsCard
                  customValues={form.get('customValues') as Map<string, string>}
                  dispatch={dispatch}
                  customFields={customFields}
                  onFieldValueChange={({ fieldUuid, value }) =>
                    setForm(f => f.setIn(['customValues', fieldUuid], value))
                  }
                  token={token}
                />
                <div>
                  <Link to="/new-analysis/costs">
                    <Button text="Next" className="i18n dib" />
                  </Link>
                </div>
              </>
            )}
          </Route>
          <Route path="/new-analysis/costs">
            <div className="flex items-stretch justify-start">
              <NewAnalysisCard>
                <Textarea
                  className="i18n"
                  title="Injury Report"
                  value={form.get('injuries', '')}
                  onChange={e => setForm(form.set('injuries', e.target.value))}
                />
                <Textarea
                  className="i18n"
                  title="Safety Impact"
                  value={form.get('safetyImpact', '')}
                  onChange={e => setForm(form.set('safetyImpact', e.target.value))}
                />
                <Textarea
                  className="i18n"
                  title="Environmental Impact"
                  value={form.get('environmentalImpact', '')}
                  onChange={e => setForm(form.set('environmentalImpact', e.target.value))}
                />
                <Textarea
                  className="i18n"
                  title="Customer Impact"
                  value={form.get('customerImpact', '')}
                  onChange={e => setForm(form.set('customerImpact', e.target.value))}
                />
                <Input
                  className="i18n"
                  title="Production/Schedule Cost"
                  type="number"
                  min={0}
                  value={form.get('productionCost', '')}
                  onChange={e => setForm(form.set('productionCost', e.target.value))}
                />
                <Input
                  className="i18n"
                  title="Property/Equipment Cost"
                  type="number"
                  min={0}
                  value={form.get('propertyCost', '')}
                  onChange={e => setForm(form.set('propertyCost', e.target.value))}
                />
                <Input
                  className="i18n"
                  title="Labor/Time Cost"
                  type="number"
                  min={0}
                  value={form.get('laborCost', '')}
                  onChange={e => setForm(form.set('laborCost', e.target.value))}
                />
                <Input
                  className="i18n"
                  title="Frequency per Year"
                  type="number"
                  min={0}
                  value={form.get('frequency', '')}
                  onChange={e => setForm(form.set('frequency', e.target.value))}
                />
              </NewAnalysisCard>
              <div className="flex flex-column pa4 sticky--top" style={{ maxHeight: 50 }}>
                <div className="f3">{toUSD(totalCost)}</div>
                <div className="i18n mt1 f6">Annualized Financial Cost</div>
              </div>
            </div>
            <div>
              <Link to="/new-analysis/team">
                <Button text="Next" className="i18n dib" />
              </Link>
            </div>
          </Route>
          <Route path="/new-analysis/team">
            <NewAnalysisCard>
              <div className="flex flex-column items-stretch justify-start">
                <h2 className="i18n">Choose Team Members</h2>
                {/*@ts-ignore*/}
                <OrgMembersSelect
                  isSearchable
                  value={null}
                  isMulti
                  placeholder={_(i18n, 'Available Users')}
                  isLoading={orgMembers.length < 1}
                  data={orgMembers}
                  onChange={(value, meta) => {
                    if (meta.action === 'select-option') {
                      return handleAddMember({
                        username: value[0].value,
                        id: value[0].userId,
                        name: value[0].name,
                      });
                    }
                  }}
                />
                <hr />
                {teamMembers.map((member, idx) => (
                  <div className="w-100" key={shortid()}>
                    <div
                      className={cx('flex items-center justify-start pv3 pb3', {
                        'bb b--black-10': idx !== teamMembers.length - 1,
                      })}
                    >
                      <Avatar name={member.name} />
                      <span className="pl2">{member.username}</span>
                      <button className="unstyled ml-auto" onClick={() => handleRemoveMember(member)}>
                        <Close16 style={{ width: 20, height: 20 }} fill="var(--black-50)" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </NewAnalysisCard>
          </Route>
        </div>
      </div>
    </Page>
  );
};
