import React from 'react';
import * as R from 'recharts';
import shortid from 'shortid';

import { hexToRGBA } from 'utils';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';
import { COLORS } from './styles';
import { ChartContainer, ChartContainerProps } from './Container';
import { Tooltip } from './Tooltip';

import { VerticalLegend } from './VerticalLegend';

type DataItem = { label: string; value: number; fill?: string };

type DonutChartProps = ChartContainerProps & {
  height?: number;
  width?: number;
  innerRadius?: number;
  itemSpacing?: number;
  data: DataItem[];
  legend?: boolean;
};

type ChartLegendProps =
  | {
      legend: true;
      renderLegend?: ContentType;
    }
  | {
      legend?: false;
      renderLegend?: never;
    };

export const DonutChart = ({ width, height, ...props }: Omit<DonutChartProps, 'children'> & ChartLegendProps) => {
  const legend = props.legend || false;
  const dimensions = React.useMemo(() => ({ width: width || 150, height: height || 150 }), [width, height]);
  const styledItems = React.useMemo(() => {
    if (props.data) {
      return props.data.map((item, i) => {
        const baseMultiplier = 10 / props.data.length;
        const opacity = (baseMultiplier * (props.data.length - i)) / 10;

        return {
          ...item,
          fill: hexToRGBA({ hex: item.fill || COLORS.BLUE, alpha: opacity }),
        };
      });
    }

    return props.data;
  }, [props.data]);

  const [_, setActiveIndex] = React.useState<number | null>(null);

  const handleOnHover = React.useCallback((_, index) => {
    setActiveIndex(index);
  }, []);

  // const resetActiveIndex = React.useCallback(() => setActiveIndex(null), []);

  return (
    <ChartContainer title={props.title} subtitle={props.subtitle} height={dimensions.height}>
      <R.PieChart>
        <R.Pie
          cx="75%"
          onMouseEnter={handleOnHover}
          animationDuration={1200}
          animationEasing="ease-in-out"
          data={styledItems}
          dataKey="value"
          width={width}
          height={height}
          innerRadius={props.innerRadius || 32}
          paddingAngle={props.itemSpacing || 2}
        >
          {styledItems.map(item => {
            return <R.Cell key={shortid()} fill={item.fill} />;
          })}
        </R.Pie>
        <R.Tooltip content={Tooltip} />
        {legend ? (
          <R.Legend
            wrapperStyle={{
              left: 0,
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
            }}
            layout="vertical"
            verticalAlign="bottom"
            align="left"
            iconType="square"
            /* @ts-ignore-next-line Recharts types are broken; ignoring issues */
            content={VerticalLegend}
          />
        ) : null}
      </R.PieChart>
    </ChartContainer>
  );
};
