import { StylingProps } from '@interfaces';
import cx from 'classnames';
import React, { FormEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Spinner } from 'components';
import { Unstyled } from './Unstyled';
import styles from './styles.module.css';
import type { CommonContentProps } from './types';
import { usePopover } from '../PopoverContext';

type NewFormContentProps = {
  onSubmit?: (v?: string | number) => void;
  inputType?: string;
  title?: string;
  subtitle?: string;
};

export const NewForm: React.FC<StylingProps & CommonContentProps & NewFormContentProps> = ({
  className,
  inputType,
  onSubmit,
  ...props
}) => {
  const { hide } = usePopover();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState<string | number | undefined>(undefined);

  const handleOnChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  }, []);

  const handleOnSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      setIsSubmitting(true);
      onSubmit && onSubmit(inputValue);
      setIsSubmitting(false);
      hide();
      setInputValue(undefined);
    },
    [onSubmit, inputValue, hide],
  );

  useEffect(() => {
    inputRef.current?.focus();
  });

  return (
    <Unstyled className={cx(styles.formContent, styles.solidContentBorder, className)} {...props}>
      <div
        className={cx(styles.dashedBottomBorder, {
          [styles.contentHeaderSlim]: props.title && !props.subtitle,
          [styles.contentHeader]: props.title && props.subtitle,
        })}
      >
        {props.title ? <p className={styles.formContentTitle}>{props.title}</p> : null}
        {props.subtitle ? <p className={styles.formContentSubtitle}>{props.subtitle}</p> : null}
      </div>
      <div className={cx('flex items-center justify-start', styles.contentBody)}>
        <input
          className="w-100 bw1 pa2 bb bg-black-05"
          type={inputType}
          onChange={handleOnChange}
          value={inputValue}
          ref={inputRef}
        />
        <button className={cx('w-25 white bg-black pa2 ph3 items-center flex justify-center')} onClick={handleOnSubmit}>
          {isSubmitting ? <Spinner color="white" size={20} /> : 'OK'}
        </button>
      </div>
    </Unstyled>
  );
};
