import React from 'react';
import { Map } from 'immutable';
import { useInView } from 'react-intersection-observer';

import { State, ImmutableStateMap } from '@interfaces';
import { HomeNav, ViewerNav } from './Nav';
import { LoadingWidget } from './TreeViewer';
import { Hero } from './components';

type PageProps = {
  state: ImmutableStateMap | State;
  dispatch: React.Dispatch<Map<string, unknown>>;
  loading?: boolean;
  viewOnly?: boolean;
  onVisibilityStatusChange?: (args: { visible: boolean; entry?: IntersectionObserverEntry }) => void;
  title: string;
  id?: string;
  pageNavLinks?: { to: string; label: string }[];
};

export const Page: React.FC<PageProps> = ({
  state,
  dispatch,
  onVisibilityStatusChange,
  loading = false,
  title,
  id,
  children,
  pageNavLinks,
  ...props
}) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.05,
  });

  React.useEffect(() => {
    onVisibilityStatusChange && onVisibilityStatusChange({ visible: inView, entry });
  }, [inView, onVisibilityStatusChange, entry]);

  return (
    <div id={id} className="flex h-100 bg-white">
      {loading ? (
        <div className="h-100 flex-auto flex-column items-center justify-center">
          <LoadingWidget message="Loading..." error={null} />
        </div>
      ) : (
        <>
          {props.viewOnly ? <ViewerNav state={state} /> : <HomeNav state={state} dispatch={dispatch} />}
          <div
            // id used here for easily using document.querySelector + scrollTo
            id="scroll-target"
            className="flex-auto overflow-y-auto absolute right-0 bottom-0 left-0"
            style={{ top: '48px' }}
          >
            <Hero
              showSubNavigation={pageNavLinks && pageNavLinks.length > 0}
              subNavigationLinks={pageNavLinks}
              pageTitle={title}
              ref={ref}
            />
            <div className="ph4 w-100 h-100">
              <div className="ph4 h-100 w-100">{children}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
