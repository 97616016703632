import { Group } from '@interfaces';
import React from 'react';
import type { Props as SelectProps } from 'react-select';

import { Select } from './Select';

type GroupsSelectProps = {
  data?: Group[];
} & SelectProps;

export const GroupsSelect: React.FC<GroupsSelectProps> = ({
  data,
  value,
  ...props
}) => {
  const [isFetchingGroups, setIsFetchingGroups] = React.useState(true);

  const groupOptions = React.useMemo(
    () =>
      data?.map(d => ({
        value: d.groupUuid,
        label: d.name,
        className: 'no-i18n',
      })),
    [data],
  );

  // const groupObject = React.useMemo(() => {
  //   const results = groupOptions?.find(g => g.value === value);

  //   return results;
  // }, [value]);

  React.useEffect(() => {
    if (data) {
      setIsFetchingGroups(false);
    }
  }, [data]);

  return (
    <Select
      name="groups"
      id="groups"
      loadingMessage={() => (
        <div className="flex w-100 align-center justify-start">
          <span>Loading groups. Please wait.</span>
        </div>
      )}
      styles={{
        // @ts-ignore
        menu: provided => ({
          ...provided,
          width: '100%',
        }),
      }}
      isLoading={isFetchingGroups}
      // @ts-ignore
      options={groupOptions}
      // @ts-ignore
      value={value}
      // onInputChange={handleOnInputChange}
      // onChange={handleOnChange}
      {...props}
    />
  );
};
