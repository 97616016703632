import React from 'react';
import CSS from 'csstype';
import cx from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ReactTooltip from 'react-tooltip';
import { StylingProps } from '@interfaces';
import styles from './styles.module.css';

type ConfirmationPopoverProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  /* handlers */
  onConfirm: (args?: any[]) => void;
  onCancel?: (args?: any[]) => void;
  /* Trigger handling */
  disabled?: boolean;
  /* CSS/style overrides */
  triggerStyle?: CSS.Properties;
  triggerClassName?: string;
  popoverStyle?: CSS.Properties;
  popoverClassName?: string;
  /* tooltip control & copy */
  tooltip?: string;
  label?: string;
  labelClassName?: string;
  description?: string;
} & StylingProps;

export const ConfirmationPopover: React.FC<ConfirmationPopoverProps> = ({
  onConfirm,
  onCancel,
  triggerClassName,
  ...props
}) => {
  const label = props.label || 'Are you sure?';
  const description = props.description || null;
  const disabled = props.disabled || false;
  const [isVisible, setIsVisible] = React.useState(false);
  const detectClicksRef = useDetectClickOutside({
    onTriggered: () => {
      return setIsVisible(false);
    },
  });
  const handleShowConfirmation = React.useCallback(() => {
    ReactTooltip.hide();
    setIsVisible(true);
  }, []);
  const handleConfirm = React.useCallback(() => {
    setIsVisible(false);
    onConfirm();
  }, [onConfirm]);
  const handleCancel = React.useCallback(() => {
    setIsVisible(false);
    onCancel && onCancel();
  }, [onCancel]);

  const conditionalProps = React.useMemo(
    () =>
      props.tooltip && !isVisible
        ? {
            'data-tip': props.tooltip,
            'data-place': 'top',
            'data-effect': 'solid',
          }
        : {},
    [props.tooltip, isVisible],
  );

  return (
    <div
      ref={detectClicksRef}
      className={cx(styles.popoverContainer, props.className)}
      {...conditionalProps}
      {...props}
    >
      <button
        className={cx(styles.popoverTrigger, triggerClassName)}
        style={props.triggerStyle}
        onClick={handleShowConfirmation}
        disabled={disabled}
      >
        {props.children}
      </button>
      {isVisible ? (
        <div
          className={cx(
            styles.confirmationPopover,
            'flex items-center justify-start flex-column',
            props.popoverClassName,
            // { [styles.visible]: isVisible },
          )}
          style={props.popoverStyle}
        >
          <p className={cx(styles.confirmationPopoverLabel, props.labelClassName)}>{label}</p>
          {description ? <p className="m0 fs-xs">{description}</p> : null}
          <div className={cx(styles.confirmationPopoverActions, 'flex items-center justify-center')}>
            <button
              className={cx(
                styles.confirmationPopoverActionItem,
                styles.confirmationPopoverConfirmActionItem,
                'red fw6',
              )}
              onClick={handleConfirm}
            >
              <span className="i18n">Yes</span>
            </button>
            <button className={cx(styles.confirmationPopoverActionItem, 'black-60')} onClick={handleCancel}>
              <span className="i18n">No</span>
            </button>
          </div>
          <div className={cx(styles.arrow, styles.arrowDown)} />
        </div>
      ) : null}
    </div>
  );
};
