import React, { useState } from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';
import { CheckboxCheckedFilled24, Checkbox24 } from '@carbon/icons-react';

export const Button = ({
  text,
  disabled = false,
  icon = null,
  className = '',
  onClick = e => null,
  buttonClasses = [],
  theme = 'primary',
  small = false,
  ...props
}) => {
  const [state, setState] = useState(Map());
  // onClick = onClick || (e => null);

  return (
    <div
      className={classNames(className)}
      onMouseEnter={() => setState(state.set('hover', true))}
      onMouseLeave={() => setState(state.delete('hover'))}
      onFocus={() => setState(state.set('hover', true))}
      onBlur={() => setState(state.delete('hover'))}
      style={{ minWidth: '120px' }}
    >
      <button
        id={props.id}
        className={classNames('flex items-center justify-between trans ba', buttonClasses, {
          'w-100': text,
          'white b--dark-blue bg-dark-blue pointer': state.get('hover') && !disabled && theme !== 'danger',
          'bg-dark-red white pointer b--dark-red': state.get('hover') && !disabled && theme === 'danger',
          'b--black-20 bg-black-20 white-40 not-allowed': disabled,
          'white b--blue bg-blue': !disabled && !state.get('hover') && theme === 'primary',
          'ba bg-transparent b--blue blue': !disabled && !state.get('hover') && theme === 'secondary',
          'ba bg-transparent b--dark-red dark-red': !disabled && !state.get('hover') && theme === 'danger',
          'pv3 ph3': !small,
          pa2: small,
        })}
        disabled={disabled}
        onClick={e => {
          if (!disabled) {
            onClick(e);
          } else {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        {...props}
      >
        <div className={classNames('pr3', { dn: !text })}>{text}</div>
        {icon}
      </button>
    </div>
  );
};

export const Checkbox = ({ text, onClick, value, ...props }) => {
  return (
    <div
      className={classNames(
        'flex pointer',
        { 'items-center': !props.description, 'items-start': props.description && props.description !== '' },
        props.className,
      )}
      id={props.id}
      onClick={() => {
        if (props.disabled) {
          return;
        }

        return onClick(!value);
      }}
    >
      {props.hideCheckmark ? null : value ? (
        <CheckboxCheckedFilled24 className="fill--blue" />
      ) : (
        <Checkbox24 fill={props.uncheckedBorderColor} />
      )}
      {props.description ? (
        <div className="ml2 flex flex-column items-start justify-center">
          <div className="select-none">{text}</div>
          <div className="f6 fg--black-80 select-none">{props.description}</div>
        </div>
      ) : (
        <div className="ml2 flex items-center select-none">{text}</div>
      )}
    </div>
  );
};
