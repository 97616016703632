import React, { useMemo } from 'react';
import cx from 'classnames';
import BaseSelect, { GroupBase, OptionProps, Options } from 'react-select';
import { Checkmark16 } from '@carbon/icons-react';
import CreatableSelect from 'react-select/creatable';
import { FormatOptionLabelContext, Props } from 'react-select/dist/declarations/src/Select';
import styles from './styles.module.css';

export type { MultiValue } from 'react-select';

interface FormatOptionLabelMeta<Option> {
  context: FormatOptionLabelContext;
  inputValue: string;
  selectValue: Options<Option>;
}

export type BaseOption = { label: string; value: string };

const Option = ({
  data,
  innerProps,
  ...rest
}: OptionProps<BaseOption, false> & {
  selectProps: Props<BaseOption, false, GroupBase<BaseOption>>;
}) => {
  const { onMouseOver: _, onMouseMove: __, ...restInnerProps } = innerProps;
  const { innerRef } = rest;

  return (
    <div
      ref={innerRef}
      className={cx('pointer ph2 pv2 flex w-100 items-center justify-start relative', {
        [styles['select-option']]: true,
        [styles['select-option--light']]: !rest.selectProps.isDark,
        [styles['select-option--dark']]: rest.selectProps.isDark,
      })}
      {...restInnerProps}
    >
      {data.label}
      {rest.isSelected ? <Checkmark16 fill="green" /> : null}
    </div>
  );
};

export function Select<
  Option extends BaseOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  formatOptionLabel,
  isSearchable,
  closeMenuOnSelect,
  controlShouldRenderValue,
  openMenuOnClick,
  /* eslint-disable-next-line */
  getOptionValue, // pulling this out of props to exclude from spread below
  isClearable,
  styles: componentStyles,
  allowOptionCreation = false,
  ...props
}: Props<Option, IsMulti, Group>) {
  const theme = React.useRef({
    borderRadius: 4,
    colors: {
      primary: 'var(--blue)',
      primary25: 'var(--blue-25)',
      primary50: 'var(--blue-50)',
      primary75: 'var(--blue-75)',
      danger: 'var(--dark-red)',
      dangerLight: 'var(--dark-red-50)',
      neutral0: 'var(--white)',
      neutral5: 'var(--black-05)',
      neutral10: 'var(--black-10)',
      // border color (default and hover)
      neutral20: 'var(--black-20)',
      neutral30: 'var(--black-30)',
      //
      neutral40: 'var(--black-40)',
      neutral50: 'var(--black-50)',
      neutral60: 'var(--black-60)',
      neutral70: 'var(--black-70)',
      neutral80: 'var(--black-80)',
      neutral90: 'var(--black-90)',
    },
  });
  const darkTheme = React.useRef({
    borderRadius: 4,
    colors: {
      primary: 'var(--white-40)',
      // primary25: 'var(--blue-25)',
      primary25: 'var(--white-20)',
      primary50: 'var(--white-50)',
      primary75: 'var(--white-75)',
      danger: 'var(--dark-red)',
      dangerLight: 'var(--dark-red-50)',
      neutral0: 'hsla(0, 0%, 100%, 0.2)',
      neutral5: 'var(--white-30)',
      neutral10: 'var(--white-30)',
      // border color (default and hover)
      neutral20: 'var(--white-40)',
      neutral30: 'var(--white-40)',
      //
      neutral40: 'var(--white-60)',
      neutral50: 'var(--white-70)',
      neutral60: 'var(--white-70)',
      neutral70: 'var(--white-70)',
      neutral80: 'var(--white-80)',
      neutral90: 'var(--white-90)',
    },
  });

  const defaultFormatLabel = React.useCallback<
    (data: Option, optionMeta: FormatOptionLabelMeta<Option>) => React.ReactNode
  >(
    (data, info) => {
      if (formatOptionLabel) {
        return formatOptionLabel(data, info);
      }

      return data.label;
    },
    [formatOptionLabel],
  );

  const SelectedComponent = useMemo(() => {
    if (allowOptionCreation) {
      return CreatableSelect;
    }

    return BaseSelect;
  }, [allowOptionCreation]);

  const customComponents = props.components || { Option };

  // const getOptionValueFn = () => 'Choose more';

  return (
    <SelectedComponent
      classNamePrefix="select"
      theme={({ spacing }) => ({
        spacing,
        ...(props.isDark ? darkTheme.current : theme.current),
      })}
      allowCreateWhileLoading={false}
      styles={{
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.theme.colors.neutral40,
          borderBottomColor: 'red',
        }),
        menu: provided => ({
          ...provided,
          backgroundColor: props.isDark ? 'var(--black)' : 'var(--white)',
        }),
        menuList: provided => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        ...componentStyles,
      }}
      id={props.id}
      name={props.name}
      // @ts-ignore
      components={customComponents}
      formatOptionLabel={defaultFormatLabel}
      className={cx('w-100', styles.select, props.className)}
      // getOptionValue={getOptionValueFn}
      closeMenuOnSelect={closeMenuOnSelect}
      controlShouldRenderValue={controlShouldRenderValue}
      openMenuOnClick={openMenuOnClick}
      isSearchable={isSearchable}
      isClearable={isClearable}
      style={props.style}
      {...props}
    />
  );
}
