import { fromJS, List, Map, Set } from 'immutable';

export const initialState = Map({});

export const fire = (dispatch, type, msg) =>
  dispatch(fromJS(msg || {}).set('type', type));

const parseOrganizations = orgs => {
  const p = orgs
        .groupBy(o => o.get('organizationsId'))
        .valueSeq()
        .sortBy(x => x.getIn([0, 'organizationsId']))
        .map(org =>
             org
             .groupBy(o => o.get('email'))
             .valueSeq()
             .map((usersRoles) => {
               return usersRoles
                 .first()
                 .set(
                   'roles',
                   Set([usersRoles.first().get('admin') ? 'ADMIN' : false,
                        usersRoles.first().get('paid') ? 'PAID' : false,
                        usersRoles.first().get('complimentary') ? 'COMPLIMENTARY' : false,
                        usersRoles.first().get('superadmin') ? 'SUPERADMIN' : false,
                       ].filter(role => role)
                   ),
                 );
             }),
            );
  return p;
};

export const reducer = (state, action) => {
  const type = action.get('type');
  console.log(type, state.toJS());

  switch (type) {
    case 'SET_ORGANIZATIONS':
      return state.set(
        'organizations',
        parseOrganizations(action.get('organizations')),
      );
    case 'SET_ORGANIZATION':
      return state.set('organization', action.get('organization'));
    case 'SET_USER':
      return state.set('user', action.get('user'));
    case 'SET_COUNTS':
      const counts = action.get('counts');
      return state.set(
        'counts',
        Map(counts.map(x => List([x.get('username'), x.get('count')]))),
      );
    case 'SHOW_MODAL':
      return state.set('modal', action.delete('type'));
    case 'HIDE_MODAL':
      return state.delete('modal');
    default:
      console.error(`missing reducer: ${type}`);
      return state;
  }
};
