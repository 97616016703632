import { Spinner } from 'components';
import React, { Suspense } from 'react';
import { FieldRCAContainer } from './Container';

const Fallback = () => (
  <div className="flex items-center justify-center mt-auto mb-auto h-100">
    <Spinner size={32} />
    <p className="pl2 fw-medium fs-m">Loading...</p>
  </div>
);

export const FieldRCAs: React.FC = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <FieldRCAContainer />
    </Suspense>
  );
};
