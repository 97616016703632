import { ActiveStatus, DeepHierarchyObject, Task, TaskType } from '@interfaces';

export enum Actions {
  GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA',
  GET_DASHBOARD_TASKS = 'GET_DASHBOARD_TASKS',
  SIGN_OUT = 'SIGN_OUT',
  LIST = 'LIST',
  SET_TREE_PUBLISHED = 'SET_TREE_PUBLISHED',
}

export type ActionType = keyof typeof Actions;

export type TasksRequestParams = {
  lt2Weeks?: boolean;
  gt2Weeks?: boolean;
  groupUuid?: string;
  facilityUuid?: string;
  overdue?: boolean;
  upcoming?: boolean;
};

export type RequestPayloads = {
  [Actions.GET_DASHBOARD_DATA]: {
    periodMonths?: number;
    groupUuid?: string;
  };
  [Actions.LIST]: Record<string, unknown>;
  [Actions.SIGN_OUT]: Record<string, unknown>;
  [Actions.GET_DASHBOARD_TASKS]: {
    taskType: TaskType;
  } & TasksRequestParams;
};

// export type DispatchableAction<
//   /*T extends ActionType*/ T extends keyof Actions
// > = {
//   type: Actions[T];
// } & RequestPayloads[keyof typeof T];

export type DispatchableAction =
  | ({ type: 'LIST' } & Record<string, unknown>)
  | { type: 'LIST_GROUPS' }
  | ({ type: 'SIGN_OUT' } & Record<string, unknown>)
  | {
      type: 'COMPLETE_TASK';
      completed: boolean;
      taskUuid: string;
      treeUuid: string;
    }
  // | {
  //     type: 'GET_DASHBOARD_DATA';
  //     periodMonths?: number;
  //     groupUuid?: string | null;
  //     owner?: string | null;
  //     facility?: DeepHierarchyObject;
  //     equipment?: DeepHierarchyObject;
  //   }
  | {
      type: 'GET_ACTIONCENTER_TASKS';
    }
  // | {
  //     type: 'GET_DASHBOARD_TASKS';
  //     taskType: TaskType;
  //     lt2Weeks?: boolean;
  //     gt2Weeks?: boolean;
  //     groupUuid?: string;
  //     owner?: string;
  //     facility: string | null;
  //     equipment: string | null;
  //     overdue?: boolean;
  //     upcoming?: boolean;
  //   }
  | { type: 'GET_ORGANIZATION' };
// | { type: 'GET_DASHBOARD_TASKS_CSV' };

// type ActionByType<T extends DispatchableAction['type'], E = DispatchableAction> = E extends { type: E } ? T : never;
// type ResponsePayload = {
//   readonly type: ActionType
// }

// const b: ResponsePayload = {
//   type: ''
// }

export type ResponsePayload = {
  [Actions.GET_DASHBOARD_TASKS]: {
    tasks: Task[] | [];
  };
};

export type PeriodTaskData = {
  created: number;
  completed: number;
  overdue: {
    lt2Weeks: number;
    gt2Weeks: number;
  };
  upcoming: {
    lt2Weeks: number;
    gt2Weeks: number;
  };
  completedByWeek: number[];
};

export type PeriodTreeData = {
  byStatus: Record<ActiveStatus, number>;
  created: number;
  completed: number;
  updated: number;
  totalCost: string;
  correctiveActions: number;
  impactedFacilities: number;
};

export type DashboardDataForPeriod = {
  period: { from: string; to: string };
  trees: PeriodTreeData;
  tasks: {
    count: number;
    correctiveAction: PeriodTaskData;
    verification: PeriodTaskData;
  };
  roots: { uncorrectedRoots: number };
};

export type DashboardDataComparisonSnapshot = {
  current: DashboardDataForPeriod;
  previous: DashboardDataForPeriod;
  all: Partial<DashboardDataForPeriod>;
};
