import React from 'react';
import { ResponsiveContainer, ResponsiveContainerProps } from 'recharts';

export type ChartContainerProps = ResponsiveContainerProps & {
  title?: string;
  subtitle?: string;
};

export const ChartContainer: React.FC<ChartContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <div className="w-100 h-100 flex flex-column">
      {props.title && (
        <div className="w-100 h-100" style={{ maxHeight: 47 }}>
          <h2 className="f3 fw6 ma0" style={{ fontSize: 18, marginBottom: 4 }}>
            {props.title}
          </h2>
          {props.subtitle && (
            <h3
              className="f5 fw3 ma0"
              style={{ fontSize: 15, color: 'var(--black-60)' }}
            >
              {props.subtitle}
            </h3>
          )}
        </div>
      )}

      <ResponsiveContainer {...props}>{children}</ResponsiveContainer>
    </div>
  );
};
