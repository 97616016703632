import React, { useEffect, useState } from 'react';
import { List, Map, Set } from 'immutable';
import { Add16 } from '@carbon/icons-react';
import { Blade, Section } from './Blade';

export const TemplateOptions = ({ state, dispatch }) => {
  const uuid = state.get('uuid');
  const statePath = state.getIn(['tree', 'view', 'selected', 'path'], List());
  const elements = state.getIn(['tree', 'elements'], Map());
  const [model, setModel] = useState(Map());

  const node = elements.getIn(model.get('path', List()), Map());

  const allResults = Set(state.getIn(['tree', 'view', 'templateOptions']));
  const childTexts = Set(node.get('children', List()).map(x => x.get('text')));

  const results = allResults.subtract(childTexts).toList().sort();

  // we do this so if a user clicks off the node, it gets added to the last clicked
  useEffect(() => {
    if (statePath.size > 0) {
      setModel(model.set('path', statePath));
    }
  }, [model, setModel, statePath]);

  return (
    <Blade
      title={'Analysis Assistant'}
      show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'TEMPLATES'}
      state={state}
      dispatch={dispatch}
    >
      <Section title="PROACT&#174;">
        {results.isEmpty() ? (
          <div className={'white-40 mt2'}>None found</div>
        ) : (
          results.map(text => (
            <div
              onClick={() => {
                dispatch(
                  Map({
                    type: 'FETCH_TEMPLATE',
                    path: model.get('path'),
                    text,
                    uuid,
                  }),
                );
              }}
              className={'pv1 dim pointer flex items-center'}
              key={text}
            >
              <Add16 className={'fill--white'} />
              {text}
            </div>
          ))
        )}
      </Section>
      <Section title={'Internal'}>
        <div className={'white-40 mt2'}>None found</div>
      </Section>
    </Blade>
  );
};
