import React from 'react';
import './ToggleButton.css';

const ToggleButton = ({ disabled, ...props }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        disabled={disabled}
        checked={props.checked}
        onChange={props.onChange}
        {...props}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default ToggleButton;
