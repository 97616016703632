import React from 'react';
import cx from 'classnames';
import {
  TableProps as BaseTableProps,
  useGlobalFilter,
  useSortBy,
  useTable,
  useFilters,
  usePagination,
  useRowSelect,
  Column,
} from 'react-table';
import shortid from 'shortid';
import { RCA, AnalysisMethodology, DispatchProp, AnalysisStatusDisplay, AnalysisStatus } from '@interfaces';
import { _, calculateFailureCost, extractDatePart, toUSD } from 'utils';
import { Pagination, Select, ConfirmationPopover } from 'components';
import { Map } from 'immutable';
import { Archive20, TrashCan20, Wikis16 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { SingleValue } from 'react-select';

import { updateTree } from 'Sync';
import { Avatar } from '../../../Nav';
import { HeaderCell } from '../common/HeaderCell';
import { MethodologyCell } from './MethodologyCell';
// import { SelectionActions } from './SelectionActions';
import { TableFilters } from './TableFilters';
import { DataTableProvider, useDataTableContext } from '../Context';
import styles from '../styles.module.css';

const getTooltip = ({ isPublished, isOwnerOrAdmin }) => {
  if (isPublished && isOwnerOrAdmin) {
    return 'Unpublish';
  } else if (isPublished && !isOwnerOrAdmin) {
    return 'Published';
  } else if (!isPublished && isOwnerOrAdmin) {
    return 'Publish';
  }
};

const PublishedIndicator = ({
  // canChangeStatus,
  onClick,
  isPublished,
  isCompleted,
  isOwner,
  isAdmin,
  disabled = false,
}: {
  onClick: (...a: any[]) => void;
  // hidden: boolean;
  // canChangeStatus: boolean;
  // tooltip: string;
  isOwner: boolean;
  isAdmin: boolean;
  isCompleted: boolean;
  isPublished: boolean;
  disabled?: boolean;
}) => {
  if (!isCompleted || (!isAdmin && !isPublished && !isOwner)) {
    return null;
  }

  const tooltipProps = {
    'data-tip': getTooltip({ isPublished, isOwnerOrAdmin: isOwner || isAdmin }),
    'data-place': 'top',
    'data-effect': 'solid',
  };

  const Wrapper =
    isOwner || isAdmin
      ? p => (
          <button disabled={disabled} onClick={onClick} className="icon-button ph2" {...p}>
            {p.children}
          </button>
        )
      : p => <span {...p}>{p.children}</span>;

  return (
    <Wrapper onClick={onClick} className="icon-button ph2" {...tooltipProps}>
      <Wikis16
        className={cx({
          'fg--green': isPublished,
          'fg--black-50': !isPublished,
        })}
      />
    </Wrapper>
  );
};

type FilteringProps = {
  globalFilter?: string;
  filterableColumnId?: string;
  currentColumnFilterValue?: string;
};

type TableProps = Omit<BaseTableProps, 'columns'> &
  FilteringProps & {
    token: string;
    currentUser?: string;
    currentUserRoles?: string[];
    onClearFilter: () => void;
    onSearch: (q: string) => void;
    onSelectStatus: (s: SingleValue<string>) => void;
    onSelectOwner: (s: SingleValue<string>) => void;
    onSelectType: (s: SingleValue<AnalysisMethodology>) => void;
    onPublish: (...a: any[]) => void;
    owners: { fullName: string | null; username: string }[] | [];
    totalResults: number;
    searchQuery?: string;
    data: Map<string, RCA>;
    dispatch: DispatchProp;
    isLoading: boolean;
  };

export const RCATable: React.FC<TableProps> = ({
  dispatch,
  token,
  currentUserRoles,
  currentUser,
  data,
  isLoading,
  ...props
}) => {
  const { setInstance } = useDataTableContext<RCA>();
  const statusSelectStyles = React.useRef({
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      paddingRight: 8,
      paddingLeft: 8,
    }),
    valueContainer: provided => ({
      ...provided,
      paddingRight: 0,
    }),
    control: provided => ({
      ...provided,
      borderColor: 'transparent',
      background: 'transparent',
    }),
  }).current;
  const statusOptions = React.useMemo(
    () =>
      Object.keys(AnalysisStatusDisplay).map(key => ({
        label: AnalysisStatusDisplay[key],
        value: key,
      })),
    [],
  );
  const handleChangeRCAStatus = React.useCallback(
    treeUuid => newValue => {
      return dispatch(Map({ type: 'SET_STATUS', treeUuid, status: newValue.value }));
    },
    [dispatch],
  );
  const memoizedData = React.useMemo(() => {
    const js = data.toJS();
    const collection = Object.values(js) as RCA[];

    return collection;
  }, [data]);

  const memoizedColumns: Column<RCA & { score?: number }>[] = React.useMemo(
    () => {
      return [
        {
          Header: 'Tree UUID',
          width: 0,
          id: 'treeUuid',
          accessor: 'treeUuid',
        },
        {
          Header: 'Number',
          width: 0,
          id: 'number',
          accessor: 'number',
        },
        {
          Header: 'Score',
          width: 0,
          id: 'score',
          accessor: 'score',
        },
        {
          id: 'isPublished',
          accessor: row => row.published !== false,
        },
        {
          id: 'isOwner',
          accessor: row => row.owner === currentUser,
        },
        {
          id: 'isAdmin',
          accessor: () => currentUserRoles.includes('ADMIN'),
        },
        {
          Header: ({ column }) => (
            <HeaderCell align="left" {...column.getHeaderProps()} width={85}>
              Owner
            </HeaderCell>
          ),
          id: 'owner',
          accessor: 'owner',
          Cell: ({ row }) => {
            const username = row.original['ownerName'];
            console.info('owner row', row);
            return (
              <div className="w-100 flex items-center justify-center">
                <Avatar username={username} height={40} />
              </div>
            );
          },
        },
        {
          Header: ({ column }) => (
            <HeaderCell {...column.getHeaderProps()} width={70}>
              Type
            </HeaderCell>
          ),
          id: 'type',
          accessor: row => row.methodology,
          Cell: ({ value }: { value: AnalysisMethodology }) => {
            return <MethodologyCell type={value} />;
          },
        },
        {
          id: 'description',
          width: 0,
          accessor: rca =>
            // @ts-ignore
            rca.description || _(props.i18n, 'No Problem Statement'),
        },
        {
          Header: ({ column }) => (
            <HeaderCell
              {...column.getHeaderProps({
                ...column.getSortByToggleProps(),
              })}
              border
              key={column.id}
              isSorted={column.isSorted}
              isSortedDesc={column.isSortedDesc}
              sortable
              maxWidth={475}
            >
              Title & Description
            </HeaderCell>
          ),
          id: 'title-and-description',
          Cell: ({ value, row }) => {
            const treeUuid = row.values['treeUuid'];
            const problemStatement = row.values['description'];
            const number = row.values['number'];
            const href = `/tree/${treeUuid}`;
            return (
              <div className="truncate-parent flex flex-column items-start justify-center">
                <Link to={href} key={treeUuid} className={cx('no-underline black', styles.bodyCellTitleWrapper)}>
                  <div className="flex flex-row items-stretch justify-start">
                    <span className="mr2 f7">#{number}</span>
                    <p className={cx('no-i18n fw6', styles.bodyCellTitle)}>
                      <div className={`row_id_${row.id}`}>{value}</div> {/* the row_id_ class is for Intercom tour */}
                    </p>
                  </div>
                  <p className={cx(styles.bodyCellDescription, 'truncate-overflow')}>{problemStatement}</p>
                  {/* <p className={cx(styles.bodyCellDescription, 'truncate-overflow')}>{row.original.treeUuid}</p> */}
                </Link>
              </div>
            );
          },
          sortType: 'string',
          // sortType: (a, b, id, descending) => {
          //   const titleRowA = a.values[id].title;
          //   const titleRowB = b.values[id].title;

          //   if (descending) {
          //     if (
          //       titleRowA.includes('[Untitled]') ||
          //       titleRowB.includes('[Untitled]')
          //     ) {
          //       return -1;
          //     }

          //     const uppercasedA = titleRowA.toUpperCase();
          //     const uppercasedB = titleRowB.toUpperCase();

          //     return uppercasedA < uppercasedB
          //       ? -1
          //       : uppercasedA > uppercasedB
          //       ? 1
          //       : 0;
          //   } else {
          //     if (
          //       titleRowA.includes('[Untitled]') ||
          //       titleRowB.includes('[Untitled]')
          //     ) {
          //       return 1;
          //     }
          //   }

          //   return 1;
          // },
          accessor: row => {
            return (
              row.title ||
              // @ts-ignore
              `[${_(props.i18n, 'Untitled')}] ${row.methodology} ${_(
                // @ts-ignore
                props.i18n,
                'Analysis',
              )}`
            );
            // return {
            //   title: row.title || `[Untitled] ${row.methodology} Analysis`,
            //   description: row.description || 'No Problem Statement',
            // };
          },
        },
        {
          Header: 'Start',
          id: 'start',
          width: 125,
          accessor: row => {
            if (row.startAt) {
              return extractDatePart(new Date(row.startAt)).toLocaleDateString();
            }
            return extractDatePart(new Date(row.createdAt)).toLocaleDateString();
          },
          sortType: (a, b) => {
            // Note here that we are sorting by startSort, this is because it is an actual date, otherwise
            // we cannot know what format the date is in (e.g. in EU it is DD/MM/YYYY).
            const a1 = a.values.startSort;
            const b1 = b.values.startSort;
            if (a1 < b1) return 1;
            else if (a1 > b1) return -1;
            else return 0;
          },
        },
        {
          id: 'startSort',
          accessor: row => {
            if (row.startAt) {
              return new Date(row.startAt);
            }
            return new Date(row.createdAt);
          },
        },
        {
          Header: 'Completion',
          id: 'completion',
          width: 140,
          accessor: row => {
            if (row.completedAt) {
              return extractDatePart(new Date(row.completedAt)).toLocaleDateString();
            }
            // @ts-ignore
            return _(props.i18n, 'N/A');
          },
          sortType: (a, b) => {
            const a1 = a.values.completionSort;
            const b1 = b.values.completionSort;
            if (a1 < b1) return 1;
            else if (a1 > b1) return -1;
            else return 0;
          },
        },
        {
          id: 'completionSort',
          accessor: row => {
            if (row.completedAt) {
              return new Date(row.completedAt);
            }
            return 0;
          },
        },
        {
          // Header: ({ column }) => (
          //   <HeaderCell
          //     {...column.getHeaderProps({
          //       ...column.getSortByToggleProps(),
          //     })}
          //     border
          //     key={column.id}
          //     isSorted={column.isSorted}
          //     isSortedDesc={column.isSortedDesc}
          //     align="right"
          //   >
          //     Cost
          //   </HeaderCell>
          // ),
          Header: 'Cost',
          id: 'cost',
          width: 110,
          align: 'right',
          accessor: row => {
            const { total } = calculateFailureCost({
              laborCost: row.laborCost,
              productionCost: row.productionCost,
              propertyCost: row.propertyCost,
              frequency: row.frequency,
            });

            return total;
          },
          Cell: ({ value }) => {
            const formattedCost = toUSD(value, { dropDecimal: true });
            return (
              <p style={{ textAlign: 'right' }}>
                {/* @ts-ignore */}
                {formattedCost === '$0' ? _(props.i18n, 'N/A') : formattedCost}
              </p>
            );
          },
        },
        {
          Header: 'Tasks',
          align: 'right',
          width: 100,
          accessor: row => {
            const totalTasks = row.tasksCompleted + row.tasksOpen;

            return `${row.tasksCompleted}/${totalTasks}`;
          },
          Cell: ({ value }) => <p style={{ textAlign: 'right' }}>{value}</p>,
        },
        {
          Header: 'Status',
          width: 170,
          id: 'status',
          // accessor: row => ({ status: row.status, treeUuid: row.treeUuid }),
          accessor: 'status',
          Cell: ({ row, value }) => {
            const treeUuid = row.values['treeUuid'];
            const isPublished = row.values['isPublished'];
            const handleOnChange = handleChangeRCAStatus(treeUuid);

            return (
              // @ts-ignore
              <Select
                isDisabled={isPublished}
                className="i18n"
                styles={statusSelectStyles}
                options={statusOptions as { label: string; value: AnalysisStatus }[]}
                value={{
                  value: value,
                  label: AnalysisStatusDisplay[value],
                }}
                onChange={handleOnChange}
                isClearable={false}
              />
            );
          },
        },
        {
          Header: () => (
            <HeaderCell width={100} maxWidth={100} border={false}>
              &nbsp;
            </HeaderCell>
          ),
          accessor: row => row,
          id: 'actions',
          sortable: false,
          Cell: ({ row, value, cell }) => {
            const isTemplate = cell.value.isTemplate;
            const isCompleted = cell.value.status === AnalysisStatus.COMPLETE;
            const isPublished = cell.value.published !== false;
            const uuid = value.treeUuid;

            return (
              <div className="relative flex items-center justify-end">
                <PublishedIndicator
                  isOwner={row.values.isOwner}
                  isAdmin={row.values.isAdmin}
                  isCompleted={isCompleted}
                  isPublished={isPublished}
                  onClick={
                    !row.values.isOwner && !row.values.isAdmin
                      ? () => null
                      : () => {
                          if (!isCompleted) {
                            return dispatch(
                              Map({
                                type: 'SHOW_TOAST',
                                message: 'Cannot publish an incomplete analysis.',
                                style: 'SUCCESS',
                              }),
                            );
                          }

                          dispatch(
                            Map({
                              type: 'SET_TREE_PUBLISHED',
                              treeUuid: uuid,
                              published: isPublished ? false : true,
                            }),
                          );
                          props.onPublish({ isPublished: isPublished ? false : true, treeUuid: uuid });
                        }
                  }
                />
                {/* {currentUser === owner ||
                (currentUserRoles.includes('ADMIN') && (
                  <PublishedIndicator
                    hidden={false}
                    disabled={!isCompleted}
                    tooltip={isPublished ? 'Unpublish' : 'Publish'}
                    canChangeStatus={currentUserRoles.includes('ADMIN')}
                    isPublished={isPublished}
                  />
                ))}
              {isPublished && !currentUserRoles.includes('ADMIN') && (
                <PublishedIndicator
                  tooltip="Published"
                  hidden={false}
                  onClick={() => {
                    console.warn('tree is published, doing nothing');
                  }}
                  // onClick={() => {
                  //   dispatch(
                  //     Map({
                  //       type: 'SET_TREE_PUBLISHED',
                  //       treeUuid: row.original.treeUuid,
                  //       published: isPublished ? false : true,
                  //     }),
                  //   );
                  // }}
                  canChangeStatus={false}
                  isPublished
                />
              )} */}
                {row.values.isOwner || row.values.isAdmin ? (
                  <>
                    <ConfirmationPopover
                      // @ts-ignore
                      tooltip={_(props.i18n, 'Delete')}
                      // @ts-ignore
                      label={_(props.i18n, 'Really delete?')}
                      onConfirm={() => {
                        dispatch(
                          Map({
                            type: 'DELETE_TREE',
                            treeUuid: row.original.treeUuid,
                          }),
                        );
                      }}
                    >
                      <TrashCan20 className="fg--black-50 fg-hover" />
                    </ConfirmationPopover>
                    <button
                      className="icon-button ph2 i18n"
                      data-tip={
                        isTemplate
                          ? // @ts-ignore
                            `${_(props.i18n, 'Remove from Template Library')}`
                          : // @ts-ignore
                            `${_(props.i18n, 'Add to Template Library')}`
                      }
                      data-effect="solid"
                      data-class="i18n"
                      onClick={() => {
                        return updateTree({
                          token,
                          treeUuid: row.original.treeUuid,
                          updates: { isTemplate: isTemplate ? false : true },
                        })
                          .then(results => {
                            if (results.ok) {
                              dispatch(
                                Map({
                                  type: 'SHOW_TOAST',
                                  message: `Successfully ${isTemplate ? 'removed from' : 'added to'} template library.`,
                                  style: 'SUCCESS',
                                }),
                              );
                              return dispatch({
                                type: 'TREE_UPDATED',
                                treeUuid: row.original.treeUuid,
                                updates: { isTemplate: isTemplate ? false : true },
                              });
                            } else {
                              return dispatch(
                                Map({
                                  type: 'SHOW_TOAST',
                                  message: results.error,
                                  duration: 4000,
                                  style: 'ERROR',
                                }),
                              );
                            }
                            // }, 5000);
                          })
                          .catch(e => console.error(e));
                        // if (isTemplate) {
                        //   updateTree({ treeUuid: row.original.treeUuid, updates: { isTemplate: false }, token })
                        //     .then(({ data }) => {
                        //       dispatch(
                        //         Map({
                        //           type: 'TREE_UPDATED',
                        //           treeUuid: row.original.treeUuid,
                        //           updates: data,
                        //         }),
                        //       );
                        //       dispatch(
                        //         Map({
                        //           type: 'SHOW_TOAST',
                        //           message: 'Successfully removed from template library.',
                        //         }),
                        //       );
                        //     })
                        //     .catch(() => {
                        //       dispatch(
                        //         Map({
                        //           type: 'SHOW_TOAST',
                        //           message: 'Failed to remove from template library.',
                        //         }),
                        //       );
                        //     });
                        // } else {
                        //   updateTree({ treeUuid: row.original.treeUuid, updates: { isTemplate: true }, token })
                        //     .then(() => {
                        //       dispatch(
                        //         Map({
                        //           type: 'SHOW_TOAST',
                        //           message: 'Successfully added to template library.',
                        //         }),
                        //       );
                        //     })
                        //     .catch(() => {
                        //       dispatch(
                        //         Map({
                        //           type: 'SHOW_TOAST',
                        //           message: 'Failed to add to template library.',
                        //         }),
                        //       );
                        //     });
                        // }
                      }}
                    >
                      <Archive20 className={isTemplate ? 'fg-hover fg--blue' : 'fg-hover fg--black-50'} />
                    </button>
                  </>
                ) : null}
              </div>
            );
          },
        },
      ];
    },
    // @ts-ignore
    [dispatch, props.i18n, currentUser, currentUserRoles, data], // props.i18n
  );

  const initialFilters = React.useMemo(
    () => [
      {
        id: 'type',
        value: '',
      },
      {
        id: 'status',
        value: '',
      },
      {
        id: 'isPublished',
        value: '',
      },
      {
        id: 'owner',
        value: '',
      },
    ],
    [],
  );

  const instance = useTable<RCA>(
    {
      data: memoizedData,
      // @ts-ignore
      columns: memoizedColumns,
      manualGlobalFilter: true,
      autoResetFilters: false,
      autoResetPage: false,
      disableSortRemove: true,
      initialState: {
        filters: initialFilters,
        hiddenColumns: [
          'treeUuid',
          'number',
          'score',
          'isPublished',
          'description',
          'startSort',
          'completionSort',
          'isOwner',
          'isAdmin',
        ],
        pageSize: 10,
        sortBy: [props.searchQuery ? { id: 'score', desc: true } : { id: 'start', desc: false }],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    setAllFilters,
    setFilter,
    rows,
    // toggleAllRowsSelected,
    // selectedFlatRows,
    state: { pageIndex, pageSize, filters },
  } = instance;

  React.useEffect(() => setInstance(instance), [instance, setInstance]);

  /*
   * watch for filter changes and filter the rows appropriately
   * ...using separate effects for each filter type
   */
  // React.useEffect(() => {
  //   if (currentFilterType) {
  //     setFilter(currentFilterType, currentFilterValue);
  //   }
  // }, [currentFilterType, currentFilterValue, setFilter]);

  // React.useEffect(() => {
  //   if (props.filterableColumnId && props.currentColumnFilterValue) {
  //     setFilter(props.filterableColumnId, props.currentColumnFilterValue);
  //   }
  // }, [props.filterableColumnId, props.currentColumnFilterValue, setFilter]);

  return (
    <DataTableProvider dispatch={dispatch}>
      <div className="w-100 flex flex-column align-center justify-start">
        <TableFilters
          // @ts-ignore
          i18n={props.i18n}
          // @ts-ignore
          orgMembers={props.owners}
          onSearchChange={props.onSearch}
          setAllFilters={setAllFilters}
          setFilter={setFilter}
          filters={filters}
          searchQuery={props.searchQuery}
        />
        <div className={styles.tableFilters}>
          {/* For now, we'll launch without selection actions */}
          {/* {Object.keys(selectedRowIds).length > 0 && (
            <SelectionActions
              // totalSelected={Object.keys(selectedRowIds).length}
              selectedRows={selectedFlatRows}
              onClearSelection={toggleAllRowsSelected}
              onDelete={() => null}
              onAddToTemplates={() => null}
            />
          )} */}
          <p className="i18n f5 mb0 mt0 ph2 fw5">{rows.length} results</p>
          <Pagination
            className="ml-auto"
            currentPage={pageIndex + 1}
            onClickNext={nextPage}
            onClickPrevious={previousPage}
            hasPrevious={canPreviousPage}
            hasNext={canNextPage}
            totalPages={pageCount}
            onClickPageNumber={gotoPage}
            isLoading={isLoading}
          />
        </div>
        <table {...getTableProps()} className={cx('collapse w-100', styles['fixedLayout'])}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className={styles['headerRow']} {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column, idx) => {
                  if (column.Header && typeof column.Header === 'function') {
                    return column.render('Header');
                  }

                  return (
                    <HeaderCell
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                      })}
                      maxWidth={column.maxWidth}
                      width={column.width}
                      border={++idx != headerGroup.headers.length - 1}
                      key={column.id}
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                      // @ts-ignore
                      align={column.align}
                      sortable={column.canSort}
                    >
                      {column.render('Header')}
                    </HeaderCell>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, idx) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id} className={idx % 2 === 0 ? '' : 'bg--black-05'}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} key={shortid()} className={styles.bodyCell}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </DataTableProvider>
  );
};
