import React from 'react';
import { usePopper } from 'react-popper';
import { Map } from 'immutable';
import cx from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';
import maxSize from 'popper-max-size-modifier';
import { UserMultiple16, Close20 } from '@carbon/icons-react';
import { Avatar } from './Nav';

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { height, width } = state.modifiersData.maxSize;

    state.styles.popper = {
      ...state.styles.popper,
      zIndex: '2',
      maxHeight: `${height}px`,
      maxWidth: `${width}px`,
    };
  },
};

export const GroupMembers = ({
  buttonLabel = '',
  currentUser,
  currentUserIsAdmin,
  admins,
  members,
  groupUuid,
  onToggleAdminStatus,
  onRemoveMember,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      maxSize,
      {
        name: 'flip',
        options: { flipVariations: false, fallbackPlacements: [] },
      },
      // @ts-expect-error
      applyMaxSize,
    ],
    placement: 'bottom-start',
  });

  const handleOnClick = React.useCallback(() => {
    setIsVisible(state => !state);
  }, []);

  const detectClicksRef = useDetectClickOutside({
    onTriggered: () => setIsVisible(false),
  });

  return (
    <div ref={detectClicksRef}>
      <button
        className="toggle-button pa2 flex items-center justify-start"
        onClick={handleOnClick}
        ref={setReferenceElement}
      >
        <UserMultiple16 className="mr2" />
        <span className="i18n">{buttonLabel || 'Members'}</span>
      </button>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="members-popover">
            {members && members.size < 1 ? (
              <div className="pa3 flex items-stretch justify-start flex-column">
                <div className="f6 fg--black-80">No members found</div>
              </div>
            ) : (
              <ul>
                {members.map &&
                  members
                    .sort((a, b) => {
                      const splitNameA = a.get('fullName').split(' ');
                      const splitNameB = b.get('fullName').split(' ');
                      const lastNameA = splitNameA[splitNameA.length - 1];
                      const lastNameB = splitNameB[splitNameB.length - 1];

                      if (lastNameA < lastNameB) {
                        return -1;
                      }

                      if (lastNameA > lastNameB) {
                        return 1;
                      }

                      return 0;
                    })
                    .map(m => {
                      const memberUsername = m.get('username');
                      const isAdmin = admins.includes(memberUsername);
                      // Cannot edit if (1) you are not admin (2) edit yourself if no other admins exist (3) if it's the only member (TODO: empty groups are a problem).
                      const disableAdminToggling =
                        !currentUserIsAdmin ||
                        (memberUsername === currentUser && admins.size <= 1) ||
                        members.length == 1;
                      const handleToggleAdmin = () =>
                        onToggleAdminStatus({
                          username: memberUsername,
                          groupUuid,
                          isPromotion: !isAdmin,
                        });

                      return (
                        <li key={memberUsername}>
                          <div className="member-list-item flex items-stretch justify-start">
                            <button
                              disabled={disableAdminToggling}
                              className="flex items-center justify-start pa2"
                              onClick={handleToggleAdmin}
                            >
                              <div className="flex avatar">
                                <Avatar height={40} username={memberUsername} />
                              </div>
                              <div className="w-70 ph2 flex items-start justify-start flex-column meta">
                                <span className="fw6 pb1 full-name" style={{ textAlign: 'left' }}>
                                  {m.get('fullName')}
                                </span>
                                <span className="username" style={{ textAlign: 'left' }}>
                                  {memberUsername}
                                </span>
                              </div>
                              <div className="flex ph3 items-center justify-start">
                                <input
                                  type="checkbox"
                                  id={`${memberUsername}-isAdmin`}
                                  disabled={disableAdminToggling}
                                  checked={isAdmin}
                                />
                                <label
                                  className={cx('ml2 f6 fw6', {
                                    'o-50': disableAdminToggling,
                                  })}
                                  htmlFor={`${memberUsername}-isAdmin`}
                                >
                                  Admin
                                </label>
                              </div>
                            </button>
                            {!disableAdminToggling && (
                              <button
                                className={cx('mw2 ph2 flex items-center justify-center ml-auto bg-white', {
                                  'o-50': members.size === 1,
                                })}
                                onClick={() => {
                                  onRemoveMember(memberUsername);
                                }}
                              >
                                <Close20 />
                              </button>
                            )}
                          </div>
                        </li>
                      );
                    })}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
