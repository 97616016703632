// @ts-nocheck
import { Map, List, Set, fromJS } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import { _ } from 'utils';
import watermark from 'watermarkjs';
import { debounce, get } from 'lodash';
import { reducer as saReducer } from '@applications';
import { AnalysisStatusDisplay, EVENTS, ImmutableStateMap } from '@interfaces';
import { sendWS } from './Sync';
import { wsReducer, copyToClipboard } from './WSReducer';
import { applyChain, patchReducer } from './Patch';
import { typeToChildNode as proactTypeToChild } from './PROACT';
import { typeToChildNode as fishboneTypeToChild } from './Fishbone';
import { typeToChildNode as fiveWhysTypeToChild } from './FiveWhys';
import { Node } from './Tree';
import { shutdownIntercomIo } from './intercom';
import { trackEvent, identify, setProfileProperties } from './containers';

const blankAsNull = (s: any) => (!s ? null : s);

const debugHostnames = Set([
  'localhost:3000',
  'localhost:1338',
  'staging.easyrca.com',
  'dev.easyrca.com',
  'dev2.easyrca.com',
  'dev3.easyrca.com',
  '127.0.0.1:3000',
]);

export const defaultApplicationState = Map({
  url: window.location.hash.substring(1),
  referrer: null,
  debug: debugHostnames.has(window.location.host),
  customFields: Map(),
  filterType: 'ALL',
  trees: Map(),
  reports: Map(),
  username: null,
  users: Map(),
  token: null,
  ws: null,
  i18n: {},
  webhooks: [],
});

export const showToast = (state, message, style) => state.set('toast', Map({ message, style }));
// const tryParseFloat = f => {
//   const x = parseFloat(f);
//   return Number.isNaN(x) ? 0.0 : x;
// };

// const tryParseInt = (i: string) => {
//   const x = parseInt(i);
//   return Number.isNaN(x) ? 0 : x;
// };

const statuses = List(['PENDING', 'IN_PROCESS', 'REVIEW', 'COMPLETE', 'ARCHIVE']);
const readableStatusMap = {
  PENDING: 'Pending',
  IN_PROCESS: 'In Process',
  REVIEW: 'Review',
  COMPLETE: 'Complete',
  ARCHIVE: 'Archive',
};

const prevStatus = status => {
  const prevIdx = Math.max(0, statuses.indexOf(status) - 1);
  return statuses.get(prevIdx);
};

const nextStatus = status => {
  const nextIdx = Math.min(statuses.size - 1, statuses.indexOf(status) + 1);
  return statuses.get(nextIdx);
};

const unselectCyElements = state => state.getIn(['tree', 'view', 'cy']).elements().unselect();

const childNode = (state, path) => {
  const type = state.getIn(['tree', 'elements']).getIn(path).get('type');
  const treeUuid = state.getIn(['tree', 'uuid']);
  const methodology = state.getIn(['trees', treeUuid, 'methodology']);

  switch (methodology) {
    case '5WHYS':
      return fiveWhysTypeToChild(type);
    case 'PROACT':
      return proactTypeToChild(type);
    case 'FISHBONE':
      return fishboneTypeToChild(type);
    default:
      console.error(`unknown type: ${type}`);
  }
};

const updateMetadata = (state, path, k, v) => {
  const oid = state.getIn(['tree', 'elements']).getIn(path).get('oid');
  return state.setIn(['tree', 'metadata', oid, k], v);
};

const eventToCoordinates = event => Map(event.target.renderedBoundingBox());

const watermarker = (state, blob, callback) => {
  const cy = state.getIn(['tree', 'view', 'cy']);
  const isPaid = Set(state.getIn(['users', state.get('username'), 'roles'], List())).has('PAID');

  const { w, h } = cy.elements().boundingBox();
  const textSize = Math.min(w, h) / 5;

  if (isPaid) {
    callback(null, blob);
  } else {
    watermark([new File([blob], 'image.png')])
      .blob(
        /* @ts-ignore */
        watermark.text.center('EasyRCA Free Trial', `${textSize}px sans-serif`, '#ff8700', 0.5),
      )
      .render()
      .blob(
        /* @ts-ignore */
        watermark.text.upperLeft('EasyRCA Free Trial', `${textSize}px sans-serif`, '#ff8700', 0.5, textSize),
      )
      .render()
      .blob(
        /* @ts-ignore */
        watermark.text.lowerRight('EasyRCA Free Trial', `${textSize}px sans-serif`, '#ff8700', 0.5),
      )
      .then(blob => callback(null, blob))
      .catch(err => callback(err, null));
  }
};

// When we copy a subtree, we want new OIDs so we don't have issues with
// references to things like notes.
const modifyOids = subtree => Node(subtree).update('children', chs => chs.map(modifyOids));

const updateChildElements = (children, isHidden) => {
  // children = children.reverse();
  return children.map(child => {
    let updatedChild = child.set('hidden', isHidden);
    const nextChild = updatedChild.get('children');
    if (!nextChild.isEmpty()) {
      const newChild = updateChildElements(nextChild, isHidden);
      updatedChild = updatedChild.set('children', newChild);
      return updatedChild;
    } else {
      return updatedChild;
    }
  });
  // console.log(node.toJS(), "NodeFOREACH")
};

const debouncedSendWS = debounce(sendWS, 500);

// const updateTree = (state, tree, treeUuid) => {
//   const methodology = tree.get('methodology');
//   const members = tree.get('members');
//   const createdAt = tree.get('createdAt');
//   const createdBy = tree.get('createdBy');
//   const deletedAt = tree.get('deletedAt', null);
//   const canView = tree.get('canView', false);
//   const isTemplate = tree.get('isTemplate', false);
//   const title = blankAsNull(tree.get('title'));
//   const description = blankAsNull(tree.get('description'));
//   const findings = blankAsNull(tree.get('findings'));
//   const groupUuid = blankAsNull(tree.get('groupUuid'));

//   const equipmentTypeUuid = tree.get('equipmentTypeUuid', null);
//   const equipmentClassUuid = tree.get('equipmentClassUuid', null);
//   const equipmentCodeUuid = tree.get('equipmentCodeUuid', null);
//   const equipmentType = tree.get('equipmentType', null);
//   const equipmentClass = tree.get('equipmentClass', null);
//   const equipmentCode = tree.get('equipmentCode', null);

//   const injuries = blankAsNull(tree.get('injuries'));
//   const status = tree.get('status');

//   const safetyImpact = blankAsNull(tree.get('safetyImpact'));
//   const environmentalImpact = blankAsNull(tree.get('environmentalImpact'));
//   const customerImpact = blankAsNull(tree.get('customerImpact'));
//   const productionCost = blankAsNull(tree.get('productionCost'));
//   const propertyCost = blankAsNull(tree.get('propertyCost'));
//   const laborCost = blankAsNull(tree.get('laborCost'));
//   const frequency = blankAsNull(parseInt(tree.get('frequency')) || '');

//   const startAt = tree.get('startAt');
//   const eventAt = tree.get('eventAt');
//   const expectedAt = tree.get('expectedAt');
//   const completedAt = tree.get('completedAt');

//   const tasksCompleted = tree.get('tasksCompleted');
//   const tasksOpen = tree.get('tasksOpen');
//   const owner = tree.get('owner');
//   const ownerName = tree.get('ownerName');

//   const newTree = Map({
//     canView,
//     completedAt,
//     createdAt,
//     createdBy,
//     customerImpact,
//     deletedAt,
//     description,
//     environmentalImpact,
//     equipmentClass,
//     equipmentClassUuid,
//     equipmentCode,
//     equipmentCodeUuid,
//     equipmentType,
//     equipmentTypeUuid,
//     eventAt,
//     expectedAt,
//     facilityDepartment: tree.get('facilityDepartment'),
//     facilityDepartmentUuid: tree.get('facilityDepartmentUuid'),
//     facilityLocation: tree.get('facilityLocation'),
//     facilityLocationUuid: tree.get('facilityLocationUuid'),
//     facilitySite: tree.get('facilitySite'),
//     facilitySiteUuid: tree.get('facilitySiteUuid'),
//     findings,
//     frequency,
//     groupUuid,
//     injuries,
//     isTemplate,
//     laborCost,
//     members,
//     methodology,
//     owner,
//     ownerName,
//     productionCost,
//     propertyCost,
//     safetyImpact,
//     startAt,
//     status,
//     tasksCompleted,
//     tasksOpen,
//     title,
//     treeUuid,
//   });

//   sendWS(
//     state.get('ws'),
//     Map({
//       type: 'UPDATE_TREE',
//       tree: newTree,
//     }),
//   );
//   return state.setIn(['trees', treeUuid], newTree);
// };

export const reducer = (state: ImmutableStateMap, action: Map<string, any> | Record<string, unknown>) => {
  // @ts-ignore
  const type = action.get ? action.get('type') : action.type;
  const messageType = action.getIn ? action.getIn(['message', 'type'], null) : get(action, ['message', 'type'], null);

  // if (__DEV__) {
  console.groupCollapsed(messageType ? `${messageType} (WS)` : type);
  console.info('state', state.toJS ? state.toJS() : state);
  console.info('action', action.toJS ? action.toJS() : action);
  console.trace({ action, state });
  console.groupEnd();
  // }

  const i18n = state.get('i18n');

  switch (type) {
    case 'COPY_TO_CLIPBOARD': {
      const { value, message } = action;
      copyToClipboard(value);
      return state.set(
        'toast',
        /* @ts-ignore more immutable.js typing nonsense */
        Map({
          message,
          style: 'SUCCESS',
        }),
      );
    }

    // superadmin actions
    case 'SET_ORGANIZATIONS':
    case 'SET_ORGANIZATION':
    case 'SET_USER':
    case 'SET_COUNTS':
    case 'SHOW_MODAL':
    case 'HIDE_MODAL': {
      return saReducer(state, action);
    }

    case 'SET_I18N_STRINGS': {
      // @ts-ignore
      return state.set('i18n', action.get('strings'));
    }

    case 'SET_WEBHOOKS': {
      const results = state.merge({ webhooks: action.data });

      return results;
    }

    case 'ADD_WEBHOOK': {
      const results = state.merge({
        webhooks: state.get('webhooks').concat(action.data),
      });

      return results;
    }

    case 'UPDATE_WEBHOOK': {
      const existingHooks = state.get('webhooks');
      const existingWebhook = existingHooks.filter(h => h.key === action.webhookUuid)[0];

      if (!existingWebhook) {
        console.error(`Failed to update webhook. No webhook with key ${action.webhookUuid} found`);
        return state;
      }

      const updates = existingHooks.map(hook => {
        if (hook.key === action.webhookUuid) {
          return {
            ...hook,
            testMode: action.testMode,
          };
        }

        return hook;
      });

      const results = state.merge({
        webhooks: updates,
      });

      return results;
    }

    case 'GET_CUSTOM_FIELDS': {
      const customFields = action.get('payload');
      const results = state.merge({ customFields });

      return results;
    }

    case 'ADD_CUSTOM_FIELD_VALUE': {
      const payload = action.get('payload');
      const { treeUuid, value, customFieldUuid } = payload;
      const results = state.mergeIn(
        ['trees', treeUuid, 'customValues'],
        Map({ [customFieldUuid]: value }),
        // Map({
        //   label: value,
        //   type,
        //   size,
        // }),
      );

      return results;
    }

    case 'REMOVE_CUSTOM_FIELD_VALUE': {
      const payload = action.get('payload');
      const { treeUuid, customFieldUuid } = payload;
      const results = state.deleteIn(['trees', treeUuid, 'customValues', customFieldUuid]);

      return results;
      // state.updateIn(['trees', treeUuid, 'customValues', customFieldUuid], customValues => customValues.filter(v => {
      //   console.trace('custom value', v)
      //   return v.uuid !== customFieldUuid;
      // }))
      // const results = state.mergeIn(['trees', treeUuid, 'customValues'], { 'xxx-yyy-zzz-placeholder-uuid': value });

      // return results;
    }

    case 'LIST_GROUPS': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          username: action.get('username'),
        }),
      );

      return state;
    }

    case 'ADD_GROUP': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          groupName: action.get('groupName'),
        }),
      );

      return state;
    }

    case 'DELETE_GROUP': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          groupUuid: action.get('groupUuid'),
        }),
      );

      return state;
    }

    case 'UPDATE_GROUP': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          groupUuid: action.get('groupUuid'),
          // @ts-ignore
          groupName: action.get('groupName'),
        }),
      );

      return state;
    }

    case 'GET_GROUP_MEMBERS': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          groupUuid: action.get('groupUuid'),
        }),
      );

      return state;
    }

    case 'ADD_USER_TO_GROUP': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          groupUuid: action.get('groupUuid'),
          // @ts-ignore
          username: action.get('username'),
          // @ts-ignore
          admin: action.get('admin'),
        }),
      );

      return state;
    }

    case 'REMOVE_USER_FROM_GROUP': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          groupUuid: action.get('groupUuid'),
          // @ts-ignore
          username: action.get('username'),
        }),
      );

      return state;
    }

    case 'SET_GROUP_ADMIN': {
      sendWS(
        state.get('ws'),
        Map({
          type,
          // @ts-ignore
          groupUuid: action.get('groupUuid'),
          // @ts-ignore
          username: action.get('username'),
          // @ts-ignore
          admin: action.get('admin'),
        }),
      );

      return state;
    }

    case 'UNDO': {
      // @ts-ignore
      const patch = state.getIn(['tree', 'view', 'undoStack']).peek();
      if (patch) {
        const before = patch.get('before');

        sendWS(
          state.get('ws'),
          Map({
            type: 'PATCH',
            chain: before,
            treeUuid: state.getIn(['tree', 'uuid']),
          }),
        );

        return (
          state
            .updateIn(['tree', 'elements'], root => applyChain(root, before))
            // @ts-ignore
            .updateIn(['tree', 'view', 'undoStack'], us => us.pop())
            // @ts-ignore
            .updateIn(['tree', 'view', 'redoStack'], rs => rs.push(patch))
        );
      } else {
        return state;
      }
    }

    case 'REDO': {
      // @ts-ignore
      const patch = state.getIn(['tree', 'view', 'redoStack']).peek();
      if (patch) {
        const after = patch.get('after');

        sendWS(
          state.get('ws'),
          Map({
            type: 'PATCH',
            chain: after,
            treeUuid: state.getIn(['tree', 'uuid']),
          }),
        );

        return (
          state
            .updateIn(['tree', 'elements'], root => applyChain(root, after))
            // @ts-ignore
            .updateIn(['tree', 'view', 'redoStack'], rs => rs.pop())
            // @ts-ignore
            .updateIn(['tree', 'view', 'undoStack'], us => us.push(patch))
        );
      } else {
        return state;
      }
    }

    case 'AUTOCOMPLETE': {
      return state;
    }

    case 'FETCH_TEMPLATE': {
      // @ts-ignore
      const path = action.get('path');
      // @ts-ignore
      const text = action.get('text');
      const uuid = state.get('uuid');

      sendWS(state.get('ws'), Map({ type: 'FETCH_TEMPLATE', path, text, uuid }));

      return state;
    }

    case 'FETCH_TEMPLATE_LIST': {
      sendWS(state.get('ws'), action);
      return state;
    }

    case 'SET_SELECTED_TEMPLATE': {
      const treeUuid = action.getIn(['template', 'treeUuid']);
      const templateUuid = action.getIn(['template', 'templateUuid']);
      const isInternal = treeUuid !== undefined && !templateUuid;

      return state
        .set('selectedTemplate', fromJS(action.get('template')))
        .set('selectedTemplateUuid', action.get('uuid'))
        .set('selectedTemplateIsInternal', isInternal);
    }

    // case 'FETCH_TEMPLATE_PREVIEW':
    // case 'FETCH_INTERNAL_TEMPLATE_PREVIEW': {
    //   const type = action.get('type');
    //   // we're changing between 'treeUuid' and 'templateUuid' based on whether or not it's an internal template.
    //   const payload =
    //     type === 'FETCH_TEMPLATE_PREVIEW'
    //       ? action
    //       : {
    //           type: action.get('type'),
    //           treeUuid: action.get('templateUuid'),
    //         };
    //   sendWS(state.get('ws'), payload);

    //   return state.set('selectedTemplateUuid', action.get('templateUuid'));
    // }

    // case 'FETCH_INTERNAL_TEMPLATE_PREVIEW': {
    //   sendWS(state.get('ws'), { type: action.get('type'), treeUuid: action.get('templateUuid') });
    // }

    case 'CLEAR_TEMPLATE_PREVIEW': {
      return state.delete('selectedTemplate').delete('selectedTemplateIsInternal').delete('selectedTemplateUuid');
    }

    case 'FETCH_TEMPLATE_OPTIONS': {
      // @ts-ignore
      const text = action.get('text');

      if (text) {
        sendWS(state.get('ws'), Map({ type: 'FETCH_TEMPLATE_OPTIONS', text }));
      }

      return state;
    }

    case 'CANVAS_VIEWPORT_STOP': {
      const selectionType = state.getIn(['tree', 'view', 'selected', 'type']);
      if (selectionType) {
        const coords = Map(
          // @ts-ignore
          state.getIn(['tree', 'view', 'selected', 'cyElement']).renderedBoundingBox(),
        );
        return state.setIn(['tree', 'view', 'selected', 'coordinates'], coords);
      } else {
        return state;
      }
    }

    case 'FREE': {
      const selectionType = state.getIn(['tree', 'view', 'selected', 'type']);
      if (selectionType) {
        // @ts-ignore
        const coords = eventToCoordinates(action.get('event'));
        return state.setIn(['tree', 'view', 'selected', 'coordinates'], coords);
      }
      return state;
    }

    case 'CANVAS_VIEWPORT_START':
      return state.deleteIn(['tree', 'view', 'selected', 'coordinates']);

    case 'UNSELECT': {
      unselectCyElements(state);
      return state
        .deleteIn(['tree', 'view', 'selected'])
        .delete('connect')
        .deleteIn(['tree', 'view', 'templateOptions']);
    }

    case 'CREATE_CHILD': {
      // @ts-ignore
      const path = action.get('path');
      unselectCyElements(state);

      const child = childNode(state, path);

      return patchReducer(state, Map({ type: 'ADD', path, node: child })).set('focusChild', true);
    }

    case 'GRAB': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      const selectionType = state.getIn(['tree', 'view', 'selected', 'type']);
      // @ts-ignore
      const path = action.get('path');
      // @ts-ignore
      const otherOid = state.getIn(['tree', 'elements'], Map()).getIn(path, Map()).get('oid');

      if (selectionType === 'DOUBLECLICK') {
        return state.setIn(['tree', 'view', 'selected', 'type'], 'SELECT');
      } else {
        const parentOid = state.get('connect');
        if (parentOid) {
          sendWS(
            state.get('ws'),
            Map({
              type: 'EDGE_ADD',
              edge: List([parentOid, otherOid]),
              treeUuid,
            }),
          );
          return state
            .updateIn(['tree', 'edges'], List(), l =>
              // @ts-ignore
              l.push(List([parentOid, otherOid])),
            )
            .delete('connect');
        }

        return state.delete('connect');
      }
    }

    case 'PROCESS_SUGGESTIONS': {
      const path = action.get('path');
      const data = action.get('data');
      const node = state.getIn(['tree', 'elements']).getIn(path);
      const suggestions = data.suggestions;
      const nextType = data.type;

      unselectCyElements(state);
      for (let i = 0; i < suggestions.length; i++) {
        const suggestion = suggestions[i];
        const child = childNode(state, path).set('text', suggestion).set('type', nextType);
        state = patchReducer(state, Map({ type: 'ADD', path, node: child }));
        if (i == 0) {
          state = state.set('focusChild', true);
        }
      }
      return state;
    }

    case 'SELECT_NODE': {
      // @ts-ignore
      const path = action.get('path');
      const coordinates = eventToCoordinates(action.get('event'));
      // @ts-ignore
      const text = state.getIn(['tree', 'elements']).getIn(path).get('text');

      if (text) {
        sendWS(state.get('ws'), Map({ type: 'FETCH_TEMPLATE_OPTIONS', text }));
      }

      return state.update(prev => {
        const node = state.getIn(['tree', 'elements']).getIn(path);
        const categories = prev.getIn(['tree', 'categories'], []);
        const nodeUuid = node.get('oid');
        const treeWithCategories = prev.setIn(['tree', 'categories'], categories);

        return treeWithCategories
          .setIn(
            ['tree', 'view', 'selected'],
            Map({
              uuid: nodeUuid,
              path,
              coordinates,
              categories: categories.filter(c => c.nodeUuid === nodeUuid),
              type: 'SELECT',
              // @ts-ignore
              cyElement: action.get('event').target,
            }),
          )
          .setIn(['tree', 'view', 'templateOptions'], List());
      });
    }

    case 'EDGE_DELETE': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      // @ts-ignore
      const selectedEdge = action.get('edge').map(p =>
        // @ts-ignore
        state.getIn(['tree', 'elements'], Map()).getIn(p, Map()).get('oid'),
      );
      sendWS(state.get('ws'), Map({ type: 'EDGE_DELETE', edge: selectedEdge, treeUuid }));
      return state.updateIn(['tree', 'edges'], List(), l =>
        // @ts-ignore
        l.filterNot(e => e.equals(selectedEdge)),
      );
    }

    case 'DRAG': {
      const selectionType = state.getIn(['tree', 'view', 'selected', 'type']);
      return selectionType !== 'SELECT' ? state : state.deleteIn(['tree', 'view', 'selected', 'coordinates']);
    }

    case 'ADD_TO_TEMPLATE_LIBRARY': {
      // @ts-ignore
      const treeUuid = action.get('treeUuid');
      const tree = state.getIn(['trees', treeUuid]);
      // @ts-ignore
      return updateTree(state, tree.merge({ isTemplate: true }), treeUuid);
    }

    case 'REMOVE_FROM_TEMPLATE_LIBRARY': {
      // @ts-ignore
      const treeUuid = action.get('treeUuid');
      const tree = state.getIn(['trees', treeUuid]);
      // @ts-ignore
      return updateTree(state, tree.merge({ isTemplate: false }), treeUuid);
    }

    case 'TREE_UPDATED': {
      const { updates, treeUuid } = action;

      // console.info(`[BEFORE] Tree ${treeUuid}`, state.getIn(['trees', treeUuid]));
      const merged = state.mergeIn(['trees', treeUuid], Map(updates));
      // console.info(`[AFTER] Tree ${treeUuid}`, merged.getIn(['trees', treeUuid]));

      return merged;
      // return state;
    }

    case 'PREV_STATUS': {
      const treeUuid = action.get('treeUuid');
      const oldStatus = state.getIn(['trees', treeUuid, 'status']);
      const status = prevStatus(oldStatus);

      sendWS(state.get('ws'), Map({ type: 'SET_STATUS', treeUuid, status }));

      return state.setIn(['trees', treeUuid, 'status'], status);
    }

    case 'NEXT_STATUS': {
      const treeUuid = action.get('treeUuid');
      const oldStatus = state.getIn(['trees', treeUuid, 'status']);
      const status = nextStatus(oldStatus);

      sendWS(state.get('ws'), Map({ type: 'SET_STATUS', treeUuid, status }));
      return state.setIn(['trees', treeUuid, 'status'], status);
    }

    case 'SET_STATUS': {
      const treeUuid = action.get('treeUuid');
      const status = action.get('status');
      const updates = state.setIn(['trees', treeUuid, 'status'], status);
      const tree = state.getIn(['trees', treeUuid]);

      if (status === 'COMPLETE') {
        trackEvent('Complete Analysis', {
          'Analysis Methodology': tree.get('methodology'),
          'Analysis Name': tree.get('title'),
        });
      } else {
        trackEvent('Update Analysis Status', {
          'Analysis Methodology': tree.get('methodology'),
          'Analysis Name': tree.get('title'),
          'Analysis Status': readableStatusMap[status],
        });
      }

      sendWS(state.get('ws'), Map({ type: 'SET_STATUS', treeUuid, status }));
      return updates.set(
        'toast',
        /* @ts-ignore more immutable.js typing nonsense */
        Map({
          message: `${_(i18n, 'Successfully set status to')} ${_(i18n, AnalysisStatusDisplay[status])}`,
          style: 'SUCCESS',
        }),
      );
    }

    case 'UPDATE_CUSTOM_NAMES': {
      return state;
      // const customFieldNames = action.get('customFieldNames');
      // const custom_names = {
      //   equipment: {
      //     type: customFieldNames.get('equipment').get('type'),
      //     class: customFieldNames.get('equipment').get('class'),
      //     code: customFieldNames.get('equipment').get('code'),
      //   },
      //   facility: {
      //     location: customFieldNames.get('facility').get('location'),
      //     site: customFieldNames.get('facility').get('site'),
      //     department: customFieldNames.get('facility').get('department'),
      //   },
      // };
      // console.log(custom_names, 'custom_namescustom_namescustom_names');
      // sendWS(state.get('ws'), Map({ type: 'UPDATE_CUSTOM_NAMES', custom_names }));
      // return state.setIn(['organization', 'user', 'customNames'], customFieldNames);
    }

    case 'UPDATE_USER': {
      const userEdit = action.get('userEdit');
      const fullname = userEdit.get('fullName');
      let jobTitle = userEdit.get('jobTitle');
      let phone = userEdit.get('phone');
      const username = userEdit.get('username', '').toLowerCase();
      const email = userEdit.get('email', '').toLowerCase();

      if (!jobTitle) {
        jobTitle = undefined;
      }
      if (!phone) {
        phone = undefined;
      }

      console.log('username fullName, jobTitle, phone', username, fullname, jobTitle, phone);
      sendWS(
        state.get('ws'),
        Map({
          type: 'UPDATE_USER',
          username,
          email,
          fullname,
          jobTitle,
          phone,
        }),
      );
      return state.setIn(['users', username], userEdit);
    }

    case 'SET_TREE_FAVORITE': {
      const treeUuid = action.get('treeUuid');
      const isFavorite = action.get('isFavorite');

      sendWS(state.get('ws'), Map({ type: 'SET_TREE_FAVORITE', treeUuid, isFavorite }));
      return state.setIn(['trees', treeUuid, 'isFavorite'], isFavorite);
    }

    case 'SET_TREE_OWNER': {
      sendWS(
        state.get('ws'),
        Map({
          type: 'SET_TREE_OWNER',
          treeUuid: action.get('treeUuid'),
          owner: action.get('owner'),
        }),
      );

      const newState = showToast(state, 'Ownership transferred successfully', 'SUCCESS');

      const updatedTree = newState.setIn(['trees', action.get('treeUuid'), 'owner'], action.get('owner'));

      return updatedTree;
    }

    case 'ADD_MEMBER': {
      const member = action.get('member');
      const treeUuid = action.get('treeUuid');
      const title = action.get('title');

      sendWS(state.get('ws'), Map({ type: 'ADD_MEMBER', treeUuid, member, title }));

      return state.updateIn(['trees', treeUuid, 'members'], ms => ms.add(member));
    }

    case 'DELETE_ORG_MEMBER':
    case 'DELETE_ORG_INVITE': {
      const member = action.get('member');
      const updates = state.updateIn(['users'], u => u.delete(member));

      return updates;
    }

    case 'DELETE_MEMBER': {
      const member = action.get('member');
      const treeUuid = action.get('treeUuid');

      sendWS(state.get('ws'), Map({ type: 'DELETE_MEMBER', treeUuid, member }));

      return state.updateIn(['trees', treeUuid, 'members'], ms => ms.delete(member));
    }

    case 'NODE_ADD': {
      const cy = state.getIn(['tree', 'view', 'cy']);
      if (!cy) {
        return state;
      }
      const layout = state.getIn(['tree', 'view', 'layout']);
      const cyElem = action.get('event').target;

      // FIXME: you don't want to rearrange the nodes when
      // somebody else adds a node, might need to see if you
      // can layout a subset
      // @TODO remove side-effects from reducer
      if (!state.get('focusChild')) {
        const l = cy.layout(layout.toJS());

        l.one('layoutstop', () => {
          cy.panningEnabled(true);
          cy.zoomingEnabled(true);
        });
        cy.panningEnabled(false);
        cy.zoomingEnabled(false);
        l.run();

        return state;
      } else {
        const l = cy.layout(layout.toJS());
        const zoom = cy.zoom();
        l.one('layoutstop', () => {
          cy.zoom({
            level: zoom,
            renderedPosition: cyElem.renderedPosition(),
          });
        });
        l.run();

        const path = action.get('path');
        const coordinates = eventToCoordinates(action.get('event'));

        const selectedMap = Map({
          path,
          coordinates,
          type: 'DOUBLECLICK',
          cyElement: cyElem,
        });

        const newState = updateMetadata(state, path, 'invisible', true)
          .setIn(['tree', 'view', 'selected'], selectedMap)
          .delete('focusChild');

        return newState;
      }
    }

    case 'DOUBLECLICK': {
      const path = action.get('path');
      const coordinates = eventToCoordinates(action.get('event'));
      const cyElement = action.get('event').target;

      const selectedMap = Map({
        path,
        coordinates,
        type: 'DOUBLECLICK',
        cyElement,
      });

      const isHidden = state.getIn(['tree', 'elements']).getIn(path).get('hidden');

      if (isHidden) {
        return state;
      } else {
        return updateMetadata(state, path, 'invisible', true).setIn(['tree', 'view', 'selected'], selectedMap);
      }
    }

    case 'SET_HIDDEN': {
      const path = action.get('path');
      const isHidden = action.get('hide');
      const toggleAll = action.get('toggleAll');

      const parentNode = state.getIn(['tree', 'elements']).getIn(path);
      const isParentHidden = parentNode.get('hidden');
      const children = parentNode.get('children');
      if (isParentHidden) {
        const node = toggleAll
          ? parentNode
              .set(
                'children',
                children.map(ch => ch.set('hidden', isHidden)),
              )
              .set('hidden', isHidden)
          : parentNode.set('hidden', isHidden);
        return patchReducer(state, Map({ type: 'UPDATE', path, node, toggleAll }));
      }
      const node = !children.isEmpty()
        ? !toggleAll
          ? parentNode.set(
              'children',
              children.map(ch => ch.set('hidden', isHidden)),
            )
          : parentNode.set('children', updateChildElements(children, isHidden))
        : parentNode.set('hidden', isHidden);

      return patchReducer(state, Map({ type: 'UPDATE', path, node, toggleAll }));
    }

    case 'FIX_LAYOUT': {
      const layout = state.getIn(['tree', 'view', 'layout']);
      const cy = state.getIn(['tree', 'view', 'cy']);
      const l = cy.layout(layout.toJS());

      l.one('layoutstop', () => {
        cy.panningEnabled(true);
        cy.zoomingEnabled(true);
      });
      cy.panningEnabled(false);
      cy.zoomingEnabled(false);
      l.run();
      return state;
    }

    case 'MARK_NOT_TRUE': {
      const path = action.get('path');
      const node = state.getIn(['tree', 'elements']).getIn(path).set('possible', false);
      return patchReducer(state, Map({ type: 'UPDATE', path, node }));
    }

    case 'MARK_POSSIBLE': {
      const path = action.get('path');
      const node = state.getIn(['tree', 'elements']).getIn(path).set('possible', true);
      return patchReducer(state, Map({ type: 'UPDATE', path, node }));
    }

    case 'MARK_VERIFIED': {
      const path = action.get('path');
      const node = state.getIn(['tree', 'elements']).getIn(path).set('verified', true);
      return patchReducer(state, Map({ type: 'UPDATE', path, node }));
    }

    case 'MARK_HYPOTHETICAL': {
      const path = action.get('path');
      const node = state.getIn(['tree', 'elements']).getIn(path).set('verified', false);
      return patchReducer(state, Map({ type: 'UPDATE', path, node }));
    }

    case 'UPDATE_TEXT': {
      const path = action.get('path');
      const oldNode = state.getIn(['tree', 'elements']).getIn(path);
      const oldText = oldNode.get('text');
      const newText = action.get('text');

      unselectCyElements(state);

      if (newText === oldText) {
        return updateMetadata(state, path, 'invisible', false);
      } else {
        const newNode = oldNode.set('text', newText);
        return updateMetadata(
          patchReducer(state, Map({ type: 'UPDATE', path, node: newNode })),
          path,
          'invisible',
          false,
        );
      }
    }

    case 'ASSIGN_TYPE': {
      const path = action.get('path');
      const nodeType = action.get('nodeType');
      const node = state.getIn(['tree', 'elements']).getIn(path).set('type', nodeType);
      return patchReducer(state, Map({ type: 'UPDATE', path, node }));
    }

    case 'CLEAR_TEXT': {
      return updateMetadata(state, action.get('path'), 'invisible', true);
    }

    case 'SET_CY':
      return state.setIn(['tree', 'view', 'cy'], action.get('cy'));

    case 'SET_WS': {
      const ws = action.get('ws');
      const debug = action.get('debug');

      return state.set('ws', ws).set('debug', debug).delete('reconnecting');
    }

    case 'SET_URL': {
      return state.set('url', action.get('url'));
    }

    case 'WS_MESSAGE': {
      return wsReducer(state, action.get('message'));
    }

    case 'PNG_START': {
      const url = state.get('imageBlobURL');

      if (url) {
        URL.revokeObjectURL(url);
      }

      const onBlob = action.get('onBlob');
      const cy = state.getIn(['tree', 'view', 'cy']);
      cy.png({
        output: 'blob-promise',
        full: true,
        bg: 'white',
        scale: 1,
      })
        .then(blob => watermarker(state, blob, onBlob))
        .catch(err => onBlob(err, null));

      return state;
    }

    // warning: browsers have a race condition here, needs to be done
    // with some kind of timeout... not sure if requestAnimationFrame
    // will fly, might need to be longer.
    case 'PNG_DELETE': {
      const url = state.get('imageBlobURL');
      URL.revokeObjectURL(url);
      return state.delete('imageBlobURL');
    }

    case 'SHOW_TOAST':
      return state.set('toast', action.delete('type'));

    case 'HIDE_TOAST':
      return state.delete('toast');

    case 'RECONNECTING':
      return state.set('reconnecting', true);

    case 'RUN_LAYOUT':
      state
        .getIn(['tree', 'view', 'cy'])
        .layout(state.getIn(['tree', 'view', 'layout']).toJS())
        .run();
      return state;

    case 'TOGGLE_GRID': {
      return state.updateIn(['tree', 'view', 'grid'], true, g => !g);
    }

    case 'DELETE_SUBTREE': {
      const path = action.get('path');
      const node = state.getIn(['tree', 'elements']).getIn(path);
      unselectCyElements(state);

      return patchReducer(state, Map({ type: 'DELETE', path, node })).deleteIn(['tree', 'view', 'selected']);
    }

    case 'CUT_SUBTREE': {
      const path = action.get('path');
      const subtree = state.getIn(['tree', 'elements']).getIn(path);

      return patchReducer(state, Map({ type: 'DELETE', path, node: subtree }))
        .deleteIn(['tree', 'view', 'selected'])
        .set('clipboard', Map({ type: 'CUT', subtree }));
    }

    case 'COPY_SUBTREE': {
      const path = action.get('path');
      const elements = action.get('elements');
      const subtree = state.getIn(List(['tree', 'elements']).concat(path), elements);

      return state.set('clipboard', Map({ type: 'COPY', subtree }));
    }

    case 'PASTE_SUBTREE': {
      const type = state.getIn(['clipboard', 'type']);
      const subtree = state.getIn(['clipboard', 'subtree']);

      if (subtree) {
        const path = action.get('path');
        const patch = Map({
          type: 'ADD',
          path,
          node: type === 'CUT' ? subtree : modifyOids(subtree),
        });
        // reset clipboard on paste to disallow multiple pastes to avoid duplicating oids
        return patchReducer(state, patch).set('clipboard', Map({}));
      } else {
        return state;
      }
    }

    case 'LOAD_TREE_ELEMENTS': {
      const treeUuid = action.get('treeUuid');

      sendWS(state.get('ws'), Map({ type: 'LOAD_TREE_ELEMENTS', treeUuid }));

      return state;
    }

    case 'OPEN_TREE': {
      const treeUuid = action.get('treeUuid');

      sendWS(state.get('ws'), Map({ type: 'SYNC', treeUuid }));
      sendWS(state.get('ws'), Map({ type: 'GET_MEMBERS', treeUuid }));

      return state;
    }

    case 'SET_CATEGORIES': {
      return state.merge(
        Map({
          tags: {
            categories: action.payload.map(({ name, ...rest }) => ({
              tagName: name,
              ...rest,
            })),
          },
        }),
      );
    }

    case 'SET_TREE_CATEGORIES': {
      const { payload } = action;
      const categories = List(payload.categories.map(({ treeUuid, ...rest }) => ({ ...rest })));
      const updatedTree = state.mergeIn(['tree'], Map({ categories }));

      return updatedTree;
    }

    case 'ADD_NODE_CATEGORY': {
      const { node, category } = action.payload;

      const categoryObjects = state.getIn(['tags', 'categories']);
      const existingCategories = state.getIn(['tree', 'categories']);
      const fullCategory = {
        ...(categoryObjects.find(c => c.tagUuid === category.tagUuid) || {}),
        nodeUuid: node,
      };
      const updatedCategories = List([...existingCategories, fullCategory]);

      return state.setIn(['tree', 'categories'], updatedCategories);
    }

    case 'REMOVE_NODE_CATEGORY': {
      const { category, node } = action.payload;

      const categories = state.getIn(['tree', 'categories']);
      const removedCategoryIdx = categories.findIndex(c => {
        return c.nodeUuid === node && c.tagUuid === category;
      });
      const filteredCategories = categories.delete(removedCategoryIdx);
      const updates = state.setIn(['tree', 'categories'], filteredCategories);

      return updates;
    }

    case 'CLOSE_TREE': {
      const treeUuid = action.get('treeUuid');
      sendWS(state.get('ws'), Map({ type: 'STOP_SYNC', treeUuid }));
      return state.delete('tree');
    }

    case 'DELETE_TREE': {
      const treeUuid = action.get('treeUuid');
      sendWS(state.get('ws'), Map({ type: 'DELETE_TREE', treeUuid }));
      return state;
    }

    case 'RESTORE_TREE': {
      const treeUuid = action.get('treeUuid');

      sendWS(state.get('ws'), Map({ type: 'RESTORE_TREE', treeUuid }));

      return state.deleteIn(['trees', treeUuid, 'deletedAt']);
    }

    case 'NEW_TREE': {
      const elements = action.get('elements');
      const tree = action.get('tree');
      const events = action.get('events');
      const templateUuid = action.get('templateUuid');
      const methodology = tree.get('methodology');
      const customValues = tree.get('customValues');
      const members = tree.get('members', Set()).add(state.get('username'));
      const title = blankAsNull(tree.get('title'));
      const description = blankAsNull(tree.get('description'));
      const injuries = blankAsNull(tree.get('injuries'));
      const safetyImpact = blankAsNull(tree.get('safetyImpact'));
      const environmentalImpact = blankAsNull(tree.get('environmentalImpact'));
      const customerImpact = blankAsNull(tree.get('customerImpact'));
      const productionCost = blankAsNull(tree.get('productionCost'));
      const propertyCost = blankAsNull(tree.get('propertyCost'));
      const laborCost = blankAsNull(tree.get('laborCost'));
      const frequency = blankAsNull(parseInt(tree.get('frequency')));
      const canView = tree.get('canView', true);
      const isTemplate = tree.get('isTemplate', false);
      const status = 'PENDING';
      const startAt = tree.get('startAt');
      const eventAt = tree.get('eventAt');
      const equipment = tree.get('equipment', null);
      const facility = tree.get('facility', null);
      const expectedAt = tree.get('expectedAt');
      const groupUuid = tree.get('groupUuid');
      const newTree = Map({
        methodology,
        members,
        title,
        description,
        status,
        customValues,
        equipment,
        facility,
        injuries,
        safetyImpact,
        environmentalImpact,
        customerImpact,
        productionCost,
        propertyCost,
        laborCost,
        frequency,
        canView,
        isTemplate,
        startAt,
        eventAt,
        expectedAt,
        groupUuid,
      });

      const message = Map({ type: 'NEW_TREE', tree: newTree, events });

      trackEvent<EVENTS.CREATE_ANALYSIS>('Create Analysis', {
        'Analysis Methodology': methodology,
        'Analysis Name': title,
        'Group UUID': groupUuid,
      });

      if (templateUuid) {
        sendWS(state.get('ws'), message.set('templateUuid', templateUuid));
      } else if (elements) {
        sendWS(state.get('ws'), message.set('elements', elements));
      } else {
        sendWS(state.get('ws'), message);
      }

      return state;
    }

    case 'FONT_SIZE_UP': {
      const labelStyle = state.getIn(['tree', 'view', 'stylesheet']).find(x => x.get('selector') === 'node[label]');

      return state.updateIn(['tree', 'view', 'stylesheet'], ss =>
        List([labelStyle.updateIn(['style', 'font-size'], fs => fs + 2)]).concat(ss.filterNot(x => x === labelStyle)),
      );
    }

    case 'FONT_SIZE_DOWN': {
      const labelStyle = state.getIn(['tree', 'view', 'stylesheet']).find(x => x.get('selector') === 'node[label]');

      return state.updateIn(['tree', 'view', 'stylesheet'], ss =>
        List([labelStyle.updateIn(['style', 'font-size'], fs => fs - 2)]).concat(ss.filterNot(x => x === labelStyle)),
      );
    }

    case 'ORIENTATION': {
      const orientation = action.get('orientation', 'TB');
      return state
        .updateIn(['tree', 'view', 'stylesheet'], ss =>
          ss
            .filterNot(x => x.get('selector') === 'edge')
            .push(
              Map({
                selector: 'edge',
                style: {
                  'curve-style': 'taxi',
                  'taxi-direction': orientation === 'TB' ? 'vertical' : 'horizontal',
                },
              }),
            ),
        )
        .setIn(['tree', 'view', 'layout', 'rankDir'], orientation);
    }

    case 'SET_ACTIVE_DASHBOARD_TAB': {
      return state.set('activeDashboardTab', action.get('filterType'));
    }

    case 'SET_CONTEXTUAL_DRAWER': {
      return state.setIn(['tree', 'view', 'contextualDrawer'], action.get('drawer'));
    }

    case 'SET_SELECTED_TREE': {
      return state.set('selectedTreeUUID', action.get('uuid'));
    }

    case 'CLEAR_SELECTED_TREE': {
      return state.delete('selectedTreeUUID');
    }

    case 'CLEAR_CONTEXTUAL_DRAWER': {
      // @note see EventInfoOptions at top of file.
      // @note (cont) this is a hack to stop UPDATE_TREE from blowing away dates when the event info panel is opened.
      // @note (cont) if we don't flip this flag back to true, the bug surfaces when the pane is opened again.
      window.IGNORE_TREE_UPDATES = true;
      return state.deleteIn(['tree', 'view', 'contextualDrawer']);
    }

    case 'CLEAR_UPLOAD_FILES': {
      return state.delete('uploads');
    }

    case 'UPLOAD_FILES': {
      const files = action.get('files');
      const nodeUuid = action.get('nodeUuid');
      const treeUuid = state.getIn(['tree', 'uuid']);

      const uploadsList = files.map(f =>
        Map({
          uploadId: uuidv4(),
          filename: f.name,
          size: f.size,
          contentType: f.type || 'application/octet-stream',
          blob: f,
          nodeUuid,
        }),
      );

      uploadsList.forEach(f =>
        sendWS(
          state.get('ws'),
          Map({
            type: 'UPLOAD_FILE',
            size: f.get('size'),
            uploadId: f.get('uploadId'),
            treeUuid,
          }),
        ),
      );

      return state.set('uploads', Map(uploadsList.map(up => [up.get('uploadId'), up])));
    }

    case 'RETRY_UPLOAD_FILES': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      const erroredUploads = state
        .get('uploads')
        .valueSeq()
        .filter(v => v.get('status') === 'ERROR');

      erroredUploads.forEach(f =>
        sendWS(
          state.get('ws'),
          Map({
            type: 'UPLOAD_FILE',
            treeUuid,
            size: f.get('size'),
            uploadId: f.get('uploadId'),
          }),
        ),
      );

      return state;
    }

    case 'SET_UPLOAD_STATUS': {
      const uploadId = action.get('uploadId');
      const status = action.get('status');
      return state.setIn(['uploads', uploadId, 'status'], status);
    }

    case 'SET_UPLOAD_PROGRESS': {
      const uploadId = action.get('uploadId');
      const progress = action.get('progress');
      return state.setIn(['uploads', uploadId, 'progress'], progress);
    }

    case 'REMOVE_UPLOAD_PROGRESS': {
      const uploadId = action.get('uploadId');

      return state.deleteIn(['uploads', uploadId]);
    }

    case 'DOWNLOAD_FILE': {
      const fileUuid = action.get('fileUuid');
      const treeUuid = state.getIn(['tree', 'uuid']);

      sendWS(state.get('ws'), Map({ type: 'DOWNLOAD_FILE', treeUuid, fileUuid }));

      return state;
    }

    case 'CANCEL_UPLOAD': {
      const file = action.get('file');
      return state.update('uploads', ups => ups.delete(file.get('uploadId')));
    }

    case 'ADD_FILE': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      const file = action.get('file');
      sendWS(state.get('ws'), Map({ type: 'ADD_FILE', treeUuid, file }));

      return state;
    }

    case 'GET_FILE_METADATA': {
      sendWS(state.get('ws'), action);
      return state;
    }

    case 'DELETE_FILE': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      const fileUuid = action.get('fileUuid');

      sendWS(state.get('ws'), Map({ type: 'DELETE_FILE', treeUuid, fileUuid }));

      return state.deleteIn(['files', treeUuid, fileUuid]);
    }

    case 'ADD_TASK': {
      const title = action.get('title');
      const description = action.get('description');
      const deadline = action.get('deadline');
      const doers = action.get('doers');
      const taskType = action.get('taskType') === 'Corrective Action' ? 'CORRECTIVE_ACTION' : 'VERIFICATION';
      const treeUuid = action.get('treeUuid');
      const nodeUuid = action.get('nodeUuid');
      const nodeText = action.get('nodeText');
      const treeId = state.getIn(['trees', treeUuid, 'id']) as number;

      trackEvent<EVENTS.CREATE_TASK>('Create Task', {
        'Analysis UUID': treeUuid,
        'Task Name': title,
        'Task Type': taskType,
        'Analysis ID': treeId,
      });

      sendWS(
        state.get('ws'),
        Map({
          type: 'ADD_TASK',
          treeUuid,
          task: Map({
            title,
            description,
            doers,
            deadline,
            nodeUuid,
            nodeText,
            taskType,
          }),
        }),
      );

      // Trigger a PATCH so that a completely new blank tree gets saved or else elements might be NULL
      const path = action.get('path');
      const node = state.getIn(['tree', 'elements']).getIn(path);
      return patchReducer(state, Map({ type: 'UPDATE', path, node }));
    }

    case 'EDIT_TASK': {
      const title = action.get('title');
      const description = action.get('description');
      const deadline = action.get('deadline');
      const doers = action.get('doers');
      const taskType = action.get('taskType') === 'Corrective Action' ? 'CORRECTIVE_ACTION' : 'VERIFICATION';
      const treeUuid = action.get('treeUuid');
      const nodeUuid = action.get('nodeUuid');
      const taskUuid = action.get('taskUuid');

      sendWS(
        state.get('ws'),
        Map({
          type: 'EDIT_TASK',
          treeUuid,
          task: Map({
            title,
            description,
            doers,
            deadline,
            nodeUuid,
            taskType,
            taskUuid,
          }),
        }),
      );

      return state;
    }

    case 'GET_TASKS': {
      const treeUuid = action.get('treeUuid');
      sendWS(state.get('ws'), Map({ type: 'GET_TASKS', treeUuid }));

      return state;
    }

    case 'DELETE_TASK': {
      const treeUuid = action.get('treeUuid');
      const taskUuid = action.get('taskUuid');

      sendWS(state.get('ws'), Map({ type: 'DELETE_TASK', treeUuid, taskUuid }));

      return state.deleteIn(['tasks', treeUuid, taskUuid]);
    }

    case 'COMPLETE_TASK': {
      const username = state.get('username');
      const treeUuid = action.get('treeUuid');
      const taskUuid = action.get('taskUuid');
      const completed = action.get('completed');
      const task = state.getIn(['tasks', treeUuid, taskUuid]);
      const tree = state.getIn(['trees', treeUuid]);

      sendWS(state.get('ws'), Map({ type: 'COMPLETE_TASK', treeUuid, taskUuid, completed }));

      trackEvent<EVENTS.COMPLETE_TASK>('Complete Task', {
        'Analysis Name': tree.get('title'),
        'Analysis UUID': tree.get('treeUuid'),
        'Task Name': task.get('title'),
        'Task Type': task.get('taskType'),
        'Analysis ID': tree.get('id'),
        Completed: completed,
      });

      return completed
        ? state.setIn(['tasks', treeUuid, taskUuid], task.set('completedAt', Date.now()).set('completedBy', username))
        : state.setIn(['tasks', treeUuid, taskUuid], task.delete('completedBy').delete('completedAt'));
    }

    case 'SET_FEED_TASK': {
      const taskUuid = action.get('taskUuid');
      const tsf = state.get('tasksFeed');

      return tsf.update(taskUuid, t => t.set('completedAt', Date.now()).set('completedBy', state.get('username')));
    }

    case 'ADD_NOTE': {
      const treeUuid = action.get('treeUuid');
      const text = action.get('text');
      const noteType = action.get('noteType');
      const nodeUuid = action.get('nodeUuid');

      sendWS(state.get('ws'), Map({ type: 'ADD_NOTE', treeUuid, note: { text, noteType, nodeUuid } }));

      // Trigger a PATCH so that a completely new blank tree gets saved or else elements might be NULL
      const path = action.get('path');
      const node = state.getIn(['tree', 'elements']).getIn(path);
      return patchReducer(state, Map({ type: 'UPDATE', path, node }));
    }

    case 'EDIT_NOTE': {
      const treeUuid = action.get('treeUuid');
      const text = action.get('text');
      const noteType = action.get('noteType');
      const noteUuid = action.get('noteUuid');

      sendWS(state.get('ws'), Map({ type: 'EDIT_NOTE', treeUuid, noteUuid, text, noteType }));

      return state
        .setIn(['notes', treeUuid, noteUuid, 'noteType'], noteType)
        .setIn(['notes', treeUuid, noteUuid, 'text'], text);
    }

    case 'GET_NOTES': {
      const treeUuid = action.get('treeUuid');
      sendWS(state.get('ws'), Map({ type: 'GET_NOTES', treeUuid }));

      return state;
    }

    case 'DELETE_NOTE': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      const noteUuid = action.get('noteUuid');

      sendWS(state.get('ws'), Map({ type: 'DELETE_NOTE', treeUuid, noteUuid }));

      return state.deleteIn(['notes', treeUuid, noteUuid]);
    }

    case 'CONNECT': {
      const oid = action.getIn(['node', 'oid']);
      return state.set('connect', oid);
    }

    case 'GET_REPORT_DATA': {
      sendWS(
        state.get('ws'),
        Map({
          type: 'GET_REPORT_DATA',
          treeUUID: action.get('treeUUID'),
          signature: action.get('signature'), // For view-only requests
        }),
      );

      return state;
    }

    case 'GET_SIGNATURE': {
      const value = action.get('value');
      const tag = action.get('tag');
      if (tag === 'reportLink') {
        const notAllowed = action.get('shareLinkNotAllowed');
        if (notAllowed) {
          return showToast(state, _(i18n, 'View-only access not allowed for this tree.'), 'ERROR');
        }
      }
      sendWS(state.get('ws'), Map({ type: 'GET_SIGNATURE', tag, value }));
      return state;
    }

    case 'GENERATE_REPORT': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      const cy = state.getIn(['tree', 'view', 'cy']);
      const timezone = new Date().getTimezoneOffset();
      const generateReport = (err, blob) => {
        if (err) {
          console.error(err);
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const b64 = reader.result;
          const endOfAttrs = b64.indexOf(',') + 1;
          sendWS(
            state.get('ws'),
            Map({
              type: 'GENERATE_REPORT',
              treeUuid,
              image: b64.substring(endOfAttrs),
              timezone,
            }),
          );
        };
      };

      try {
        cy.png({
          output: 'blob-promise',
          full: true,
          bg: 'white',
          scale: 1,
        })
          .then(blob => watermarker(state, blob, generateReport))
          .catch(err => console.error(err));
      } catch (err) {
        if (err.name === 'NS_ERROR_FAILURE') {
          // Image too large
          console.log('Got NS_ERROR_FAILURE, retrying cy.png with smaller scale');
          cy.png({
            output: 'blob-promise',
            full: true,
            bg: 'white',
            scale: 0.5, // smaller scale
          })
            .then(blob => watermarker(state, blob, generateReport))
            .catch(err => console.error(err));
        }
      }

      return state;
    }

    case 'GET_ORGANIZATION': {
      sendWS(state.get('ws'), Map({ type: 'GET_ORGANIZATION' }));
      // @NOTE we're using this to set 'organization' in the current user's profile...in WSReducer
      return state;
    }

    case 'GET_USER': {
      const member = action.get('member', state.get('username'));

      identify(member);

      // @NOTE more profile updates happen in WSReducer
      setProfileProperties({
        Username: member,
      });

      sendWS(state.get('ws'), Map({ type: 'GET_USER', member }));
      return state;
    }

    case 'GET_ROLES': {
      const member = action.get('member', state.get('username'));
      sendWS(state.get('ws'), Map({ type: 'GET_ROLES', member }));
      return state;
    }

    case 'GET_ROLES_USERS': {
      const members = action.get('members', state.get('username'));
      sendWS(state.get('ws'), Map({ type: 'GET_ROLES_USERS', members }));
      return state;
    }

    case 'SET_USERNAME': {
      return state.set('username', action.get('username').toLowerCase());
    }

    case 'SET_TOKEN': {
      const updates = state.set('token', action.get('credentials'));
      return updates;
    }

    case 'INVITE_EMAIL': {
      const member = {
        email: action.get('member').get('email', '').toLowerCase(),
        first_name: action.get('member').get('first_name'),
        last_name: action.get('member').get('last_name'),
        job_title: action.get('member').get('job_title'),
        phone: action.get('member').get('phone'),
      };
      if (!member.job_title) {
        delete member.job_title;
      }
      if (!member.phone) {
        delete member.phone;
      }
      sendWS(
        state.get('ws'),
        Map({
          type: 'INVITE_EMAIL',
          ...member,
        }),
      );

      return state;
    }

    case 'RESET_MEMBER': {
      sendWS(state.get('ws'), Map({ type: 'RESET_MEMBER', member: action.get('member') }));
      return state;
    }

    case 'RESEND_INVITE': {
      sendWS(state.get('ws'), Map({ type: 'RESEND_INVITE', email: action.get('email') }));
      return state;
    }

    case 'DEMOTE_MEMBER': {
      sendWS(state.get('ws'), Map({ type: 'DEMOTE_MEMBER', member: action.get('member') }));
      return state;
    }

    case 'PROMOTE_MEMBER': {
      sendWS(state.get('ws'), Map({ type: 'PROMOTE_MEMBER', member: action.get('member') }));
      return state;
    }

    case 'REVOKE_MEMBER': {
      sendWS(state.get('ws'), Map({ type: 'REVOKE_MEMBER', member: action.get('member') }));
      return state;
    }

    case 'ASSIGN_MEMBER': {
      sendWS(state.get('ws'), Map({ type: 'ASSIGN_MEMBER', member: action.get('member') }));
      return state;
    }

    case 'LIST': {
      sendWS(state.get('ws'), Map({ type: 'LIST', treeUuid: action.get('treeUuid') }));
      return state;
    }

    case 'SET_ANALYSES_FILTER': {
      const filterType = action.get('filterType');
      return state.set('filterType', filterType);
    }

    case 'GET_MEMBERS': {
      const treeUuid = action.get('treeUuid');
      sendWS(state.get('ws'), Map({ type: 'GET_MEMBERS', treeUuid }));
      return state;
    }

    case 'SHARE_TREE': {
      sendWS(state.get('ws'), action);
      return state;
    }

    case 'SET_VIEWER_ACL': {
      const treeUuid = state.getIn(['tree', 'uuid']);
      const canView = action.get('canView');

      sendWS(
        state.get('ws'),
        Map({
          type: 'SET_VIEWER_ACL',
          treeUuid,
          canView,
        }),
      );

      return state.setIn(['trees', treeUuid, 'canView'], canView);
    }

    case 'SET_TREE_PUBLISHED': {
      const treeUuid = action.get('treeUuid');
      const isPublished = action.get('published');

      const updatedState = state.setIn(['trees', treeUuid, 'published'], isPublished);

      return showToast(
        updatedState,
        isPublished ? 'Successfully published analysis' : 'Successfully unpublished analysis',
        'SUCCESS',
      );
    }

    case 'PRESENCE': {
      sendWS(state.get('ws'), action);
      return state;
    }

    case 'HIGHLIGHT': {
      const nodeUUID = action.get('nodeUUID');
      return state.setIn(['tree', 'metadata', nodeUUID, 'selected'], true);
    }

    case 'UNHIGHLIGHT': {
      const nodeUUID = action.get('nodeUUID');
      return state.deleteIn(['tree', 'metadata', nodeUUID, 'selected']);
    }

    case 'GET_FEED': {
      const duration = action.get('duration');
      const before = Date.now();
      const after = before - duration;

      sendWS(state.get('ws'), Map({ type, before, after }));

      return state;
    }

    case 'GET_TASKS_FEED': {
      sendWS(state.get('ws'), Map({ type }));
      return state;
    }

    case 'GET_NODES': {
      sendWS(state.get('ws'), Map({ type }));
      return state;
    }

    case 'SEARCH_NODES': {
      const query = action.get('query');
      const updates = state.merge({
        searchQuery: query,
      });

      debouncedSendWS(state.get('ws'), Map({ type, query }));
      return updates;
    }

    case 'APPLY_RCA_LIST_FILTER': {
      const filterType = action.get('filter');
      const value = action.get('value');

      return state.merge({ rcaFilterType: filterType, rcaFilterValue: value });
    }

    case 'CLEAR_RCA_LIST_FILTER': {
      return state.set('rcaListFilter', Map({ type: null, value: null }));
    }

    case 'CLEAR_SEARCH': {
      const updates = Map({
        searchQuery: null,
        searchResults: Map({ trees: List(), nodes: List() }),
      });
      return state.merge(updates);
    }

    case 'GET_TIMELINE_EVENTS': {
      const treeUuid = action.get('treeUuid');
      sendWS(state.get('ws'), Map({ type, treeUuid }));
      return state;
    }

    case 'SET_TIMELINE_EVENT': {
      const treeUuid = action.get('treeUuid');
      const eventUuid = action.get('eventUuid');
      const time = action.get('time');
      const text = action.get('text');
      const event = Map({
        eventUuid,
        time,
        text,
      });

      sendWS(
        state.get('ws'),
        Map({
          type,
          treeUuid,
          event,
        }),
      );

      return state.setIn(['events', treeUuid, eventUuid], event);
    }

    case 'ADD_TIMELINE_EVENT': {
      const treeUuid = action.get('treeUuid');
      const time = action.get('time');
      const text = action.get('text');

      sendWS(
        state.get('ws'),
        Map({
          type,
          treeUuid,
          time,
          text,
        }),
      );

      return state;
    }

    case 'EDIT_TIMELINE_EVENT': {
      const treeUuid = action.get('treeUuid');
      const time = action.get('time');
      const text = action.get('text');
      const eventUuid = action.get('eventUuid');

      sendWS(
        state.get('ws'),
        Map({
          type,
          treeUuid,
          time,
          text,
          eventUuid,
        }),
      );

      return state;
    }

    case 'DELETE_TIMELINE_EVENT': {
      const treeUuid = action.get('treeUuid');
      const eventUuid = action.get('eventUuid');

      sendWS(
        state.get('ws'),
        Map({
          type,
          treeUuid,
          eventUuid,
        }),
      );

      return state.deleteIn(['events', treeUuid, eventUuid]);
    }

    // case 'REQUEST_TICKET': {
    //   const files = action.get('files');
    //   const uploadType = action.get('uploadType');
    //   sendWS(state.get('ws'), Map({ type }));
    //   return state.set('fileToUpload', files).set('uploadType', uploadType);
    // }

    case 'DOWNLOAD_CSV_SAMPLE': {
      const csvType = action.get('csvType');
      const url = action.get('url');
      const filename = action.get('filename');
      if (csvType === 'equipment') {
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const objectUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = objectUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
              a.remove();
              window.URL.revokeObjectURL(objectUrl);
            }, 1500);
          });
      } else {
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const objectUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = objectUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
              a.remove();
              window.URL.revokeObjectURL(objectUrl);
            }, 1500);
          });
      }

      return state;
    }

    case 'SIGN_OUT': {
      const ws = state.get('ws');
      sendWS(ws, Map({ type: 'SIGN_OUT' }));
      shutdownIntercomIo();
      // see WSReducer for persisted state/auth handling
      return state;
    }

    case 'GET_RCAS_CSV': {
      sendWS(state.get('ws'), Map({ type }));
      return state;
    }

    default:
      return state.setIn(['toast', 'message'], `${type}: not implemented yet.`);
  }
};
