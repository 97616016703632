import React from 'react';
import { StylingProps } from '@interfaces';

type DividerProps = StylingProps & {
  dashed?: boolean;
  margin?: number | string;
  width?: string | number;
  height?: string | number;
  color?: string;
};

export const Divider: React.FC<DividerProps> = ({
  dashed = true,
  margin = 0,
  height = 'auto',
  width = 'auto',
  style,
  color,
}) => {
  const styles = React.useMemo(
    () => ({
      height,
      width,
      border: 'none',
      borderTop: `${dashed ? 'dashed' : 'solid'} 1px ${
        color || 'var(--black-15)'
      }`,
      margin,
      ...style,
    }),
    [margin, dashed, color, style, height, width],
  );
  return <hr style={styles} />;
};
