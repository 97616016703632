import React from 'react';
import cx from 'classnames';
import { Close16 } from '@carbon/icons-react';

import { Overlay } from './Overlay';
import styles from './styles.module.css';

export type FlyoutProps = {
  maxWidth?: number;
  onOpen?: () => Promise<unknown>;
  onDismiss?: () => void;
  position?: 'left' | 'right';
  title?: string;
  dismiss: () => void;
  isOpen?: boolean;
};

export const Flyout: React.FC<FlyoutProps> = ({ children, position, isOpen, onOpen, ...props }) => {
  const [contentReady, setContentReady] = React.useState(false);

  React.useEffect(() => {
    if (onOpen && isOpen && !contentReady) {
      onOpen();
    }

    return setContentReady(true);
  }, [isOpen, onOpen, contentReady]);

  const computedClassName = React.useMemo(
    () =>
      cx(styles['flyout'], {
        [styles['positionLeft']]: position !== 'left',
        [styles['positionRight']]: position === 'right',
      }),
    [position],
  );

  const computedStyles = {
    maxWidth: props.maxWidth || 580,
    zIndex: 99999,
  };

  if (!isOpen) return null;

  return (
    <>
      <div className={computedClassName} style={{ ...computedStyles, zIndex: 9999999 }}>
        <div className="flex items-center justify-start">
          {props.title ? <h1 className="i18n">{props.title}</h1> : null}
          <button className="ml-auto" onClick={props.dismiss}>
            <Close16 fill="var(--black-60)" />
          </button>
        </div>
        <div className="w-100 h-100 pb5">{!contentReady ? <p>loading</p> : children}</div>
      </div>
      <Overlay visible={isOpen} onClick={props.dismiss} />
    </>
  );
};
