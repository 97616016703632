import { useRef } from 'react';

/**
 * Uses React.useRef to create a stable reference to `fn`'s return value
 * @name useConstant
 * @param fn
 * @returns {any} T
 */
export function useConstant<T>(fn: () => T): T {
  const ref = useRef<T | null>(null);
  if (ref.current === null) ref.current = fn();
  return ref.current;
}

export function hexToRGBA({ hex, alpha }: { hex: string; alpha: number }) {
  try {
    const opacity = alpha || 1.0;
    const R = parseInt(hex.slice(1, 3), 16);
    const G = parseInt(hex.slice(3, 5), 16);
    const B = parseInt(hex.slice(5, 7), 16);

    return `rgba(${R},${G},${B},${opacity})`;
  } catch (error) {
    throw new Error(error as any);
  }
}

export function extractDatePart(date: Date): Date {
  // Dates are stored as UTC timestamps on the BE. The browser will
  // convert it to local time, which can alter the date, so we need to
  // convert it back to UTC first.
  const offsetForDate = new Date(date).getTimezoneOffset() * 60000;
  const utcDate = new Date(new Date(date).getTime() + offsetForDate);
  // extract the date part
  const year = utcDate.getFullYear();
  const month = utcDate.getMonth();
  const day = utcDate.getDate();
  return new Date(year, month, day);
}
