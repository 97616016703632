// import React from 'react';
import ReactDOM from 'react-dom';
import Dagre from 'cytoscape-dagre';
import Cytoscape from 'cytoscape';
import gridGuide from 'cytoscape-grid-guide';
import dblclick from 'cytoscape-dblclick';
import { HashRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as mixpanel from 'mixpanel-browser';
import { BrowserTracing } from '@sentry/tracing';
import { AnalyticsProvider } from 'containers';

import { App } from './App';

import './tachyons.min.css';
import './index.css';
import './styles/print.css';

gridGuide(Cytoscape);

Cytoscape.use(Dagre);
Cytoscape.use(dblclick);

const isDevelopment = process.env.NODE_ENV === 'development';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
  console.log('Sentry initialized with DSN:', process.env.REACT_APP_SENTRY_DSN);
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: isDevelopment,
  });
} else {
  console.warn('REACT_APP_MIXPANEL_TOKEN not found in environment. Skipping initialization of Mixpanel.');
  // You still need to initialize it with a bogus token
  // https://github.com/mixpanel/mixpanel-js/issues/133#issuecomment-371623167
  mixpanel.init('BOGUS_TOKEN');
}

ReactDOM.render(
  <AnalyticsProvider token={process.env.REACT_APP_MIXPANEL_TOKEN}>
    <HashRouter>
      <App />
    </HashRouter>
  </AnalyticsProvider>,
  document.getElementById('root'),
);
