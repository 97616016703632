import React from 'react';
import { usePopper } from 'react-popper';
import { debounce, memoize } from 'lodash';
import { FixedSizeList as List } from 'react-window';
import cx from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';
import maxSize from 'popper-max-size-modifier';
import { Avatar } from './Nav';

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { height, width } = state.modifiersData.maxSize;

    state.styles.popper = {
      ...state.styles.popper,
      zIndex: 2,
      maxHeight: `${height}px`,
      maxWidth: `${width}px`,
    };
  },
};

const Row = ({ index, style, data }) => {
  const obj = data.items[index];

  const handleAddMember = () => data.toggleItemActive({ username: obj.username, admin: false });

  return (
    <button
      // onClick={data.toggleItemActive}
      onClick={handleAddMember}
      className="flex items-center bg-transparent justify-start w-100"
      style={style}
    >
      <div className="pr1">
        <Avatar height={40} username={obj.username} />
      </div>

      <div className="w-70 flex items-start justify-start flex-column white">
        <span className="fw6" style={{ fontSize: '0.8rem' }}>
          {obj.fullName}
        </span>
        <span style={{ fontSize: '0.85rem' }}>{obj.username}</span>
      </div>
    </button>
  );
};

const createItemData = memoize((items, toggleItemActive) => ({
  items,
  toggleItemActive,
}));

const SearchResults = ({ results, onSelectMember, height }) => {
  // const itemCount = results.size ?? 0;
  const itemData = createItemData(results, onSelectMember);

  return (
    <List itemData={itemData} height={height} itemCount={itemData.items.length} itemSize={40} width="100%">
      {Row}
    </List>
  );
};

export const AddGroupMember = ({ memberIds, orgMembers, onSelectMember }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [query, setQuery] = React.useState(null);

  // current list items, gets populated by availableMembers handling
  const [results, setResults] = React.useState([]);

  // sorted members list for all available org members
  const availableMembers = React.useMemo(() => {
    const available = orgMembers.filter(m => {
      if (memberIds && memberIds.includes) {
        return !memberIds.includes(m.get('username'));
      }

      return false;
    });

    const sortedMembers = Object.values(
      available
        .filter(u => !!u.get('fullName') && u.get('fullName') !== '')
        .sort((a, b) => {
          const aName = a.get('fullName').toLowerCase();
          const bName = b.get('fullName').toLowerCase();

          if (aName < bName) {
            return -1;
          }

          if (aName > bName) {
            return 1;
          }

          return 0;
        })
        .toJS(),
    );

    if (query) {
      const searchResults = sortedMembers.filter(member => {
        const usernameMatch = member.username.includes(query);
        const nameMatch = member.fullName.toLowerCase().includes(query);

        return usernameMatch || nameMatch;
      });
      // .toJS();

      return setResults(searchResults);
    }

    setResults(sortedMembers);

    return sortedMembers;
  }, [memberIds, query, orgMembers]);

  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      maxSize,
      {
        name: 'flip',
        options: { flipVariations: false, fallbackPlacements: [] },
      },
      /* @ts-expect-error */
      applyMaxSize,
    ],
    placement: 'bottom-start',
  });

  const handleSearch = React.useCallback(
    e => {
      setQuery(e.target.value);
    },
    [availableMembers],
  );

  const debouncedHandleSearch = React.useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  /* eslint-disable react-hooks/exhaustive-deps */
  // cancel our debounced search handler if we're unmounted
  React.useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleOnClick = React.useCallback(() => {
    setIsVisible(state => !state);
  }, []);

  const detectClicksRef = useDetectClickOutside({
    onTriggered: () => setIsVisible(false),
  });

  return (
    <div ref={detectClicksRef} style={{ marginLeft: '16px' }}>
      <button
        className={cx('pa2 flex items-center justify-start', {})}
        onClick={handleOnClick}
        ref={setReferenceElement}
      >
        <span className="i18n">Add Member</span>
      </button>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="members-popover add-member dark-bg">
            <div className="flex members-popover-header">
              <input
                onChange={debouncedHandleSearch}
                className="pa2 bg-white-05 w-100"
                type="search"
                placeholder="Email or name"
                autoFocus
                /* @ts-expect-error */
                tabIndex="1"
              />
            </div>
            {results.length > 0 ? (
              <SearchResults
                height={attributes.popper.maxHeight ?? 370}
                onSelectMember={onSelectMember}
                results={results}
              />
            ) : (
              <div className="empty-state">
                <span className="f6 white">No members found.</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
