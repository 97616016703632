import { ChevronLeft20, ChevronRight20 } from '@carbon/icons-react';
import cx from 'classnames';
import { FC } from 'react';
import { range } from 'lodash';
import { Spinner } from 'components/ActivityIndicators';
import styles from './styles.module.css';

type PaginationProps = {
  hasPrevious?: boolean;
  hasNext?: boolean;
  currentPage?: number;
  className?: string;
  itemsPerPage?: number;
  totalPages: number;
  isLoading?: boolean;
  onClickPageNumber?: (page: number) => void;
  onClickNext: () => void;
  onClickPrevious: () => void;
};

export const Pagination: FC<PaginationProps> = ({
  hasPrevious = false,
  hasNext = true,
  currentPage = 1,
  className,
  isLoading = true,
  onClickNext,
  onClickPrevious,
  onClickPageNumber,
  ...props
}) => {
  if (isLoading) {
    return (
      <div className={cx(styles['placeholder'], 'pv2', className)}>
        <Spinner />
      </div>
    );
  }

  const lastPage = Math.min(Math.max(currentPage + 2, 6), props.totalPages);
  const firstPage = Math.max(1, lastPage - 5);
  const currentRange = range(firstPage, lastPage);
  const finalPage = props.totalPages;

  return (
    <div className={cx('flex items-center justify-between fadeIn', className)}>
      <nav className="isolate inline-flex" aria-label="Pagination">
        <button
          disabled={hasPrevious !== true}
          onClick={onClickPrevious}
          className={cx('bg-transparent br0 relative inline-flex items-center br0 ph3  gray', {
            'hover-blue': hasPrevious,
            'o-50': hasPrevious !== true,
          })}
          style={{ cursor: hasPrevious !== true ? 'default' : 'pointer' }}
        >
          <span className="hidden hide">Previous</span>
          <ChevronLeft20 className="h1 w1" aria-hidden="true" />
        </button>

        {firstPage > 1 ? (
          <>
            <button
              onClick={() => onClickPageNumber(1)}
              className="bg-inherit hover-blue relative inline-flex items-center ph3 f6 fw8 gray"
            >
              1
            </button>
            <span className="relative inline-flex items-center ph3 pv2 f6 fw6 gray">...</span>
          </>
        ) : null}

        <div className={styles['page-buttons']}>
          {currentRange.map((page, idx) => {
            if (idx > 5) return;

            const classNames = cx('relative f6 fw6', {
              'bg-inherit gray': page !== currentPage,
              // 'underline blue bg-inherit': currentPage === 0 ? page === 1 : page === currentPage,
              'underline blue bg-inherit': page === currentPage,
            });

            return (
              <button
                onClick={() => onClickPageNumber(page - 1)}
                aria-current="page"
                className={classNames}
                style={{ minWidth: 41, padding: '0 12px', height: 35 }}
              >
                {page}
              </button>
            );
          })}
        </div>

        {currentPage < finalPage - 3 ? (
          <span className="relative inline-flex items-center ph3  f6 fw6 gray">...</span>
        ) : null}
        <button
          onClick={() => onClickPageNumber(finalPage - 1)}
          className={cx('relative inline-flex items-center ph3  f6 fw8', {
            'bg-inherit gray hover-blue': currentPage !== finalPage,
            'underline blue bg-inherit': currentPage === finalPage,
          })}
        >
          {finalPage}
        </button>

        <button
          disabled={hasNext !== true}
          onClick={onClickNext}
          className={cx('bg-transparent relative inline-flex items-center br0 ph3  gray', {
            'hover-blue': hasNext !== false,
            'o-50': hasNext !== true,
          })}
          style={{ cursor: hasNext !== true ? 'default' : 'pointer' }}
        >
          <span className="hide">Next</span>
          <ChevronRight20 className="h1 w1" aria-hidden="true" />
        </button>
      </nav>
    </div>
  );
};
