import React from 'react';

import { useNodeContentForType } from '../../../hooks';
import styles from './styles.module.css';

export const Node = ({ text, type }) => {
  const { color, icon: Icon, title } = useNodeContentForType({ type });

  return (
    <div className={styles['wrapper']}>
      <div className={styles['Node']} style={{ borderColor: color }}>
        <div className={styles['nodeHeader']} style={{ backgroundColor: color }}>
          <p className="ma0 pa0">{title}</p>
        </div>
        <p className={styles['text']}>{text}</p>
        <div className={styles['iconRow']}>
          <Icon fill={color} />
        </div>
      </div>
    </div>
  );
};
