import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type PopoverContextValue = {
  isVisible: boolean;
  onToggle: () => void;
  toggle: () => void;
  hide: () => void;
  show: () => void;
};

const PopoverContext = createContext<PopoverContextValue | null>(null);

export const usePopover = () => {
  const context = useContext(PopoverContext);

  if (!context) {
    throw new Error('usePopover must be used within a PopoverProvider');
  }

  return context as PopoverContextValue;
};

export const PopoverProvider: React.FC<{ onToggle?: () => void }> = ({
  children,
  onToggle,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggle = useCallback(() => setIsVisible(s => !s), []);
  const handleOnToggle = useCallback(() => {
    if (onToggle) return onToggle();

    return;
  }, [onToggle]);
  const hide = useCallback(() => setIsVisible(false), []);
  const show = useCallback(() => setIsVisible(true), []);
  const value: PopoverContextValue = useMemo(
    () => ({
      isVisible,
      toggle,
      onToggle: handleOnToggle,
      hide,
      show,
    }),
    [isVisible, toggle, handleOnToggle, hide, show],
  );

  return (
    <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
  );
};
