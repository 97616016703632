import React /*, {useState}*/ from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { InProgress20, ErrorFilled24, Login20 } from '@carbon/icons-react';
import { Input } from './TextInputs';
import { Button } from './Buttons';

import { verifyPasswordResetRequest, submitNewPassword } from './Sync';

import logoSrc from './svgs/logo.svg';

export const ResetPassword = () => {
  const [model, setModel] = React.useState({
    error: null,
    submitting: false,
    validRequest: false,
    loading: true,
    password: null,
    passwordConfirmation: null,
    successfullyUpdated: false,
  });
  const error = model['error'];
  const submitting = model['submitting'];
  const params = useParams();

  const handleOnSubmit = React.useCallback(() => {
    const userId = params.token.split('-')[1];

    if (model.password !== model.passwordConfirmation) {
      return setModel(state => ({
        ...state,
        error: 'Passwords do not match.',
      }));
    }

    if (model.password.length < 8) {
      return setModel(state => ({
        ...state,
        error: 'Password must be at least 8 characters long.',
      }));
    }

    return submitNewPassword({
      token: params.token,
      password: model.password,
      userId,
    }).then(r => {
      if (r.error) {
        return setModel(state => ({ ...state, error: r.error }));
      }

      setModel(state => ({
        ...state,
        password: null,
        passwordConfirmation: null,
        successfullyUpdated: true,
        error: null,
      }));
    });
  }, [params.token, model.password, model.passwordConfirmation]);

  React.useEffect(() => {
    verifyPasswordResetRequest({ token: params.token }, [params.token]).then(
      r => {
        if (r.error) {
          // token validation failed
          return setModel(state => ({
            ...state,
            loading: false,
            error: r.error,
          }));
        }

        // token is valid, allow a new password to be set
        return setModel(state => ({
          ...state,
          loading: false,
          validRequest: true,
        }));
      },
    );
  }, [params.token]);

  return (
    <div className="h-100 w-100 flex flex-column justify-center items-center bg-grid pb4">
      <div
        className="ba bw2 pa4 b--navy bg-white mb4"
        style={{ width: '400px' }}
      >
        <div className="mb4">
          <img
            alt="easy rca logo"
            src={logoSrc}
            className="db"
            style={{ width: '200px' }}
          />
        </div>
        <div
          className={classNames(
            'bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red',
            {
              dn: !error,
              flex: error,
            },
          )}
        >
          <ErrorFilled24 className="fill--red mr2" />
          <div className="flex-auto">{error}</div>
        </div>
        {model.successfullyUpdated ? (
          <div className="flex items-center justify-start pa4 bg-light-blue black">
            <svg
              className="w1"
              data-icon="info"
              viewBox="0 0 32 32"
              style={{ fill: 'black' }}
            >
              <title>info icon</title>
              <path d="M16 0 A16 16 0 0 1 16 32 A16 16 0 0 1 16 0 M19 15 L13 15 L13 26 L19 26 z M16 6 A3 3 0 0 0 16 12 A3 3 0 0 0 16 6"></path>
            </svg>
            <span className="lh-title ml3">Password successfully updated.</span>
          </div>
        ) : (
          <div className="pt3">
            {model.loading ? (
              <div className="flex w-100 items-center justify-center">
                <InProgress20 className="spin black fill--black" />
              </div>
            ) : (
              <>
                <div className="f3">Reset Password</div>
                {model.validRequest ? (
                  <>
                    <div className="f5 black-60 mt1 mb4 lh-title">
                      {model.salutation}
                    </div>
                    <form
                      onSubmit={handleOnSubmit}
                      className="w-100 db flex flex-column"
                    >
                      <Input
                        autoFocus
                        type="password"
                        className="mb3"
                        placeholder="Password"
                        onChange={e =>
                          setModel(state => ({
                            ...state,
                            password: e.target.value,
                          }))
                        }
                        value={model.password}
                      />
                      <Input
                        type="password"
                        className="mb3"
                        placeholder="Verify Password"
                        onChange={e =>
                          setModel(state => ({
                            ...state,
                            passwordConfirmation: e.target.value,
                          }))
                        }
                        value={model.passwordConfirmation}
                      />
                      <Button
                        text="Submit"
                        disabled={submitting}
                        icon={
                          submitting ? (
                            <InProgress20 className="spin white fill--white" />
                          ) : (
                            <Login20 className="fill--white white" />
                          )
                        }
                      />
                    </form>
                  </>
                ) : (
                  <div
                    className={classNames(
                      'bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red',
                      {
                        dn: !error,
                        flex: error,
                      },
                    )}
                  >
                    <ErrorFilled24 className={'fill--red w3 mr2'} />
                    <div className={'flex-auto'}>{error}</div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <Link className="black underline link" to="/login">
        Back to Login
      </Link>
    </div>
  );
};
