import { EventNames, EventPayloads, EVENTS } from '@interfaces';
import type { Config, RequestOptions, Callback, people } from 'mixpanel-browser';
import * as mixpanel from 'mixpanel-browser';
import React, { createContext, useContext, useMemo } from 'react';

type AnalyticsProviderProps = {
  token?: string;
};

type TrackFn = <T extends EVENTS>(
  event_name: T extends undefined ? EventNames : `${T}`,
  properties?: T extends undefined ? EventPayloads : EventPayloads[T],
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,
) => void;

type IdentifyFn = (identifier: string) => void;

type AnalyticsContextValue = {
  reset: typeof mixpanel.reset;
  track: TrackFn;
  identify: (identifier: string) => void;
  profile: typeof people;
  init: (config: Partial<Config>) => void;
};

const AnalyticsContext = createContext<AnalyticsContextValue | null>(null);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }

  return context;
};

// @TODO this is a temporary measure to track in areas outside of components
// @TODO (cont) since we're starting our tracking off in our reducers, we can't rely on context/useAnalytics
export const trackEvent: TrackFn = (event, properties) => mixpanel.track(event, properties);

export const identify: IdentifyFn = id => mixpanel.identify(id);
export const setProfileProperties: (properties: Record<string, unknown>) => void = payload =>
  mixpanel.people.set_once(payload);

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children, token }) => {
  const value = useMemo<AnalyticsContextValue>(
    () => ({
      reset: () => {
        console.warn('reset called...calling mixpanel.reset');
        return mixpanel.reset();
      },
      track: (event, properties) => mixpanel.track(event, properties),
      identify: user => mixpanel.identify(user),
      profile: mixpanel.people,
      init: config => {
        if (!token) {
          throw new Error('AnalyticsProvider did not receive a token. Cannot init.');
        }

        try {
          mixpanel.init(token, config);
        } catch (error) {
          console.error('Failed to initialize Mixpanel', error);
        }
      },
    }),
    [token],
  );
  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
