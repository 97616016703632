import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { Help16 } from '@carbon/icons-react';
import ReactTooltip from 'react-tooltip';

import styles from './styles.module.css';

type CardProps = {
  title?: string;
  subtitle?: string;
  helpText?: string;
  children: React.ReactNode;
  minHeight?: string;
};

export const Card = ({ title, minHeight, subtitle, helpText, children }: CardProps) => {
  const helpTextProps = helpText
    ? {
        'data-tip': helpText,
      }
    : {};

  React.useEffect(() => {
    if (!helpText) {
      ReactTooltip.hide();
    }

    ReactTooltip.rebuild();
  }, [helpText]);

  const sizeStyles = {
    minHeight: minHeight || 'auto',
  };

  return (
    <>
      <div className={cx(styles['card'], styles['cardPadding'])} style={sizeStyles}>
        <div className={styles['cardHeader']}>
          <div className={cx(styles['headerTitles'], 'i18n')}>
            <p className="ma0 fs-s fw-medium mb1">{title ? title : null}</p>
            <p className="ma0 fs-s fw-light">{subtitle ? subtitle : null}</p>
          </div>
          {helpText ? (
            <>
              <ReactTooltip />
              <button className="icon-button pa0" disabled={false} {...helpTextProps}>
                <Help16 fill="var(--black-50)" />
              </button>
            </>
          ) : null}
        </div>
        {children}
      </div>
    </>
  );
};

Card.Content = function CardContent(props: { style?: CSSProperties; className?: string; children: React.ReactNode }) {
  return (
    <div className={cx(styles['cardContent'], props.className, 'i18n')} style={props.style}>
      {props.children}
    </div>
  );
};
