import { useEffect, useRef, useState } from 'react';
import { List, Map, Set } from 'immutable';
import { Time20, View24, ChevronUp16, ChevronDown16 } from '@carbon/icons-react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useAnalytics, useAuth } from 'containers';
import logoSvg from './svgs/logo-v2-light.svg';
import supportSvg from './svgs/support.svg';
import logoutSvg from './svgs/logout.svg';
import settingsSvg from './svgs/settings.svg';
import { CreateRCAButton } from './components';

import styles from './styles/navigation.module.css';

const NavContainer = ({ children }) => {
  return (
    <div
      // style={{ height: '48px' }}
      className={cx(
        'hide-for-print top-0 left-0 right-0 z-2 bg-black justify-start items-stretch flex fixed',
        styles['navigation'],
      )}
    >
      {children}
    </div>
  );
};

const nameToLetters = name => {
  if (!name || name === 'null null' || !name.split) {
    return '..';
  }

  return name
    .split(' ')
    .map(x => x[0])
    .join('')
    .toUpperCase()
    .substring(0, 3);
};

// used a prime length to prevent modulo bias
// who cares?
const colors = List(['light-red', 'orange', 'purple', 'dark-pink', 'dark-green', 'green', 'navy', 'blue', 'dark-blue']);

const hash = s => {
  let hashInt = 0;
  let i;
  let chr = null;

  if (!s || !s.length) {
    return null;
  }

  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hashInt = (hashInt << 5) - hashInt + chr;
    hashInt |= 0;
  }
  return hashInt;
};

const nameToColor = name => colors.get(hash(name) % colors.size);

export const Avatar = ({ state = null, username, height }) => {
  const color = nameToColor(username);
  const scale = height / 48;
  const name = state ? state.getIn(['users', username, 'fullName'], username) : username;
  /* @ts-expect-error */
  useEffect(() => ReactTooltip.rebuild(), []);

  return (
    <div
      data-tip={name}
      data-place={'bottom'}
      data-delay-show={250}
      style={{
        height: `${height}px`,
        width: `${height}px`,
        transform: `scale(${scale})`,
      }}
    >
      <div
        className={cx('h-100 w-100 white flex justify-center items-center fw7', `bg-${color}`)}
        style={{ fontSize: '20px', borderRadius: '1px' }}
      >
        {nameToLetters(name)}
      </div>
    </div>
  );
};
const Link = ({ children, className, to, ...props }) => {
  const location = useLocation();
  const active = location.pathname === to ? styles['link-active'] : null;

  return (
    <NavLink className={cx('i18n label', styles['link'], active, className)} to={to} {...props}>
      {children}
    </NavLink>
  );
};

const UserSettings = ({ state, dispatch }) => {
  const username = state.get('username', '');
  const me = state.getIn(['users', username], Map());
  const name = me.get('fullName', '');
  const { logout } = useAuth();
  const { reset } = useAnalytics();
  const isAdmin =
    state.get('users', Map()).filter(u => u.get('roles', Set()).has('ADMIN') && u.get('username') === username).size >
    0;
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (username) {
      dispatch(Map({ type: 'GET_ROLES', member: username }));
      dispatch(Map({ type: 'GET_USER', member: username }));
    }

    /**
     * Close dropdown if clicked outside
     */
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch, username]);

  return (
    <div
      className={cx(
        'fill--white white pointer relative justify-center',
        styles['link'],
        {
          dn: !name,
          flex: name,
        },
        'ml0',
      )}
      ref={wrapperRef}
    >
      <div className="flex" onClick={() => setMenuDropdownOpen(!menuDropdownOpen)}>
        <Avatar state={state} username={username} height={42} />
        <div
          className={cx('ml2 f6 flex items-center mr2 trans', {
            'white-50': menuDropdownOpen,
          })}
        >
          <div>
            <div className={'fw7'}>{name}</div>
            <div className={''} style={{ fontSize: '12px' }}>
              {username}
            </div>
          </div>
        </div>
        <div className={'flex justify-center items-center ph3'}>
          {menuDropdownOpen ? <ChevronUp16 /> : <ChevronDown16 />}
        </div>
      </div>
      <div
        style={{ top: '2.65rem', width: '262px' }}
        className={cx('bg-black white absolute right-0', {
          dn: !menuDropdownOpen,
        })}
      >
        <div className="i18n pv2 ph3" style={{ paddingTop: '20px' }}>
          <div
            className={cx('flex items-center label dim')}
            onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}
          >
            <div className="mr2 flex items-center">
              <img alt="help" src={settingsSvg} height={24} width="100%" />
            </div>
            <span>Settings &nbsp; </span>
            <div style={{ marginLeft: 'auto' }}>{settingsDropdownOpen ? <ChevronUp16 /> : <ChevronDown16 />}</div>
          </div>

          <div
            className={cx('ml2', {
              dn: !settingsDropdownOpen,
            })}
            style={{ top: '2rem', zIndex: 9999 }}
          >
            {isAdmin ? (
              <>
                <Link to="/settings/users" className={cx('flex items-center', styles['link'], styles['dropdown-link'])}>
                  <span className="i18n label">Users</span>
                </Link>
                <Link
                  to="/settings/groups"
                  className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                >
                  <span className="i18n label">Groups</span>
                </Link>
                <Link
                  to="/settings/integrations"
                  className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                >
                  <span className="i18n label">Integrations</span>
                </Link>
                <a
                  href="/v2/settings/assets"
                  className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                >
                  <span className="i18n label">Assets</span>
                </a>
                <a
                  href="/v2/settings/trend-categories"
                  className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                >
                  <span className="i18n label">Trend Categories</span>
                </a>
                <a
                  href="/v2/settings/custom-fields"
                  className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                >
                  <span className="i18n label">Custom Fields</span>
                </a>
              </>
            ) : (
              <>
                <Link to="/settings/users" className={cx('flex items-center', styles['link'], styles['dropdown-link'])}>
                  <span className="i18n label">Users</span>
                </Link>
                <Link
                  to="/settings/groups"
                  className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                >
                  <span className="i18n label">Groups</span>
                </Link>
              </>
            )}
          </div>
        </div>
        <div
          className={'i18n label pv2 ph3 dim flex items-center'}
          onClick={() => window.open('https://www.reliability.com/easyrca-faqs', '_blank')}
        >
          <div className="mr2 flex items-center">
            <img alt="help" src={supportSvg} height={24} width="100%" />
          </div>
          <span>Help</span>
        </div>
        <div
          className={'i18n label pv2 ph3 mb2 dim flex items-center'}
          style={{ paddingBottom: '20px' }}
          onClick={() => {
            reset();
            logout();
          }}
        >
          <div className="mr2 flex items-center">
            <img alt="help" src={logoutSvg} height={24} width="100%" />
          </div>
          Logout
        </div>
      </div>
    </div>
  );
};

const HomeNavLink = ({ url, text, dropdown, className }) => {
  return (
    <NavLink
      to={url}
      activeClassName={styles['link-active']}
      className={cx('i18n label white ph2 ml3', styles['link'], className)}
    >
      {text}
    </NavLink>
  );
};

export const SubNavigation = ({ items }) => {
  return (
    <div className="flex align-center justify-start">
      {items.map(i => {
        return (
          <NavLink
            activeClassName={styles['activePageNavLink']}
            className={styles['pageNavLink']}
            exact
            key={i.to}
            to={i.to}
          >
            <span className="i18n label">{i.label}</span>
          </NavLink>
        );
      })}
    </div>
  );
};

const Logo = () => {
  return (
    <div className="ml3 mr4 flex items-center justify-center">
      <img alt="easyrca logo" src={logoSvg} height={29} />
    </div>
  );
};

export const ViewerNav = ({ state, uuid = null }) => {
  const treeUuid = state.getIn(['tree', 'uuid']);
  const treeTitle = state.getIn(['trees', treeUuid, 'title']);
  const treeNumber = state.getIn(['trees', treeUuid, 'number']);
  const username = state.get('username', '');

  useEffect(() => {
    if (treeTitle) {
      window.document.title = `#${treeNumber} ${treeTitle}`;
    } else {
      window.document.title = `EasyRCA #${treeNumber}`;
    }
  }, [treeTitle]);

  return (
    <NavContainer>
      <div className={'flex flex-auto justify-between items-center'}>
        <div className={'flex items-center flex-auto'} style={{ flex: 1 }}>
          <a href={'https://app.easyrca.com'} className={'dim white no-underline flex items-center'}>
            <Logo />
            <div className={'ml3'}>
              Easy<span className={'blue fw7'}>RCA</span>
            </div>
          </a>
        </div>
        <div className={'flex items-center justify-end'}>
          <div
            className={'flex items-center justify-end'}
            style={{ flex: 1 }}
            data-tip={'For edit access, please contact the tree owner for permission or a license.'}
            data-place={'bottom'}
          >
            <View24 className={'white fill--white'} />
            <div className="white ml2 mr3">View-only</div>
          </div>
          {!username ? (
            <Link
              to={uuid ? `/login?referrer=/tree/${uuid}` : '/login'}
              className="no-underline black mr2 bg-gold ph4 pv2 fw7 f6 flex justify-center items-center dim"
            >
              Login
            </Link>
          ) : null}
        </div>
      </div>
    </NavContainer>
  );
};

const TrialBadge = () => {
  // const roles = state.getIn(['users', state.get('username'), 'roles'], List());
  // const isPaid = Set(roles).has('PAID');

  return (
    <a
      href="mailto:sebastian@reliability.com?subject=Purchase a license"
      // className={cx('fadeIn no-underline', {
      //   dn: isPaid,
      //   dib: !isPaid,
      // })}
      className="dim fadeIn no-underline flex items-center justify-between bg-gold ph2 pv1 fw7 f6 mr2"
      style={{ flexWrap: 'nowrap', minWidth: 'fit-content' }}
    >
      <div className="black bg-gold ph2 pv1 fw7 f6 flex justify-center items-center dim">
        <Time20 className={'mr1'} />
        FREE TRIAL
      </div>
    </a>
  );
};

export const HomeNav = ({ state, dispatch }) => {
  const treeUuid = state.getIn(['tree', 'uuid']);
  const treeTitle = state.getIn(['trees', treeUuid, 'title']);
  const treeNumber = state.getIn(['trees', treeUuid, 'number']);
  const username = state.get('username');
  const token = state.get('token', null);

  useEffect(() => {
    if (treeTitle && treeNumber) {
      window.document.title = `#${treeNumber} ${treeTitle}`;
    } else if (!treeTitle && treeNumber) {
      window.document.title = `#${treeNumber} [Untitled]`;
    } else {
      window.document.title = 'EasyRCA';
    }
  }, [treeTitle]);

  const roles = state.getIn(['users', state.get('username'), 'roles'], List());
  const isPaid = Set(roles).has('PAID');
  const isComplimentary = Set(roles).has('COMPLIMENTARY');
  const aiFeaturesEnabled = state.getIn(['organization', 'user', 'orgFeatures', 'aiFeatures'], false);

  return (
    <NavContainer>
      <ReactTooltip />
      <Logo />
      <a href="/v2/" className={cx('flex items-center', styles['link'])}>
        <span className="i18n label">Home</span>
      </a>
      <Link to="/rcas" className={cx('flex items-center', styles['link'])}>
        <span className="i18n label">RCAs</span>
      </Link>
      <a href="/v2/tasks" className={cx('flex items-center', styles['link'], styles['dropdown-link'])}>
        <span className="i18n label">Tasks</span>
      </a>
      <Link className={cx('flex items-center', styles['link'])} to="/templates">
        <span className="i18n label">Templates</span>
      </Link>
      <Link to="/dashboard" className={cx('flex items-center', styles['link'])}>
        <span className="i18n label">Dashboard</span>
      </Link>

      <div className={'flex items-center justify-end ml-auto'}>
        <CreateRCAButton dispatch={dispatch} allowAiOptions={aiFeaturesEnabled} />
        {isPaid || isComplimentary ? null : <TrialBadge />}
        <UserSettings state={state} dispatch={dispatch} />
      </div>
    </NavContainer>
  );
};
