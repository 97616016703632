import React from 'react';
import cx from 'classnames';
import { Help16 } from '@carbon/icons-react';
import ReactTooltip from 'react-tooltip';
import { Divider } from 'components';

import styles from './styles.module.css';

export type StackFigureProps = {
  firstRowLabel: string;
  firstRowValue: string;
  firstRowHelpText?: string;
  secondRowLabel: string;
  secondRowValue: string;
  secondRowHelpText?: string;
  className?: string;
};

export const StackFigure: React.FC<StackFigureProps> = ({
  firstRowLabel,
  firstRowValue,
  firstRowHelpText,
  secondRowLabel,
  secondRowValue,
  secondRowHelpText,
  className,
}) => {
  React.useEffect(() => {
    if (firstRowHelpText || secondRowHelpText) {
      ReactTooltip.rebuild();
    }
  }, [firstRowHelpText, secondRowHelpText]);

  return (
    <div className={cx(styles['card'], className)}>
      {firstRowHelpText || secondRowHelpText ? <ReactTooltip /> : null}
      <div className={styles['cardStackContent']}>
        <div className={cx(styles['cardStackRow'], 'flex items-center justify-start')}>
          <span className="i18n fs-s fw-medium">{firstRowLabel}</span>

          <span className="i18n fs-baseline fw-medium mono ml-auto">{firstRowValue}</span>

          {firstRowHelpText ? (
            <button className="i18n flex flex-column icon-button pa0 pl2" disabled={false} data-tip={firstRowHelpText}>
              <Help16 fill="var(--black-50)" />
            </button>
          ) : null}
        </div>
        <Divider dashed />
        <div className={cx(styles['cardStackRow'], 'flex items-center justify-start')}>
          <span className="i18n fs-s fw-regular">{secondRowLabel}</span>

          <span className={cx(styles['figureLabel'], 'fs-baseline fw-regular mono ml-auto')}>{secondRowValue}</span>

          {secondRowHelpText ? (
            <button className="i18n flex flex-column icon-button pa0 pl2" disabled={false} data-tip={secondRowHelpText}>
              <Help16 fill="var(--black-50)" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
