import React, { CSSProperties, forwardRef, LegacyRef } from 'react';
import cx from 'classnames';
import { SubNavigation } from 'Nav';

type HeroProps = {
  showSubNavigation?: boolean;
  subNavigationLinks?: { to: string; label: string }[];
  ref?: LegacyRef<HTMLHeadingElement>;
  pageTitle: string;
  className?: string;
  style?: CSSProperties;
};

export const Hero = forwardRef<any, HeroProps>(
  ({ showSubNavigation, subNavigationLinks, pageTitle, ...props }, ref) => {
    return (
      <div className={cx('page-header black pa3 pv4 ph5', props.className)} {...props}>
        <div className="flex items-stretch justify-start">
          <h1 ref={ref} className="i18n f4 fs-xxl normal ma0">
            {pageTitle}
          </h1>
          {showSubNavigation ? (
            <div className="ml-auto">
              <SubNavigation items={subNavigationLinks} />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);
