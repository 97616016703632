import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { List, Map, Set } from 'immutable';
import classNames from 'classnames';
import {
  Renew20,
  SubtractAlt20,
  View20,
  ViewOff20,
  Unlocked20,
  ChevronLeft20,
  ChevronRight20,
  Add20,
  Edit20,
  Credentials24,
  Close24,
  MachineLearning20,
} from '@carbon/icons-react';
import { format, setHours, setMinutes, parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { MemberContextMenu } from 'containers/tree-editor';
import { DeepSelect, OrgMembersSelect } from 'components';
import { noop } from 'lodash';
import { CustomFieldMap } from '@interfaces';
import ReactTooltip from 'react-tooltip';
import {
  getAvailableCustomFields,
  addCustomFieldValueToTree,
  updateTree,
  removeCustomFieldValueFromTree,
} from './Sync';
import { Accordion, Blade, Section } from './Blade';
import { Input, Textarea, Select } from './TextInputs';
import { Avatar } from './Nav';
import { _, extractDatePart, calculateFailureCost } from './utils';
import { debounce } from './Tree';
import { Button } from './Buttons';
// import { NewMultiLevelDropdown } from './FileInputs';

const pages = List(['Info', 'Team', 'Equipment', 'Costs']);

// @ts-ignore
window.IGNORE_TREE_UPDATES = true;

function formatDate(...args: Parameters<typeof format>) {
  try {
    return format.apply(this, args);
  } catch (error) {
    console.error('[formatDate]:', error.message);
    return;
  }
}

const fetchAISummary = async (treeUuid, token) => {
  const resp = await fetch(`api/v0/tree/${treeUuid}/aiSummary`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!resp.ok) {
    throw new Error('unable to fetch AI summary');
  } else {
    const {
      data: { summary },
    } = await resp.json();
    return summary;
  }
};

export const ViewOnlyMember = ({ state, value, onUpdate, sharingDisabled }) => {
  const treeUuid = state.getIn(['tree', 'uuid']);
  const canView = state.getIn(['trees', treeUuid, 'canView']) || value;

  return (
    <div className={'flex items-center mb2'}>
      <div className={'flex items-center justify-center bg-blue'} style={{ height: '50px', width: '50px' }}>
        {canView && !sharingDisabled ? (
          <View20 className={'white fill--white'} />
        ) : (
          <ViewOff20 className={'white fill--white'} />
        )}
      </div>
      <div className={'ml3 flex-auto'}>
        <div className={'f6 fw7'}>View-only Users</div>
        <div className={'f6'}>
          {sharingDisabled || !canView ? 'Sharing is disabled' : null}
          {canView && !sharingDisabled
            ? 'Anyone on the Internet with the link can view the Analysis tree and Report'
            : null}
        </div>
      </div>
      <div
        className={'flex justify-center items-center w2 red fill--red dim pointer'}
        onClick={() => {
          if (sharingDisabled) return;

          return onUpdate(!canView);
        }}
      >
        {sharingDisabled ? null : canView ? <SubtractAlt20 /> : <Unlocked20 />}
        {/* {canView && !sharingDisabled ? <SubtractAlt20 /> : <Unlocked20 />} */}
      </div>
    </div>
  );
};

const addDateAndTime = (dateStr, timeStr) => {
  try {
    let result = parseISO(dateStr);
    if (timeStr) {
      const [hr, min] = timeStr.split(':');
      result = setMinutes(setHours(result, hr), min);
    }
    return result.getTime();
  } catch (e) {
    console.error(e);
  }
};

export const RoleIcon = ({ className, isOwner = false }) => {
  if (isOwner) {
    return <Credentials24 className={className} data-tip="Owner" fill="var(--white)" />;
  }

  return null;
};

export const Member = ({ state, useContextMenu = false, isOwner, dispatch, member, onRemove, onTransferOwnership }) => {
  const name = state.getIn(['users', member, 'name'], member);
  // const username = state.getIn(['users', member, 'name'], member);
  const treeUuid = state.getIn(['tree', 'uuid']);

  const handleTransferOwnership = newOwner => {
    // return onTransferOwnership({ owner: newOwner });
    return onTransferOwnership(newOwner);
  };

  const handleRemoveMember = member => {
    onRemove(member);
  };

  return (
    <div className="flex items-center mb2">
      <Avatar state={state} username={member} height={50} />
      <div className="ml3 flex-auto">
        <div className="f6 fw7 truncate">{name}</div>
        <div className="f6 truncate">{member}</div>
      </div>
      <RoleIcon className="mr3" isOwner={isOwner} />
      {useContextMenu ? (
        <MemberContextMenu
          state={state}
          dispatch={dispatch}
          username={member}
          treeUuid={treeUuid}
          disableTransfer={isOwner}
          onRemoveMember={handleRemoveMember}
          onTransferOwnership={handleTransferOwnership}
        />
      ) : null}
      {/* <div
        className={classNames(
          'justify-center items-center w2 red fill--red dim pointer',
          { dn: member === username, flex: member !== username },
        )}
        data-tip="Remove"
        onClick={onClick}
      >
        <SubtractAlt20 />
      </div> */}
    </div>
  );
};

// const transmit = debounce((state, dispatch, newTree) => {
//   const treeUuid = state.getIn(['tree', 'uuid']);
//   const oldTree = state.getIn(['trees', treeUuid]);
//   const oldMembers = oldTree.get('members');
//   const newMembers = newTree.get('members');
//   const title = oldTree.get('title');

//   if (!oldMembers.equals(newMembers)) {
//     const addedUsers = newMembers.subtract(oldMembers);
//     const deletedUsers = oldMembers.subtract(newMembers);

//     addedUsers.forEach(member => {
//       dispatch(Map({ type: 'ADD_MEMBER', treeUuid, member, title }));
//     });

//     deletedUsers.forEach(member => {
//       dispatch(Map({ type: 'DELETE_MEMBER', treeUuid, member }));
//     });
//   }

//   dispatch(Map({ type: 'UPDATE_TREE', tree: newTree }));
// }, 250);

const TimelineRecord = ({ state, dispatch, editingDisabled, event, setPage, setModel }) => {
  const epochTime = event.get('time');
  const text = event.get('text');
  const treeUuid = state.getIn(['tree', 'uuid']);
  const eventUuid = event.get('eventUuid');

  const dateObj = new Date(epochTime);
  const date = formatDate(dateObj, 'd MMM uuuu');
  const time = formatDate(dateObj, 'hh:mm aa');

  const onDelete = () => {
    if (editingDisabled) return;
    dispatch(Map({ type: 'DELETE_TIMELINE_EVENT', treeUuid, eventUuid }));
  };

  return (
    <div className={'mv2 bg-black pa3 relative'}>
      <div className="gray f5">{date}</div>
      <div className="gray f6 mb3 mt1">{time}</div>
      <div className="f6">{text}</div>
      <div className="absolute top-0 right-0 ma2 hover pointer flex">
        <div
          onClick={() => {
            setPage('Edit Event');
            setModel(m =>
              m
                .set('date', formatDate(dateObj, 'yyyy-MM-dd'))
                .set('time', formatDate(dateObj, 'HH:mm'))
                .set('text', text)
                .set('eventUuid', eventUuid),
            );
          }}
          data-tip="Edit"
          className="pa2 items-center dim pointer"
        >
          <Edit20 />
        </div>
        <div className="pa2 red dim" data-tip="Delete" onClick={onDelete}>
          <SubtractAlt20 />
        </div>
      </div>
    </div>
  );
};

const Timeline = ({ state, dispatch, events, onSubmit, editingDisabled }) => {
  const [page, setPage] = useState('Timeline');
  const pages = List(['Timeline', page === 'Edit Event' ? page : 'Add Event']);
  const [model, setModel] = useState(Map());

  useEffect(() => {
    if (page !== 'Edit Event') {
      setModel(Map());
    }
  }, [page]);
  return (
    <div className="pb5 i18n">
      <Accordion options={pages} onChange={setPage} value={page} />
      <div
        className={classNames('ph3', {
          dn: page !== 'Edit Event' && page !== 'Add Event',
        })}
      >
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            value={model.get('date')}
            onChange={e => {
              setModel(model.set('date', e.target.value));
            }}
            title="Date"
            type="date"
            inverse
          />
        </div>
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            value={model.get('time')}
            onChange={e => {
              setModel(model.set('time', e.target.value));
            }}
            title="Time"
            type="time"
            inverse
          />
        </div>
        <div className="mb2">
          <Textarea
            disabled={editingDisabled}
            value={model.get('text')}
            onChange={e => setModel(model.set('text', e.target.value))}
            title={'Event Description'}
            inverse
          />
        </div>
        <div>
          <Button
            onClick={e => {
              let time;
              try {
                time = (model.get('time') as string) || '0:0';
                const date = parseISO(model.get('date') as string);
                const [hr, min] = time.split(':');
                time = setMinutes(setHours(date, Number(hr)), Number(min)).getTime();
              } catch (e) {
                console.error(e);
              }

              const text = model.get('text');

              if (time && text) {
                setPage('Timeline');
                setModel(Map());
                onSubmit(time, text, page, model.get('eventUuid'), e);
              } else {
                dispatch(
                  Map({
                    type: 'SHOW_TOAST',
                    toast: {
                      message: 'All fields are required.',
                      style: 'ERROR',
                    },
                  }),
                );
              }
            }}
            disabled={editingDisabled}
            small
            text="Save Event"
            icon={<Add20 className="white" />}
          />
        </div>
      </div>
      <div className={classNames('ph3', { dn: page !== 'Timeline' })}>
        {events.size === 0 ? (
          <div className="gray">No timeline events.</div>
        ) : (
          events
            .valueSeq()
            .sortBy(e => e.get('time'))
            .map(e => (
              <TimelineRecord
                editingDisabled={editingDisabled}
                setPage={setPage}
                setModel={setModel}
                key={e.get('eventUuid')}
                state={state}
                dispatch={dispatch}
                event={e}
              />
            ))
        )}
      </div>
    </div>
  );
};

const defaultTree = Map({ canView: true });

const CustomFieldValueInput: FC<{
  onSaveToAnalysis: ({ value, customFieldUuid }: { value: string; customFieldUuid: string }) => void;
  label: string;
  currentValue?: string;
  customFieldUuid: string;
  editingDisabled: boolean;
}> = ({ onSaveToAnalysis, editingDisabled, customFieldUuid, label, currentValue = '' }) => {
  const [value, setValue] = useState(currentValue);
  const handleSetValue = e => setValue(e.target.value);

  useEffect(() => {
    if (editingDisabled) return;

    if (!value || value === '' || value === currentValue) return;

    // const timer = setTimeout(() => onSaveToAnalysis({ value, customFieldUuid }), 3000);
    // return () => clearTimeout(timer);
  }, [value, onSaveToAnalysis]);

  const handleSaveFieldValue = () => {
    return onSaveToAnalysis({ value, customFieldUuid });
  };

  return (
    <div className="w-100 flex flex-column mb3">
      <label className="white mb1 f5" htmlFor={label}>
        {label}
      </label>
      <div className="w-100 flex items-stretch justify-start">
        <input
          value={value}
          className="bw1 pa2 w-100 bb bg-black-10 bg-black-05 bg-white-20 white o-50 b--white-70"
          id={label}
          type="text"
          onChange={handleSetValue}
          onBlur={handleSaveFieldValue}
          disabled={editingDisabled}
        />
      </div>
    </div>
  );
};

const CustomFieldValueDisplay: FC<{
  onRemoveFromAnalysis: ({ customFieldUuid }: { customFieldUuid: string }) => void;
  currentValue: string;
  editingDisabled: boolean;
  label: string;
  customFieldUuid: string;
}> = ({ onRemoveFromAnalysis, customFieldUuid, label, currentValue, editingDisabled }) => {
  const handleRemoveValue = () => {
    if (editingDisabled) return;

    return onRemoveFromAnalysis({ customFieldUuid });
  };

  return (
    <div className="w-100 flex flex-column mb3">
      <ReactTooltip />
      <label className="white mb1 f5" htmlFor={label}>
        {label}
      </label>
      <div className="w-100 flex items-stretch justify-start">
        <div className="w-100 pv3 ph3 bb b--white">
          <span className="f5 w1002 white">{currentValue}</span>
        </div>
        <button data-tip={`Remove value for ${label}`} onClick={handleRemoveValue} className="bg-none bb b--white">
          <Close24 fill="white" />
        </button>
      </div>
    </div>
  );
};

export const EventInformation = ({ state, dispatch, onUpdate, editingDisabled }) => {
  const i18n = state.get('i18n');
  const treeUuid = state.getIn(['tree', 'uuid']);
  const tree = state.getIn(['trees', treeUuid], defaultTree);
  // const members = state.getIn(['trees', treeUuid, 'members'], List());
  const events = state.getIn(['events', treeUuid], Map());
  const groups = state.get('groups', List());

  const rawFacilities = state.getIn(['facilities', 'list'], List());
  const rawEquipments = state.getIn(['equipments', 'list'], List());

  const username = state.get('username');
  const user = state.getIn(['users', username]);
  const aiFeatureEnabled = user.getIn(['orgFeatures', 'aiFeatures'], false);

  useEffect(() => {
    // @ts-ignore
    if (!window.IGNORE_TREE_UPDATES) return;

    const timeout = setTimeout(() => {
      console.info('setting window.IGNORE_TREE_UPDATES to false');
      // @ts-ignore
      window.IGNORE_TREE_UPDATES = false;
    }, 2000);

    return () => {
      console.info('clearing timeout for window.IGNORE_TREE_UPDATES hack');
      clearTimeout(timeout);
    };
  }, []);

  const facilities = useMemo(
    () => (rawFacilities && rawFacilities.toJS ? rawFacilities.toJS() : rawFacilities),
    [rawFacilities],
  );
  const equipments = useMemo(
    () => (rawEquipments && rawEquipments.toJS ? rawEquipments.toJS() : rawEquipments),
    [rawEquipments],
  );

  // const facilityKeys = {
  //   main_menu_key: 'location',
  //   sub_menu_key: 'site',
  //   final_key: 'department',
  //   id_key: 'facilityUuid',
  //   sub_menu_list_key: 'sites',
  //   final_menu_list_key: 'departments',
  //   main_list_key: 'facilities',
  //   selected_id_key: 'facilityUuid',
  // };

  // const equipmentKeys = {
  //   main_menu_key: 'type',
  //   sub_menu_key: 'class',
  //   final_key: 'code',
  //   id_key: 'equipmentUuid',
  //   sub_menu_list_key: 'classes',
  //   final_menu_list_key: 'codes',
  //   main_list_key: 'equipments',
  //   selected_id_key: 'equipmentUuid',
  // };

  const [model] = useState(tree);
  const [eventsModel, setEventsModel] = useState(Map());
  const [page, setPage] = useState(pages.first());
  const usersState = state.get('users', List());
  const token = state.get('token');
  const orgMembers = React.useMemo(() => usersState.valueSeq().toJS(), [usersState]);

  const [title, setTitle] = useState(tree.get('title'));
  const [description, setDescription] = useState(tree.get('description'));

  // const [groupUuid, setGroupUuid] = useState(tree.get('groupUuid'));
  const [findings, setFindings] = useState(tree.get('findings'));
  const [members, setMembers] = useState(tree.get('members'));
  const [injuries, setInjuries] = useState(tree.get('injuries'));
  const [safetyImpact, setSafetyImpact] = useState(tree.get('safetyImpact'));
  const [environmentalImpact, setEnvironmentalImpact] = useState(tree.get('environmentalImpact'));
  const [customerImpact, setCustomerImpact] = useState(tree.get('customerImpact'));
  const [productionCost, setProductionCost] = useState(tree.get('productionCost'));
  const [propertyCost, setPropertyCost] = useState(tree.get('propertyCost'));
  const [laborCost, setLaborCost] = useState(tree.get('laborCost'));
  const [frequency, setFrequency] = useState(tree.get('frequency'));
  const [owner, setOwner] = useState(tree.get('owner'));
  const customValues = tree.get('customValues', Map());
  const _equipment = tree.get('equipment', null);
  const _facility = tree.get('facility', null);
  const equipment = _equipment?.toJS ? _equipment.toJS() : _equipment;
  const facility = _facility?.toJS ? _facility.toJS() : _facility;

  const [aiInProgress, setAIInProgress] = useState(false);
  const [aiResults, setAIResults] = useState(findings !== '' ? List([findings]) : List());
  const [aiResultsIdx, setAIResultsIdx] = useState(null);
  const aiDisablingStatuses = Set(['COMPLETE', 'ARCHIVE', null]);
  const aiFeatureEnabledForTree = !aiDisablingStatuses.has(tree.get('status', null));

  useEffect(() => {
    const newResult = aiResults.get(aiResultsIdx);

    if (newResult) {
      setFindings(newResult);
    }
  }, [aiResults, aiResultsIdx, setFindings]);

  const handleSaveCustomFieldValue = ({ value, customFieldUuid }) => {
    if (!value) return;
    // if ()
    return addCustomFieldValueToTree({
      token,
      value,
      customFieldUuid,
      treeUuid,
    })
      .then(() => {
        dispatch(
          Map({
            type: 'ADD_CUSTOM_FIELD_VALUE',
            payload: {
              value,
              treeUuid,
              customFieldUuid,
            },
          }),
        );
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: 'Successfully added value for field',
            style: 'SUCCESS',
            duration: 4000,
          }),
        );
      })
      .catch(() => {
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: 'Failed to add value for field',
            style: 'ERROR',
            duration: 4000,
          }),
        );
      });
  };

  const handleRemoveCustomFieldValue = ({ customFieldUuid }) => {
    return removeCustomFieldValueFromTree({ token, customFieldUuid, treeUuid })
      .then(() => {
        dispatch(
          Map({
            type: 'REMOVE_CUSTOM_FIELD_VALUE',
            payload: {
              treeUuid,
              customFieldUuid,
            },
          }),
        );
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: 'Successfully removed value for field',
            style: 'SUCCESS',
            duration: 4000,
          }),
        );
      })
      .catch(() => {
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: 'Failed to remove value for field',
            style: 'ERROR',
            duration: 4000,
          }),
        );
      });
  };

  useEffect(() => {
    onUpdate({ title });
  }, [title]);

  useEffect(() => {
    onUpdate({ description });
  }, [description]);

  useEffect(() => {
    onUpdate({ findings });
  }, [findings]);

  useEffect(() => {
    onUpdate({ description });
  }, [description]);

  useEffect(() => {
    onUpdate({ injuries });
  }, [injuries]);

  useEffect(() => {
    onUpdate({ safetyImpact });
  }, [safetyImpact]);

  useEffect(() => {
    onUpdate({ environmentalImpact });
  }, [environmentalImpact]);

  useEffect(() => {
    onUpdate({ customerImpact });
  }, [customerImpact]);

  useEffect(() => {
    onUpdate({ productionCost });
  }, [productionCost]);

  useEffect(() => {
    onUpdate({ propertyCost });
  }, [propertyCost]);

  useEffect(() => {
    onUpdate({ laborCost });
  }, [laborCost]);

  useEffect(() => {
    onUpdate({ frequency: Number(frequency) });
  }, [frequency]);

  useEffect(() => {
    onUpdate({ members });
  }, [members]);

  useEffect(() => {
    const ownerObj = orgMembers.find(m => m.username === owner);
    onUpdate({ owner, ownerName: ownerObj.fullName || '' });
  }, [owner]);

  const eventAt = tree.get('eventAt', null);
  const startAt = tree.get('startAt', null);
  const expectedAt = tree.get('expectedAt', null);
  const completedAt = tree.get('completedAt', null);

  const [eventAtDate, setEventAtDate] = useState(eventAt ? formatDate(new Date(eventAt), 'yyyy-MM-dd') : null);
  const [eventAtTime, setEventAtTime] = useState(eventAt ? formatDate(new Date(eventAt), 'HH:mm') : null);

  const [startAtDate, setStartAtDate] = useState(startAt ? formatDate(extractDatePart(startAt), 'yyyy-MM-dd') : null);
  const [expectedAtDate, setExpectedAtDate] = useState(
    expectedAt ? formatDate(extractDatePart(expectedAt), 'yyyy-MM-dd') : null,
  );
  const [completedAtDate, setCompletedAtDate] = useState(
    completedAt ? formatDate(extractDatePart(completedAt), 'yyyy-MM-dd') : null,
  );

  const costInput = {
    frequency,
    laborCost,
    productionCost,
    propertyCost,
  };

  const totalCost = calculateFailureCost(costInput);

  useEffect(() => {
    setEventsModel(events);
  }, [events, setEventsModel]);

  useEffect(() => {
    if (treeUuid) {
      dispatch(Map({ type: 'GET_TIMELINE_EVENTS', treeUuid }));
    }
  }, [dispatch, treeUuid]);

  const onTimelineSubmit = (time, text, page, eventUuid) => {
    const treeUuid = state.getIn(['tree', 'uuid']);
    if (treeUuid) {
      if (page === 'Edit Event') {
        dispatch(Map({ type: 'EDIT_TIMELINE_EVENT', treeUuid, time, text, eventUuid }));
      } else {
        dispatch(Map({ type: 'ADD_TIMELINE_EVENT', treeUuid, time, text }));
      }
    } else {
      const eventUuid = uuidv4();
      const newEventsModel = eventsModel.set(eventUuid, Map({ eventUuid, time, text }));
      setEventsModel(newEventsModel);
      onUpdate(model, newEventsModel);
    }
  };

  const isAdmin = state
    .getIn(['users', state.getIn(['organization', 'user', 'username']), 'roles'], [])
    .includes('ADMIN');
  const isOwner = owner === state.getIn(['organization', 'user', 'username']);
  const currentOrgIsPhilips = state.getIn(['organization', 'user', 'slug']) === 'philips';

  const handleRemoveMember = member => {
    return setMembers(m => m.filter(mem => mem !== member));
  };

  const numericalPattern = /^[\.0-9]*$/;

  useEffect(() => {
    getAvailableCustomFields({ token })
      .then(data => {
        dispatch(
          Map({
            type: 'GET_CUSTOM_FIELDS',
            payload: Map(data),
          }),
        );
      })
      .catch(error => console.info('custom fields error:', error));
  }, []);

  const customFields: CustomFieldMap = state.get('customFields', Map()).toJS();

  return (
    <div className="i18n">
      {/* <ReactTooltip /> */}
      <Accordion options={pages} onChange={setPage} value={page} />
      <div className={classNames('ph3', { dn: page !== 'Info' })}>
        <div className={'mb2'}>
          <Input
            className="i18n"
            title="Title"
            inverse
            disabled={editingDisabled}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        {groups.size > 0 && (
          <div className={'mb2'}>
            <Select
              disabled={editingDisabled}
              title="Group"
              value={tree.get('groupName', null)}
              inverse
              options={groups.map(g => g.get('name')).insert(0, '')}
              onChange={e => {
                if (editingDisabled) {
                  console.warn('editing is disabled. Cannot change group to', e.target.selectedIndex);
                }

                let groupUuid = '';
                let groupName = '';
                if (e.target.selectedIndex > 0) {
                  // idx of 0 is the blank
                  const group = groups.get(e.target.selectedIndex - 1);
                  groupUuid = group.get('groupUuid');
                  groupName = group.get('name');
                }
                // const newModel = model.set('groupUuid', groupUuid);
                // setModel(newModel);
                onUpdate({ groupUuid, groupName });
              }}
            />
          </div>
        )}
        <div className={'mb2'}>
          <Textarea
            className="i18n"
            disabled={editingDisabled}
            title="Problem Statement"
            inverse
            value={description}
            // onChange={updateFieldFn('description')}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div className={'mb2'}>
          <Textarea
            disabled={editingDisabled}
            title={
              <div className="flex flex-row justify-between align-center">
                <div>Summary of Findings</div>
                {aiFeatureEnabled && aiResultsIdx !== null && (
                  <div className="flex justify-center items-center select-none">
                    <ChevronLeft20
                      className={classNames('pointer', { 'white-40': aiResultsIdx === 0 })}
                      onClick={() => setAIResultsIdx(idx => Math.max(idx - 1, 0))}
                    />
                    {`${aiResultsIdx + 1} / ${aiResults.size}`}
                    <ChevronRight20
                      className={classNames('pointer', { 'white-40': aiResultsIdx === aiResults.size - 1 })}
                      onClick={() => setAIResultsIdx(idx => Math.min(idx + 1, aiResults.size - 1))}
                    />
                  </div>
                )}
              </div>
            }
            inverse
            value={aiInProgress ? 'Generating... please wait.' : findings}
            onChange={e => {
              !aiInProgress && setFindings(e.target.value);
            }}
            style={{ height: '20rem', resize: 'none' }}
          />
          {aiFeatureEnabled && aiFeatureEnabledForTree && (
            <Button
              icon={<Renew20 />}
              text={aiResultsIdx === null ? 'Create AI Summary' : 'Regenerate'}
              small
              disabled={aiInProgress}
              onClick={() => {
                setAIInProgress(true);
                fetchAISummary(treeUuid, token)
                  .then(summary => {
                    setAIResultsIdx(aiResults.size);
                    setAIResults(res => res.push(summary));
                  })
                  .catch(() =>
                    dispatch(
                      Map({
                        type: 'SHOW_TOAST',
                        toast: {
                          message: 'Unable to generate AI Summary.',
                          style: 'ERROR',
                        },
                      }),
                    ),
                  )
                  .finally(() => setAIInProgress(false));
              }}
            />
          )}
        </div>

        <div className="mb2">
          <Input
            className="i18n"
            disabled={editingDisabled}
            value={eventAtDate}
            onChange={e => {
              if (editingDisabled) {
                return;
              }

              setEventAtDate(e.target.value);

              return onUpdate({
                eventAt:
                  e.target.value === '' ? null : new Date(addDateAndTime(e.target.value, eventAtTime)).toISOString(),
              });
            }}
            title="Event Occurred Date"
            type="date"
            inverse
          />
        </div>
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            className="i18n"
            value={eventAtTime}
            onChange={e => {
              if (editingDisabled) {
                return;
              }

              setEventAtTime(e.target.value);

              return onUpdate({
                eventAt:
                  e.target.value === '' ? null : new Date(addDateAndTime(eventAtDate, e.target.value)).toISOString(),
              });
            }}
            title="Event Occurred Time"
            type="time"
            inverse
          />
        </div>

        <div className="mb2">
          <Input
            className="i18n"
            disabled={editingDisabled}
            value={startAtDate}
            onChange={e => {
              if (editingDisabled) {
                return;
              }

              setStartAtDate(e.target.value);

              return onUpdate({
                startAt: new Date(e.target.value).toISOString(),
              });
            }}
            title="Analysis Start Date"
            type="date"
            inverse
          />
        </div>
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            className="i18n"
            value={expectedAtDate}
            onChange={e => {
              if (editingDisabled) {
                return;
              }

              setExpectedAtDate(e.target.value);

              return onUpdate({
                expectedAt: new Date(e.target.value).toISOString(),
              });
            }}
            title="Expected Completion Date"
            type="date"
            inverse
          />
        </div>

        <div className="mb2">
          <Input
            className="i18n"
            value={completedAtDate}
            onChange={e => {
              setCompletedAtDate(e.target.value);

              return onUpdate(
                {
                  completedAt: new Date(e.target.value).toISOString(),
                },
                editingDisabled !== false,
              );
            }}
            title="Analysis Completed Date"
            type="date"
            inverse
          />
        </div>
      </div>
      <section className={classNames('w100 mt4 ph3', { dn: page !== 'Info' })}>
        <h1 className="white-70 ttc fs-xl fw-medium">Custom Fields</h1>
        {Object.values(customFields)
          .sort((a, b) => a.index - b.index)
          .map(customField => {
            const currentValue = customValues.get(customField.uuid);

            if (!currentValue) {
              return (
                <CustomFieldValueInput
                  editingDisabled={editingDisabled}
                  customFieldUuid={customField.uuid}
                  key={customField.uuid}
                  label={customField.label}
                  onSaveToAnalysis={handleSaveCustomFieldValue}
                  currentValue={currentValue}
                />
              );
            }

            return (
              <CustomFieldValueDisplay
                editingDisabled={editingDisabled}
                customFieldUuid={customField.uuid}
                key={customField.uuid}
                label={customField.label}
                currentValue={currentValue}
                onRemoveFromAnalysis={handleRemoveCustomFieldValue}
              />
            );
          })}
      </section>
      <div className={classNames('mt4', { dn: page !== 'Info' })}>
        <Timeline
          state={state}
          dispatch={dispatch}
          events={eventsModel}
          onSubmit={onTimelineSubmit}
          editingDisabled={editingDisabled}
        />
      </div>
      <div
        className={classNames('ph3', {
          dn: page !== 'Equipment',
        })}
      >
        <div className="mb2 cf">
          <div className="f5 mb1 white">Facility</div>
          <DeepSelect
            className="py0"
            token={token}
            theme="dark"
            type="facilities"
            defaultValue={facility}
            onChange={(value, meta) => {
              /**
               * select option: {
               *  label: "Location 1 Site 2", // namePath.join(' ')
               *  p: ["0085", "0029"], // path
               *  path: "Location 1/Site 2", // namePath.join('/')
               *  value: "93feb2d0-217f-44c6-9bdb-895b1015d825" // last(uuidPath)
               * }
               *
               * facility/equipment data: {
                    namePath: ["Location 1", "Site 2"];
                    uuidPath: ["...", "93feb2d0-217f-44c6-9bdb-895b1015d825"];
                    path: ["0085", "0029"];
               * }
               */
              if (editingDisabled) {
                return;
              }

              if (meta.action === 'clear') {
                return onUpdate({ facility: null });
              } else if (meta.action === 'select-option') {
                return onUpdate({ facility: value.object });
              }
            }}
          />
        </div>

        <div className="mb2 cf">
          <div className="f5 mb1 white">Equipment</div>
          <DeepSelect
            className="py0"
            token={token}
            theme="dark"
            type="equipment"
            defaultValue={equipment}
            defaultInputValue={equipment?.namePath.join(' ')}
            onChange={(value, meta) => {
              /**
               * select option: {
               *  label: "Location 1 Site 2", // namePath.join(' ')
               *  p: ["0085", "0029"], // path
               *  path: "Location 1/Site 2", // namePath.join('/')
               *  value: "93feb2d0-217f-44c6-9bdb-895b1015d825" // last(uuidPath)
               * }
               *
               * facility/equipment data: {
                    namePath: ["Location 1", "Site 2"];
                    uuidPath: ["...", "93feb2d0-217f-44c6-9bdb-895b1015d825"];
                    path: ["0085", "0029"];
               * }
               */
              if (editingDisabled) {
                return;
              }

              if (meta.action === 'clear') {
                return onUpdate({ equipment: null });
              } else if (meta.action === 'select-option') {
                return onUpdate({ equipment: value.object });
              }
            }}
          />
        </div>
      </div>
      <div className={classNames('ph3', { dn: page !== 'Costs' })}>
        <div className="mb2">
          <Textarea
            disabled={editingDisabled}
            title={_(i18n, 'Injury Report')}
            inverse
            // value={model.get('injuries', '')}
            // onChange={updateFieldFn('injuries')}
            value={injuries}
            onChange={e => setInjuries(e.target.value)}
          />
        </div>
        <div className="mb2">
          <Textarea
            disabled={editingDisabled}
            title={_(i18n, 'Safety Impact')}
            type="text"
            inverse
            value={safetyImpact}
            onChange={e => setSafetyImpact(e.target.value)}
          />
        </div>
        <div className="mb2">
          <Textarea
            disabled={editingDisabled}
            title={_(i18n, 'Environmental Impact')}
            type="text"
            inverse
            value={environmentalImpact}
            onChange={e => setEnvironmentalImpact(e.target.value)}
          />
        </div>
        <div className="mb2">
          <Textarea
            disabled={editingDisabled}
            title={_(i18n, 'Customer Impact')}
            type="text"
            inverse
            value={customerImpact}
            onChange={e => setCustomerImpact(e.target.value)}
          />
        </div>
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            title={_(i18n, 'Production/Schedule Cost')}
            type="text"
            inverse
            value={productionCost}
            onChange={e => {
              if (numericalPattern.test(e.target.value)) {
                setProductionCost(e.target.value);
              }
            }}
          />
        </div>
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            title={_(i18n, 'Property/Equipment Cost')}
            type="text"
            inverse
            value={propertyCost}
            onChange={e => {
              if (numericalPattern.test(e.target.value)) {
                setPropertyCost(e.target.value);
              }
            }}
          />
        </div>
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            title={_(i18n, 'Labor/Time Cost')}
            type="text"
            inverse
            value={laborCost}
            onChange={e => {
              if (numericalPattern.test(e.target.value)) {
                setLaborCost(e.target.value);
              }
            }}
          />
        </div>
        <div className="mb2">
          <Input
            disabled={editingDisabled}
            title={_(i18n, 'Frequency per Year')}
            type="number"
            inverse
            value={frequency}
            onChange={e => {
              if (numericalPattern.test(e.target.value)) {
                setFrequency(e.target.value);
              }
            }}
          />
        </div>
        <div className="mt3">
          <div className="f3">${totalCost.total}</div>
          <div className="mt1 f6 light-gray">Annualized Financial Cost</div>
        </div>
      </div>
      <div className={classNames({ dn: page !== 'Team' })}>
        <Section title={_(i18n, 'Add New Member')}>
          <div className={'mb2'}>
            <OrgMembersSelect
              isDisabled={editingDisabled}
              data={orgMembers}
              isDark
              className="w-100 br0"
              isSearchable
              isMulti={false}
              isClearable={false}
              controlShouldRenderValue={false}
              placeholder="Email address"
              onChange={(value, m) => {
                if (m.action === 'select-option') {
                  setMembers(m => [...m, value.value]);
                }
              }}
            />
          </div>
        </Section>
        <Section title={_(i18n, 'Members')}>
          {members
            // .add(username)
            .sort()
            .map(u => {
              return (
                <Member
                  state={state}
                  dispatch={dispatch}
                  useContextMenu={(isAdmin || isOwner) && !editingDisabled}
                  key={u}
                  member={u}
                  onRemove={handleRemoveMember}
                  onTransferOwnership={owner => {
                    setOwner(owner);
                  }}
                  isOwner={owner === u}
                />
              );
            })}
          <ViewOnlyMember
            state={state}
            value={tree.get('canView')}
            sharingDisabled={currentOrgIsPhilips}
            onUpdate={canView => {
              if (editingDisabled) {
                return;
              }

              return onUpdate({ canView });
            }}
          />
        </Section>
      </div>
    </div>
  );
};

export const EventInfoOptions = ({ state, dispatch, editingDisabled }) => {
  const [saving] = useState(false);
  const ws = state.get('ws');
  const token = state.get('token');
  const treeUuid = state.getIn(['tree', 'uuid'], null);

  useEffect(() => {
    if (ws) {
      dispatch(Map({ type: 'LIST_GROUPS' }));
    }
  }, [ws, dispatch]);

  const handleOnUpdate = useCallback(
    debounce((updates, forceUpdate) => {
      // forceUpdate added to handler to allow completedAt to be updated for completed analyses
      // REL-2304 / #3115
      if (editingDisabled && !forceUpdate) {
        return;
        // @ts-ignore
      } else if (window.IGNORE_TREE_UPDATES) {
        return;
      }

      if (!token) {
        console.error('[handleOnUpdate]: cannot update tree because token is missing.');
        return;
      }

      return updateTree({ token, treeUuid, updates })
        .then(results => {
          if (results.ok) {
            return dispatch({ type: 'TREE_UPDATED', treeUuid, updates });
          } else {
            return dispatch(
              Map({
                type: 'SHOW_TOAST',
                message: results.error,
                duration: 4000,
                style: 'ERROR',
              }),
            );
          }
        })
        .catch(e => console.error(e));
    }, 275),
    [],
  );

  return (
    <Blade
      onClose={noop}
      title="Event Information"
      show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'EVENT_INFO'}
      state={state}
      dispatch={dispatch}
    >
      <EventInformation state={state} dispatch={dispatch} editingDisabled={editingDisabled} onUpdate={handleOnUpdate} />
      <div
        className={classNames('items-center mh3', {
          dn: !saving,
          'flex fadeIn': saving,
        })}
      >
        <Renew20 className={'spin blue fill--blue'} />
        <div className={'ml2 white-80 f6'}>Auto-syncing</div>
      </div>
    </Blade>
  );
};
