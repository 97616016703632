import React from 'react';
import type { TooltipProps as BaseTooltipProps } from 'recharts';
import styles from './styles.module.css';

type ValueType = number | string | Array<number | string>;
type NameType = number | string;

type TooltipProps = BaseTooltipProps<ValueType, NameType> & {
  height?: number;
};

export const Tooltip: React.FC<TooltipProps> = ({ active, payload, ...props }) => {
  const height = props.height ? props.height - 8 : 24;
  if (active && payload && payload.length) {
    return (
      <div className="bg-white br1 fs-xxs fg--black-80 shadow--large">
        <div className="flex items-center justify-center">
          <p className="flex1 pv2 ma0 ph2 fw-semibold">{payload[0].payload.label}</p>
          <div className={styles['separator']} style={{ height: height - 8 }} />
          <p className="ma0 flex1 pa2 mono ta-center fw-regular">{payload[0].payload.value}</p>
        </div>
      </div>
    );
  }

  return null;
};
