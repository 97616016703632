import React from 'react';

import { useCopyToClipboard } from 'components';

export const CopyToClipboardButton: React.FC<{
  label: string;
  onSuccess?: () => void;
  onError?: () => void;
  source: React.RefObject<HTMLDivElement>;
}> = ({ label, source, onSuccess, onError }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const [handleCopyToClipboard] = useCopyToClipboard({
    source,
    textarea: textareaRef,
    onSuccess,
    onError,
  });

  if (!source) {
    console.error('CopyToClipboardButton did not receive a source ref.');
    return null;
  }

  return (
    <>
      <button className="bg-transparent ba mt2 b--black black pa2 w-80 mb3 i18n" onClick={handleCopyToClipboard}>
        {label}
      </button>
      <textarea className="hide" ref={textareaRef} />
    </>
  );
};
