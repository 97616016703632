import { Map, List } from 'immutable';
import selectedSrc from './svgs/node_selected.svg';
import attachmentOverlayIncompleteSrc from './svgs/node_attachment_overlay_incomplete.svg';
import attachmentOverlayOverdueSrc from './svgs/node_attachment_overlay_overdue.svg';
import attachmentOverlayCompleteSrc from './svgs/node_attachment_overlay_complete.svg';
import attachmentOverlayOtherSrc from './svgs/node_attachment_overlay_other.svg';
import hiddenSrc from './svgs/node_hidden.svg';
import genericSrc from './svgs/node_generic.svg';

export const styleNode = (selector, style) =>
  Map({
    selector,
    style: Map(style),
  });

export const styleEdge = styleNode;

const styleNodeTextDefault = Map({
  selector: 'node[label]',
  style: Map({
    label: 'data(label)',
    'font-size': 18,
    'font-family': '"IBM Plex Sans", sans-serif',
    'line-height': 1.125,
    'text-wrap': 'wrap',
    'text-max-width': 220,
    color: 'black',
    'text-justification': 'left',
    'text-halign': 'center',
    'text-valign': 'center',
  }),
});

export const bgMap = src =>
  Map({
    'background-image': src,
  });

const bgMapAttachment = (src, overlay) =>
  Map({
    'background-image': List([src, overlay]),
    'background-position-x': List([0, 0]),
    'background-position-y': List([0, '100%']),
  });

export const bgMapAttachmentStyles = (selectorPrefix, src) => {
  return [
    styleNode(selectorPrefix+'__A_Overdue', bgMapAttachment(src, attachmentOverlayOverdueSrc)),
    styleNode(selectorPrefix+'__A_Incomplete', bgMapAttachment(src, attachmentOverlayIncompleteSrc)),
    styleNode(selectorPrefix+'__A_Complete', bgMapAttachment(src, attachmentOverlayCompleteSrc)),
    styleNode(selectorPrefix+'__A_Other', bgMapAttachment(src, attachmentOverlayOtherSrc)),
  ];
}
const overlay = Map({
  'overlay-opacity': 0.2,
  'overlay-color': '#ffb700',
});

const styleNodeShape = Map({
  shape: 'rectangle',
  height: 200,
  width: 250,
  'border-width': 0,
  'background-color': 'white',
  'background-image': genericSrc,
});

const styleNodeDefault = Map({
  selector: 'node',
  style: styleNodeShape,
});

export const defaultStylesheet = l =>
  List([
    styleNode('node:selected', overlay),
    styleNodeTextDefault,
    styleNodeDefault,
    styleNode('node.empty', Map({ color: '#aaa' })),
    styleNode('node.invisible', Map({ color: '#fff' })),
    styleNode('node.selected', bgMap(selectedSrc)),
    styleNode(
      'node.hidden',
      Map({
        'background-image': hiddenSrc,
        height: 50,
        width: 95,
        'border-width': 0,
        'background-color': 'white',
      }),
    ),
  ]).concat(l);
