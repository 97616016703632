import React from 'react';
import { ArrowUp16 } from '@carbon/icons-react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { debounce } from 'lodash';

import styles from './styles.module.css';

export const BackToTop = ({ scrollTargetId = 'scroll-target' }) => {
  const target = document.querySelector(`#${scrollTargetId}`);
  const [isVisible, setIsVisible] = React.useState(false);

  const checkScrollPosition = e => {
    if (e.target && e.target.scrollTop >= 200) {
      return setIsVisible(true);
    }
    return setIsVisible(false);
  };

  const scrollToTop = () => {
    if (target && target.scrollTo) {
      return target.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleScroll = debounce(checkScrollPosition, 250, { leading: true });

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);
  /* eslint-enable */

  return (
    <CSSTransition
      in={isVisible}
      timeout={500}
      classNames={{
        enter: styles.transitionEnter,
        enterActive: styles.transitionEnterActive,
        enterDone: styles.transitionEnterDone,
        exit: styles.transitionExit,
        exitActive: styles.transitionExitActive,
        exitDone: styles.transitionExitDone,
      }}
    >
      <button onClick={scrollToTop} className={cx('hide-for-print i18n', styles.BackToTop)}>
        <ArrowUp16 />
        <span className={styles.buttonLabel}>Back to Top</span>
      </button>
    </CSSTransition>
  );
};
