import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

type ValueSentimentDisplayProps = {
  value: string | number;
  tooltip?: string;
  postfix?: string;
};

export const ValueSentimentDisplay: React.FC<ValueSentimentDisplayProps> = ({ value, tooltip, postfix }) => {
  const sentiment = React.useMemo(() => {
    if (Number(value) < 0) {
      return 'negative';
    }

    if (Number(value) > 0) {
      return 'positive';
    }

    return 'neutral';
  }, [value]);
  return (
    <span
      data-tip={tooltip}
      className={cx('fs-baseline fw-medium mono flex items-center justify-around', styles['previousValue'], {
        'fg--red': sentiment === 'negative',
        'fg--black': sentiment === 'neutral',
        'fg--green': sentiment === 'positive',
      })}
    >
      {sentiment === 'positive' ? '+' : null}
      {value}
      {postfix ? postfix : null}
    </span>
  );
};
