import classNames from 'classnames';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Set, List, Map } from 'immutable';
import {
  useLocation,
  useHistory,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';
import Fuse from 'fuse.js';
import { auth, initXhr, xhr, updateOrganizations } from './Fetch';
import { fire } from './Reducer';
import { userItems, orgItems, Controls } from './Controls';
import { copyCSVToClipboard } from './CSV';

const SidebarItem = ({ state, dispatch, text, route }) => {
  const location = useLocation();
  const url = `/sa/${route}`;
  return (
    <Link
      to={url}
      className={classNames('no-underline black', {
        fw7: location.pathname === url,
      })}
    >
      <div className="ph3 pa1 dim mb2">{text}</div>
    </Link>
  );
};

const SidebarHeader = ({ state, dispatch, text }) => {
  return <div className="f6 fw7 ph3 pv1 gray ttu">{text}</div>;
};

const Sidebar = ({ state, dispatch }) => {
  const companyName = state.getIn(['organization', 'companyName'], '');
  const email = state.getIn(['user', 'email'], '');
  return (
    <div
      className="absolute top-0 left-0 bottom-0 br bw3 b--black"
      style={{ width: '350px' }}
    >
      <div className="pa3 bb bw1 b--light-gray">
        <div className="f4 mb1">{companyName}</div>
        <div className="f5">{email}</div>
      </div>
      <div className="pv3">
        <div className="mb4">
          <SidebarHeader
            state={state}
            dispatch={dispatch}
            text="User-specific Actions"
          />
          {userItems.entrySeq().map(([k, v]) => (
            <SidebarItem
              key={k}
              state={state}
              dispatch={dispatch}
              text={v}
              route={k}
            />
          ))}
        </div>
        <div className="mb4">
          <SidebarHeader
            state={state}
            dispatch={dispatch}
            text="Organizational Actions"
          />
          {orgItems.entrySeq().map(([k, v]) => (
            <SidebarItem
              key={k}
              state={state}
              dispatch={dispatch}
              text={v}
              route={k}
            />
          ))}
        </div>
        <div className="mb4">
          <SidebarHeader state={state} dispatch={dispatch} text="Export" />
          <div
            onClick={() => {
              fire(dispatch, 'SHOW_MODAL', {
                message: 'CSV is in your clipboard',
                style: 'SUCCESS',
              });
              copyCSVToClipboard(state);
            }}
            className="ph3 pa1 dim mb2 pointer"
          >
            Copy CSV to Clipboard
          </div>
        </div>
      </div>
    </div>
  );
};

const Badge = ({ text, className }) => {
  return (
    <div className={classNames('dib ba f6 ttl pa1 mh1', className)}>{text}</div>
  );
};

const OrganizationUser = ({ state, dispatch, user }) => {
  const expired = user.get('expired');
  const email = user.get('email');
  const roles = user.get('roles');
  const name = user.get('fullName');
  const phone = user.get('phone');
  const count = state.getIn(['counts', email.toLowerCase()], 0);

  const userCreatedAt = Math.max(
    user.get('userCreatedAt'),
    user.get('organizationCreatedAt'),
  );

  return (
    <div
      className={classNames(
        'dim pv2 bb b--white flex items-center pointer',
        {},
      )}
      onClick={() => {
        fire(dispatch, 'SET_USER', { user });
        fire(dispatch, 'SET_ORGANIZATION', {
          organization: user,
        });
      }}
      key={`${email} ${roles}`}
    >
      <div className="w-20">{email}</div>
      <div className="w-20">{name}</div>
      <div className="w-10">{phone}</div>
      <div className="w-20">
        {expired ? (
          <Badge text={'expired'} className={'bg-red white'} />
        ) : (
          <div />
        )}
        {roles
          .filter(x => x)
          .map(r => (
            <Badge
              key={r}
              text={r}
              className={{
                'bg-blue white': r === 'ADMIN',
                'bg-green white': r === 'PAID',
              }}
            />
          ))}
      </div>
      <div className="w-10">{count}</div>
      <div className="flex-auto">
        {new Date(userCreatedAt).toLocaleDateString()}
      </div>
    </div>
  );
};

const OrganizationRow = ({ state, dispatch, organization }) => {
  const name = organization.getIn([0, 'companyName']);
  const orgId = organization.getIn([0, 'organizationsId']);
  const licenses = organization.getIn([0, 'licenses']);
  const templates = organization.getIn([0, 'features', 'templates']);
  const emailDash = organization.getIn([0, 'features', 'emailDash'], false);
  const taskMetadata = organization.getIn([0, 'features', 'taskMetadata']);
  const i18n = organization.getIn([0, 'features', 'i18n']);
  const usedLicenses = organization
    .filter(u => Set(u.get('roles')).has('PAID'))
    .count();
  const createdAt = organization.getIn([0, 'organizationCreatedAt'], 0);

  return (
    <div className="ba mb2 b--light-gray">
      <div className="flex justify-between mb2 bg-light-gray items-center">
        <div className="flex-auto fw7 pa2">{name}</div>
        <div className="w-10 tr pa2">
         id: {orgId}
        </div>
        <div className="w-10 tr pa2">
         KPI Email: {JSON.stringify(emailDash)}
        </div>
        <div className="w-10 tr pa2">
         I18n: {JSON.stringify(i18n)}
        </div>
        <div className="w-10 tr pa2">
         Templates: {JSON.stringify(templates)}
        </div>
        <div className="w-10 tr pa2">
         Task Metadata: {JSON.stringify(taskMetadata)}
        </div>
        <div
          className={classNames('w-10 pa2', {
            'bg-green white': usedLicenses === licenses && licenses > 0,
            'bg-yellow black': (usedLicenses < licenses || licenses < 0) && usedLicenses > 0,
            'bg-washed-red black': usedLicenses === 0,
          })}
        >
          {usedLicenses} of {licenses > 0 ? licenses : 'unlimited'}
        </div>
        <div className="w-10 tr pa2">
          {new Date(createdAt).toLocaleDateString()}
        </div>
      </div>
      <div className="ph2">
        {organization
          .sortBy(u => !Set(u.get('roles')).has('ADMIN'))
          .map(u => (
            <OrganizationUser
              key={u.get('id')}
              state={state}
              dispatch={dispatch}
              user={u}
            />
          ))}
      </div>
    </div>
  );
};

const OrganizationsList = ({ state, dispatch }) => {
  useEffect(() => {
    updateOrganizations(dispatch);
  }, [dispatch]);

  const organizations = state.get('organizations', List());
  const [search, setSearch] = useState('');

  const fuse = useMemo(
    () =>
      new Fuse(organizations.flatMap(o => o).toJS(), {
        keys: ['companyName', 'fullName', 'roles', 'username'],
      }),
    [organizations],
  );

  const userSearch = useCallback(
    query =>
      List(fuse.search(query).map(x => Map(x.item)))
        .groupBy(x => x.get('organizationsId'))
        .valueSeq(),
    [fuse],
  );

  return (
    <div
      className="absolute top-0 right-0 pa3 overflow-y-scroll bb bw3 b--black"
      style={{ left: '350px', bottom: '350px' }}
    >
      <div className="mb3">
        <input
          placeholder="Search"
          type="text"
          className="w-50 sa"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {
        <div>
          {(search !== '' ? userSearch(search) : organizations).map(o => (
            <OrganizationRow
              state={state}
              dispatch={dispatch}
              key={o.first().get('organizationsId')}
              organization={o}
            />
          ))}
        </div>
      }
    </div>
  );
};

export const Login = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const key = model.get('key', '');
  const error = model.get('error');
  const history = useHistory();

  const onError = useCallback(
    err => {
      console.error(err);
      setModel(m =>
        m.set(
          'error',
          'Invalid key: Talk to becker@reliability.com to get a valid key.',
        ),
      );
    },
    [setModel],
  );

  const onSubmit = useCallback(
    e => {
      if (key === '') {
        onError();
      } else {
        initXhr(key);
        xhr('GET', '/', null)
          .then(res => {
            if (res.status === 200) {
              history.replace('/sa');
            } else {
              onError();
            }
          })
          .catch(onError);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [key, onError, history],
  );

  return (
    <div className="absolute top-0 left-0 bottom-0 right-0 z-1 flex justify-center items-center bg-navy">
      <div className="bw1 pa4 mw6 w-100 mb4 bg-white shadow-1">
        <div className="mt3">
          <form onSubmit={onSubmit}>
            <input
              value={key}
              onChange={e => setModel(model.set('key', e.target.value))}
              className="w-100 mb2 sa"
              autoFocus
            />
            <button type="submit">Unlock</button>
          </form>
          <div className={classNames('mt3 red f6', { dn: !error, db: error })}>
            {error || ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PrivateRoute = ({ state, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect to={{ pathname: '/sa_login', state: { from: location } }} />
        )
      }
    />
  );
};

const Modal = ({ state, dispatch }) => {
  const modalMessage = state.getIn(['modal', 'message']);
  const modalType = state.getIn(['modal', 'style']);
  const isError = modalType === 'ERROR';

  useEffect(() => {
    if (modalMessage) {
      setTimeout(() => {
        fire(dispatch, 'HIDE_MODAL');
      }, 3000);
    }
  }, [modalMessage, dispatch]);

  return (
    <div
      className={classNames('fixed z-1 top-0 right-0 mt4 mr4 fw7 pa2', {
        'bg-red white': isError,
        'bg-black white': !isError,
        dn: !modalMessage,
      })}
      style={{ height: '100px', width: '400px' }}
    >
      <div>
        <div className="dib ba ttu fw7 f6 pa1 mb1">
          {isError ? 'ERROR' : 'SUCCESS'}
        </div>
        <div>{modalMessage}</div>
      </div>
    </div>
  );
};

export const SuperAdmin = ({ state, dispatch }) => {
  return (
    <>
      <Sidebar state={state} dispatch={dispatch} />
      <Modal state={state} dispatch={dispatch} />
      <OrganizationsList state={state} dispatch={dispatch} />
      <Controls state={state} dispatch={dispatch} />
    </>
  );
};
