import React, { useState, useEffect } from 'react';
import { List, Map, Set, Range } from 'immutable';
import { formatDistance } from 'date-fns';
import classNames from 'classnames';
import { Launch20, NextFilled20, PreviousFilled20 } from '@carbon/icons-react';
import { Legend, BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Page } from './Page';
import { Avatar } from './Nav';
import { ppStatus } from './LegacyAnalyses';
import { filterTypeToFilter } from './LegacyAnalyses';
import { initDispatchedWS } from './Sync';

const generateMessageString = (state, feedMessage) => {
  const type = feedMessage.get('messageType');
  const message = feedMessage.get('message');

  const me = state.get('username');
  const feedUsername = feedMessage.get('username');
  const username = me === feedUsername ? 'You' : feedUsername;

  switch (type) {
    case 'NEW_TREE': {
      return `${username} created a new analysis.`;
    }

    case 'SET_STATUS': {
      const treeUuid = message.get('treeUuid');
      const status = message.get('status');
      const tree = state.getIn(['trees', treeUuid], Map());
      const treeTitle = tree.get('title');

      if (tree) {
        return `${username} set the status of "${treeTitle || 'Untitled Analysis'}" to "${ppStatus(status)}."`;
      } else {
        return `${username} set the status of a tree to ${ppStatus(status)}.`;
      }
    }

    case 'ADD_FILE': {
      const treeUuid = message.get('treeUuid');
      const filename = message.get('filename');
      const title = state.getIn(['trees', treeUuid, 'title']);

      if (title) {
        return `${username} added a file called "${filename}" to "${title}."`;
      } else {
        return `${username} added a file called "${filename}" to a tree.`;
      }
    }

    case 'ADD_TASK': {
      const doers = message.get('doers');
      const title = message.get('title');
      const deadline = message.get('deadline');
      const treeUuid = message.get('treeUuid');
      const treeTitle = state.getIn(['trees', treeUuid, 'title']);

      if (treeTitle) {
        return `${username} added a task to ${doers.join(', ')} on "${title}" due ${formatDistance(
          deadline,
          Date.now(),
        )}`;
      } else {
        return `${username} assigned a task to ${doers.join(', ')} due ${formatDistance(deadline, Date.now())}`;
      }
    }

    case 'ADD_NOTE': {
      const treeUuid = message.get('treeUuid');
      const treeTitle = state.getIn(['trees', treeUuid, 'title']);
      const noteType = message.get('noteType') === 'VERIFICATION' ? 'verification task' : 'comment';

      if (treeTitle) {
        return `${username} added a ${noteType} to "${treeTitle}."`;
      } else {
        return `${username} added a ${noteType}.`;
      }
    }

    case 'COMPLETE_TASK': {
      const completedBy = message.get('completedBy') || 'A user';
      const treeUuid = message.get('treeUuid');
      const taskUuid = message.get('taskUuid');
      const taskTitle = state.getIn(['tasks', taskUuid, 'title']) || 'Untitled Task';
      const treeTitle = state.getIn(['trees', treeUuid, 'title']);
      const completed = message.get('completed') ? 'completed' : 'restarted';

      if (treeTitle) {
        return `${completedBy} ${completed} task "${taskTitle}" on "${treeTitle}".`;
      } else {
        return `${completedBy} ${completed} task "${taskTitle}".`;
      }
    }

    default:
      return <div>{`(UNKNOWN) ${username} took an action to modify an analysis.`}</div>;
  }
};

const FeedItem = ({ state, dispatch, message, index }) => {
  const username = message.get('username');
  const eventAt = message.get('eventAt');
  const fullName = state.getIn(['users', username, 'fullName']);

  return (
    <div className={classNames('bb bl br pa3 b--gray', { bt: index === 0 })}>
      <div className="flex justify-between">
        <div className="flex items-center">
          <Avatar height={42} username={username} state={state} dispatch={dispatch} />
          <div className="ml2">
            <div className="f6 fw7">{fullName}</div>
          </div>
        </div>
        <div className="f6 gray flex">{`${formatDistance(eventAt, Date.now())} ago`}</div>
      </div>
      <div className="mt2">{generateMessageString(state, message)}</div>
    </div>
  );
};

const FeedList = ({ state, dispatch, feeds }) => {
  return (
    <div className="animated fadeIn">
      <div>
        {feeds
          .sortBy(message => -1 * message.get('eventAt'))
          .map((message, index) => (
            <FeedItem
              key={`${message.get('messageType')}:${message.get('eventAt')}`}
              message={message}
              state={state}
              dispatch={dispatch}
              index={index}
            />
          ))}
      </div>
    </div>
  );
};

const TaskItem = ({ state, dispatch, task, index }) => {
  const title = task.get('title');
  const treeTitle = task.get('treeTitle');
  const deadline = task.get('deadline');
  const now = Date.now();
  const deadlineString = formatDistance(task.get('deadline'), now);
  const description = task.get('description');
  const completedAt = task.get('completedAt');
  const treeUuid = task.get('treeUuid');
  const doers = task.get('doers');
  const overdue = now > deadline;

  const timeMessage = completedAt
    ? `Completed ${formatDistance(completedAt, new Date())} ago.`
    : overdue
    ? `Overdue by ${deadlineString}.`
    : `Due in ${deadlineString}.`;

  return (
    <div
      className={classNames('bb bl br pa3 w-100 relative', {
        'bg-washed-red b--red': overdue && !completedAt,
        'bg-white b--gray': !overdue,
        bt: index === 0,
      })}
    >
      <div className="absolute top-0 right-0 ma3">
        <div
          className="pa2 mt2 bg-blue white dim pointer"
          onClick={() => dispatch(Map({ type: 'OPEN_TREE', treeUuid }))}
        >
          <Launch20 />
        </div>
      </div>
      <div className="mr4 w-100">
        <div className="flex items-center justify-between mr4 mb3">
          <div style={{ flex: 1 }}>
            <div className="mt2 fw6">Task: {title}</div>
            <div className="mt1 f6 mr3 tj">Description: {description}</div>
            {treeTitle ? <div className="mt1 f6">Title: {treeTitle}</div> : null}
            <div
              className={classNames('mt2 f6', {
                'red fw7': overdue && !completedAt,
                gray: !overdue,
              })}
            >
              {timeMessage}
            </div>
          </div>
        </div>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
          }}
        >
          {doers.map((doer, index) => (
            <Avatar key={index} state={state} dispatch={dispatch} height={42} username={doer} />
          ))}
        </div>
      </div>
    </div>
  );
};

const getFilterFromType = (state, type) => t => {
  const completedAt = t.get('completedAt');
  const deadline = t.get('deadline');
  const taskType = t.get('taskType');
  const doers = t.get('doers');
  const me = state.get('username');
  const now = Date.now();
  switch (type) {
    case 'MINE':
      return doers.filter(d => d.toLowerCase().includes(me.toLowerCase())).size > 0 && !completedAt;
    case 'INCOMPLETE':
      return !completedAt;
    case 'COMPLETED':
      return completedAt;
    case 'LATE':
      return deadline < now && !completedAt;
    case 'CORRECTIVE_ACTION':
      return taskType === 'CORRECTIVE_ACTION' && !completedAt;
    default:
      return true;
  }
};

const TasksList = ({ state, dispatch, page, count }) => {
  const tasks = state.get('finalTaskFeed', Map());
  const feeds = state.get('feed', List());
  const [model, setModel] = useState(Map({ filterType: 'MINE' }));

  const filterType = model.get('filterType');
  const isIncomplete = filterType === 'INCOMPLETE';
  const isPastDue = filterType === 'LATE';
  const isCompleted = filterType === 'COMPLETED';
  const isMine = filterType === 'MINE';
  const isActivities = filterType === 'ACTIVITIES';
  const isCA = filterType === 'CORRECTIVE_ACTION';

  const paginate = array => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    if (isActivities) {
      return array.sortBy(message => -1 * message.get('eventAt')).slice((page - 1) * count, page * count);
    }
    return array
      .valueSeq()
      .filter(getFilterFromType(state, filterType))
      .sortBy(t => -1 * t.get('deadline'))
      .slice((page - 1) * count, page * count);
    // return array;
  };

  return (
    <div className="w-100">
      <div className="flex items-center f6 bb b--gray mb3 w-100">
        <div
          onClick={() => {
            setModel(m => m.set('filterType', 'MINE'));
            dispatch(Map({ type: 'SET_ACTIVE_DASHBOARD_TAB', filterType: 'MINE' }));
          }}
          className={classNames('trans dim pointer pa2', {
            'bb bw1 b--blue blue': isMine,
            gray: !isMine,
          })}
        >
          Tasks
        </div>
        <div
          onClick={() => {
            setModel(m => m.set('filterType', 'CORRECTIVE_ACTION'));
            dispatch(
              Map({
                type: 'SET_ACTIVE_DASHBOARD_TAB',
                filterType: 'CORRECTIVE_ACTION',
              }),
            );
          }}
          className={classNames('trans dim pointer pa2', {
            'bb bw1 b--blue blue': isCA,
            gray: !isCA,
          })}
        >
          Corrective Actions
        </div>
        <div
          onClick={() => {
            setModel(m => m.set('filterType', 'INCOMPLETE'));
            dispatch(
              Map({
                type: 'SET_ACTIVE_DASHBOARD_TAB',
                filterType: 'INCOMPLETE',
              }),
            );
          }}
          className={classNames('trans dim pointer pa2', {
            'bb bw1 b--blue blue': isIncomplete,
            gray: !isIncomplete,
          })}
        >
          Open
        </div>
        <div
          onClick={() => {
            setModel(m => m.set('filterType', 'LATE'));
            dispatch(Map({ type: 'SET_ACTIVE_DASHBOARD_TAB', filterType }));
          }}
          className={classNames('trans dim pointer pa2', {
            'bb bw1 b--blue blue': isPastDue,
            gray: !isPastDue,
          })}
        >
          Overdue
        </div>
        <div
          onClick={() => {
            setModel(m => m.set('filterType', 'COMPLETED'));
            dispatch(
              Map({
                type: 'SET_ACTIVE_DASHBOARD_TAB',
                filterType: 'COMPLETED',
              }),
            );
          }}
          className={classNames('trans dim pointer pa2', {
            'bb bw1 b--blue blue': isCompleted,
            gray: !isCompleted,
          })}
        >
          Completed
        </div>
        <div
          onClick={() => {
            setModel(m => m.set('filterType', 'ACTIVITIES'));
            dispatch(
              Map({
                type: 'SET_ACTIVE_DASHBOARD_TAB',
                filterType: 'ACTIVITIES',
              }),
            );
          }}
          className={classNames('trans dim pointer pa2', {
            'bb bw1 b--blue blue': isActivities,
            gray: !isActivities,
          })}
        >
          Team Activity
        </div>
      </div>
      <div className="b--gray w-100">
        {isActivities ? (
          <FeedList feeds={paginate(feeds)} state={state} dispatch={dispatch} />
        ) : (
          <>
            {paginate(tasks).map((t, index) => (
              <TaskItem key={t.get('taskUuid')} state={state} dispatch={dispatch} task={t} index={index} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const data = [];

for (let i = 0; i < 12; i++) {
  data.push({ name: i, y: Math.pow(i, 2) });
}

const activities = Map({
  NEW_TREE: 'New Trees',
  SET_STATUS: 'Status Update',
  ADD_FILE: 'Files Uploaded',
  ADD_TASK: 'Tasks Added',
  COMPLETE_TASK: 'Tasks Completed',
  ADD_NOTE: 'Verification Records',
});

const ActivitiesOverTime = ({ feed }) => {
  const data = activities
    .keySeq()
    .map(a => {
      return Map({
        name: activities.get(a),
        'Event Count': feed.filter(x => x.get('messageType') === a).size,
      });
    })
    .sortBy(x => -1 * x.get('Event Count'))
    .toJS();

  return (
    <div className="w-100 mb4 pa3" style={{ maxWidth: '33rem' }}>
      <div className="pa2 tc mb3 f4 tc">Team Activity Last 30 Days</div>
      <ResponsiveContainer height={300} width="100%">
        <BarChart data={data}>
          <Tooltip />
          <Legend />
          <XAxis dataKey="name" />
          <YAxis dataKey="Event Count" />
          <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
          <Bar dataKey="Event Count" fill="#357ddd" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const ignoreStatuses = Set(['COMPLETE', 'ARCHIVE', 'DELETED']);

const BiggestCosts = ({ trees }) => {
  let treesArray = trees.valueSeq().map(t => {
    let cost = 0;
    if (t.get('laborCost') && t.get('laborCost') !== '0') {
      cost = cost + parseInt(t.get('laborCost'));
    }
    if (t.get('productionCost') && t.get('productionCost') !== '0') {
      cost = cost + parseInt(t.get('productionCost'));
    }
    if (t.get('propertyCost') && t.get('propertyCost') !== '0') {
      cost = cost + parseInt(t.get('propertyCost'));
    }
    return Map(t.set('cost', cost));
  });
  const data = treesArray
    .valueSeq()
    .filter(t => (t.get('cost') || 0) !== 0)
    .filter(t => !ignoreStatuses.has(t.get('status')))
    .sortBy(t => -1 * (t.get('cost') | 0))
    .take(20)
    .map((t, i) =>
      Map({
        name: t.get('title'),
        'Cost[USD]': t.get('cost') | 0,
      }),
    )
    .toJS();

  return (
    <div className="w-100 mb4 pa3" style={{ maxWidth: '33rem' }}>
      <div className="pa2 tc mb3 f4 tc">Highest Cost Open RCAs</div>
      <ResponsiveContainer height={300} width="100%">
        <BarChart data={data}>
          <Tooltip />
          <Legend />
          <XAxis dataKey="name" />
          <YAxis dataKey="Cost[USD]" type="number" />
          <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
          <Bar dataKey="Cost[USD]" fill="#a67153" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const NodesHistogram = ({ data, title, color, dataKey, sortBy }) => {
  sortBy = sortBy || (([k, v]) => -1 * v);

  const rechartsData = data
    .entrySeq()
    .sortBy(sortBy)
    .map(([k, v]) => {
      return { name: k, [dataKey]: v };
    })
    .toJS();

  return (
    <div className="w-100 mb4 pa3" style={{ maxWidth: '33rem' }}>
      <div className="pa2 tc mb3 f4 tc">{title}</div>
      <ResponsiveContainer height={300} width="100%">
        <BarChart data={rechartsData}>
          <Tooltip />
          <Legend />
          <XAxis dataKey="name" />
          <YAxis dataKey={dataKey} />
          <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
          <Bar dataKey={dataKey} fill={color} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const AnalysisProgress = ({ trees }) => {
  const count = type => trees.filter(filterTypeToFilter(type)).size;

  const data = Map({
    Pending: count('PENDING'),
    'In Process': count('IN_PROCESS'),
    Review: count('REVIEW'),
    Complete: count('COMPLETE'),
  });

  return <NodesHistogram data={data} dataKey="Analysis State" title="Analysis Progress" color="#4a40ff" />;
};

const TaskProgress = ({ tasks }) => {
  const now = Date.now();

  const data = Map({
    Overdue: List(tasks.filter(t => t.get('deadline') < now && !t.get('completedAt'))).size,
    Open: List(tasks.filter(t => t.get('deadline') >= now && !t.get('completedAt'))).size,
    Completed: List(tasks.filter(t => t.get('completedAt'))).size,
  });

  return <NodesHistogram data={data} dataKey="Task Count" title="Progress on Tasks" color="#6a12e8" />;
};

const Analytics = ({ state, dispatch }) => {
  const feed = state.get('feed', List());
  const trees = state.get('trees', Map());
  const nodesFeed = state.get('nodesFeed', Map());
  const tasksFeed = state.get('finalTaskFeed', List());
  const top10Text = nodesFeed.get('top10Text', Map());
  const top10Type = nodesFeed.get('top10Type', Map());
  const top10Verified = nodesFeed.get('top10Verified', Map());
  const top10Depth = nodesFeed.get('top10Depth', Map());

  return (
    <div className="flex flex-wrap">
      <TaskProgress tasks={tasksFeed} />
      <AnalysisProgress trees={trees} />
      <BiggestCosts trees={trees} />
      <ActivitiesOverTime feed={feed} />
      <NodesHistogram data={top10Text} dataKey="Hypothesis Type" title="Most Frequent Hypotheses" color="#03588c" />
      <NodesHistogram data={top10Type} dataKey="Node Type" title="Node Type Frequency" color="#034c8c" />
      <NodesHistogram data={top10Verified} dataKey="Has Evidence" title="Verification Levels" color="#4557bf" />
      <NodesHistogram
        data={top10Depth}
        title="Analysis Depth"
        dataKey="Deepest Node"
        color="#576ef2"
        sortBy={([k, v]) => k}
      />
    </div>
  );
};

export const Dashboard = ({ state, dispatch }) => {
  const ws = state.get('ws');

  useEffect(() => {
    if (!ws) {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          dispatch(Map({ type: 'SET_URL', url: '/' }));
        }
      });
    } else {
      const lastThirty = 1000 * 60 * 60 * 24 * 30;
      dispatch(Map({ type: 'LIST' }));
      dispatch(Map({ type: 'GET_ORGANIZATION' }));
      dispatch(Map({ type: 'GET_FEED', duration: lastThirty }));
      dispatch(Map({ type: 'GET_NODES' }));
      dispatch(Map({ type: 'GET_TASKS_FEED' }));
      dispatch(
        Map({
          type: 'SET_ACTIVE_DASHBOARD_TAB',
          filterType: 'MINE',
        }),
      );
    }
  }, [ws, dispatch]);

  const [totalPages, setTotalPages] = useState(List([]));
  const [minPageNumberSet, setMinPageNumberSet] = useState(1);
  const [maxPageNumberSet, setMaxPageNumberSet] = useState(10);
  const [page, setPage] = useState(1);

  const tasks = state.get('finalTaskFeed', Map());
  const feeds = state.get('feed', List());
  const filterType = state.get('activeDashboardTab');
  const count = 30;

  useEffect(() => {
    setPage(1);
    let totalPageValue = Math.ceil(
      tasks
        .valueSeq()
        .filter(getFilterFromType(state, filterType))
        .sortBy(t => -1 * t.get('deadline')).size / count,
    );
    if (filterType === 'ACTIVITIES') {
      totalPageValue = Math.ceil(feeds.sortBy(message => -1 * message.get('eventAt')).size / count);
    }

    setTotalPages(Range(1, totalPageValue + 1).toList());
  }, [filterType, tasks, feeds, state]);

  const onPageChange = value => {
    if (totalPages.size >= value) {
      setPage(value);
    }
  };

  const onClickPageSet = type => {
    if (type === 'next') {
      if (totalPages.size > maxPageNumberSet) {
        let minPageSet = minPageNumberSet + 10;
        let maxPageSet = maxPageNumberSet + 10;
        setMinPageNumberSet(minPageSet);
        setMaxPageNumberSet(maxPageSet);
      }
    } else {
      if (totalPages.size > minPageNumberSet && minPageNumberSet > 1) {
        let minPageSet = minPageNumberSet - 10;
        let maxPageSet = maxPageNumberSet - 10;
        setMinPageNumberSet(minPageSet);
        setMaxPageNumberSet(maxPageSet);
      }
    }
  };

  return (
    <Page title={'Dashboard'} state={state} dispatch={dispatch}>
      <div className="w-100 flex justify-between flex-wrap">
        <div className="mw6">
          <TasksList page={page} count={count} state={state} dispatch={dispatch} />
        </div>
        <div className="ml4 pa3" style={{ flex: 0.9 }}>
          <Analytics state={state} dispatch={dispatch} />
        </div>
        {totalPages.size > 0 ? (
          <div className="tc mw8 center mt2">
            <div className="dib overflow-hidden ba br2 b--light-silver">
              <nav className="cf" data-name="pagination-numbers-bordered">
                <button
                  className="fl dib link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                  onClick={() => {
                    if (page > 1) {
                      let newPage = page - 1;
                      onPageChange(newPage);
                    }
                  }}
                  title="Previous"
                >
                  &larr; Previous
                </button>
                <button
                  className="fr dib link dim black f6 f5-ns b pa3 bg-white"
                  onClick={() => {
                    if (page !== totalPages) {
                      let newPage = page + 1;
                      onPageChange(newPage);
                    }
                  }}
                  title="Next"
                >
                  Next &nbsp;&rarr;
                </button>
                {totalPages.size >= maxPageNumberSet ? (
                  <>
                    <button
                      className="fl dib link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                      onClick={() => {
                        onClickPageSet('prev');
                      }}
                      title="Previous"
                    >
                      <PreviousFilled20 />
                    </button>
                    <button
                      className="fr dib link dim black f6 f5-ns b pa3 br bg-white"
                      onClick={() => {
                        onClickPageSet('next');
                      }}
                      title="Next"
                    >
                      <NextFilled20 />
                    </button>
                  </>
                ) : null}

                <div className="overflow-hidden center dt tc">
                  {totalPages
                    .filter(i => i >= minPageNumberSet && i <= maxPageNumberSet)
                    .map(x => (
                      <button
                        className="dtc link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                        onClick={() => onPageChange(x)}
                        title={x}
                        key={x}
                      >
                        {x}
                      </button>
                    ))}
                </div>
              </nav>
            </div>
          </div>
        ) : null}
      </div>
    </Page>
  );
};
