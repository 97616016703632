import { NavLink, NavLinkProps } from 'react-router-dom';
import cx from 'classnames';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { ChevronDown16, ChevronUp16, Time20 } from '@carbon/icons-react';
import { StylingProps, UserRoleProperties } from '@interfaces';
import { Avatar, CreateRCAButton } from 'components';
import { useAuth } from 'containers';

import { Map } from 'immutable';
import { useRootReducer } from 'hooks';
import markSvg from '../../svgs/logo-v2-light.svg';
import supportSvg from '../../svgs/support.svg';
import logoutSvg from '../../svgs/logout.svg';
import settingsSvg from '../../svgs/settings.svg';
import styles from './styles.module.css';

type NavigationProps = {
  isAdmin?: boolean;
  onLogout?: () => void;
};

const Logo = () => {
  return (
    <div className="ml3 mr4 flex items-center justify-center">
      <img alt="EasyRCA" src={markSvg} height={30} />
    </div>
  );
};

const TrialBadge = () => {
  return (
    <a
      href="mailto:sebastian@reliability.com?subject=Purchase a license"
      className="dim fadeIn no-underline flex items-center justify-between bg-gold ph2 pv1 fw7 f6 mr2"
      style={{ flexWrap: 'nowrap', minWidth: 'fit-content' }}
    >
      <Time20 className={'mr1'} />
      <span className="black">FREE TRIAL</span>
    </a>
  );
};

const Link: React.FC<NavLinkProps & StylingProps> = ({ children, className, ...props }) => (
  <NavLink className={cx(styles['link'], className)} activeClassName={cx(styles['link-active'])} {...props}>
    {children}
  </NavLink>
);

const UserSettings = ({
  username,
  name,
  roles,
  onLogout,
}: {
  username?: string;
  name?: string;
  roles?: Partial<UserRoleProperties>;
  onLogout: () => void;
}) => {
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Close dropdown if clicked outside
     */
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [username, roles]);

  if (username && roles) {
    return (
      <div className={cx('fill--white pointer relative pa2 justify-center flex')} ref={wrapperRef}>
        <div className="flex" onClick={() => setMenuDropdownOpen(!menuDropdownOpen)}>
          <Avatar name={name || username} borderRadius="1px" />
          <div className="ml2 f6 flex white-50 items-center mr2 trans">
            <div className="fill-white">
              <div className="fw-bold fg--white">{name}</div>
              <div className="fs-xxs fg--white">{username}</div>
            </div>
          </div>
          <div className="flex justify-center items-center ph3">
            {menuDropdownOpen ? <ChevronUp16 /> : <ChevronDown16 />}
          </div>
        </div>
        <div
          style={{ top: '3rem', width: '262px' }}
          className={cx('bg-black white absolute right-0 flex-column items-stretch', {
            dn: !menuDropdownOpen,
          })}
        >
          <div className="i18n pv2 ph3 " style={{ paddingTop: '20px' }}>
            <div
              className={cx('flex items-center label dim')}
              onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}
            >
              <div className="mr2 flex items-center">
                <img alt="EasyRCA" src={settingsSvg} height={24} />
              </div>
              <span className="i18n ">Settings &nbsp; </span>
              <div style={{ marginLeft: 'auto' }}>{settingsDropdownOpen ? <ChevronUp16 /> : <ChevronDown16 />}</div>
            </div>

            <div
              className={cx('bg-black', {
                dn: !settingsDropdownOpen,
              })}
              style={{ top: '2rem', zIndex: 9999 }}
            >
              {roles?.admin ? (
                <>
                  <Link
                    to="/settings/users"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Users</span>
                  </Link>
                  <Link
                    to="/settings/groups"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Groups</span>
                  </Link>
                  <Link
                    to="/settings/integrations"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Integrations</span>
                  </Link>
                  <a
                    href="/v2/settings/assets"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Assets</span>
                  </a>
                  <a
                    href="/v2/settings/trend-categories"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Trend Categories</span>
                  </a>
                  <a
                    href="/v2/settings/custom-fields"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Custom Fields</span>
                  </a>
                </>
              ) : (
                <>
                  <Link
                    to="/settings/users"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Users</span>
                  </Link>
                  <Link
                    to="/settings/groups"
                    className={cx('flex items-center', styles['link'], styles['dropdown-link'])}
                  >
                    <span className="i18n label">Groups</span>
                  </Link>
                </>
              )}
            </div>
          </div>
          <a
            className={'i18n label pv2 ml3 dim white no-underline flex items-center'}
            href="https://www.reliability.com/easyrca-faqs"
            target="_blank"
            rel="noreferrer"
          >
            <div className="mr2 flex items-center">
              <img alt="EasyRCA" src={supportSvg} height={24} />
            </div>{' '}
            <span>Help</span>
          </a>

          <div className="ml3 dim label">
            <button className="flex items-center unstyled " onClick={onLogout} style={{ paddingBottom: '20px' }}>
              <div className="mr2 flex items-center">
                <img alt="EasyRCA" src={logoutSvg} height={24} />
              </div>
              <span className={'i18n pv2 white'}>Logout</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export const Navigation: React.FC<NavigationProps> = ({ onLogout }) => {
  const { username, roles, features, name, token } = useAuth();
  const dispatch = useRootReducer();
  const handleLogout = () => {
    if (onLogout) {
      return onLogout();
    }
  };

  return (
    <div
      className={cx(
        styles['navigation'],
        'hide-for-print top-0 left-0 right-0 z-2 bg-black white justify-between items-stretch flex fixed',
      )}
    >
      <div className="flex items-stretch flex-auto">
        <Logo />
        <a href="/v2/" className={cx('flex items-center', styles['link'])}>
          <span className="i18n">Home</span>
        </a>
        <Link className={cx('flex items-center', styles['link'])} to="/rcas">
          <div className="i18n">RCAs</div>
        </Link>
        <a href="/v2/tasks" className={cx('flex items-center', styles['link'], styles['dropdown-link'])}>
          <span className="i18n">Tasks</span>
        </a>
        <Link className={cx('flex items-center', styles['link'])} to="/templates">
          <div className="i18n">Templates</div>
        </Link>
        <Link className={cx('flex items-center', styles['link'])} to="/dashboard">
          <div className="i18n">Dashboard</div>
        </Link>
      </div>

      <div className="flex items-center mr1">
        {/* @ts-expect-error */}
        <CreateRCAButton dispatch={dispatch} allowAiOptions={features?.aiFeatures && features?.aiFeatures !== false} />
      </div>

      <div className={cx(styles['currentSessionInfo'], 'flex items-center justify-between')}>
        {roles?.paid || roles?.complimentary ? null : <TrialBadge />}
        <UserSettings username={username} name={name} roles={roles} onLogout={handleLogout} />
      </div>
    </div>
  );
};
