export const bootIntercomIo = user => {
  if (!window) {
    console.error('global "window" is unavailable.');
    return;
    // @ts-ignore
  } else if (!window.Intercom) {
    console.error('global "window.Intercom" is unavailable.');
    return;
  }

  // @ts-ignore
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    name: user.get('fullName'),
    email: user.get('email'),
    phone: user.get('phone'),
    created_at: new Date(user.get('createdAt')).getTime() / 1000,
    paid: user.get('paid'),
    admin: user.get('admin'),
    expired: user.get('expired'),
    complimentary: user.get('complimentary'),
    company_name: user.get('companyName'),
  });
};

export const shutdownIntercomIo = () => {
  if (!window) {
    console.error('global "window" is unavailable.');
    return;
    // @ts-ignore
  } else if (!window.Intercom) {
    console.error('global "window.Intercom" is unavailable.');
    return;
  }

  // @ts-ignore
  window.Intercom('shutdown');
};
