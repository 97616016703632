import { Map, List, Stack } from 'immutable';
import { sendWS } from './Sync';

const pathToAllPaths = (root, path) => {
  const paths = [];

  for (let i = 0; i <= path.size && root.hasIn(path.slice(0, i)); i += 2) {
    paths.push(path.slice(0, i));
  }

  return List(paths);
};

const pathToNodes = (root, path) => {
  const nodes = pathToAllPaths(root, path).map(p => root.getIn(p));

  return nodes.reduceRight((child, parent) => {
    return parent.set('child', child);
  });
};

const getPatch = (type, root, path, node) => {
  const before = pathToNodes(root, path);
  const chainPath = path.filter(x => x === 'children').map(x => 'child');

  switch (type) {
    case 'DELETE': {
      const after = before.setIn(chainPath.push('deleted'), true);
      return Map({ type, before, after, path });
    }
    case 'ADD': {
      const childPath = chainPath.push('child');
      const after = before.setIn(childPath, node);
      const beforeNodeAdded = before.setIn(childPath, node.set('deleted', true));
      return Map({ type, before: beforeNodeAdded, after, path });
    }
    case 'UPDATE': {
      const after = before.setIn(chainPath, node);
      return Map({ type, before, after, path });
    }
    case 'FORCE': {
      const oid = before.getIn(chainPath).get('oid');
      const after = before.setIn(chainPath, node.set('oid', oid));
      return Map({ type, before, after, path });
    }
    default: {
      console.error('invalid patch type');
      return Map({ type, before, after: before, path });
    }
  }
};

export const applyChain = (root, chain) => {
  const chainChild = chain.get('child');
  const chainHasChild = !!chainChild;
  const updatedRoot = chain.delete('child');

  if (!root) {
    return applyChain(updatedRoot, updatedRoot);
  }

  if (chain.get('deleted')) {
    return null;
  }

  if (!chainHasChild) {
    return root.merge(updatedRoot);
  }

  const rootChildIndex = root.get('children').findIndex(rootChild => rootChild.get('oid') === chainChild.get('oid'));

  if (rootChildIndex === -1) {
    return root.merge(updatedRoot.delete('children')).update('children', chs => chs.push(chainChild));
  }

  return root
    .merge(updatedRoot.delete('children'))
    .updateIn(List(['children', rootChildIndex]), rootChild => applyChain(rootChild, chainChild))
    .update('children', chs => chs.filter(x => x));
};

export const revertPatch = patch => {
  const before = patch.get('before');
  const after = patch.get('after');

  return patch.set('before', after).set('after', before);
};

// FIXME: THIS NEEDS TO BE PURE... WTF?!
// .~~~
// (-_-)
// /----\
// ==<>==
//  ----
//  ||||
//  //\\
export const patchReducer = (state, action) => {
  const treeUuid = state.getIn(['tree', 'uuid']);
  const root = state.getIn(['tree', 'elements']);
  const type = action.get('type');
  const path = action.get('path');
  const node = action.get('node');
  const patch = getPatch(type, root, path, node);
  const after = patch.get('after');

  sendWS(
    state.get('ws'),
    Map({
      type: 'PATCH',
      chain: after,
      treeUuid,
    }),
  );

  return state
    .setIn(['tree', 'elements'], applyChain(root, after))
    .setIn(['tree', 'view', 'redoStack'], Stack())
    .updateIn(['tree', 'view', 'undoStack'], Stack(), us => us.push(patch));
};
