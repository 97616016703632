export enum DatePeriod {
  PAST_1_MONTH = 'PAST_1_MONTH',
  PAST_3_MONTHS = 'PAST_3_MONTHS',
  PAST_6_MONTHS = 'PAST_6_MONTHS',
  PAST_1_YEAR = 'PAST_1_YEAR',
  PAST_2_YEARS = 'PAST_2_YEARS',
  PAST_3_YEARS = 'PAST_3_YEARS',
  PAST_4_YEARS = 'PAST_4_YEARS',
  PAST_5_YEARS = 'PAST_5_YEARS',
}

export const DatePeriodDisplay: Record<DatePeriod, string> = {
  [DatePeriod.PAST_1_MONTH]: 'Past 1 Month',
  [DatePeriod.PAST_3_MONTHS]: 'Past 3 Months',
  [DatePeriod.PAST_6_MONTHS]: 'Past 6 Months',
  [DatePeriod.PAST_1_YEAR]: 'Past 1 Year',
  [DatePeriod.PAST_2_YEARS]: 'Past 2 Years',
  [DatePeriod.PAST_3_YEARS]: 'Past 3 Years',
  [DatePeriod.PAST_4_YEARS]: 'Past 4 Years',
  [DatePeriod.PAST_5_YEARS]: 'Past 5 Years',
};

export const DatePeriodMonths: Record<DatePeriod, number> = {
  [DatePeriod.PAST_1_MONTH]: 1,
  [DatePeriod.PAST_3_MONTHS]: 3,
  [DatePeriod.PAST_6_MONTHS]: 6,
  [DatePeriod.PAST_1_YEAR]: 1 * 12,
  [DatePeriod.PAST_2_YEARS]: 2 * 12,
  [DatePeriod.PAST_3_YEARS]: 3 * 12,
  [DatePeriod.PAST_4_YEARS]: 4 * 12,
  [DatePeriod.PAST_5_YEARS]: 5 * 12,
};

export type ValidDatePeriod = keyof typeof DatePeriod;

export type ValidObject = Record<string, unknown>;
