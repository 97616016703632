import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { AnalysisMethodology, RCA } from '@interfaces';

import { Spinner } from 'components';
import { Checkmark20 } from '@carbon/icons-react';
import { ErrorBanner } from '../ErrorBanner';

type Inputs = Pick<RCA, 'title' | 'description'> & {
  name?: string;
  eventAt: string;
  attachments?: FileList | null;
};

export const RCAForm: React.FC<{ orgUuid: string }> = ({ orgUuid }) => {
  const [data, setData] = useState<Partial<Inputs>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [wasCreated, setWasCreated] = useState(false);
  const isValid = useMemo(() => {
    if (data.description && data.eventAt && data.name && data.title) {
      return true;
    }

    return false;
  }, [data.description, data.eventAt, data.name, data.title]);

  const onSubmit = async e => {
    e.preventDefault();

    if (!isValid) return;

    try {
      setIsSubmitting(true);

      const results = await fetch('/field/new-tree', {
        method: 'POST',
        body: JSON.stringify({
          tree: {
            ...data,
            methodology: AnalysisMethodology.PROACT,
            description: `${data.description}\n\nSubmitted by ${data.name}`,
            eventAt: Date.parse(data.eventAt!),
          },
          orgUuid,
        }),
      });

      const response = await results.json();

      if (!response || response.error !== undefined) {
        throw new Error();
      }

      setWasCreated(true);
    } catch (error) {
      setError('Failed to create analysis. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={onSubmit} className="w-100 pv3 flex flex-column">
      <legend className="f2 fw-medium">Report an Incident</legend>

      {wasCreated ? (
        <div className="flex items-center justify-start w-100">
          <Checkmark20 fill="var(--green)" />
          <span className="f5 pv5 pl2">Incident successfully reported</span>
        </div>
      ) : (
        <>
          <div className="flex items-stretch mv3 bw0">
            <div className="pv2 flex flex-column w-100">
              <label className="label fw-medium" htmlFor="title">
                Quick Description of Incident
              </label>
              <span className="hint mb2 mt1">
                e.g. <em>Chronic Failure of Pump CP-003</em>
              </span>
              <input
                required
                className="pa1"
                id="title"
                type="text"
                onChange={s => setData(d => ({ ...d, title: s.target.value }))}
              />
            </div>
          </div>

          <div className="pv2 flex flex-column">
            <label className="label fw-medium" htmlFor="event-at">
              When Did It Happen?
            </label>
            <input
              required
              className="pa1 w-100 mt1"
              id="event-at"
              type="datetime-local"
              onChange={s => {
                // console.warn(s.target.value);
                return setData(d => ({ ...d, eventAt: s.target.value }));
              }}
            />
          </div>

          <div className="pv2 flex flex-column">
            <label className="label fw-medium" htmlFor="attachments">
              Please add as many photos and videos of the incident as possible
              here
            </label>
            <input
              className="pa1 w-100"
              id="attachments"
              type="file"
              multiple
              onChange={s =>
                setData(d => ({ ...d, attachments: s.target.files }))
              }
            />
          </div>

          <div className="pv2 flex flex-column">
            <label className="label fw-medium" htmlFor="description">
              Describe In Detail What Happened
            </label>
            <span className="hint mb2 mt1">
              e.g.{' '}
              <em>This is an example of highly detailed descriptive text...</em>
            </span>
            <textarea
              required
              className="pa1 w-100 h4"
              id="description"
              onChange={s =>
                setData(d => ({ ...d, description: s.target.value }))
              }
            />
          </div>

          <div className="pv2 flex flex-column">
            <label className="label fw-medium" htmlFor="name">
              Your Name
            </label>
            <input
              required
              className="pa1 w-100 mt1"
              id="name"
              type="text"
              onChange={s => setData(d => ({ ...d, name: s.target.value }))}
            />
          </div>

          {error && <ErrorBanner message={error} />}
          <button
            disabled={!isValid || isSubmitting}
            className={cx(
              'flex items-center justify-center pa2 pv3 mt3 bg-black white',
              {
                'o-50 not-allowed': !isValid,
              },
            )}
            type="submit"
          >
            {isSubmitting ? (
              <Spinner color="white" className="white" />
            ) : (
              'Create Analysis'
            )}
          </button>
        </>
      )}
    </form>
  );
};
