import { lowerCase, startCase } from 'lodash';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const abbreviatedCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'short',
});

export function toUSD(s, opts) {
  if (opts && opts.dropDecimal) {
    return currencyFormatter.format(s).replace(/(\.|,)00$/g, '');
  }

  return currencyFormatter.format(s);
}

export function toAbbreviatedUSD(s) {
  return abbreviatedCurrencyFormatter.format(s);
}

export function toTitleCase(s) {
  return startCase(lowerCase(s));
}
