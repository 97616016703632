import React, { ChangeEvent } from 'react';
import { Filters } from 'react-table';
import { ActionMeta, SingleValue } from 'react-select';

import { _ } from 'utils';
import { AnalysisStatusDisplay } from '@interfaces';
import { Wikis16 } from '@carbon/icons-react';
// import { Checkbox } from 'Buttons';
import { MethodologySelect, SearchInput, Select } from '../../Inputs';
import { Checkbox } from '../../../Buttons';
type TableFiltersProps = {
  searchQuery?: string;
  onSearchChange: (q: string) => void;
  setFilter: (columnId: string, filterValue: string) => void;
  setAllFilters: (filters: Filters<Record<string, unknown>>) => void;
  filters: Filters<Record<string, unknown>>;
  orgMembers: { username: string; fullName: string }[] | [];
};

type BaseSelectOption = {
  label: string;
  value: string;
};

export const TableFilters: React.FC<TableFiltersProps> = ({
  onSearchChange,
  setFilter,
  searchQuery,
  orgMembers,
  filters,
  ...props
}) => {
  const preparedOrgMembers = React.useMemo(() => {
    return orgMembers.map(m => {
      return { label: m.fullName, value: m.username };
    });
  }, [orgMembers]);

  const handleSearch = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      return onSearchChange(e.target.value);
    },
    [onSearchChange],
  );

  const handleChange = React.useCallback(
    ({ id, value }: { id: string; value: string }) => {
      setFilter(id, value);
    },
    [setFilter],
  );

  const handleTypeChange = React.useCallback(
    (newValue: SingleValue<BaseSelectOption>, meta: ActionMeta<BaseSelectOption>) => {
      if (meta.action === 'clear') {
        return handleChange({ id: 'type', value: '' });
      } else if (meta.action === 'select-option' && newValue) {
        return handleChange({ id: 'type', value: newValue.value });
      }

      return;
    },
    [handleChange],
  );

  const handleStatusChange = React.useCallback(
    (newValue: SingleValue<BaseSelectOption>, meta: ActionMeta<BaseSelectOption>) => {
      if (meta.action === 'clear') {
        return handleChange({ id: 'status', value: '' });
      } else if (meta.action === 'select-option' && newValue) {
        console.warn({ value: newValue });
        return handleChange({ id: 'status', value: newValue.value });
      }

      return;
    },
    [handleChange],
  );

  const handleIsPublishedChange = React.useCallback(
    (checked: boolean) => {
      if (!checked) {
        return handleChange({ id: 'isPublished', value: '' });
      }

      return handleChange({ id: 'isPublished', value: `${checked}` });
    },
    [handleChange],
  );

  const handleOwnerChange = React.useCallback(
    (newValue: SingleValue<BaseSelectOption>, meta: ActionMeta<BaseSelectOption>) => {
      if (meta.action === 'clear') {
        return handleChange({ id: 'owner', value: '' });
      } else if (meta.action === 'select-option' && newValue) {
        return handleChange({ id: 'owner', value: newValue.value });
      }

      return;
    },
    [handleChange],
  );

  const statusOptions = Object.keys(AnalysisStatusDisplay).map(key => ({
    label: AnalysisStatusDisplay[key],
    value: key,
  }));

  const isPublishedFilter = React.useMemo(() => filters.find(f => f.id === 'isPublished'), [filters]);
  const filteringForIsPublished = React.useMemo(() => {
    return isPublishedFilter && isPublishedFilter.value !== '' && isPublishedFilter.value !== 'false';
  }, [isPublishedFilter]);

  console.warn({ isPublishedFilter, filteringForIsPublished });

  return (
    <div className="w-100 flex items-center justify-center pv3">
      <div className="w-25 flex flex-column">
        <label htmlFor="search" className="i18n f6 fw6">
          Search
        </label>
        <SearchInput
          tabIndex={1}
          placeholder="Search by title, type, status, etc."
          id="search"
          className="i18n mt2 w-100"
          onChange={handleSearch}
          value={searchQuery}
        />
      </div>
      <div className="flex items-center justify-end ml-auto w-75">
        <div className="w-30 mr2">
          <label htmlFor="owner" className="i18n f6 fw6">
            Owner
          </label>
          {/* @ts-ignore */}
          <Select
            tabIndex={2}
            className="mt2"
            name="owner"
            escapeClearsValue
            isClearable
            // @ts-ignore
            placeholder={`${_(props.i18n, 'All')}`}
            inputId="owner"
            isSearchable
            onChange={handleOwnerChange}
            options={preparedOrgMembers}
          />
        </div>
        <div className="w-20 mr2">
          <label className="i18n f6 fw6" htmlFor="type">
            Type
          </label>
          <MethodologySelect
            isClearable
            // @ts-ignore
            i18n={props.i18n}
            tabIndex={3}
            escapeClearsValue
            onChange={handleTypeChange}
            className="no-i18n mt2"
            inputId="type"
          />
        </div>
        <div className="w-20 mr2">
          <label htmlFor="status" className="i18n f6 fw6">
            Status
          </label>
          {/* @ts-ignore */}
          <Select
            tabIndex={4}
            escapeClearsValue
            className="i18n mt2"
            inputId="status"
            isClearable
            // @ts-ignore
            placeholder={`${_(props.i18n, 'All')}`}
            isSearchable
            name="status"
            onChange={handleStatusChange}
            options={statusOptions}
          />
        </div>
        <div className="pr2">
          <label htmlFor="published" className="f6 fw6">
            &nbsp;
          </label>
          <Checkbox
            onClick={handleIsPublishedChange}
            disabled={false}
            text={
              <Wikis16
                data-tip="Published Analyses Only"
                className="mr2 mt-auto"
                fill={filteringForIsPublished ? 'var(--green)' : 'var(--black-50)'}
              />
            }
            className="flex items-center justify-start"
            value={filteringForIsPublished}
            uncheckedBorderColor="white"
            hideCheckmark
          />
        </div>
      </div>
    </div>
  );
};
