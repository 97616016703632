import React, { useMemo } from 'react';
import { Fish16, DecisionTree16, Number_524 } from '@carbon/icons-react';
import {
  OptionProps,
  /*ValueContainerProps,*/ StylesConfig,
  Props as SelectProps,
  Props,
  GroupBase,
} from 'react-select';
import { AnalysisMethodology, MethodologyDisplay, MethodologyValueStrings } from '@interfaces';
import cx from 'classnames';
import { _ } from 'utils';
import { Select } from './Select';
import styles from './styles.module.css';

type MethodologySelectOption = { label: string; value: AnalysisMethodology };

export type MethodologySelectProps = Omit<SelectProps<MethodologySelectOption, false>, 'options'> & {
  options?: Array<MethodologyValueStrings>;
};

export type MethodologySelectStyles = StylesConfig<MethodologySelectOption, false>;

const Option = ({
  data,
  innerProps,
  ...rest
}: OptionProps<MethodologySelectOption, false> & {
  selectProps: Props<MethodologySelectOption, false, GroupBase<MethodologySelectOption>>;
}) => {
  const { onMouseOver: _, onMouseMove: __, ...restInnerProps } = innerProps;
  const { innerRef } = rest;
  return (
    <div
      ref={innerRef}
      className={cx('pointer ph2 pv2 flex w-100 items-center justify-start relative', {
        [styles['select-option']]: true,
        [styles['select-option--light']]: !rest.selectProps.isDark,
        [styles['select-option--dark']]: rest.selectProps.isDark,
      })}
      {...restInnerProps}
    >
      <div className="no-18n flex align-center justify-center mr1" style={{ width: 24 }}>
        {data.label === AnalysisMethodology.PROACT && <DecisionTree16 className="rotate-90" />}
        {data.label === AnalysisMethodology.FISHBONE && <Fish16 style={{ position: 'relative', top: 1 }} />}
        {data.label === AnalysisMethodology.FIVE_WHYS && <Number_524 style={{ position: 'relative', top: 1 }} />}
      </div>
      <span className={cx('flex-2', styles['methodology-select-option-label'])}>{MethodologyDisplay[data.label]}</span>
    </div>
  );
};

// const OldOption = (props: OptionProps<MethodologySelectOption, false>) => {
//   return (
//     <div
//       className={cx('flex items-center justify-start ph2 pv2 w-100', styles['select-option'])}
//       {...props.innerProps}
//     >
//       <div className="no-18n flex align-center justify-center mr1" style={{ width: 24 }}>
//         {props.label === AnalysisMethodology.PROACT && <DecisionTree16 className="rotate-90" />}
//         {props.label === AnalysisMethodology.FISHBONE && <Fish16 style={{ position: 'relative', top: 1 }} />}
//         {props.label === AnalysisMethodology.FIVE_WHYS && <Number_524 style={{ position: 'relative', top: 1 }} />}
//       </div>
//       <span className={cx('flex-2', styles['methodology-select-option-label'])}>
//         {MethodologyDisplay[props.label]}
//       </span>
//     </div>
//   );
// };

// const ValueContainer = ({ innerProps, children }: ValueContainerProps<MethodologySelectOption, false>) => {
//   return (
//     <div className={styles['value-container']} {...innerProps}>
//       {children}
//     </div>
//   );
// };

const defaultOptions = [
  {
    value: AnalysisMethodology.PROACT,
    label: AnalysisMethodology.PROACT,
  },
  {
    value: AnalysisMethodology.FISHBONE,
    label: AnalysisMethodology.FISHBONE,
  },
  {
    value: AnalysisMethodology.FIVE_WHYS,
    label: AnalysisMethodology.FIVE_WHYS,
  },
];

export const MethodologySelect: React.FC<MethodologySelectProps> = ({
  defaultValue = AnalysisMethodology.PROACT,
  options = [],
  ...props
}) => {
  const enabledOptions = useMemo(() => {
    if (!options || options.length < 1) {
      return defaultOptions;
    }
    return options.map(opt => ({ value: opt, label: opt }));
  }, [options]);

  return (
    // @ts-ignore
    <Select
      // @ts-ignore
      placeholder={_(props.i18n, 'All')}
      className={styles['methodology-select']}
      styles={{
        menu: provided => ({
          ...provided,
          width: '100%',
        }),
      }}
      components={{ Option }}
      /* ts is complaining because of react-select's options typing...not a high priority */
      /* @ts-expect-error */
      options={enabledOptions}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
