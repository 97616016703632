import React from 'react';
import { StylesConfig, Props as SelectProps } from 'react-select';
import { DatePeriod, DatePeriodDisplay } from '@interfaces';
import cx from 'classnames';

import { Select } from './Select';
import styles from './styles.module.css';

type DatePeriodSelectOption = {
  label: string;
  value: string;
};

export type DataPeriodSelectProps = SelectProps<DatePeriodSelectOption, false>;

export type DataPeriodSelectStyles = StylesConfig<DatePeriodSelectOption, false>;

/**
 *
 */
export const DatePeriodSelect: React.FC<DataPeriodSelectProps> = props => {
  const options: DatePeriodSelectOption[] = [
    {
      value: DatePeriod.PAST_1_MONTH,
      label: DatePeriodDisplay.PAST_1_MONTH,
    },
    {
      value: DatePeriod.PAST_3_MONTHS,
      label: DatePeriodDisplay.PAST_3_MONTHS,
    },
    {
      value: DatePeriod.PAST_6_MONTHS,
      label: DatePeriodDisplay.PAST_6_MONTHS,
    },
    {
      value: DatePeriod.PAST_1_YEAR,
      label: DatePeriodDisplay.PAST_1_YEAR,
    },
    {
      value: DatePeriod.PAST_2_YEARS,
      label: DatePeriodDisplay.PAST_2_YEARS,
    },
    {
      value: DatePeriod.PAST_3_YEARS,
      label: DatePeriodDisplay.PAST_3_YEARS,
    },
    {
      value: DatePeriod.PAST_4_YEARS,
      label: DatePeriodDisplay.PAST_4_YEARS,
    },
    {
      value: DatePeriod.PAST_5_YEARS,
      label: DatePeriodDisplay.PAST_5_YEARS,
    },
  ];

  return (
    // @ts-ignore
    <Select
      defaultValue={options[1]}
      placeholder="Choose Period"
      className={cx(styles['date-period-select'], 'i18n')}
      styles={{
        menu: provided => ({
          ...provided,
          width: '100%',
        }),
      }}
      options={options}
      {...props}
    />
  );
};
