import React, { useState } from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { Accordion, Blade } from './Blade';
import { AddTask, EditTask, Task } from './TasksOptions';
import { AddNote, EditNote, Note } from './NotesOptions';
import { FileDownload } from './AttachmentOptions';
import { FileUploader } from './FileInputs';

const getNodeAttributes = (state, attribute) => {
  const treeUuid = state.getIn(['tree', 'uuid']);
  const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
  const node = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());
  const nodeUuid = node.get('oid');

  return state
    .getIn([attribute, treeUuid], Map())
    .valueSeq()
    .sortBy(t => -1 * (t.get('deadline') || t.get('createdAt')))
    .filter(n => n.get('nodeUuid') === nodeUuid);
};

const Notes = ({ state, dispatch, editingDisabled }) => {
  const notes = getNodeAttributes(state, 'notes');
  const [editingNote, setEditingNote] = useState(null);

  return (
    <div className={'mb4'}>
      <div className={'ma3'}>
        {editingNote && !editingDisabled ? (
          <EditNote
            state={state}
            disabled={editingDisabled}
            dispatch={dispatch}
            note={editingNote}
            onEdit={() => setEditingNote(null)}
            onCancel={() => setEditingNote(null)}
          />
        ) : (
          <AddNote state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
        )}
      </div>

      <div className={'mh3 mt3'}>
        {notes.map(n => (
          <Note
            editingDisabled={editingDisabled}
            state={state}
            dispatch={dispatch}
            key={n.get('noteUuid')}
            note={n}
            onEdit={() => {
              if (editingDisabled) return;

              return setEditingNote(n);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const Files = ({ state, dispatch, editingDisabled }) => {
  const files = getNodeAttributes(state, 'files');

  return (
    <div className={'mb4'}>
      <div className={'mh3 mt3'}>
        <FileUploader
          disabled={editingDisabled}
          onChange={(e, inputRef) => {
            if (editingDisabled) return;

            const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
            const node = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());
            const nodeText = node.get('text');
            const nodeUuid = node.get('oid');

            if (nodeText === '') {
              dispatch(
                Map({
                  type: 'SHOW_TOAST',
                  message: 'Cannot attach files to empty nodes.',
                  style: 'ERROR',
                }),
              );
            } else {
              dispatch(
                Map({
                  type: 'UPLOAD_FILES',
                  files: List(e.target.files),
                  nodeUuid,
                }),
              );
              inputRef.current.value = ''; // reset the input so you upload again
            }
          }}
          state={state}
          dispatch={dispatch}
        />
      </div>

      <div className={'mh3'}>
        {files.map(f => (
          <FileDownload disabled={editingDisabled} state={state} dispatch={dispatch} key={f.get('fileUuid')} file={f} />
        ))}
      </div>
    </div>
  );
};

const Tasks = ({ state, dispatch, editingDisabled }) => {
  const selectedNodeTasks = getNodeAttributes(state, 'tasks');
  const [editingTask, setEditingTask] = useState(null);

  return (
    <div className={'mb4'}>
      <div className={'ma3'}>
        {editingTask && !editingDisabled ? (
          <EditTask
            disabled={editingDisabled}
            state={state}
            dispatch={dispatch}
            task={editingTask}
            onEdit={() => setEditingTask(null)}
            onCancel={() => setEditingTask(null)}
          />
        ) : (
          <AddTask state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
        )}
      </div>
      <div className={'mh3 mt3'}>
        {selectedNodeTasks.map(t => (
          <Task
            state={state}
            dispatch={dispatch}
            key={t.get('taskUuid')}
            task={t}
            disabled={editingDisabled}
            onEdit={() => {
              if (editingDisabled) return;

              return setEditingTask(t);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const NodeAttachmentOptions = ({ state, dispatch, editingDisabled }) => {
  const defaultState = Map({ mode: 'Tasks' });
  const [model, setModel] = useState(defaultState);
  const mode = model.get('mode');
  const path = state.getIn(['tree', 'view', 'selected', 'path']);
  let title = 'Node Attachments';
  if (path) {
    const node = state.getIn(['tree', 'elements']).getIn(path);
    title = node.get('text').length > 25 ? `${node.get('text').substring(0, 25)}...` : node.get('text');
  }
  return (
    <Blade
      title={title}
      show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'INFORMATION'}
      state={state}
      dispatch={dispatch}
    >
      <Accordion
        options={List(['Tasks', 'Files', 'Notes'])}
        value={model.get('mode')}
        onChange={m => setModel(model.set('mode', m))}
      />
      <div className={classNames({ dn: mode !== 'Tasks' })}>
        <Tasks state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
      </div>
      <div className={classNames({ dn: mode !== 'Files' })}>
        <Files state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
      </div>
      <div className={classNames({ dn: mode !== 'Notes' })}>
        <Notes state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
      </div>
    </Blade>
  );
};
