import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export const Table = ({ children, border = true, className = null, ...props }) => {
  return (
    <table
      className={cx(
        styles.Table,
        'collapse w-100 pv1 ph2',
        props.unstyled ? {} : { 'b--black-20 ba': border },
        className,
      )}
      {...props}
    >
      {children}
    </table>
  );
};

Table.Header = function TableHeader({ children, ...props }) {
  return <thead {...props}>{children}</thead>;
};

Table.Body = function TableBody({ children, ...props }) {
  return <tbody {...props}>{children}</tbody>;
};

Table.HeaderCell = function TableHeaderCell({ children, capitalize = true, ...props }) {
  return (
    <th
      className={cx(
        styles.tableCell,
        props.unstyled ? 'bg-white pa0 mh0' : 'bg-light-gray',
        'tl fw6',
        { ttc: capitalize },
        props.className,
      )}
      {...props}
    >
      {children}
    </th>
  );
};

Table.Cell = function TableCell({
  children,
  padding = 'auto',
  border = true,
  background = 'transparent',
  width = 'auto',
  className = 'cell',
  ...props
}) {
  const classNames = React.useMemo(
    () => cx(styles.tableCell, { 'bt b--black-10': border !== false }, className),
    [className, border],
  );
  return (
    <td
      width={width}
      className={classNames}
      style={{
        padding,
        background,
        ...props.style,
      }}
      {...props}
    >
      {children}
    </td>
  );
};

Table.Row = function TableRow({ pointerOnHover = false, striped = false, ...props }) {
  const classNames = React.useMemo(
    () => cx({ [styles.stripedRow]: striped, pointer: pointerOnHover }, props.className),
    [pointerOnHover, striped, props.className],
  );
  return (
    <tr className={classNames} {...props}>
      {props.children}
    </tr>
  );
};
