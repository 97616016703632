import React, { useEffect } from 'react';
import { Map } from 'immutable';
import { ProgressBarRound32 } from '@carbon/icons-react';
import { useParams } from 'react-router-dom';
import logoSrc from './svgs/logo-v2.svg';
import { storeToken } from './Sync';

export const SSOFailure = ({ dispatch }) => {
  useEffect(() => {
    setTimeout(() => dispatch(Map({ type: 'SET_URL', url: '/' })), 3000);
  }, [dispatch]);

  return (
    <div className={'h-100 w-100 flex justify-center items-center bg-grid'}>
      <div className={'ba bw2 pa4 b--navy bg-white mb4'} style={{ width: '400px' }}>
        <div>
          <div className={'mb4'}>
            <img alt="easy rca logo" src={logoSrc} className={'db'} style={{ width: '200px' }} />
          </div>
          <div className={'f3 i18n'}>Login Failed</div>
          <div className={'f5 black-60 mb3 lh-copy'}>We were unable to log you in.</div>
          <div>
            <ProgressBarRound32 className="spin fill--red" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SSOSuccess = ({ state, dispatch }) => {
  const { usernameEncoded, token } = useParams();

  useEffect(() => {
    if (usernameEncoded && token) {
      const username = decodeURI(usernameEncoded);
      storeToken(username, token);

      const referrer = new URL(window.location.href.replace(/#/g, '')).searchParams.get('referrer');
      const url = referrer ? referrer : '/v2';
      setTimeout(() => dispatch(Map({ type: 'SET_URL', url })), 1500);
    }
  }, [usernameEncoded, token, dispatch]);

  return (
    <div className={'h-100 w-100 flex justify-center items-center bg-grid'}>
      <div className={'ba bw2 pa4 b--navy bg-white mb4'} style={{ width: '400px' }}>
        <div>
          <div className={'mb4'}>
            <img alt="easy rca logo" src={logoSrc} className={'db'} style={{ width: '200px' }} />
          </div>
          <div className={'f3 i18n'}>Login Successful</div>
          <div className={'f5 black-60 mb3 lh-copy'}>Please wait while we log you in.</div>
          <div>
            <ProgressBarRound32 className="spin fill--blue" />
          </div>
        </div>
      </div>
    </div>
  );
};
