import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Download20, SubtractAlt20 } from '@carbon/icons-react';
import { List, Map } from 'immutable';
import prettyBytes from 'pretty-bytes';
import { formatDistance } from 'date-fns';
import { Accordion, Blade, highlight } from './Blade';
import { EditTask, Task } from './TasksOptions';
import { EditNote, Note } from './NotesOptions';
import { Avatar } from './Nav';

export const FileDownload = ({ state, dispatch, file, disabled }) => {
  const filename = file.get('filename');
  const fileUuid = file.get('fileUuid');
  const size = file.get('size');
  const createdAt = file.get('createdAt');
  const uploadedBy = file.get('uploadedBy');
  const nodeUuid = file.get('nodeUuid');
  const treeUuid = state.getIn(['tree', 'uuid']);

  const uploadedAgo = formatDistance(new Date(createdAt), new Date());

  return (
    <div className={'w-100 mb1 bg-black pa3 relative pr4'} onMouseEnter={() => highlight(dispatch, nodeUuid)}>
      <div>
        <div className={'fw6 f6 mb2'}>{filename}</div>
        <div className={'f6 light-gray'}>
          {prettyBytes(size)}, {`${uploadedAgo} ago`}
        </div>
        <div className="mt3">
          <Avatar height={40} state={state} dispatch={dispatch} username={uploadedBy} />
        </div>
      </div>
      {!disabled ? (
        <div
          className={'red flex items-center dim pointer absolute bottom-0 right-0 pa2'}
          data-tip="Delete"
          onClick={() => dispatch(Map({ type: 'DELETE_FILE', fileUuid }))}
        >
          <SubtractAlt20 />
        </div>
      ) : null}
      <div
        className={'bg-blue white flex items-center dim pointer absolute top-0 right-0 pa2'}
        data-tip="Download"
        onClick={() => dispatch(Map({ type: 'DOWNLOAD_FILE', treeUuid, fileUuid }))}
      >
        <Download20 />
      </div>
    </div>
  );
};

const getAllAttachmentType = (state, type) => {
  const treeUUID = state.getIn(['tree', 'uuid']);

  return state
    .getIn([type, treeUUID], Map())
    .valueSeq()
    .sortBy(t => -1 * (t.get('deadline') || t.get('createdAt')));
};

const MissingItem = ({ list, noun }) => {
  return (
    <div className={classNames('ma3 white-50', { dn: !list.isEmpty() })}>There are no {noun} on this analysis.</div>
  );
};

const AllTasks = ({ state, dispatch, editingDisabled }) => {
  const tasks = getAllAttachmentType(state, 'tasks');
  const [editingTask, setEditingTask] = useState(null);

  return (
    <div className={'mb4'}>
      <MissingItem list={tasks} noun={'tasks'} />
      <div className={'mh3 mt3'}>
        {editingTask && !editingDisabled ? (
          <EditTask
            state={state}
            dispatch={dispatch}
            task={editingTask}
            onEdit={() => setEditingTask(null)}
            onCancel={() => setEditingTask(null)}
          />
        ) : (
          tasks.map(t => (
            <Task
              state={state}
              dispatch={dispatch}
              key={t.get('taskUuid')}
              disabled={editingDisabled}
              task={t}
              onEdit={() => {
                if (editingDisabled) return;

                return setEditingTask(t);
              }}
            />
          ))
        )}
      </div>
    </div>
  );
};

const AllFiles = ({ state, dispatch, editingDisabled }) => {
  const files = getAllAttachmentType(state, 'files');

  return (
    <div className={'mb4'}>
      <MissingItem list={files} noun={'files'} />
      <div className={'mh3'}>
        {files.map(f => (
          <FileDownload state={state} dispatch={dispatch} key={f.get('fileUuid')} disabled={editingDisabled} file={f} />
        ))}
      </div>
    </div>
  );
};

const AllNotes = ({ state, dispatch, editingDisabled }) => {
  const notes = getAllAttachmentType(state, 'notes');
  const [editingNote, setEditingNote] = useState(null);

  return (
    <div className={'mb4'}>
      <MissingItem list={notes} noun={'notes'} />
      <div className={'mh3'}>
        {editingNote && !editingDisabled ? (
          <EditNote
            state={state}
            disabled={editingDisabled}
            dispatch={dispatch}
            note={editingNote}
            onEdit={() => setEditingNote(null)}
            onCancel={() => setEditingNote(null)}
          />
        ) : (
          notes.map(n => (
            <Note
              editingDisabled={editingDisabled}
              state={state}
              dispatch={dispatch}
              key={n.get('noteUuid')}
              note={n}
              onEdit={() => {
                if (editingDisabled) return;

                return setEditingNote(n);
              }}
            />
          ))
        )}
      </div>
    </div>
  );
};

export const AttachmentOptions = ({ state, dispatch, editingDisabled }) => {
  const treeUuid = state.getIn(['tree', 'uuid']);
  const [model, setModel] = useState(Map({ mode: 'Tasks' }));
  const mode = model.get('mode');

  useEffect(() => {
    if (treeUuid) {
      dispatch(Map({ type: 'GET_FILE_METADATA', treeUuid }));
      dispatch(Map({ type: 'GET_TASKS', treeUuid }));
      dispatch(Map({ type: 'GET_NOTES', treeUuid }));
    }
  }, [treeUuid, dispatch]);

  return (
    <Blade
      title={'All Attachments'}
      show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'ATTACHMENTS'}
      state={state}
      dispatch={dispatch}
    >
      <Accordion
        options={List(['Tasks', 'Files', 'Notes'])}
        value={model.get('mode')}
        onChange={o => setModel(model.set('mode', o))}
      />
      <div className={classNames({ dn: mode !== 'Tasks' })}>
        <AllTasks state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
      </div>
      <div className={classNames({ dn: mode !== 'Files' })}>
        <AllFiles state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
      </div>
      <div className={classNames({ dn: mode !== 'Notes' })}>
        <AllNotes state={state} dispatch={dispatch} editingDisabled={editingDisabled} />
      </div>
    </Blade>
  );
};
