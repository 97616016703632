import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  OptionProps,
  StylesConfig,
  Props,
  ControlProps,
  components,
  DropdownIndicatorProps,
  InputProps,
  PlaceholderProps,
  IndicatorSeparatorProps,
  GroupBase
} from 'react-select';
import Select, { AsyncProps as SelectProps } from 'react-select/async';
import { ChevronDown20, ChevronUp20 } from '@carbon/icons-react';
import cx from 'classnames';
import { searchEquipment, searchFacilities } from 'Sync';
import { sortBy } from 'lodash';
import { _ } from 'utils';
import type { DeepHierarchyObject } from '@interfaces';

import { AlternativeSpinner } from '../ActivityIndicators';
import styles from './styles.module.css';

interface ExtendedOptionProps extends OptionProps<DeepSelectOption, false, GroupBase<DeepSelectOption>> {
  isDarkTheme: boolean;
}

type DeepSelectOption = { label: string; value: string; object: DeepHierarchyObject };

export type DeepSelectProps = Omit<SelectProps<DeepSelectOption, false, null>, 'theme'> & {
  token: string;
  type: 'equipment' | 'facilities';
  theme?: 'dark' | 'light';
};

export type DeepSelectStyles = StylesConfig<DeepSelectOption, false>;

type SearchResult = {
  item: {
    path: Array<string>;
    namePath: Array<string>;
  };
  score: number;
};

type APISearchResponse = Array<SearchResult>;

const processResults = (response: APISearchResponse) => {
  const sorted = sortBy(response, x => x.score);
  const results = sorted.map(result => result.item);

  return results;
};

const Option = ({
  data,
  innerProps,
  isDarkTheme,
  ...rest
}: OptionProps<DeepSelectOption, false> & {
  selectProps: Props<DeepSelectOption, false>;
  isDarkTheme: boolean;
}) => {
  const { onMouseOver: _, onMouseMove: __, ...restInnerProps } = innerProps;
  const { innerRef, selectOption } = rest;

  const path = data.object.namePath.join('/');

  const truncatedPath = path.length > 300 ? `${path.substring(0, 300)}...` : path;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      key={data.value}
      ref={innerRef}
      onClick={() => selectOption(data)}
      className={cx(
        'pointer flex-column items-center justify-start relative',
        isDarkTheme ? styles['deep-select-option'] : styles['deep-select-option-light'],
        isDarkTheme ? styles['option-bottom-border'] : styles['option-bottom-border-light'],
      )}
      {...restInnerProps}
    >
      <button
        className={cx('absolute right-0 top-0 unstyled white', styles['deep-select-option-toggle'])}
        style={{ width: 60, height: 60, zIndex: 99999 }}
        onClick={e => {
          e.stopPropagation();
          return setIsExpanded(s => !s);
        }}
      >
        {isExpanded ? <ChevronUp20 /> : <ChevronDown20 />}
      </button>
      <div className={cx(styles['deep-select-name-label'], 'flex items-center justify-start')}>{data.label}</div>
      <div className={
        isDarkTheme ? styles['deep-select-deep-label'] : styles['deep-select-deep-label-light']
      }
      >{data.object.path.join('-')}</div>
      <div className={isDarkTheme ? styles['deep-select-path-label'] : styles['deep-select-path-label-light']}>{isExpanded ? path : truncatedPath}</div>
    </div>
  );
};

const ValueContainer = ({ children, className, ...props }: ControlProps<DeepSelectOption, false, null>) => {
  const { innerProps, hasValue, getValue } = props;
  // const { inputValue } = selectProps;

  const val = getValue()[0];
  console.info(val);

  return (
    <div className={cx('ValueContainer flex items-stretch justify-start pr1', className)} {...innerProps}>
      {hasValue ? (
        <div className="pl2 flex flex-column flex-wrap items-stretch justify-start">
          <span className="pv1">{getValue()[0].label}</span>
          <span className="f7 fg--white-70">{getValue()[0].value}</span>
        </div>
      ) : (
        <div className="flex items-stretch flex-grow-1 flex-shrink-0">{children}</div>
      )}
    </div>
  );
};

const Placeholder = (p: PlaceholderProps) => (
  <components.Placeholder {...p} className={cx(p.className, styles['deep-select-placeholder'])} />
);
const Input = ({ ...p }: InputProps) => (
  <components.Input {...p} />
  // <input
  //   type="text"
  //   {...p}
  //   defaultValue={defaultValue}
  //   value={value}
  //   className={cx(className, styles['deep-select-input'], 'black bg-transparent bn')}
  //   onChange={e => p.setValue(e.target.value, null)}
  // />
);
const DropdownIndicator = ({ hasValue, ...p }: DropdownIndicatorProps) => {
  if (hasValue) {
    return null;
  }

  return <components.DropdownIndicator hasValue={hasValue} {...p} />;
};
const IndicatorSeparator = ({ hasValue, ...p }: IndicatorSeparatorProps) => {
  if (hasValue) {
    return null;
  }

  return <components.IndicatorSeparator hasValue={hasValue} {...p} />;
};

const darkTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#909090',
    primary75: '#90909080',
  },
});

const lightTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    neutral0: 'white',
    primary: '#CBCBCB',
    primary25: '#F4F4F4',
  },
});

export const DeepSelect: React.FC<DeepSelectProps> = ({
  token,
  defaultInputValue,
  defaultValue,
  type,
  theme = 'light',
  className,
  ...props
}) => {
  const getLoadingMessage = e => {
    if (type === 'equipment') {
      return `Searching equipment for ${e.inputValue}`;
    }

    return `Searching facilities for ${e.inputValue}`;
  };

  const darkTheme_backgroundColor = '#474747';
  const darkTheme_border = 'solid 1 px #909090';
  const darkTheme_color = '#fefefe';

  const lightTheme_backgroundColor = 'white';
  const lightTheme_border = 'solid 1px #CBCBCB';
  const lightTheme_color = '#7F7F7F';

  const isDarkTheme = theme === 'dark';
  

  const memoizedStyles: DeepSelectStyles = useMemo(
    () => ({
      control: provided => ({
        ...provided,
        boxShadow: 'none',
        '&:focus-within': {
          borderColor: isDarkTheme ? 'cccccc' : '#357edd'
        },
        padding: '6px 6px 8px 6px',
        backgroundColor: isDarkTheme ? darkTheme_backgroundColor : lightTheme_backgroundColor,
        border: isDarkTheme ? darkTheme_border : lightTheme_border,
        '&:hover': {
          borderColor: isDarkTheme ? '#cccccc' : '#357edd'
        },
        alignItems: 'stretch',
        color: isDarkTheme ? darkTheme_color : lightTheme_color,
        flexWrap: 'nowrap',
        borderRadius: '4px'
      }),
      input: p => ({
        ...p,
        color: isDarkTheme ? darkTheme_color : lightTheme_color,
      }),
      valueContainer: p => ({
        ...p,
        color: isDarkTheme ? darkTheme_color : lightTheme_color,
      }),
      singleValue: p => ({
        ...p,
        color: isDarkTheme ? darkTheme_color : lightTheme_color,
      }),
      // multiValue: () => ({
      //   display: 'none',
      // }),
      // option: provided => ({
      //   ...provided,
      // }),
      indicatorSeparator: p => ({
        ...p,
        marginRight: 3,
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: 'var(--white-50)',
        display: 'none'
      }),
      // indicatorsContainer: p => ({
      //   ...p,
      //   // backgroundColor: state.options[0].label,
      //   // paddingRight: 3,
      // }),
      dropdownIndicator: p => ({
        ...p,
        color: 'var(--white-50)',
        display: 'none'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: 331,
        padding: 0,
      }),
      // option: (p, state) => ({
      //   ...p,
      //   backgroundColor: state.isSelected || state.isFocused ? 'black' : '#202020',
      // }),
      menu: provided => ({
        ...provided,
        backgroundColor: isDarkTheme ? darkTheme_backgroundColor : lightTheme_backgroundColor,
        borderRadius: 3,
        overflow: 'hidden',
        marginTop: 8,
        width: '100%',
        height: 'content-fit',
      }),
      noOptionsMessage: p => ({
        ...p,
        padding: 12,
        fontSize: 14,
        color: isDarkTheme ? darkTheme_color : lightTheme_color,
      }),
    }),
    [],
  );

  const handleLoadOptions = useCallback(
    async (query: string) => {
      if (type === 'equipment') {
        return searchEquipment(token, query).then(processResults);
      } else if (type === 'facilities') {
        return searchFacilities(token, query).then(processResults);
      }
    },
    [type],
  );

  const optionsCache = useRef();

  const calculatedDefaultValue = useMemo(() => {
    if (defaultValue) {
      return {
        /* @ts-expect-error */
        value: defaultValue.path.join('-'),
        /* @ts-expect-error */
        label: defaultValue.namePath.join(' '),
      };
    }
  }, [defaultInputValue, defaultValue]);

  return (
    <Select
      placeholder={type === 'equipment' ? 'Search by equipment name...' : 'Search by facility name...'}
      loadOptions={q =>
        new Promise(resolve => {
          const results = handleLoadOptions(q).then(r => {
            const x = r.map(o => ({
              label: o.namePath.join(' '),
              value: o.path.join('-'),
              object: o,
            }));

            return x;
          });

          return resolve(results);
        })
      }
      openMenuOnClick={false}
      openMenuOnFocus={false}
      loadingMessage={getLoadingMessage}
      className={cx(isDarkTheme ? styles['deep-select']: styles['deep-select-light'], className)}
      classNames={{
        control: () => 'pr2',
      }}
      closeMenuOnSelect={false}
      noOptionsMessage={e => (!e.inputValue ? null : `No results for ${e.inputValue}`)}
      isSearchable
      isClearable
      isDisabled={!token || token === null || token === ''}
      theme={theme === 'dark' ? darkTheme : lightTheme}
      defaultValue={calculatedDefaultValue}
      getOptionValue={(option: DeepSelectOption) => {
        return option?.object?.path?.join('-');
      }}
      styles={memoizedStyles}
      isMulti={false}
      allowOptionCreation={false}
      tabSelectsValue={false}
      cacheOptions={optionsCache}
      controlShouldRenderValue
      blurInputOnSelect
      components={{
        Option: (props) => <Option {...props as ExtendedOptionProps} isDarkTheme={theme === 'dark' ? true : false} />,
        ValueContainer,
        Placeholder,
        Input,
        DropdownIndicator,
        IndicatorSeparator,
        LoadingIndicator: () => <AlternativeSpinner size={12} className="mr2" />,
      }}
      {...props}
    />
  );
};
