import React from 'react';
import cx from 'classnames';
import { CaretDown16, CaretUp16 } from '@carbon/icons-react';
import styles from '../styles.module.css';

type DimensionsStyleObject = {
  maxWidth?: string | number;
  width?: string | number;
};

export const HeaderCell: React.FC<{
  isSorted?: boolean;
  isSortedDesc?: boolean;
  border?: boolean;
  maxWidth?: number | string;
  sortable?: boolean;
  width?: number | string;
  align?: 'left' | 'center' | 'right';
}> = ({ isSorted = false, border = true, isSortedDesc = false, align = 'left', maxWidth, width, ...props }) => {
  const dimensionStyles = React.useMemo(() => {
    const styleObject: Record<string, never> | DimensionsStyleObject = {};

    if (width) {
      styleObject.width = width;
    }

    if (maxWidth) {
      styleObject.maxWidth = maxWidth;
    }

    return styleObject;
  }, [width, maxWidth]);

  return (
    <th {...props} className={cx({ [styles['isSortable']]: props.sortable })} style={dimensionStyles}>
      <div className={cx(styles.headerCell, 'flex items-center justify-center', { [styles['border']]: border })}>
        <div className={cx('i18n', styles.headerCellLabel, styles[align])}>{props.children}</div>
        {isSorted ? (
          isSortedDesc ? (
            <CaretDown16 className={styles['headerCellAction']} />
          ) : (
            <CaretUp16 className={styles['headerCellAction']} />
          )
        ) : null}
        {/*isSorted && (
          <div className={styles.headerCellAction}>
            {isSortedDesc ? <CaretDown16 /> : <CaretUp16 />}
          </div>
        )*/}
      </div>
    </th>
  );
};
