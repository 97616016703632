export enum ROUTES {
  RCA_LIST = '/rcas',
  TREE_EDITOR = '/tree',
  REPORT = '/report',
  DASHBOARD = '/dashboard',
  DASHBOARD_TASKS = '/dashboard/tasks',
  EXPERIMENTS = '/experiments',
  NEW_ANALYSIS_WIZARD = '/new-analysis',
  CREATE_ACCOUNT = '/create-account',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SUPERADMIN_LOGIN = '/sa_login',
  ORGANIZATION_SETTINGS = '/organization',
  SETTINGS = '/settings',
  ORGANIZATION_SETTINGS_GROUPS = '/organization/groups',
  ORGANIZATION_SETTINGS_WEBHOOKS = '/organization/webhooks',
  SETTINGS_USERS = '/settings/users',
  SETTINGS_GROUPS = '/settings/groups',
  SETTINGS_INTEGRATIONS = '/settings/integrations',
  TEMPLATES = '/templates',
  EQUIPMENT = '/equipment',
  FACILITIES = '/facilities',
  TREE = '/tree',
  SSO = '/sso',
  SHOWCASE = '/showcase',
  FIELD_RCA = '/field-rca',
}

export enum QUERY_PARAMS {
  q = 'q',
  period = 'period',
  sort = 'sort',
  groupUuid = 'groupUuid',
  uuid = 'uuid',
  identifier = 'identifier',
  token = 'token',
  treeUUID = 'treeUUID',
  signature = 'signature',
  usernameEncoded = 'usernameEncoded',
}

export type ExperimentQueryParams = {
  [QUERY_PARAMS.identifier]: string;
  [QUERY_PARAMS.uuid]: string;
};

export type DashboardQueryParams = {
  [QUERY_PARAMS.period]: string;
  [QUERY_PARAMS.sort]: string;
  [QUERY_PARAMS.q]: string;
  [QUERY_PARAMS.groupUuid]: string;
};
