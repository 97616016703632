import React, { useEffect, useRef, useState } from 'react';
import { List, Map } from 'immutable';
import {
  InProgress20,
  Upload20,
  Doc24,
  Cad24,
  Csv24,
  Gif24,
  Hdr24,
  Http24,
  Iso24,
  Jpg24,
  Json24,
  Mov24,
  Mp324,
  Mp424,
  Mpeg24,
  Mpg224,
  Pdf24,
  Png24,
  Ppt24,
  Raw24,
  Sql24,
  Svg24,
  Tif24,
  Tsv24,
  Txt24,
  Wmv24,
  Xls24,
  Xml24,
  Zip24,
  ErrorFilled20,
  CheckmarkFilled20,
  Erase24,
  Reset24,
  CaretDown24,
  Add24,
} from '@carbon/icons-react';
import classNames from 'classnames';
import $ from 'jquery';
import { Button } from './Buttons';
import './FileInputs.css';

const extToIcon = Map({
  doc: <Doc24 />,
  docx: <Doc24 />,
  cad: <Cad24 />,
  csv: <Csv24 />,
  gif: <Gif24 />,
  hdr: <Hdr24 />,
  http: <Http24 />,
  iso: <Iso24 />,
  jpg: <Jpg24 />,
  json: <Json24 />,
  mov: <Mov24 />,
  mp3: <Mp324 />,
  mp4: <Mp424 />,
  mpeg: <Mpeg24 />,
  mpg2: <Mpg224 />,
  pdf: <Pdf24 />,
  png: <Png24 />,
  ppt: <Ppt24 />,
  pptx: <Ppt24 />,
  sql: <Sql24 />,
  svg: <Svg24 />,
  tif: <Tif24 />,
  tsv: <Tsv24 />,
  txt: <Txt24 />,
  wmv: <Wmv24 />,
  xls: <Xls24 />,
  xlsx: <Xls24 />,
  xml: <Xml24 />,
  zip: <Zip24 />,
});

export const fileToIcon = filename => {
  const extIdx = filename.lastIndexOf('.');
  const ext = extIdx < 0 ? null : filename.substring(extIdx + 1).toLowerCase();
  return extToIcon.get(ext, <Raw24 />);
};

const uploadFile = (
  treeUuid,
  nodeUuid,
  uploadId,
  blob,
  metadata,
  attrs,
  dispatch,
) => {
  const fileUuid = attrs.getIn(['fields', 'key']);
  const xhr = new XMLHttpRequest();
  const method = attrs.get('method') || 'POST';
  xhr.open(method, attrs.get('url'), true);

  const setStatus = status =>
    dispatch(Map({ type: 'SET_UPLOAD_STATUS', uploadId, status }));

  xhr.upload.addEventListener(
    'progress',
    ev => {
      if (ev.lengthComputable) {
        dispatch(
          Map({
            type: 'SET_UPLOAD_PROGRESS',
            uploadId,
            progress: (ev.loaded / ev.total) * 100,
          }),
        );
      }
    },
    false,
  );

  xhr.onreadystatechange = ev => {
    if (xhr.readyState === 4) {
      if (xhr.status >= 200 && xhr.status <= 300) {
        const filename = metadata.get('filename');
        const contentType = metadata.get('contentType');
        const size = metadata.get('size');

        setStatus('SUCCESS');
        dispatch(
          Map({
            type: 'ADD_FILE',
            treeUuid,
            file: Map({
              filename,
              contentType,
              size,
              nodeUuid,
              fileUuid,
            }),
          }),
        );
        setTimeout(() => {
          dispatch(Map({ type: 'REMOVE_UPLOAD_PROGRESS', uploadId }));
        }, 3000);
      } else {
        setStatus('ERROR');
      }
    }
  };

  xhr.upload.addEventListener('error', () => setStatus('ERROR'), false);

  if (method === 'POST') {
    const fd = new FormData();
    // note: order of fields matters in s3, file has to go last
    attrs.get('fields').forEach((v, k) => {
      fd.append(k, v);
    });
    fd.append('file', blob);
    xhr.send(fd);
  } else if (method === 'PUT') {
    xhr.send(blob);
  }
};

const StatusIcon = ({ status }) => {
  switch (status) {
    case 'ERROR':
      return <ErrorFilled20 className={'fill--red'} />;
    case 'UPLOADING':
      return <InProgress20 className={'fill--white spin'} />;
    case 'SUCCESS':
      return <CheckmarkFilled20 className={'fill--green'} />;
    default:
      return <InProgress20 className={'fill--white spin'} />;
  }
};

const UploadProgress = ({ treeUuid, file, dispatch }) => {
  const filename = file.get('filename');
  const blob = file.get('blob');
  const uploadAttributes = file.get('uploadAttributes');
  const progress = file.get('progress');
  const uploadId = file.get('uploadId');
  const status = file.get('status', 'FETCHING_URL');
  const contentType = file.get('contentType');
  const size = file.get('size');
  const nodeUuid = file.get('nodeUuid');

  useEffect(() => {
    if (uploadAttributes) {
      uploadFile(
        treeUuid,
        nodeUuid,
        uploadId,
        blob,
        Map({ filename, contentType, size }),
        uploadAttributes,
        dispatch,
      );
    }
  }, [
    treeUuid,
    uploadId,
    blob,
    filename,
    size,
    contentType,
    uploadAttributes,
    dispatch,
    nodeUuid,
  ]);

  return (
    <div
      className={
        'flex justify-between items-center w-100 relative mv1 pv1 pl2 bg-black-80'
      }
    >
      <div className="flex lh-copy flex-auto">
        <div className={'fill--white'}>{fileToIcon(filename)}</div>
        <div className={'pl2 f6'}>{filename}</div>
      </div>
      <div
        className={'fill--white pa2'}
        onClick={() => dispatch(Map({ type: 'CANCEL_UPLOAD', file }))}
      >
        <StatusIcon status={status} />
      </div>
      <div
        className={classNames('absolute bottom-0 left-0 z-1', {
          'bg-blue': status !== 'ERROR',
          'bg-dark-red': status === 'ERROR',
        })}
        style={{ height: '4px', width: `${progress}%` }}
      ></div>
    </div>
  );
};

const AddFilesButton = ({ state, disabled, onChange }) => {
  const inputRef = useRef();

  const isUploading = state.get('uploads', Map()).find(v => {
    const s = v.get('status');
    return s !== 'SUCCESS' && s !== 'ERROR';
  });

  return (
    <div className={classNames('w-100 relative dib', { dim: !isUploading })}>
      <input
        ref={inputRef}
        className={classNames(
          'absolute top-0 left-0 h-100 w-100 z-1 o-0 pointer',
          { dn: isUploading },
        )}
        type={'file'}
        disabled={disabled}
        multiple
        onChange={e => onChange(e, inputRef)}
      />
      <Button
        className="i18n"
        text={isUploading ? 'Uploading' : 'Upload'}
        icon={
          isUploading ? (
            <InProgress20 className={'fill--white spin'} />
          ) : (
            <Upload20 className={'fill--white'} />
          )
        }
        small
        disabled={disabled || isUploading}
      />
    </div>
  );
};

const FilesUploadingList = ({ state, dispatch }) => {
  const files = state.get('uploads', Map());

  return (
    <div className="pv2">
      {files.valueSeq().map(f => (
        <UploadProgress
          treeUuid={state.getIn(['tree', 'uuid'])}
          key={f.get('uploadId')}
          file={f}
          dispatch={dispatch}
        />
      ))}
    </div>
  );
};

const RetryOptions = ({ state, dispatch, disabled }) => {
  const hasErrors = state
    .get('uploads', Map())
    .find(up => up.get('status') === 'ERROR');

  return (
    <div
      className={classNames('mr2 justify-center items-center', {
        dn: !hasErrors,
        flex: hasErrors,
      })}
    >
      <Reset24
        className={'dim fill--white pointer mh2'}
        onClick={() => {
          if (disabled) return;

          dispatch(Map({ type: 'RETRY_UPLOAD_FILES' }));
        }}
      />
      <Erase24
        className={'dim fill--white pointer mh2'}
        onClick={() => {
          if (disabled) return;

          dispatch(Map({ type: 'CLEAR_UPLOAD_FILES' }));
        }}
      />
    </div>
  );
};

export const FileUploader = ({
  state,
  dispatch,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <>
      <div className="w-100 flex justify-between items-center">
        <AddFilesButton
          disabled={disabled}
          onChange={onChange}
          state={state}
          dispatch={dispatch}
          {...props}
        />
        <RetryOptions disabled={disabled} state={state} dispatch={dispatch} />
      </div>
      <FilesUploadingList state={state} dispatch={dispatch} />
    </>
  );
};

export const NewMultiLevelDropdown = ({
  disabled,
  inverse,
  list,
  keys,
  value,
  onChange,
  onChangeCustomText,
  customText,
  onUpdateCustomText,
  headings,
  horizontalView,
  dropDownClassName,
}) => {
  const [mainMenuOptions, setMainMenuOptions] = useState(
    List([Map({ id: '', name: '' })]),
  );
  const [subMenuOptions, setSubMenuOptions] = useState(
    List([Map({ id: '', name: '' })]),
  );
  const [finalMenuOptions, setFinalMenuOptions] = useState(
    List([Map({ id: '', name: '' })]),
  );

  useEffect(() => {
    let mainMenuList = [];
    let subMenuList = [];
    let finalMenuList = [];
    mainMenuList.push(
      Map({
        id: '',
        name: '',
      }),
    );
    if (
      list &&
      list.size > 0 &&
      list.get('list') &&
      !list.get('list').isEmpty()
    ) {
      list
        .get('list')
        .sortBy(item => item.get('name'))
        .forEach(item =>
          mainMenuList.push(
            Map({
              id: item.get(keys.id_key),
              name: item.get('name'),
            }),
          ),
        );
      if (value) {
        if (!Map.isMap(value)) {
          subMenuList.push(
            Map({
              id: '',
              name: '',
            }),
          );
          finalMenuList.push(
            Map({
              id: '',
              name: '',
            }),
          );
        }
        if (Map.isMap(value) && !value.get(keys.final_key)) {
          subMenuList.push(
            Map({
              id: '',
              name: '',
            }),
          );
          finalMenuList.push(
            Map({
              id: '',
              name: '',
            }),
          );
          if (value.get(keys.main_menu_key)) {
            let id = value.get(keys.id_key);
            if (value.get(keys.sub_menu_key)) {
              id = value.get('parentId');
            }
            list
              .get('list')
              .filter(item => id === item.get(keys.id_key))
              .sortBy(item => item.get('name'))
              .get(0)
              .get(keys.sub_menu_list_key)
              .sortBy(item => item.get('name'))
              .forEach(item =>
                subMenuList.push(
                  Map({
                    id: item.get(keys.id_key),
                    name: item.get('name'),
                  }),
                ),
              );
            if (value.get(keys.sub_menu_key)) {
              let pid = value.get('parentId');
              let id = value.get(keys.id_key);
              list
                .get('list')
                .filter(item => pid === item.get(keys.id_key))
                .sortBy(item => item.get('name'))
                .get(0)
                .get(keys.sub_menu_list_key)
                .filter(item => id === item.get(keys.id_key))
                .sortBy(item => item.get('name'))
                .get(0)
                .get(keys.final_menu_list_key)
                .sortBy(item => item.get('name'))
                .forEach(item =>
                  finalMenuList.push(
                    Map({
                      id: item.get(keys.id_key),
                      name: item.get('name'),
                    }),
                  ),
                );
            }
          }
        }
      }
    }
    if (mainMenuList.length > 0) {
      setMainMenuOptions(List(mainMenuList));
    }
    if (subMenuList.length > 0) {
      setSubMenuOptions(List(subMenuList));
    }
    if (finalMenuList.length > 0) {
      setFinalMenuOptions(List(finalMenuList));
    }
  }, [
    list,
    keys.id_key,
    value,
    keys.final_menu_list_key,
    keys.sub_menu_list_key,
    keys.final_key,
    keys.sub_menu_key,
    keys.main_menu_key,
  ]);

  let mainSelectedValue = mainMenuOptions.first().get('name');
  let subSelectedValue = subMenuOptions.first().get('name');
  let finalSelectedValue = finalMenuOptions.first().get('name');
  let isCustomValueAdded = false;
  if (value) {
    if (!Map.isMap(value)) {
      mainSelectedValue = value;
      isCustomValueAdded = true;
    } else {
      if (value.get(keys.main_menu_key)) {
        mainSelectedValue = value.get(keys.main_menu_key);
      }
      if (value.get(keys.sub_menu_key)) {
        subSelectedValue = value.get(keys.sub_menu_key);
      }
      if (value.get(keys.final_key)) {
        finalSelectedValue = value.get(keys.final_key);
      }
    }
  }
  const onClickDropdownBtn = event => {
    $(document).on('click', function (event) {
      var $trigger = $('.custom_dropdown');
      if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $('.custom_dropdown_content').removeClass('active');
      }
    });
    let parentEle = event.target.parentElement.id;
    if (!parentEle) {
      parentEle = event.target.parentElement.parentElement.id;
    }
    if (!parentEle) {
      parentEle = event.target.parentElement.parentElement.parentElement.id;
    }
    if (!parentEle) {
      parentEle =
        event.target.parentElement.parentElement.parentElement.parentElement.id;
    }
    if ($(`#${parentEle} ul`).hasClass('active')) {
      $(`#${parentEle} ul`).removeClass('active');
    } else {
      $('.custom_dropdown_content').removeClass('active');
      $(`#${parentEle} ul`).toggleClass('active');
    }
  };
  return (
    <div>
      <div
        className={classNames('f5 mb1 select-none', {
          'black-30': disabled,
          'black-80': !disabled && !inverse,
          white: !disabled && inverse,
          'fl w-third pr1 dropdown_menu_items ttc': horizontalView,
        })}
      >
        <div id={keys.main_menu_key} className="custom_dropdown w-100">
          <div
            className={classNames('f5 mb1 ttc', {
              'black-30': disabled && !inverse,
              'black-80': !disabled && !inverse,
              white: (disabled && inverse) || (!disabled && inverse),
            })}
          >
            {headings.get(keys.main_menu_key)}
          </div>
          <button
            disabled={disabled}
            onClick={onClickDropdownBtn}
            className={classNames('ph2 pv1 flex items-center relative w-100', {
              'bb bg-black-05 bg-white-20 white b--white-70':
                !disabled && inverse,
              'bg-white white-30': disabled && !inverse,
              'bg-light-gray bb b--black-20 bw1': !disabled && !inverse,
              'bg-gray white-30': disabled && !inverse,
            })}
          >
            <div className={'flex-auto'}>{mainSelectedValue}</div>
            <div className={''}>
              <CaretDown24
                className={classNames({
                  'dark-gray fill--gray': !disabled,
                  'light-gray fill--light-gray': disabled,
                })}
              />
            </div>
          </button>
          <ul
            className={classNames(
              `custom_dropdown_content ${dropDownClassName}`,
              {
                inverse_theme: inverse,
              },
            )}
          >
            {mainMenuOptions.map(i => {
              return (
                <li
                  key={i.get('id')}
                  onClick={event => {
                    let selectedItem = mainMenuOptions
                      .filter(item => item.get('id') === i.get('id'))
                      .get(0);
                    onChange(
                      Map({
                        [keys.main_menu_key]: selectedItem.get('name'),
                        [keys.id_key]: selectedItem.get('id'),
                      }),
                    );
                    $(event.target.parentElement).removeClass('active');
                  }}
                >
                  {i.get('name')}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        className={classNames('f5 mb1 select-none', {
          'black-30': disabled,
          'black-80': !disabled && !inverse,
          white: !disabled && inverse,
          'fl w-third pr1 dropdown_menu_items ttc': horizontalView,
        })}
      >
        <div id={keys.sub_menu_key} className="custom_dropdown w-100">
          <div
            className={classNames('f5 mb1 ttc', {
              'black-30': disabled && !inverse,
              'black-80': !disabled && !inverse,
              white: (disabled && inverse) || (!disabled && inverse),
            })}
          >
            {headings.get(keys.sub_menu_key)}
          </div>
          <button
            disabled={disabled}
            onClick={onClickDropdownBtn}
            className={classNames('ph2 pv1 flex items-center relative w-100', {
              'bb bg-black-05 bg-white-20 white b--white-70':
                !disabled && inverse,
              'bg-white white-30': disabled && !inverse,
              'bg-light-gray bb b--black-20 bw1': !disabled && !inverse,
              'bg-gray white-30': disabled && !inverse,
            })}
          >
            <div className={'flex-auto'}>{subSelectedValue}</div>
            <div className={''}>
              <CaretDown24
                className={classNames({
                  'dark-gray fill--gray': !disabled,
                  'light-gray fill--light-gray': disabled,
                })}
              />
            </div>
          </button>
          <ul
            className={classNames(
              `custom_dropdown_content ${dropDownClassName}`,
              {
                inverse_theme: inverse,
              },
            )}
          >
            {subMenuOptions.map(i => {
              return (
                <li
                  key={i.get('id')}
                  onClick={event => {
                    let selectedItem = subMenuOptions
                      .filter(item => item.get('id') === i.get('id'))
                      .get(0);
                    if (Map.isMap(value)) {
                      if (selectedItem.get('id') === '') {
                        onChange(
                          Map({
                            [keys.main_menu_key]: value.get(keys.main_menu_key)
                              ? value.get(keys.main_menu_key)
                              : '',
                            [keys.id_key]: value.get('parentId')
                              ? value.get('parentId')
                              : value.get(keys.id_key)
                              ? value.get(keys.id_key)
                              : '',
                          }),
                        );
                      } else {
                        onChange(
                          Map({
                            [keys.sub_menu_key]: selectedItem.get('name'),
                            [keys.main_menu_key]: value.get(keys.main_menu_key)
                              ? value.get(keys.main_menu_key)
                              : '',
                            parentId: value.get('parentId')
                              ? value.get('parentId')
                              : value.get(keys.id_key)
                              ? value.get(keys.id_key)
                              : '',
                            [keys.id_key]: selectedItem.get('id'),
                          }),
                        );
                      }
                    }
                    $(event.target.parentElement).removeClass('active');
                  }}
                >
                  {i.get('name')}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        className={classNames('f5 mb1 select-none', {
          'black-30': disabled,
          'black-80': !disabled && !inverse,
          white: !disabled && inverse,
          'fl w-third pr1 dropdown_menu_items ttc': horizontalView,
        })}
      >
        <div id={keys.final_key} className="custom_dropdown w-100">
          <div
            className={classNames('f5 mb1 ttc', {
              'black-30': disabled && inverse,
              'black-80': !disabled && !inverse,
              white: (disabled && inverse) || (!disabled && inverse),
            })}
          >
            {headings.get(keys.final_key)}
          </div>
          <button
            disabled={disabled}
            onClick={onClickDropdownBtn}
            className={classNames('ph2 pv1 flex items-center relative w-100', {
              'bb bg-black-05 bg-white-20 white b--white-70':
                !disabled && inverse,
              'bg-white white-30': disabled && !inverse,
              'bg-light-gray bb b--black-20 bw1': !disabled && !inverse,
              'bg-gray white-30': disabled && !inverse,
            })}
          >
            <div className={'flex-auto'}>{finalSelectedValue}</div>
            <div className={''}>
              <CaretDown24
                className={classNames({
                  'dark-gray fill--gray': !disabled,
                  'light-gray fill--light-gray': disabled,
                })}
              />
            </div>
          </button>
          <ul
            className={classNames(
              `custom_dropdown_content ${dropDownClassName}`,
              {
                inverse_theme: inverse,
              },
            )}
          >
            {finalMenuOptions.map(i => {
              return (
                <li
                  onClick={event => {
                    let selectedItem = finalMenuOptions
                      .filter(item => item.get('id') === i.get('id'))
                      .get(0);
                    if (Map.isMap(value)) {
                      if (selectedItem.get('id') === '') {
                        onChange(
                          Map({
                            [keys.main_menu_key]: value.get(keys.main_menu_key)
                              ? value.get(keys.main_menu_key)
                              : '',
                            [keys.sub_menu_key]: value.get(keys.sub_menu_key)
                              ? value.get(keys.sub_menu_key)
                              : '',
                            [keys.id_key]: value.get('subParentId')
                              ? value.get('subParentId')
                              : value.get(keys.id_key)
                              ? value.get(keys.id_key)
                              : '',
                            parentId: value.get('parentId')
                              ? value.get('parentId')
                              : '',
                          }),
                        );
                      } else {
                        onChange(
                          Map({
                            [keys.final_key]: selectedItem.get('name'),
                            [keys.main_menu_key]: value.get(keys.main_menu_key)
                              ? value.get(keys.main_menu_key)
                              : '',
                            [keys.sub_menu_key]: value.get(keys.sub_menu_key)
                              ? value.get(keys.sub_menu_key)
                              : '',
                            [keys.id_key]: selectedItem.get('id'),
                            subParentId: value.get('subParentId')
                              ? value.get('subParentId')
                              : value.get(keys.id_key)
                              ? value.get(keys.id_key)
                              : '',
                            parentId: value.get('parentId')
                              ? value.get('parentId')
                              : '',
                          }),
                        );
                      }
                    }
                    $(event.target.parentElement).removeClass('active');
                  }}
                  key={i.get('id')}
                >
                  {i.get('name')}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
