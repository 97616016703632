import React from 'react';
import { List, Map } from 'immutable';
import { Node, Tree } from './Tree';
import { styleNode, bgMap, bgMapAttachmentStyles, styleEdge, defaultStylesheet } from './Styles';

import notTrueSrc from './svgs/node_not_true.svg';
import notTrueUnvSrc from './svgs/node_not_true_unv.svg';
import hypothesisSrc from './svgs/node_hypothesis.svg';
import trueSrc from './svgs/node_true.svg';
import trueUnvSrc from './svgs/node_true_unv.svg';
import physicalSrc from './svgs/node_physical.svg';
import physicalUnvSrc from './svgs/node_physical_unv.svg';
import humanSrc from './svgs/node_human.svg';
import humanUnvSrc from './svgs/node_human_unv.svg';
import systemicSrc from './svgs/node_systemic.svg';
import systemicUnvSrc from './svgs/node_systemic_unv.svg';
import contributingFactorSrc from './svgs/node_contributing_factor.svg';
import contributingFactorUnvSrc from './svgs/node_contributing_factor_unv.svg';
import failureModeSrc from './svgs/node_failure_mode.svg';
import failureEventSrc from './svgs/node_failure_event.svg';
import correctiveActionSrc from './svgs/node_corrective_action.svg';
import correctiveActionUnvSrc from './svgs/node_corrective_action_unv.svg';

export const stylesheet = defaultStylesheet(
  List(
    [
      styleNode('node.failureEvent', bgMap(failureEventSrc)),
      styleNode('node.failureMode', bgMap(failureModeSrc)),
      styleNode('node.notTrue', bgMap(notTrueUnvSrc).set('color', '#aaa')),
      styleNode('node.notTrue__V', bgMap(notTrueSrc).set('color', '#aaa')),
      styleNode('node.systemic', bgMap(systemicUnvSrc)),
      styleNode('node.systemic__V', bgMap(systemicSrc)),
      styleNode('node.contributingFactor', bgMap(contributingFactorUnvSrc)),
      styleNode('node.contributingFactor__V', bgMap(contributingFactorSrc)),
      styleNode('node.human__V', bgMap(humanSrc)),
      styleNode('node.human', bgMap(humanUnvSrc)),
      styleNode('node.physical__V', bgMap(physicalSrc)),
      styleNode('node.physical', bgMap(physicalUnvSrc)),
      styleNode('node.hypothesis', bgMap(hypothesisSrc)),
      styleNode('node.hypothesis__V', bgMap(trueSrc)),
      styleNode('node.correctiveAction__V', bgMap(correctiveActionSrc)),
      styleNode('node.correctiveAction', bgMap(correctiveActionUnvSrc)),
      styleNode('node.true__V', bgMap(trueSrc)),
      styleNode('node.true', bgMap(trueUnvSrc)),
      styleNode('node.invisible', Map({ color: '#fff' })),
    ]
      .concat(bgMapAttachmentStyles('node.failureEvent', failureEventSrc))
      .concat(bgMapAttachmentStyles('node.failureMode', failureModeSrc))
      .concat(bgMapAttachmentStyles('node.notTrue', notTrueUnvSrc).map(x => x.set('color', '#aaa')))
      .concat(bgMapAttachmentStyles('node.notTrue__V', notTrueSrc).map(x => x.set('color', '#aaa')))
      .concat(bgMapAttachmentStyles('node.systemic', systemicUnvSrc))
      .concat(bgMapAttachmentStyles('node.systemic__V', systemicSrc))
      .concat(bgMapAttachmentStyles('node.contributingFactor', contributingFactorUnvSrc))
      .concat(bgMapAttachmentStyles('node.contributingFactor__V', contributingFactorSrc))
      .concat(bgMapAttachmentStyles('node.human', humanUnvSrc))
      .concat(bgMapAttachmentStyles('node.human__V', humanSrc))
      .concat(bgMapAttachmentStyles('node.physical', physicalUnvSrc))
      .concat(bgMapAttachmentStyles('node.physical__V', physicalSrc))
      .concat(bgMapAttachmentStyles('node.hypothesis', hypothesisSrc))
      .concat(bgMapAttachmentStyles('node.hypothesis__V', trueSrc))
      .concat(bgMapAttachmentStyles('node.true', trueUnvSrc))
      .concat(bgMapAttachmentStyles('node.true__V', trueSrc))
      .concat(bgMapAttachmentStyles('node.correctiveAction', correctiveActionUnvSrc))
      .concat(bgMapAttachmentStyles('node.correctiveAction__V', correctiveActionSrc))
      .concat([
        styleNode('node.invisible__A_Overdue', Map({ color: '#fff' })),
        styleNode('node.invisible__A_Incomplete', Map({ color: '#fff' })),
        styleNode('node.invisible__A_Complete', Map({ color: '#fff' })),
        styleNode('node.invisible__A_Other', Map({ color: '#fff' })),

        styleEdge(
          'edge',
          Map({
            'curve-style': 'taxi',
            'taxi-direction': 'vertical',
            'taxi-turn': '25px',
            'line-color': '#333',
          }),
        ),
        styleEdge(
          'edge.additional',
          Map({
            'curve-style': 'taxi',
            'taxi-direction': 'vertical',
            'line-style': 'dashed',
            'taxi-turn': '25px',
            'line-color': '#888',
          }),
        ),
      ]),
  ),
);

const dagreLayout = Map({
  name: 'dagre',
  fit: true,
  animate: false,
  rankDir: 'TB', //rankDir: "LR",
  padding: 100,
  // ranker: "network-simplex", //ranker: "longest-path",
});

const PROACTNode = type => Node(Map({ id: type, type }));

export const EventNode = () => PROACTNode('failureEvent');

const Hypothesis = () => PROACTNode('hypothesis');

export const FailureMode = () => PROACTNode('failureMode');

const CorrectiveAction = () => PROACTNode('correctiveAction');

export const typeToChildNode = t => {
  if (t === 'failureEvent') {
    return FailureMode();
  } else if (t === 'correctiveAction') {
    return CorrectiveAction();
  } else {
    return Hypothesis();
  }
};

export const defaultState = () =>
  Map({
    elements: EventNode(),
    view: Map({ layout: dagreLayout, stylesheet }),
  });

export const PROACTTree = ({ state, dispatch, isViewOnly, isCompleted, ...props }) => {
  return <Tree isCompleted={isCompleted} isViewOnly={isViewOnly} state={state} dispatch={dispatch} {...props} />;
};
