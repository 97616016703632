import React from 'react';
import cx from 'classnames';
import { Help16 } from '@carbon/icons-react';
import ReactTooltip from 'react-tooltip';

import styles from './styles.module.css';
import { ValueSentimentDisplay } from './ValueSentimentDisplay';

export type StandaloneFigureProps = {
  currentValue: number;
  previousValue?: number;
  label: string;
  helpText?: string;
  className?: string;
};

export const StandaloneFigure: React.FC<StandaloneFigureProps> = ({
  currentValue,
  previousValue = 0,
  helpText,
  label,
  className,
}) => {
  const helpTextProps = helpText
    ? {
        'data-tip': helpText,
      }
    : {};

  const valueDifference = React.useMemo(() => {
    if (previousValue !== undefined || previousValue === 0) {
      const diff = currentValue - previousValue;

      return diff;
    }

    return 0;
  }, [currentValue, previousValue]);

  React.useEffect(() => {
    if (!helpText) {
      ReactTooltip.hide();
    }

    ReactTooltip.rebuild();
  }, [helpText]);

  return (
    <div className={cx(styles['card'], className)}>
      <div className={styles['cardContent']}>
        <div className={cx(styles['cardPadding'], 'flex items-stretch justify-start')}>
          {helpText ? (
            <>
              <ReactTooltip />
              <div className="flex flex-column items-center justify-center">
                <button className="icon-button pa0" disabled={false} {...helpTextProps}>
                  <Help16 fill="var(--black-50)" />
                </button>
              </div>
            </>
          ) : null}
          <span className={cx(styles['figureLabel'], 'fs-s fw-medium i18n')}>{label}</span>
        </div>
        <div className={cx(styles['currentValueContainer'], 'ml-auto flex items-center justify-center')}>
          <span className="fs-baseline fw-medium">{currentValue}</span>
        </div>
        {previousValue !== undefined ? (
          <>
            <div className={styles['separator']} />
            <div className={cx('flex flex-column items-center justify-center', styles['previousValueContainer'])}>
              <ValueSentimentDisplay value={valueDifference} />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
