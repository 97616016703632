import React, { FormEvent, useCallback, useEffect, useState, MouseEvent, useRef } from 'react';
import cx from 'classnames';
import { StylingProps } from '@interfaces';

import { Spinner } from 'components';
import { Unstyled } from './Unstyled';
import styles from './styles.module.css';
import type { CommonContentProps } from './types';
import { usePopover } from '../PopoverContext';

type EditFormContentProps = {
  onSubmit?: (v?: string | number) => void;
  initialValue?: string | number;
  inputType?: string;
  title?: string;
};

export const EditForm: React.FC<StylingProps & CommonContentProps & EditFormContentProps> = ({
  initialValue,
  className,
  onSubmit,
  inputType = 'text',
  ...props
}) => {
  const { hide } = usePopover();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState<string | number | undefined>(undefined);

  const handleOnChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  }, []);

  const handleOnSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      setIsSubmitting(true);
      onSubmit && onSubmit(inputValue);
      setIsSubmitting(false);
      hide();
    },
    [onSubmit, inputValue, hide],
  );

  useEffect(() => setInputValue(initialValue), [initialValue]);
  useEffect(() => {
    inputRef.current?.focus();
  });

  return (
    <Unstyled className={cx(styles.formContent, styles.solidContentBorder, className)} {...props}>
      {props.title ? (
        <div className={cx(styles.contentHeaderSlim, styles.dashedBottomBorder)}>
          <p className={styles.formContentTitle}>{props.title}</p>
        </div>
      ) : null}
      <div className={cx('flex items-center justify-start', styles.contentBody)}>
        <input
          className="w-100 bw1 pa2 bb bg-black-05"
          type={inputType}
          onChange={handleOnChange}
          value={inputValue}
          ref={inputRef}
        />
        <button className={cx('w-25 white bg-black pa2 ph3 items-center flex justify-center')} onClick={handleOnSubmit}>
          {isSubmitting ? <Spinner color="white" size={20} /> : 'OK'}
        </button>
      </div>
    </Unstyled>
  );
};
