import cx from 'classnames';

export const Separator = props => {
  return (
    <div className={cx('w100 flex items-center justify-center relative', props.className)}>
      <span
        style={{
          fontSize: 11,
          backgroundColor: 'var(--white)',
          zIndex: 1,
        }}
        className="flex black lh-solid tc justify-center items-center relative h2 w2"
      >
        OR
      </span>
      <div
        className="absolute left-0 w-100"
        style={{
          backgroundColor: '#cccccc',
          height: 1,
          top: '50%',
          zIndex: 0,
        }}
      />
    </div>
  );
};
