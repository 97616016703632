import React from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

import { toUSD } from '../../utils';

export const RCAStatistics = ({ status, completedTasks, totalTasks, cost }) => {
  const formattedCosts = {
    total: cost.total === 0 || cost.total === '0.00' ? 'N/A' : toUSD(cost.total, { dropDecimal: true }),
    labor: cost.labor === 0 || cost.labor === '0.00' ? 'N/A' : toUSD(cost.labor, { dropDecimal: true }),
    production:
      cost.production === 0 || cost.production === '0.00' ? 'N/A' : toUSD(cost.production, { dropDecimal: true }),
    property: cost.property === 0 || cost.property === '0.00' ? 'N/A' : toUSD(cost.property, { dropDecimal: true }),
  };
  const formattedTaskCount = totalTasks === 0 ? 'N/A' : `${completedTasks}/${totalTasks}`;
  const costTooltipContent = `labor: ${formattedCosts.labor}<br/> production: ${formattedCosts.production}<br/> property: ${formattedCosts.property}`;
  return (
    <table className="w-100">
      <tbody>
        <tr>
          <td width="33%">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <div
                      data-tip={costTooltipContent}
                      data-multiline
                      data-place="bottom"
                      data-delay-show={50}
                      className={cx(styles.statValue, 'i18n')}
                    >
                      {formattedCosts.total}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={cx(styles.statLabel, 'i18n')}>Cost</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td width="33%">
            <table width="100%">
              <tbody>
                <tr>
                  <td className={cx(styles.statValue, 'i18n')}>{formattedTaskCount}</td>
                </tr>
                <tr>
                  <td className={cx(styles.statLabel, 'i18n')}>Tasks</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td width="33%">
            <table width="100%">
              <tbody>
                <tr>
                  <td className={cx(styles.statValue, 'i18n')}>{status}</td>
                </tr>
                <tr>
                  <td className={cx(styles.statLabel, 'i18n')}>Status</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
