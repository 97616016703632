import React, { CSSProperties, useMemo } from 'react';
import cx from 'classnames';
import { CarbonIconProps } from '@carbon/icons-react';

import styles from './styles.module.css';

export const IconButton: React.FC<{
  Icon: React.FC<CarbonIconProps>;
  borderWidth?: string;
  borderColor?: string;
  color?: string;
  className?: string;
  style?: CSSProperties;
}> = ({ borderWidth, borderColor, color, Icon, className, style, ...props }) => {
  const computedStyles = useMemo(() => {
    if (color) {
      return {
        color,
        borderColor: !borderColor ? color : borderColor,
        borderWidth,
        ...style,
      };
    }

    return {
      borderColor: 'var(--black)',
      color: 'var(--black)',
      borderWidth,
      ...style,
    };
  }, [style, borderWidth, borderColor, color]);

  return (
    <button className={cx('unstyled ba br--round', styles.IconButton, className)} style={computedStyles} {...props}>
      <Icon fill={color ? color : borderColor || 'var(--black)'} />
    </button>
  );
};
