import React from 'react';
import cx from 'classnames';
import { StylingProps } from '@interfaces';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.module.css';
import type { CommonContentProps } from './types';

export const Unstyled: React.FC<StylingProps & CommonContentProps> = ({
  isVisible,
  className,
  children,
  dark = false,
  ...props
}) => {
  return (
    <CSSTransition in={isVisible} timeout={200} classNames="fade" unmountOnExit>
      <div
        className={cx(styles.content, 'shadow--large', { dn: !isVisible, [styles['content-dark']]: dark }, className)}
        {...props}
      >
        {children}
      </div>
    </CSSTransition>
  );
};
