import React from 'react';
import { Map } from 'immutable';

import * as PROACT from '../../PROACT';
import * as FiveWhys from '../../FiveWhys';
import * as Fishbone from '../../Fishbone';
import { TreePreview } from '../../Tree';

export const TreeImageCapturer = ({ methodology, elements, onCapture }) => {
  const [captureCompleted, setCaptureCompleted] = React.useState(false);
  const layoutAndStyles = {
    PROACT: Map({
      stylesheet: PROACT.stylesheet,
      layout: Map({
        name: 'dagre',
        fit: true,
        animate: false,
        rankDir: 'TB',
        padding: 100,
      }),
    }),
    '5WHYS': Map({
      stylesheet: FiveWhys.stylesheet,
      layout: Map({
        name: 'dagre',
        fit: true,
        animate: false,
        padding: 100,
      }),
    }),
    FISHBONE: Map({
      stylesheet: Fishbone.stylesheet,
      layout: Map({
        name: 'dagre',
        spacingFactor: 1,
        fit: true,
        animate: false,
        padding: 100,
        rankDir: 'LR',
      }),
    }),
  };
  const ref = React.useRef();

  const layout = ref.current
    ? // @ts-ignore
      ref.current.layout(layoutAndStyles[methodology].get('layout').toJS())
    : null;

  React.useEffect(() => {
    if (ref.current && !captureCompleted) {
      const createSnapshot = async () => {
        // @ts-ignore
        const snapshot = await ref.current.png({
          output: 'blob-promise',
          full: true,
          // bg: 'transparent',
          maxHeight: 400,
          scale: 0.75,
        });

        const url = URL.createObjectURL(snapshot);

        return { snapshot, url };
      };

      // @ts-ignore
      if (ref.current && ref.current.promiseOn) {
        // @ts-ignore
        ref.current.promiseOn('layoutstop').then(() => {
          setTimeout(() => {
            return createSnapshot()
              .then(({ snapshot, url }) => {
                onCapture({ snapshot, url });
                setCaptureCompleted(true);
              })
              .catch(e => onCapture(e));
          }, 100);
        });
      }

      return () => {};
    }

    return null;
  }, [ref, methodology, layout, onCapture, captureCompleted]);

  return (
    // @ts-ignore
    <TreePreview
      onCyChange={c => (ref.current = c)}
      layout={layoutAndStyles[methodology].get('layout')}
      stylesheet={layoutAndStyles[methodology].get('stylesheet')}
      elements={elements}
    />
  );
};
