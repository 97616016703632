import React from 'react';
import {
  DatePeriod,
  DatePeriodMonths,
  ValidDatePeriod,
  DashboardDataComparisonSnapshot,
  Task,
  ROUTES,
  TasksRequestParams,
  DeepHierarchyObject,
} from '@interfaces';
import { RouteComponentProps } from 'react-router';
import { getDashboardData, getDashboardTasksData } from 'Sync';

/**
 * Represents the currently selected period
 *
 * @example
 * {
 *   interval: 3 // months
 *   identifier: 'PAST_1_MONTH'
 * }
 */
export type SelectedPeriod = {
  interval: number;
  identifier: ValidDatePeriod | string;
};

export type PeriodMonths = Record<DatePeriod, number>;

export const periodDisplayLanguage = {
  [DatePeriod.PAST_1_MONTH]: '1 Month',
  [DatePeriod.PAST_3_MONTHS]: '3 Months',
  [DatePeriod.PAST_6_MONTHS]: '6 Months',
  [DatePeriod.PAST_1_YEAR]: '1 Year',
  [DatePeriod.PAST_2_YEARS]: '2 Years',
  [DatePeriod.PAST_3_YEARS]: '3 Years',
  [DatePeriod.PAST_4_YEARS]: '4 Years',
  [DatePeriod.PAST_5_YEARS]: '5 Years',
};

type FetchDashboardDataArgs = {
  token: string;
  periodMonths?: number;
  groupUuid?: string;
  owner?: string;
  facility?: DeepHierarchyObject;
  equipment?: DeepHierarchyObject;
};

type FetchDashboardTasksDataArgs = FetchDashboardDataArgs & {
  taskType?: string;
  overdue?: boolean;

  gt2Weeks?: boolean;
  lt2Weeks?: boolean;
  upcoming?: boolean;
};

export type DashboardContextValue = {
  loading: boolean;
  selectedPeriod: SelectedPeriod;
  selectedGroup: string | null;
  selectedOwner: string;
  // @TODO MIGRATE FACILITIES/EQUIPMENT TO DEEP
  selectedFacility: DeepHierarchyObject | null;
  selectedEquipment: DeepHierarchyObject | null;
  setLoading: (s: boolean) => void;
  setSelectedGroup: (groupUuid: string) => void;
  setSelectedOwner: (s: string) => void;
  setSelectedFacility: (facility: DeepHierarchyObject) => void;
  setSelectedEquipment: (equipment: DeepHierarchyObject) => void;
  setPeriod: (identifier: string) => void;
  impactedFacilities?: number;
  /* Dashboard Data */
  data: DashboardDataComparisonSnapshot | null;
  setData: (data: DashboardDataComparisonSnapshot) => void;
  /* Tasks */
  tasks: Task[] | [];
  setTasks: (tasks: Array<Task>) => void;
  /* Data Fetching */
  fetchDashboardData: (args: FetchDashboardDataArgs) => Promise<void>;
  fetchDashboardTasksData: (args: FetchDashboardTasksDataArgs) => Promise<void>;
};

export type DashboardRoutingContextValue = {
  openVerificationsFlyout: (params: TasksRequestParams) => void;
  openCorrectiveActionsFlyout: (params: TasksRequestParams) => void;
  closeDashboardFlyout: () => void;
};

const DashboardRoutingContext = React.createContext<DashboardRoutingContextValue | null>(null);

const DashboardContext = React.createContext<DashboardContextValue | null>(null);

export const useDashboard = () => {
  const context = React.useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }

  return context as DashboardContextValue;
};

export const useDashboardRouting = () => {
  const context = React.useContext(DashboardRoutingContext);

  if (!context) {
    throw new Error('useDashboardRouting must be used within a DashboardRoutingProvider');
  }

  return context as DashboardRoutingContextValue;
};

export const DashboardRoutingProvider: React.FC<Omit<RouteComponentProps, 'staticContext' | 'match' | 'location'>> = ({
  children,
  history,
}) => {
  const getFlyoutQuery = function getFlyoutQuery(p: TasksRequestParams) {
    const query = new URLSearchParams();

    if (p.groupUuid) {
      query.append('groupUuid', p.groupUuid);
    }

    if (p.gt2Weeks) {
      query.append('gt2Weeks', String(p.gt2Weeks));
    }

    if (p.lt2Weeks) {
      query.append('lt2Weeks', String(p.lt2Weeks));
    }

    if (p.overdue) {
      query.append('overdue', String(p.overdue));
    }

    if (p.upcoming) {
      query.append('upcoming', String(p.upcoming));
    }

    return query.toString();
  };

  const closeDashboardFlyout = React.useCallback(() => history.replace(ROUTES.DASHBOARD), [history]);
  const openVerificationsFlyout = React.useCallback(
    (p: TasksRequestParams) => {
      const query = getFlyoutQuery(p);

      history.replace(`${ROUTES.DASHBOARD_TASKS}/verifications?${query}`);
    },
    [history],
  );
  const openCorrectiveActionsFlyout = React.useCallback(
    (p: TasksRequestParams) => {
      const query = getFlyoutQuery(p);

      history.replace(`${ROUTES.DASHBOARD_TASKS}/corrective-actions?${query}`);
    },
    [history],
  );

  const value: DashboardRoutingContextValue = React.useMemo(
    () => ({
      closeDashboardFlyout,
      openVerificationsFlyout,
      openCorrectiveActionsFlyout,
    }),
    [closeDashboardFlyout, openVerificationsFlyout, openCorrectiveActionsFlyout],
  );

  return <DashboardRoutingContext.Provider value={value}>{children}</DashboardRoutingContext.Provider>;
};

export const DashboardProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);
  const [selectedOwner, setSelectedOwner] = React.useState<string | null>(null);
  const [selectedFacility, setSelectedFacility] = React.useState<DeepHierarchyObject | null>(null);
  const [selectedEquipment, setSelectedEquipment] = React.useState<DeepHierarchyObject | null>(null);
  const [currentPeriod, setCurrentPeriod] = React.useState<SelectedPeriod>({
    identifier: DatePeriod.PAST_1_MONTH,
    interval: DatePeriodMonths.PAST_1_MONTH,
  });

  const [data, setData] = React.useState<DashboardDataComparisonSnapshot | null>(null);
  const [tasks, setTasks] = React.useState<Array<Task>>([]);

  const fetchDashboardData = React.useCallback(
    async (args: FetchDashboardDataArgs) => {
      const payload = {
        token: args.token,
        equipment: args.equipment,
        facility: args.facility,
        groupUuid: args.groupUuid,
        owner: args.owner,
        periodMonths: args.periodMonths,
      };

      const data = await getDashboardData(payload);

      if (data) {
        setData(data);
      }
    },
    [setData],
  );

  const fetchDashboardTasksData = React.useCallback(
    async (args: FetchDashboardTasksDataArgs) => {
      const payload = {
        token: args.token,
        taskType: args.taskType,
        equipment: args.equipment,
        facility: args.facility,
        gt2Weeks: args.gt2Weeks !== false,
        lt2Weeks: args.gt2Weeks !== true,
        overdue: args.overdue,
        upcoming: args.upcoming,
        owner: args.owner,
        groupUuid: args.groupUuid,
        periodMonths: args.periodMonths,
      };

      const tasksData = await getDashboardTasksData(payload);

      if (tasksData) {
        setTasks(tasksData);
      }
    },
    [setTasks],
  );

  const handleSetCurrentPeriod = React.useCallback((identifier: string) => {
    return setCurrentPeriod({
      interval: DatePeriodMonths[identifier],
      identifier: identifier as DatePeriod,
    });
  }, []);

  const value: DashboardContextValue = React.useMemo(
    () => ({
      selectedGroup,
      setSelectedGroup,
      selectedOwner,
      setSelectedOwner,
      selectedFacility,
      setSelectedFacility,
      selectedEquipment,
      setSelectedEquipment,
      selectedPeriod: currentPeriod,
      setPeriod: handleSetCurrentPeriod,
      fetchDashboardData,
      fetchDashboardTasksData,
      loading,
      setLoading,
      data,
      setData,
      tasks,
      setTasks,
    }),
    [
      currentPeriod,
      loading,
      data,
      tasks,
      selectedOwner,
      selectedGroup,
      selectedFacility,
      selectedEquipment,
      setTasks,
      setLoading,
      setSelectedOwner,
      handleSetCurrentPeriod,
      fetchDashboardData,
      fetchDashboardTasksData,
    ],
  );

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};
