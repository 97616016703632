import React from 'react';
import {
  useTable,
  TableCommonProps,
  Column,
  TableOptions,
  useGlobalFilter,
  usePagination,
  useFilters,
  useSortBy,
  useRowSelect,
  HeaderGroup,
  FilterProps,
  SortingRule,
} from 'react-table';
import cx from 'classnames';
import { LegacyPagination, SearchInput, Select } from 'components';
import { CaretDown16, CaretUp16 } from '@carbon/icons-react';
import { ValidObject } from '@interfaces';
import { JSXElement } from '@babel/types';

import shortid from 'shortid';
import styles from './styles.module.css';
import { TableFilters } from './Filters';

type DataTableProps<DataObj extends ValidObject> = TableCommonProps & {
  data: DataObj[];
  columns: Column<DataObj>[];
  options?: TableOptions<DataObj>;
  // unique props
  debug?: boolean;
  pagination?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  globalFiltering?: boolean;
  selectableRows?: boolean;
  initialFilters?: { id: string; value: string }[];
  initialSort?: SortingRule<DataObj>[];
} & {
  SelectFilter?: <D extends ValidObject>(p: FilterProps<D>) => JSXElement;
};

const GlobalFilter: React.FC<{
  query: string;
  onGlobalFilterChange: (value: string) => void;
}> = ({ onGlobalFilterChange, query }) => {
  return (
    <div className="w-100 flex items-center justify-start pv3">
      <div className="w-50 flex flex-column">
        <label htmlFor="search" className="f6 fw6">
          Search
        </label>
        <SearchInput
          tabIndex={1}
          placeholder="Search by title, type, status, etc."
          id="search"
          className="mt2 w-100"
          onChange={e => onGlobalFilterChange(e.target.value)}
          value={query}
        />
      </div>
    </div>
  );
};

const ColumnHeader = <D extends Record<string, unknown>>({
  getHeaderProps,
  canSort,
  getSortByToggleProps,
  maxWidth,
  width,
  isSorted,
  isSortedDesc,
  render,
}: HeaderGroup<D>) => {
  return (
    <td
      className="pa3 fw-semibold pointer"
      {...getHeaderProps({
        ...(canSort ? getSortByToggleProps() : {}),
      })}
      style={{ maxWidth: maxWidth }}
      width={width}
    >
      <div className="i18n flex items-center justify-start">
        {render('Header')}
        <div className="ml-auto">{isSorted ? isSortedDesc ? <CaretDown16 /> : <CaretUp16 /> : null}</div>
      </div>
    </td>
  );
};

export const DataTable = <DataObj extends ValidObject>(props: DataTableProps<DataObj>) => {
  const data = React.useMemo(() => props.data, [props.data]);
  const columns = React.useMemo(() => props.columns, [props.columns]);
  const plugins = React.useMemo(() => {
    const configuration: any[] = [];

    if (props.globalFiltering) {
      configuration.push(useGlobalFilter);
    }

    if (props.filterable) {
      configuration.push(useFilters);
    }

    if (props.sortable) {
      configuration.push(useSortBy);
    }

    if (props.pagination) {
      configuration.push(usePagination);
    }

    if (props.selectableRows) {
      configuration.push(useRowSelect);
    }

    console.warn(configuration);

    return configuration;
  }, [props.pagination, props.sortable, props.filterable, props.globalFiltering, props.selectableRows]);

  const instance = useTable(
    {
      data,
      columns,
      initialState: {
        sortBy: props.initialSort || [],
      },
      ...(props.options || {}),
    },
    ...plugins,
  );

  return (
    <div className={cx('data-table w-100', props.className)}>
      <div className="flex items-stretch justify-start">
        {props.globalFiltering ? (
          <GlobalFilter query={instance.state.globalFilter} onGlobalFilterChange={instance.setGlobalFilter} />
        ) : null}
        <TableFilters columns={instance.columns} />
      </div>
      <table className={cx('w-100 h-100 collapse', styles['fixedLayout'])} {...instance.getTableProps()}>
        <thead>
          {/* eslint-disable react/jsx-key */}
          {instance.headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                if (typeof column.Header === 'function') {
                  return column.render('Header');
                }

                return <ColumnHeader<DataObj> {...column} />;
              })}
            </tr>
          ))}
        </thead>
        <tbody {...instance.getTableBodyProps()}>
          {props.pagination && instance.page
            ? instance.page?.map((row, idx) => {
                instance.prepareRow(row);
                return (
                  <tr
                    key={row.id}
                    className={cx({
                      'bg--black-05': idx % 2 !== 0,
                    })}
                  >
                    {row.cells.map(cell => (
                      <td key={shortid()} className="pa3">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            : instance.rows?.map((row, idx) => {
                instance.prepareRow(row);
                return (
                  <tr
                    key={row.id}
                    className={cx({
                      'bg--black-05': idx % 2 !== 0,
                    })}
                  >
                    {row.cells.map(cell => (
                      <td key={shortid()} className="pa3">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
          {/* eslint-enable react/jsx-key */}
        </tbody>
      </table>
      {props.pagination && instance.pageCount > 1 ? (
        <div className="ml-auto w-40">
          <LegacyPagination
            currentPage={instance.state.pageIndex}
            gotoPage={instance.gotoPage}
            nextPage={instance.nextPage}
            pageCount={instance.pageCount}
            pageSize={instance.pageSize}
            pages={instance.pageOptions}
            prevPage={instance.previousPage}
            nextPageAvailable={instance.canNextPage}
            prevPageAvailable={instance.canPreviousPage}
          />
        </div>
      ) : null}
    </div>
  );
};

DataTable.SelectFilter = <D extends ValidObject>({
  setFilter,
  column,
  placeholder,
  options,
  isSearchable = false,
  ...props
}: FilterProps<D> & {
  placeholder?: string;
  isSearchable?: boolean;
  options?: any;
  Component?: React.FC<any>;
}) => {
  const handleOnChange = (newValue, { action }) => {
    if (action === 'select-option') {
      return setFilter(column.id, newValue?.value);
    } else if (action === 'clear') {
      return setFilter(column.id, '');
    }
  };

  if (!props.Component) {
    return (
      /* @ts-ignore */
      <Select
        name={column.id}
        id={column.id}
        placeholder={placeholder}
        isSearchable={isSearchable}
        options={options}
        onChange={handleOnChange}
        {...props}
      />
    );
  }

  return (
    <props.Component
      name={column.id}
      id={column.id}
      placeholder={placeholder}
      isSearchable={isSearchable}
      options={options}
      onChange={handleOnChange}
      {...props}
    />
  );
};
