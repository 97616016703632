import { UserRoleProperties } from '@interfaces';
import React from 'react';
import { useLocalStorage } from 'components';

type AuthContextValue = {
  token?: string | null;
  roles?: Partial<UserRoleProperties>;
  name?: string;
  username?: string;
  isAdmin: boolean;
  i18n: boolean;
  features: {
    aiFeatures?: boolean;
    i18n?: boolean;
  };
};

type AuthProviderProps = {
  storageKey?: string;
  initialValue?: Partial<AuthContextValue>;
  onLogout?: () => void;
};

type AuthProviderValue = AuthContextValue & {
  logout: () => { ok: boolean; error?: any };
  setState: React.Dispatch<React.SetStateAction<Partial<AuthContextValue>>>;
};

const AuthContext = React.createContext<AuthProviderValue | null>(null);

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  onLogout,
  storageKey = 'auth',
  initialValue = {
    name: undefined,
    username: undefined,
    token: undefined,
    isAdmin: false,
    i18n: false,
    features: {
      aiFeatures: false,
      i18n: false,
    },
  },
}) => {
  const [storedValue, setValue] = useLocalStorage<Partial<AuthContextValue>>({
    key: storageKey,
    initialValue,
  });

  React.useEffect(() => {
    if (initialValue.token && initialValue.username) {
      return setValue(initialValue);
    }
  }, [initialValue, setValue]);
  // const [storedToken, _] = useLocalStorage<{ token: string | null }>({
  //   key: 'token',
  //   initialValue: { token: null },
  // });

  const handleLogout = React.useCallback(() => {
    const keys = Object.keys(localStorage);

    keys.forEach(key => {
      if (key.includes('easyrca-') || key === 'auth' || key === 'token') {
        try {
          console.debug(`[AuthContext]: removing ${key} from local storage.`);
          localStorage.removeItem(key);
        } catch (error) {
          console.debug('[AuthContext]: failed to remove state from local storage', error);

          return { ok: false, error };
        }
      }
    });

    if (onLogout) {
      console.info('[AuthContext]: onLogout received; calling it');
      onLogout();
    }

    return { ok: true };
  }, [onLogout]);

  const value = React.useMemo<AuthProviderValue>(
    () => ({
      token: storedValue.token,
      i18n: storedValue.i18n || false,
      features: storedValue.features,
      isAdmin: storedValue.isAdmin || false,
      name: storedValue.name,
      roles: storedValue.roles,
      username: storedValue.username,
      logout: handleLogout,
      setState: setValue,
    }),
    [storedValue, setValue, handleLogout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
