export enum EXPERIMENTS {
  ACTION_CENTER = 'action-center',
}

export type ActionCenterTask = {
  id: number;
  creatorId: number;
  taskUuid: string;
  treeId: number;
  title: string;
  description: string;
  deadline: number;
  createdAt: number;
  completedAt: number;
  completedBy: string;
  nodeUuid: string;
  taskType: string;
  createdBy: string;
  treeUuid: string;
  treeTitle: string;
  treeDescription: string;
  treeEventAt: number;
  treeExpectedAt: number;
  treeCreatedBy: string;
  treeOwner: string;
  treeStatus: string;
  doers: string[];
  nodeText: string;
};
