import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { SocketProvider, useAuth } from 'containers';
import { DashboardQueryParams, ROUTES } from '@interfaces';
import { ErrorBoundary, DashboardTasksFlyout } from 'components';
import { Dashboard } from 'views';

import {
  DashboardProvider,
  DashboardRoutingProvider,
} from './DashboardContext';

type DashboardContainerProps = RouteComponentProps<DashboardQueryParams>;
type DashboardDetailRoutesProps = Omit<
  DashboardContainerProps,
  'staticContext'
>;

/**
 * Dedicated Switch for the dashboard's Flyout (details) routes
 * @param {Object} match
 * @param {Object} location
 * @param {Object} history
 * @returns {JSX.Element}
 */
function DashboardDetailRoutes({
  location,
  history,
}: DashboardDetailRoutesProps) {
  const handleDismissFlyout = () => history.replace(ROUTES.DASHBOARD);
  return (
    <Switch location={location}>
      <Route
        path={`${ROUTES.DASHBOARD_TASKS}/:type(verifications|corrective-actions)`}
      >
        <DashboardTasksFlyout isOpen dismiss={handleDismissFlyout} />
      </Route>
      <Redirect path={`/${ROUTES.DASHBOARD}/*`} to={`/${ROUTES.DASHBOARD}`} />
    </Switch>
  );
}

export const DashboardContainer: React.FC<DashboardContainerProps> = props => {
  const { token, username } = useAuth();

  return (
    <ErrorBoundary>
      <SocketProvider token={token} username={username}>
        <DashboardProvider>
          <DashboardRoutingProvider history={props.history}>
            <Dashboard
              history={props.history}
              location={props.location}
              match={props.match}
            />
            <DashboardDetailRoutes
              location={props.location}
              history={props.history}
              match={props.match}
            />
          </DashboardRoutingProvider>
        </DashboardProvider>
      </SocketProvider>
    </ErrorBoundary>
  );
};
