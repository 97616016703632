import React, { Children } from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

import styles from './styles.module.css';

/*
  1-Column:
    - full-width table
    - heading + paragraph(s)/copy
    - full-width attachment
    - full-width attachment gallery

  2-Column:
    - heading + paragraph(s)/copy and single attachment
    - heading + paragraph(s)/copy and statistic/figure
*/
export const ReportCard = ({ children, classes = [], id, ...props }) => {
  const { ref, inView } = useInView({ threshold: 0.5 });
  const columns = Children.toArray(children).filter(c => c.type.name === 'Column');

  return (
    <div
      id={id}
      ref={ref}
      className={cx(styles.ReportCard, classes, {
        inViewport: inView,
        [styles.fullRow]: props.full,
        singleColumn: columns.length < 1,
      })}
      {...props}
    >
      <div className={cx(styles.row, { [styles.fullRow]: props.full })}>{children}</div>
    </div>
  );
};

ReportCard.Title = function Title({ children, ...props }) {
  return (
    <h1 className={cx(styles.CardHeading, 'i18n')} {...props}>
      {children}
    </h1>
  );
};

ReportCard.Subtitle = function Subtitle({ children, type = 'h2', ...props }) {
  switch (type) {
    case 'h6':
      return (
        <h6 className={cx(styles.CardSubheading, 'f6 i18n')} {...props}>
          {children}
        </h6>
      );
    case 'h5':
      return (
        <h5 className={cx(styles.CardSubheading, 'f5 i18n')} {...props}>
          {children}
        </h5>
      );
    case 'h4':
      return (
        <h4 className={cx(styles.CardSubheading, 'f4 i18n')} {...props}>
          {children}
        </h4>
      );
    case 'h3':
      return (
        <h3 className={cx(styles.CardSubheading, 'f3 i18n')} {...props}>
          {children}
        </h3>
      );
    case 'h2':
    default:
      return (
        <h2 className={cx(styles.CardSubheading, 'f2 i18n')} {...props}>
          {children}
        </h2>
      );
  }
};

ReportCard.Attachment = function Attachment({ source, description, ...props }) {
  return (
    <ReportCard.Column {...props}>
      <div className={styles.attachmentContainer}>
        <img className={styles.attachment} src={source} alt={description} />
      </div>
    </ReportCard.Column>
  );
};

ReportCard.Figure = function Figure({ figure, caption, ...props }) {
  return (
    <ReportCard.Column {...props}>
      <blockquote className={styles.Figure}>
        <p className={styles.figureLabel}>{figure}</p>
        <em className={styles.figureCaption}>{caption}</em>
      </blockquote>
    </ReportCard.Column>
  );
};

ReportCard.Row = function Row({
  style = {},
  className = '',
  alignItems = 'stretch',
  justifyContent = 'flex-start',
  flex = 1,
  children,
  ...props
}) {
  const computedStyles = React.useMemo(
    () => ({ display: 'flex', alignItems, justifyContent, flex, ...style }),
    [style, alignItems, justifyContent, flex],
  );

  return (
    <div
      className={cx('i18n', styles.row, { [styles.fullRow]: props.full }, className)}
      style={computedStyles}
      {...props}
    >
      {children}
    </div>
  );
};

ReportCard.Column = function Column({
  style = {},
  className = '',
  alignItems = 'flex-start',
  justifyContent = 'center',
  flex = 1,
  children,
  ...props
}) {
  const computedStyles = React.useMemo(
    () => ({ ...(style || {}), flex, alignItems, justifyContent }),
    [style, alignItems, justifyContent, flex],
  );
  return (
    <div className={cx('i18n', styles.Column, className)} style={computedStyles} {...props}>
      {children}
    </div>
  );
};

ReportCard.displayName = 'ReportCard';
