import React, { InputHTMLAttributes, useCallback } from 'react';
import cx from 'classnames';
import { Search16 } from '@carbon/icons-react';

import styles from './styles.module.css';

type SearchInputProps = InputHTMLAttributes<HTMLInputElement> & {
  onClear?: () => void;
};

export const SearchInput: React.FC<SearchInputProps> = ({ className, onChange, onClear, ...props }) => {
  const handleChange = useCallback(
    e => {
      if (!e.target.value || e.target.value === '') {
        if (onClear) onClear();
      }

      return onChange(e);
    },
    [onChange, onClear],
  );

  return (
    <div className={cx('flex align-center justify-start', styles['input'], styles['search'], className)}>
      <div className={cx(styles['icon-wrapper'])}>
        <Search16 fill="var(--black-50)" />
      </div>
      <input
        id={props.id}
        name={props.name}
        style={props.style}
        className={cx('ph1 pv2 w-100', styles['search-input'])}
        type="search"
        onChange={handleChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        autoFocus={props.autoFocus}
        tabIndex={props.tabIndex}
        placeholder={props.placeholder}
        autoComplete="off"
        {...props}
      >
        {props.children}
      </input>
    </div>
  );
};
