import React, { MouseEventHandler } from 'react';

export function useCopyToClipboard({
  source,
  textarea,
  onSuccess,
  onError,
}: {
  source: React.RefObject<HTMLDivElement>;
  textarea: React.RefObject<HTMLTextAreaElement>;
  onSuccess?: (...args: any[]) => void;
  onError?: (error?: any) => void;
}) {
  const handleCopyToClipboard: MouseEventHandler<HTMLButtonElement> = React.useCallback(
    e => {
      if (!source.current || !textarea.current) return;

      try {
        textarea.current.value = source.current.innerHTML;
        textarea.current.select();

        document.execCommand('copy');

        if (onSuccess) return onSuccess(e);
      } catch (error) {
        console.error(error);

        if (onError) {
          return onError(error);
        }
      }
    },
    [onSuccess, onError, source, textarea],
  );

  const contentTypeHandler = React.useCallback(
    (e: ClipboardEvent) => {
      if (textarea.current && textarea.current.value) {
        e.preventDefault();
        return e.clipboardData?.setData('text/html', textarea.current.value);
      }

      console.warn(
        '[useCopyToClipboard] contentTypeHandler: textarea.current.value is not available',
      );
    },
    [textarea],
  );

  React.useEffect(() => {
    if (!document || !window) return;

    document.addEventListener('copy', contentTypeHandler);

    return function cleanupCopyListeners() {
      return document.removeEventListener('copy', contentTypeHandler);
    };
  }, [contentTypeHandler]);

  return [handleCopyToClipboard];
}
