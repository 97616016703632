import React, { useEffect } from 'react';
import cx from 'classnames';
import { useHistory, useRouteMatch } from 'react-router';
import { ROUTES } from '@interfaces';
import styles from './styles.module.css';
import { RCAForm } from './views/Form';


export const FieldRCAContainer: React.FC = () => {
  const { params } = useRouteMatch<{ orgUUID: string }>();
  const { push } = useHistory();

  useEffect(() => {
    if (!params.orgUUID) {
      return push(ROUTES.RCA_LIST);
    }

    return;
  }, [params.orgUUID, push]);

  return (
    <section className="flex flex-column w-100 h-100">
      <div className={cx('ml-auto mr-auto', styles['field-rca-container'])}>
        <RCAForm orgUuid={params.orgUUID} />
      </div>
    </section>
  );
};
