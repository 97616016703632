import React from 'react';
import { User } from '@interfaces';
import { validate } from 'email-validator';
import cx from 'classnames';
import { Avatar } from 'components';

import { createFilter, GroupBase, OptionProps, StylesConfig } from 'react-select';
import { Checkmark20 } from '@carbon/icons-react';
import { Props } from 'react-select/dist/declarations/src/Select';
import { sortBy } from 'lodash';
import styles from './styles.module.css';
import { Select } from './Select';

type OrgMembersSelectOption = {
  label: string;
  value: string;
  extraLabel?: string;
  userId: number;
  name?: string;
};

const Option = ({
  data,
  innerProps,
  ...rest
}: OptionProps<OrgMembersSelectOption, false> & {
  selectProps: Props<OrgMembersSelectOption, false, GroupBase<OrgMembersSelectOption>>;
}) => {
  const { onMouseOver: _, onMouseMove: __, ...restInnerProps } = innerProps;
  const { innerRef } = rest;
  let isSelected = false;

  if (rest.selectProps.markSelectedOptions && rest.selectProps.selected) {
    isSelected = rest.selectProps.selected.filter(o => o === data.userId).length > 0;
  }

  return (
    <div
      ref={innerRef}
      className={cx('pointer ph2 pv2 flex w-100 items-center justify-start relative', {
        [styles['select-option']]: true,
        [styles['select-option--light']]: !rest.selectProps.isDark,
        [styles['select-option--dark']]: rest.selectProps.isDark,
      })}
      {...restInnerProps}
    >
      {rest.label?.includes('add') ? (
        <Avatar disableTooltip height={34} fontSize={12} name="+" />
      ) : (
        <Avatar disableTooltip height={34} fontSize={12} name={data.label} />
      )}
      <div className="labels pl2 flex flex-column items-stretch justify-start">
        <span style={{ paddingBottom: '0.15rem' }} className="f6 fw5">
          {data.label || 'N/A'}
        </span>
        <span className="f7 fw4">{data.extraLabel}</span>
      </div>
      <div className="ml-auto flex items-center justify-center">
        {rest.selectProps.markSelectedOptions && isSelected ? <Checkmark20 fill="var(--black-50)" /> : null}
      </div>
    </div>
  );
};

const LoadingMessage = () => (
  <div className="flex w-100 align-center justify-start">
    <span>Loading Members. Please wait.</span>
  </div>
);

// type OrgMembersSelectProps = Props{
//   data?: User[];
//   // markSelectedOptions?: boolean;
//   selected?: (string | number)[];
//   // dark?: boolean;
//   // allowOptionCreation?: boolean;
// };

export function OrgMembersSelect<
  Option extends OrgMembersSelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<OrgMembersSelectOption> = GroupBase<OrgMembersSelectOption>,
>({
  data,
  markSelectedOptions = false,
  selected = [],
  allowOptionCreation = false,
  showPendingInvitations = false,
  ...props
}: // @ts-ignore
Partial<Props<Option, IsMulti, Group>> & {
  data?: User[];
  showPendingInvitations?: boolean;
  selected?: (number | string)[];
}) {
  // const sortedData = React.useMemo(() => {
  //   console.warn('sorting data for OrgMembersSelect...', data);
  //   if (data && data.length > 0) {
  //     return data.sort((a, b) => {
  //       if (a.fullName > b.fullName) {
  //         return 1;
  //       }

  //       return -1;
  //     });
  //   }
  // }, [data]);
  const [isFetchingMembers, setIsFetchingMembers] = React.useState(true);

  // const MemoizedOption = p => {
  //   console.warn('Option props', p);
  //   return (
  //     <Option
  //       {...p}
  //       dark={dark}
  //       markSelectedOptions={markSelectedOptions}
  //       selected={selected}
  //     />
  //   );
  // };

  // const components: Partial<SelectComponents<
  //   OrgMembersSelectOption,
  //   false,
  //   GroupBase<OrgMembersSelectOption>
  // >> = React.useMemo(() => {
  //   return ;
  // }, [MemoizedOption]);

  const options = React.useMemo(() => {
    const sortedMembers = data.sort((a, b) => {
      if (a.fullName < b.fullName) {
        return -1;
      }

      if (a.fullName > b.fullName) {
        return 1;
      }

      return 0;
    });

    if (!showPendingInvitations) {
      return sortedMembers
        .filter(u => !u.invited)
        .map(u => ({
          value: u.username,
          label: u.fullName,
          extraLabel: u.username,
          name: u.fullName,
          userId: u.id,
        }));
    }

    return sortedMembers.map(u => ({
      value: u.username,
      label: u.fullName,
      extraLabel: u.username,
      name: u.fullName,
      userId: u.id,
    }));
  }, [data, showPendingInvitations]);

  const memoizedStyles: StylesConfig<
    OrgMembersSelectOption,
    boolean,
    GroupBase<OrgMembersSelectOption>
  > = React.useMemo(
    () => ({
      menu: provided => ({
        ...provided,
        backgroundColor: props.isDark ? 'rgb(0, 0, 0)' : provided.backgroundColor,
        width: '100%',
      }),
    }),
    [props.isDark],
  );

  React.useEffect(() => {
    if (data) {
      setIsFetchingMembers(false);
    }
  }, [data]);

  const filterOption = createFilter<OrgMembersSelectOption>({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    trim: true,
    stringify: opt => `${opt.data.extraLabel} ${opt.data.name}`,
  });

  const validateNewOption = value => validate(value);

  return (
    <Select<OrgMembersSelectOption>
      {...props}
      name="org-members"
      id="org-members"
      // dark={dark}
      formatCreateLabel={allowOptionCreation ? value => `add ${value}` : undefined}
      components={{
        Option,
      }}
      isSearchable
      isDark={props.isDark}
      markSelectedOptions={markSelectedOptions}
      selected={selected}
      allowOptionCreation={allowOptionCreation}
      // @ts-ignore
      isValidNewOption={validateNewOption}
      filterOption={filterOption}
      loadingMessage={LoadingMessage}
      styles={memoizedStyles}
      isLoading={isFetchingMembers}
      options={options}
      value={props.value}
    />
  );
}
