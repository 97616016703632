import React from 'react';
import { Map } from 'immutable';
import cx from 'classnames';
import { Tree20 } from '@carbon/icons-react';
import { NavHashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router';
import { AsyncButton } from 'components/Button/AsyncButton';

import styles from './styles.module.css';

const NavLink = ({ hash, className, children }) => {
  const { pathname } = useLocation();
  return (
    <Link
      isActive={(_, location) => {
        return !location.hash || location.hash.includes(hash);
      }}
      smooth
      activeClassName={styles.activeNavLink}
      // activeStyle={{ color: 'red' }}
      className={cx(styles.NavLink, className)}
      title={children}
      to={`${pathname}#${hash}`}
    >
      {children}
    </Link>
  );
};

export const ReportNavigation = ({
  treeUuid,
  handleSaveAsDocx,
  isFetchingDocx,
  handleSaveAsPdf,
  isFetchingPdf,
  dispatch,
  printDisabled,
  docxDisabled,
  shareLinkDisabled,
  shareLinkNotAllowed,
  backToAnalysisDisabled,
  ...props
}) => {
  return (
    <div className={styles.sticky}>
      <div
        className={cx(styles.wrapper, {
          [styles.paddedWrapper]: !printDisabled,
        })}
      >
        <nav className={cx(styles.ReportNavigation, 'hide-for-print pr2')} style={props.style}>
          {!shareLinkDisabled ? (
            <button
              className={cx('save-report-button bg-transparent ba mt2 b--blue blue pa2 w-80 mb3 i18n')}
              onClick={() => {
                dispatch(
                  Map({
                    type: 'GET_SIGNATURE',
                    tag: 'reportLink',
                    value: `#/report/${treeUuid}`,
                    shareLinkNotAllowed: shareLinkNotAllowed, // let reducer deal with it
                  }),
                );
              }}
            >
              Share Link
            </button>
          ) : null}
          {props.prepend ? props.prepend : null}
          {!printDisabled ? (
            <AsyncButton className="mt3 w-80 i18n" loading={isFetchingPdf} onClick={handleSaveAsPdf}>
              Save as PDF
            </AsyncButton>
          ) : null}
          {!docxDisabled ? (
            <AsyncButton className="mt3 w-80 i18n" loading={isFetchingDocx} onClick={handleSaveAsDocx}>
              Save as Docx
            </AsyncButton>
          ) : null}
          {treeUuid && !backToAnalysisDisabled ? (
            <Link className={styles.treeEditorLink} to={`/tree/${treeUuid}`}>
              <Tree20 />
              <span className={cx(styles.treeEditorLinkLabel, 'i18n')}>Back to Analysis</span>
            </Link>
          ) : null}
          <NavLink
            className={cx(styles.standaloneLink, 'i18n', {
              pt4: backToAnalysisDisabled !== false,
            })}
            hash="executive-summary"
          >
            Executive Summary
          </NavLink>
          <span className={cx(styles.NavHeading, 'i18n')}>Appendix</span>
          <NavLink className="i18n" hash="timeline">
            Timeline
          </NavLink>
          <NavLink className="i18n" hash="summary-of-findings">
            Summary of Findings
          </NavLink>
          <NavLink className="i18n" hash="tasks">
            Tasks
          </NavLink>
          <NavLink className="i18n" hash="logic-tree">
            Logic Tree
          </NavLink>
          <NavLink className="i18n" hash="root-causes-and-findings">
            Root Causes and Findings
          </NavLink>
          <NavLink className="i18n" hash="team-members">
            Team Members
          </NavLink>
          <NavLink className="i18n" hash="attachments">
            Attachments
          </NavLink>
        </nav>
      </div>
    </div>
  );
};
