import React from 'react';
import { List, Map } from 'immutable';
import { Node, Tree } from './Tree';
import { defaultStylesheet, styleNode, styleEdge, bgMap, bgMapAttachmentStyles } from './Styles';

import why1Src from './svgs/node_why_1.svg';
import why2Src from './svgs/node_why_2.svg';
import why3Src from './svgs/node_why_3.svg';
import why4Src from './svgs/node_why_4.svg';
import why5Src from './svgs/node_why_5.svg';
import becauseSrc from './svgs/node_because.svg';
import failureEventSrc from './svgs/node_failure_event.svg';
import correctiveActionSrc from './svgs/node_corrective_action.svg';

export const stylesheet = defaultStylesheet(
  List(
    [
      styleNode('node.why1', bgMap(why1Src)),
      styleNode('node.why2', bgMap(why2Src)),
      styleNode('node.why3', bgMap(why3Src)),
      styleNode('node.why4', bgMap(why4Src)),
      styleNode('node.why5', bgMap(why5Src)),
      styleNode('node.because', bgMap(becauseSrc)),
      styleNode('node.failureEvent', bgMap(failureEventSrc)),
      styleNode('node.correctiveAction', bgMap(correctiveActionSrc)),
    ]
      .concat(bgMapAttachmentStyles('node.why1', why1Src))
      .concat(bgMapAttachmentStyles('node.why2', why2Src))
      .concat(bgMapAttachmentStyles('node.why3', why3Src))
      .concat(bgMapAttachmentStyles('node.why4', why4Src))
      .concat(bgMapAttachmentStyles('node.why5', why5Src))
      .concat(bgMapAttachmentStyles('node.because', becauseSrc))
      .concat(bgMapAttachmentStyles('node.failureEvent', failureEventSrc))
      .concat(bgMapAttachmentStyles('node.correctiveAction', correctiveActionSrc))
      .concat([
        styleEdge(
          'edge',
          Map({
            'curve-style': 'taxi',
            'taxi-direction': 'vertical',
          }),
        ),
      ]),
  ),
);

const layout = Map({
  name: 'dagre',
  spacingFactor: 1,
  fit: true,
  animate: false,
  padding: 100,
  rankDir: 'LR',
});

const FiveWhyNode = type => Node(Map({ id: type, type }));

const Because = () => FiveWhyNode('because');
const FailureEvent = () => FiveWhyNode('failureEvent');
const Why5 = () => FiveWhyNode('why5');
const Why4 = () => FiveWhyNode('why4');
const Why3 = () => FiveWhyNode('why3');
const Why2 = () => FiveWhyNode('why2');
const Why1 = () => FiveWhyNode('why1');
const CorrectiveAction = () => FiveWhyNode('correctiveAction');

export const typeToChildNode = t => {
  switch (t) {
    case 'failureEvent':
      return Why1();
    case 'why1':
      return Why2();
    case 'why2':
      return Why3();
    case 'why3':
      return Why4();
    case 'why4':
      return Why5();
    case 'correctiveAction':
      return CorrectiveAction();
    default:
      return Because();
  }
};

const defaultNodes = () =>
  List([FailureEvent(), Why1(), Why2(), Why3(), Why4()]).reduceRight((acc, v) => {
    return v.set('children', List([acc]));
  }, Why5());

export const defaultState = () =>
  Map({
    elements: defaultNodes(),
    view: Map({ layout, stylesheet }),
  });

export const FiveWhysTree = ({ state, dispatch, isViewOnly, isCompleted, ...props }) => {
  return <Tree isCompleted={isCompleted} isViewOnly={isViewOnly} state={state} dispatch={dispatch} {...props} />;
};
