export function parseF(x, d) {
  if (x) {
    return parseFloat(x);
  }

  return d;
}

export function calculateFailureCost({
  frequency = 1,
  laborCost,
  productionCost,
  propertyCost,
}) {
  const freq = parseF(frequency, 1);
  const labor = parseF(laborCost, 0);
  const production = parseF(productionCost, 0);
  const property = parseF(propertyCost, 0);

  return {
    total: (freq * (labor + production + property)).toFixed(2),
    labor,
    production,
    property,
  };
}
