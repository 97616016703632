import React, { useEffect } from 'react';
import { Map } from 'immutable';
import _, { uniqBy } from 'lodash';
import {
  Debug32,
  Help32,
  Tree32,
  Group32,
  ThumbsDown32,
  Chemistry32,
  WarningAlt32,
  OperationsField32,
  ListChecked32,
  CarbonIconType,
} from '@carbon/icons-react';
import { Node } from '@interfaces';

import { defaultApplicationState, reducer } from './Reducer';
import { toTitleCase } from './utils';
import { initDispatchedWS } from './Sync';

export const useRootReducer = () => {
  const [state, dispatch] = React.useReducer(reducer, defaultApplicationState);

  return {
    state,
    dispatch,
  };
};

function flat(array: any[]) {
  let result: any[] = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
}

export function useToggle({ visible }) {
  const [isVisible, setIsVisible] = React.useState(visible || false);
  const toggleVisibility = React.useCallback(() => {
    setIsVisible(state => !state);
  }, []);

  return [isVisible, toggleVisibility];
}

const isValidPROACTRoot = s => {
  if (s) {
    return s === 'systemic' || s === 'human' || s === 'physical';
  }

  return false;
};

const isValid5WhysRoot = s => {
  if (s) {
    return s === 'why5' || s === 'why6';
  }

  return false;
};

const isValidFishboneRoot = s => {
  if (s) {
    return (
      s === 'method' ||
      s === 'materials' ||
      s === 'machinery' ||
      s === 'motherNature' ||
      s === 'measurement' ||
      s === 'manpower' ||
      s === 'note'
    );
  }

  return false;
};

function getNodesByType({ elements, type }): Node[] {
  if (!elements || elements.length < 1) return [];

  const flattened = uniqBy(flat(elements), 'oid');
  const results = _.filter(flattened, n => n && n.type && n.type === type);

  return results;
}

export function useGetNodesByType({ elements, type }) {
  const [results, setResults] = React.useState<Node[]>([]);

  useEffect(() => {
    const results = getNodesByType({ elements, type });

    return setResults(results);
  }, [elements, type]);

  return results;
}

// export function useFindContributingFactors({ elements }) {
//   const [results, setResults] = React.useState([]);

//   useEffect(() => {
//     if (elements && elements.length > 0) {
//       const flattened = uniqBy(flat(elements), 'oid');
//       const results = _.filter(flattened, e => {
//         const { type = null } = e;

//         return type === 'contributingFactor';
//       });

//       return setResults(results);
//     }

//     return;
//   }, [elements]);

//   return results;
// }
// let iteration = 0;
// export const flattenAssociatedNodes = (data: Node[], parentOid?: string) => {
//   iteration += 1;

//   if (data && data.length > 0) {
//     return data.map(d => {
//       if (d.children) {
//         // console.info(`found nested nodes for ${d.oid}`);
//         const withParentIds = d.children.map(c => ({ ...c, parentOid: d.oid }));
//         return flattenAssociatedNodes(withParentIds, d.oid);
//       }

//       if (parentOid) {
//         return {
//           ...d,
//           parentOid,
//         };
//       }

//       return d;
//     });
//   }

//   return [];
// };

export const flattenTreeNodes = (data, parent = null) => {
  if (data.flatMap) {
    return data.flatMap(({ oid, children, ...node }) => [
      { ...node, oid, parent },
      ...flattenTreeNodes(children, oid),
    ]);
  }

  return [];
};

export function useFindRootCauses({ elements, methodology }) {
  const [results, setResults] = React.useState<Node[] | []>([]);

  useEffect(() => {
    if (elements && elements.length > 0) {
      const flattened = uniqBy(flat(elements), 'oid');
      const results = _.filter(flattened, e => {
        const { type = null, text = '' } = e;

        if (methodology === '5WHYS' && isValid5WhysRoot(type) && text !== '') {
          return e;
        } else if (
          methodology === 'PROACT' &&
          isValidPROACTRoot(type) &&
          text !== ''
        ) {
          return e;
        } else if (
          methodology === 'FISHBONE' &&
          isValidFishboneRoot(type) &&
          text !== ''
        ) {
          return e;
        }
      });

      return setResults(results);
    }

    return;
  }, [elements, methodology]);

  return results;
}

export function useFindNodesWithAttachments({ elements, attachments }) {
  const [results, setResults] = React.useState([]);

  useEffect(() => {
    if (
      elements &&
      elements.length > 0 &&
      attachments &&
      attachments.length > 0
    ) {
      const attachmentNodeIds = attachments.map(a => a.node_uuid);
      const flattened = flat(elements);

      const results = _.filter(flattened, e => {
        if (e.oid && attachmentNodeIds.includes(e.oid)) {
          return e;
        }
      });

      // @ts-ignore
      return setResults(results);
    }

    return;
  }, [attachments, elements]);

  return results;
}

export const nodeIconForType = ({ type }) => {
  switch (type) {
    case 'hypothesis':
      return Help32;
    case 'notTrue':
      return ThumbsDown32;
    case 'systemic':
      return Tree32;
    case 'human':
      return Group32;
    case 'physical':
      return Chemistry32;
    case 'contributingFactor':
      return Debug32;
    case 'correctiveAction':
      return ListChecked32;
    case 'failureMode':
      return OperationsField32;
    case 'event':
      return WarningAlt32;
    default:
      return () => null;
  }
};

export const isMultiWordType = type =>
  ['contributingFactor', 'correctiveAction', 'failureMode'].includes(type);

export const labelForType = ({ type }) => {
  switch (type) {
    case 'contributingFactor':
      return 'Contributing Factor';
    case 'correctiveAction':
      return 'Corrective Action';
    case 'human':
      return 'Human';
    case 'systemic':
      return 'Systemic';
    case 'physical':
      return 'Physical';
    case 'failureMode':
      return 'Failure Mode';
    default:
      return type;
  }
};

export const nodeColorForType = ({ type }) => {
  switch (type) {
    case 'hypothesis':
      return '#357EDD';
    case 'systemic':
      return '#E7040F';
    case 'human':
      return '#FF8700';
    case 'correctiveAction':
      return '#19A974';
    case 'event':
      return '#000000';
    case 'notTrue':
      return 'rgba(0, 0, 0, 0.15)';
    case 'physical':
      return '#5E2CA5';
    default:
    case 'contributingFactor':
      return '#D5008F';
  }
};

export function useNodeContentForType({ type }) {
  const [result, setResult] = React.useState<{
    icon: CarbonIconType | (() => null);
    title: string | null;
    color: string | null;
  }>({
    icon: () => null,
    title: null,
    color: null,
  });

  React.useEffect(() => {
    const heading = isValidPROACTRoot(type)
      ? `${toTitleCase(type)} Cause`
      : toTitleCase(type.replace(/([A-Z])/g, ' $1'));

    setResult({
      icon: nodeIconForType({ type }),
      title: heading,
      color: nodeColorForType({ type }),
    });
  }, [type]);

  return result;
}

export function usePrintDocument(opts: {
  beforePrint?: () => void;
  afterPrint?: () => void;
}) {
  const { beforePrint, afterPrint } = opts;
  const [dialogIsVisible, setDialogIsVisible] = React.useState(false);

  const handlePrint = React.useCallback(() => {
    setDialogIsVisible(true);

    if (window && window.print) {
      return window.print();
    }
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    if (beforePrint) {
      beforePrint();
    }

    return null;
  }, [beforePrint]);

  const handleAfterPrint = React.useCallback(() => {
    if (afterPrint) {
      afterPrint();
    }

    setDialogIsVisible(false);

    return null;
  }, [afterPrint]);

  React.useEffect(() => {
    if (!window) return;

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [handleAfterPrint, handleBeforePrint]);

  return {
    handlePrint,
    dialogIsVisible,
  };
}

export function useInitializeSocket({
  socket,
  dispatch,
  onInitialize,
  onError,
}) {
  const isInitializing = React.useRef(true);

  useEffect(() => {
    if (socket) {
      // socket is already available, proceed with initialization requirements
      if (isInitializing.current && onInitialize) {
        onInitialize();
        isInitializing.current = false;
      }
    } else {
      // socket is unavailable; initialize one
      return initDispatchedWS(null, dispatch, err => {
        if (err) {
          console.error(err);

          dispatch(Map({ type: 'SET_URL', url: '/' }));

          return onError(err);
        }
      });
    }
    /* eslint-disable */
  }, [onInitialize, isInitializing, onError]);
  /* eslint-enable */

  return {
    isInitializing: isInitializing.current,
  };
}
