import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { InProgress20, ErrorFilled24, Login20, ProgressBarRound20 } from '@carbon/icons-react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Input, PasswordInput } from './TextInputs';
import { Button } from './Buttons';
import logoSrc from './svgs/logo-v2.svg';
import msSigninSrc from './svgs/ms-signin.svg';
import { hasToken, initDispatchedWS } from './Sync';

const salutations = List([
  "Let's go find some roots.",
  "Let's go control the fix.",
  "Let's get proactive.",
  "Let's go find some ROI.",
  "Let's make the world a more reliable place.",
  'Go PROACT like a boss.', // Sebastian wanted this one
]);

const randomSalutation = () => salutations.get((Math.random() * salutations.size) | 0);

export const Login = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map({ salutation: randomSalutation() }));
  const error = model.get('error');

  const submitting = model.get('submitting');
  const ws = state.get('ws');
  const token = state.get('token');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const referrer = urlParams.get('referrer');

  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN && token) {
      // referrer param exists, redirect to it
      if (referrer) {
        const urlWithReferrer = `${referrer}`;
        return dispatch(Map({ type: 'SET_URL', url: urlWithReferrer }));
      }

      return dispatch(Map({ type: 'SET_URL', url: '/v2' }));
    } else {
      if (hasToken()) {
        setModel(m => m.set('submitting', true));
        initDispatchedWS(null, dispatch, (err, res) => {
          setModel(m => m.delete('submitting'));
          if (res) {
            // referrer param exists, redirect to it
            if (referrer) {
              const urlWithReferrer = `${referrer}`;
              return dispatch(Map({ type: 'SET_URL', url: urlWithReferrer }));
            }
            return dispatch(Map({ type: 'SET_URL', url: '/v2' }));
          } else {
            setModel(
              m => m.set('error', ''), // No need to scare the user with errors, it could be an expired token
            );
          }
        });
      } else {
      }
    }
  }, [ws, submitting, model, dispatch, token]);

  const onSubmit = e => {
    const username = model.get('username');
    const password = model.get('password');
    const isSubmittable = username && password && password.length > 7;
    e.preventDefault();

    if (isSubmittable) {
      setModel(m => m.delete('error').set('submitting', true));

      initDispatchedWS({ username, password }, dispatch, err => {
        if (err) {
          console.error('Failed to initialize socket:', JSON.stringify(err));
          setModel(m => m.delete('submitting').set('error', err || 'Failed to initialize socket.'));
        }
      });
    } else {
      setModel(m => m.delete('submitting').set('error', 'Your email or password is invalid.'));
    }
  };

  if (token) {
    return (
      <div className={'h-100 w-100 flex justify-center items-center bg-grid'}>
        <div className={'ba bw2 pa4 b--navy bg-white mb4'} style={{ width: '400px' }}>
          <div>
            <div className={'mb4'}>
              <img alt="easy rca logo" src={logoSrc} className={'db'} style={{ width: '200px' }} />
            </div>
            <div className={'f3 i18n'}>Login Successful</div>
            <div className={'f5 black-60 mb3 lh-copy'}>Please wait while we log you in.</div>
            <div>
              <ProgressBarRound20 className="spin fill--blue" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-100 w-100 flex flex-column justify-center items-center bg-grid pb4">
      <div className="ba bw2 pa4 b--navy bg-white mb4" style={{ width: '400px' }}>
        <div className="mb4">
          <img alt="easy rca logo" src={logoSrc} className="db" style={{ width: '200px' }} />
        </div>
        <div className="f3">Login</div>
        <div className="f5 black-60 mt1 mb4 lh-title">{model.get('salutation')}</div>
        <div
          className={classNames('bw2 bl b--red pa2 mb2 items-center justify-between red fw6 bg-washed-red', {
            dn: !error,
            flex: error,
          })}
        >
          <ErrorFilled24 className="fill--red mr2" />
          <div className="flex-auto">{error}</div>
        </div>
        <div className="flex items-center justify-start pb3">
          <button
            className="unstyled"
            onClick={() => {
              window.location = referrer
                ? `${window.location.origin}/microsoft/login?referrer=${referrer}`
                : `${window.location.origin}/microsoft/login`;
            }}
          >
            <img src={msSigninSrc} />
          </button>
        </div>
        <div className="pt3">
          <form onSubmit={onSubmit} className="w-100 db">
            <div className={classNames('flex flex-column', { 'o-50': submitting })}>
              <Input
                autoFocus
                type="email"
                className="mb2"
                disabled={submitting}
                placeholder="Email Address"
                onChange={e => setModel(model.set('username', e.target.value.toLowerCase()))}
                value={model.get('username', '')}
              />
              <PasswordInput
                placeholder="Password"
                className="mb4"
                disabled={submitting}
                value={model.get('password')}
                onChange={e => setModel(model.set('password', e.target.value))}
              />
            </div>
            <Button
              text="Log In"
              disabled={submitting}
              icon={
                submitting ? (
                  <InProgress20 className="spin white fill--white" />
                ) : (
                  <Login20 className="fill--white white" />
                )
              }
            />
          </form>
          <div
            className={classNames('mt3', {
              dn: !state.get('debug'),
            })}
          >
            <Link className="blue underline link" to="/create-account">
              (Dev Env Only) Create account here.
            </Link>
          </div>
        </div>
      </div>
      <Link className="black underline link" to="/forgot-password">
        Forgot Password?
      </Link>
    </div>
  );
};
