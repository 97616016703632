import React from 'react';
import cx from 'classnames';
import shortid from 'shortid';
import type { Payload } from 'recharts';
import { toTitleCase } from 'utils';
import { StylingProps } from '@interfaces';

import styles from './styles.module.css';

type LegendPayload = Payload<{ fill?: string; label: string; value: string }>;

type LegendItemProps = StylingProps & {
  color?: string;
  value: string;
  payload?: LegendPayload;
};

const LegendItem = ({ style, className, color, payload }: LegendItemProps) => {
  return (
    <div className={cx(styles['legendItem'], className)} style={style}>
      <div className={styles['legendItemIcon']} style={{ backgroundColor: color }} />
      <p className="ma0 fs-xxs fw-medium pl2">{toTitleCase(payload?.payload?.label)}</p>
    </div>
  );
};

type VerticalLegendProps = StylingProps & {
  payload?: LegendPayload[];
};

export const VerticalLegend = (props: VerticalLegendProps) => {
  if (props.payload) {
    return props.payload?.map(p => {
      const key = shortid();
      return <LegendItem key={key} {...p} />;
    });
  }

  return null;
};
