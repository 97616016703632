import React, { useState, useEffect } from 'react';
import { fromJS, Map } from 'immutable';
import cx from 'classnames';
import { property, get } from 'lodash';
import { format } from 'date-fns';
import { CustomFieldMap } from '@interfaces';
import shortid from 'shortid';
import { getCustomDocmosisReport } from 'Sync';
import { Attachment16, CollapseAll16, ExpandAll16 } from '@carbon/icons-react';
import { useParams } from 'react-router-dom';
import { useBlobDownload } from 'components';
import { AnalysisMethodology, Report, State } from '@interfaces';
import { Checkbox } from 'Buttons';

import {
  useFindRootCauses,
  useInitializeSocket,
  useFindNodesWithAttachments,
  usePrintDocument,
  useGetNodesByType,
  flattenTreeNodes,
} from 'hooks';
import {
  RCAStatistics,
  ReportNavigation,
  ReportSectionHeader,
  ReportCard,
  Table,
  AttachmentGallery,
  BackToTop,
  RootCauses,
  TreeImageCapturer,
  CopyToClipboardButton,
} from 'components';
import { extractDatePart, calculateFailureCost, toTitleCase } from 'utils';
import { useAnalytics } from 'containers';
import { getAvailableCustomFields } from '../../Sync';

import { Page } from '../../Page';

import styles from '../../styles/report.module.css';
import { Avatar } from '../../components';
import { LoadingWidget } from '../../TreeViewer';

type RouteParams = {
  treeUUID?: string;
  signature?: string;
};

export const ReportContainer: React.FC<{
  dispatch: React.Dispatch<Record<string, unknown>>;
  state: State;
}> = React.memo(props => {
  const { track } = useAnalytics();
  const { dispatch, state } = props;
  // @ts-ignore
  const socket = state.get('ws');
  const [titleIsVisible, setTitleIsVisible] = React.useState(true);
  const [capturedTreeImage, setCapturedTreeImage] = React.useState(null);
  const params = useParams<RouteParams>();
  const viewOnly = !!params.signature;
  // @ts-ignore

  // console.warn(state.toJS());
  // console.warn(state.getIn(['organization', 'user', 'orgUuid']));
  // @ts-ignore
  // const equipmentLabels = state.getIn(
  //   ['organization', 'user', 'customNames', 'equipment'],
  //   Map({
  //     class: 'Class',
  //     code: 'Code',
  //     type: 'Type',
  //   }),
  // );
  // @ts-ignore
  // const facilityLabels = state.getIn(
  //   ['organization', 'user', 'customNames', 'facility'],
  //   Map({
  //     department: 'Department',
  //     location: 'Location',
  //     site: 'Site',
  //   }),
  // );

  const [error, setError] = React.useState<unknown>(undefined);
  // @ts-ignore
  const report = state.getIn(['reports', params.treeUUID], {}) as Report;
  console.log(report);
  // @ts-ignore
  const users = state.get('users', Map());
  const { tree } = report;
  const shareLinkNotAllowed = tree && !tree.canView;
  // @ts-ignore
  const reportElements = React.useMemo(() => {
    if (tree) {
      const nodes = flattenTreeNodes([get(tree, 'elements', [])]);

      return nodes;
    }

    return [];
  }, [tree]);

  const [attachmentsHidden, setAttachmentsHidden] = React.useState(false);
  const [allNotesExpanded, setAllNotesExpanded] = React.useState(true);
  const methodology = tree ? tree.methodology : null;
  const reportAttachments = property('files')(report);
  const attachmentsByType = React.useMemo(() => {
    // @ts-ignore
    if (reportAttachments && reportAttachments.length > 0) {
      return {
        // @ts-ignore
        images: reportAttachments.filter(a => a.content_type.includes('image')),
        // @ts-ignore
        other: reportAttachments.filter(a => !a.content_type.includes('image')),
      };
    }
  }, [reportAttachments]);

  const { handlePrint } = usePrintDocument({
    afterPrint: () => {
      setAttachmentsHidden(false);
      track('Save Report PDF', { 'Analysis UUID': params.treeUUID });
    },
  });

  const downloadReport = () => {
    setAllNotesExpanded(true);
    setAttachmentsHidden(true);

    setTimeout(() => {
      handlePrint();
    }, 150);
  };

  const [blob, setBlob] = useState<string | null>(null);
  const [isFetchingDocx, setIsFetchingDocx] = useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);
  const [reportFilename, setReportFilename] = useState('report.docx');

  // @ts-ignore
  const token = state.get('token', null);

  useEffect(() => {
    getAvailableCustomFields({ token })
      .then(data => {
        dispatch(
          Map({
            type: 'GET_CUSTOM_FIELDS',
            payload: Map(data),
          }),
        );
      })
      .catch(error => console.info('custom fields error:', error));
  }, [token]);

  // @ts-ignore
  const rawCustomFields: CustomFieldMap = state
    // @ts-ignore
    .get('customFields', Map())
    .toJS();

  // Restructuring rawCustomFields into clean Object with key/value pairs
  const customFieldsArray = Object.keys(rawCustomFields).map(key => [key, rawCustomFields[key]['label']]);
  const customFields = Object.fromEntries(customFieldsArray);

  useBlobDownload({
    blob,
    datatype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    filename: reportFilename,
    onSave: () => {
      console.warn('[MixPanel]: tracking "Download Report Docx" for analysis', params.treeUUID);
      track('Download Report Docx', { 'Analysis UUID': params.treeUUID });
    },
  });

  const handleSaveAsDocx = () => {
    setIsFetchingDocx(true);

    getCustomDocmosisReport({
      token,
      treeUuid: tree.treeUuid,
      treeImage: capturedTreeImage,
      fileFormat: 'docx',
    })
      .then(docx => {
        try {
          setReportFilename(docx.fileName);
          setBlob(docx.fileData);
        } catch (error) {
          console.error(error);
        }
      })
      .catch(e => console.error(e))
      .finally(() => {
        setIsFetchingDocx(false);
      });
  };

  const handleSaveAsPdf = () => {
    setIsFetchingPdf(true);

    getCustomDocmosisReport({
      token,
      treeUuid: tree.treeUuid,
      treeImage: capturedTreeImage,
      fileFormat: 'pdf',
    })
      .then(docx => {
        try {
          setReportFilename(docx.fileName);
          setBlob(docx.fileData);
        } catch (error) {
          console.error(error);
        }
      })
      .catch(e => console.error(e))
      .finally(() => {
        setIsFetchingPdf(false);
      });
  };

  const rootCauses = useFindRootCauses({
    elements: reportElements,
    methodology,
  });
  const contributingFactors = useGetNodesByType({
    elements: reportElements,
    type: 'contributingFactor',
  });
  const nodesWithAttachments = useFindNodesWithAttachments({
    elements: reportElements,
    attachments: reportAttachments,
  });

  // Used for the root causes table; we decided to include contributing factors after launch
  const combinedRootCauseData = React.useMemo(() => {
    return [...rootCauses, ...contributingFactors];
  }, [contributingFactors, rootCauses]);

  const onInitialize = React.useCallback(() => {
    dispatch(
      Map({
        type: 'GET_REPORT_DATA',
        treeUUID: params.treeUUID,
        signature: params.signature,
      }),
    );
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [params.treeUUID]);
  /* eslint-enable */

  const onError = (error: unknown) => setError(error);

  const onCopyToClipboardSuccess = React.useCallback(() => {
    dispatch(
      Map({
        type: 'SHOW_TOAST',
        duration: 4000,
        message: 'Report text copied to clipboard.',
        style: 'SUCCESS',
      }),
    );
  }, [dispatch]);

  const onCopyToClipboardError = React.useCallback(() => {
    dispatch(
      Map({
        type: 'SHOW_TOAST',
        duration: 4000,
        message: 'Failed to copy report to your clipboard.',
        style: 'ERROR',
      }),
    );
  }, [dispatch]);

  const { isInitializing } = useInitializeSocket({
    socket,
    dispatch,
    onInitialize,
    onError,
  });

  const clipboardContentRef = React.useRef<HTMLDivElement | null>(null);

  if (isInitializing || !report) {
    return <LoadingWidget error={error} message="Fetching report data" />;
  }

  if (report && !capturedTreeImage && report.tree) {
    return (
      <>
        <LoadingWidget error={error} message="Capturing image of logic tree" />
        <TreeImageCapturer
          elements={fromJS(report.tree.elements)}
          methodology={report.tree.methodology}
          onCapture={({ url }) => setCapturedTreeImage(url)}
        />
      </>
    );
  }

  if (report && capturedTreeImage) {
    const rcaStatus = toTitleCase(property('tree.status')(report));

    const timelineEvents = report.timelineEvents.sort(
      /* @ts-ignore */
      (a, b) => a.time - b.time,
    );

    const cost = calculateFailureCost({
      laborCost: report.tree.laborCost,
      productionCost: report.tree.productionCost,
      propertyCost: report.tree.propertyCost,
      frequency: report.tree.frequency,
    });
    const reportTitle = report.tree.title;

    return (
      <Page
        dispatch={dispatch}
        state={state}
        id="report-details"
        title={report.tree.title}
        viewOnly={viewOnly}
        onVisibilityStatusChange={({ visible }) => setTitleIsVisible(visible)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
          }}
        >
          <ReportNavigation
            handleSaveAsDocx={handleSaveAsDocx}
            handleSaveAsPdf={handleSaveAsPdf}
            isFetchingPdf={isFetchingPdf}
            isFetchingDocx={isFetchingDocx}
            handlePrint={downloadReport}
            treeUuid={report.tree.treeUuid}
            dispatch={dispatch}
            docxDisabled={false}
            shareLinkDisabled={viewOnly}
            printDisabled={viewOnly}
            backToAnalysisDisabled={viewOnly}
            shareLinkNotAllowed={shareLinkNotAllowed}
            prepend={
              <CopyToClipboardButton
                source={clipboardContentRef}
                label="Copy to Clipboard"
                onSuccess={onCopyToClipboardSuccess}
                onError={onCopyToClipboardError}
              />
            }
          />
          <article className={styles['reportContainer']}>
            <section>
              {/* Use a dummy span to jump to executive summary properly when nav link is clicked */}
              {/* eslint-disable-next-line */}
              <span id="executive-summary" />
              {/* eslint-enable */}
              <div id="report-contents" ref={clipboardContentRef}>
                <h2 className="w-100 absolute">{report.tree.title}</h2>
                <ReportSectionHeader
                  title={titleIsVisible ? 'Executive Summary' : reportTitle}
                  sticky
                  right={
                    <RCAStatistics
                      totalTasks={report.tasks.length}
                      completedTasks={
                        /* @ts-ignore */
                        report.tasks.filter(t => t.completedAt && t.completedAt !== undefined).length
                      }
                      cost={cost}
                      status={rcaStatus}
                    />
                  }
                />

                <ReportCard id="what-happened" classes={['print-pagebreak-after']}>
                  <ReportCard.Column>
                    <ReportCard.Title>What happened?</ReportCard.Title>

                    {report.tree.description ? (
                      report.tree.description
                        .split('\n')
                        .filter(s => s !== '')
                        .map(p => <p key={shortid()}>{p}</p>)
                    ) : (
                      <p>Problem Statement has not been filled out</p>
                    )}
                  </ReportCard.Column>
                </ReportCard>
                {!report.tree.environmentalImpact &&
                !report.tree.safetyImpact &&
                !report.tree.injuries &&
                !report.tree.customerImpact &&
                !report.tree.customValues ? null : (
                  <ReportCard full id="impacts" classes={['print-pagebreak-after']}>
                    <ReportCard.Column>
                      <table className="w-100">
                        <tbody>
                          {report.tree.injuries ? (
                            <tr>
                              <td className="pb3">
                                <strong>Injury Report</strong>
                                <br /> {report.tree.injuries}
                              </td>
                            </tr>
                          ) : null}

                          {report.tree.environmentalImpact ? (
                            <tr>
                              <td className="pb3">
                                <strong>Environmental Impact</strong>
                                <br /> {report.tree.environmentalImpact}
                              </td>
                            </tr>
                          ) : null}
                          {report.tree.safetyImpact ? (
                            <tr>
                              <td className="pb3">
                                <strong>Safety Impact</strong>
                                <br />
                                {report.tree.safetyImpact}
                              </td>
                            </tr>
                          ) : null}
                          {report.tree.customerImpact ? (
                            <tr>
                              <td className="pb3">
                                <strong>Customer Impact</strong>
                                <br />
                                {report.tree.customerImpact}
                              </td>
                            </tr>
                          ) : null}
                          {report.tree.customValues &&
                          customFields &&
                          Object.entries(report.tree.customValues).length > 0 &&
                          Object.entries(report.tree.customValues).some(([_, value]) => {
                            return value && value !== '';
                          }) ? (
                            <tr>
                              <td className="pb3">
                                <span
                                  className="i18n"
                                  style={{
                                    marginBottom: '0.3rem',
                                    display: 'block',
                                    fontWeight: 400,
                                    fontSize: '1.9rem',
                                    lineHeight: '1.9rem',
                                  }}
                                >
                                  Custom Fields
                                </span>
                                <br />
                                {
                                  // @ts-ignore
                                  Object.entries(rawCustomFields)
                                    .sort((a, b) => a[1].index - b[1].index)
                                    .map(([key, _]) => {
                                      const value = report.tree.customValues[key];
                                      if (value) {
                                        return (
                                          <div className="flex items-center justify-start pb2" key={key}>
                                            <strong className="mr2">
                                              {customFields[key] ? customFields[key] : null}
                                            </strong>
                                            {value}
                                          </div>
                                        );
                                      }

                                      return null;
                                    })
                                }
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </ReportCard.Column>
                  </ReportCard>
                )}
                <ReportCard id="where-it-happened">
                  <ReportCard.Column>
                    <ReportCard.Title>Where did it happen?</ReportCard.Title>
                    {/* @ts-ignore */}
                    {!report.tree.facility && !report.tree.equipment ? (
                      <p>Facility and Equipment have not been selected</p>
                    ) : (
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="flex items-center justify-start">
                                        <strong className="mr2">Facility:</strong>
                                        <span>{report.tree.facility?.namePath.join(' ') || 'N/A'}</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="flex items-center justify-start">
                                        <strong className="mr2">Equipment:</strong>
                                        <span>{report.tree.equipment?.namePath.join(' ') || 'N/A'}</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </ReportCard.Column>
                </ReportCard>
                <ReportCard id="why-it-happened" classes={['print-pagebreak-after']}>
                  <ReportCard.Column classes={['print-pagebreak-before']}>
                    <ReportCard.Title>Why did it happen?</ReportCard.Title>
                    {rootCauses.length > 0 ? (
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Root Cause</Table.HeaderCell>
                            <Table.HeaderCell capitalize={false}>Corrective Action(s)</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {rootCauses.map(r => {
                            const associatedCorrectiveActions = reportElements.filter(
                              e => e.parent === r.oid && (e.type === 'correctiveAction' || e.type === 'because'),
                            );
                            return (
                              <Table.Row key={shortid()}>
                                <Table.Cell className="flex items-center justify-start-ns">
                                  <span className="pl1">{r.text}</span>
                                </Table.Cell>
                                <Table.Cell>
                                  {associatedCorrectiveActions.length > 0 ? (
                                    <ol>
                                      {associatedCorrectiveActions.map(ca => {
                                        if (ca) {
                                          return <li key={ca.oid}>{ca.text}</li>;
                                        }

                                        return (
                                          <span key={ca.oid} className="pl1">
                                            –
                                          </span>
                                        );
                                      })}
                                    </ol>
                                  ) : (
                                    <span className="fg--black-50">–</span>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    ) : (
                      <p>Root Causes have not been identified</p>
                    )}
                  </ReportCard.Column>
                </ReportCard>
                <ReportCard id="timeline">
                  <ReportCard.Column>
                    <ReportCard.Title>Timeline</ReportCard.Title>
                    {!timelineEvents || timelineEvents.length < 1 ? (
                      'No timeline has been created for this analysis'
                    ) : (
                      <Table unstyled>
                        <Table.Header>
                          <Table.HeaderCell unstyled>&nbsp;</Table.HeaderCell>
                          <Table.HeaderCell unstyled>&nbsp;</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                          {timelineEvents.map(event => {
                            let formattedDate;
                            if (
                              ['Start of Analysis', 'Expected Completion', 'Analysis Completed'].includes(event.text)
                            ) {
                              formattedDate = format(extractDatePart(new Date(event.time)), 'MM/dd/yyyy');
                            } else {
                              formattedDate = format(new Date(event.time), 'MM/dd/yyyy\xa0HH:mm');
                            }
                            return (
                              <Table.Row key={shortid()}>
                                <Table.Cell width="24%" border={false} padding="5px 0">
                                  <span className={styles['timelineDate']}>{formattedDate}</span>
                                </Table.Cell>
                                <Table.Cell border={false} padding="0">
                                  <p className={styles['timelineText']}>{event.text}</p>
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    )}
                  </ReportCard.Column>
                </ReportCard>
                <ReportCard id="summary-of-findings">
                  <ReportCard.Column>
                    <ReportCard.Title>Summary of Findings</ReportCard.Title>
                    <p>
                      {report.tree.findings === ''
                        ? 'Summary of findings has not been completed.'
                        : report.tree
                            .findings!.split('\n')
                            .filter(s => s !== '')
                            .map(p => <p key={shortid()}>{p}</p>)}
                    </p>
                  </ReportCard.Column>
                </ReportCard>
                <ReportCard id="tasks" full classes={['print-pagebreak-after', 'print-pagebreak-before']}>
                  <ReportCard.Column>
                    <ReportCard.Title>Tasks</ReportCard.Title>
                    {!report.tasks || report.tasks.length < 1 ? (
                      'No tasks have been added to this RCA.'
                    ) : (
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Node</Table.HeaderCell>
                            <Table.HeaderCell>Title</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Assigned&nbsp;To</Table.HeaderCell>
                            <Table.HeaderCell>Due</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {
                            /* @ts-ignore */
                            report.tasks
                              .sort(a => (a.taskType === 'VERIFICATION' ? 1 : -1))
                              .map(t => {
                                return (
                                  <Table.Row key={shortid()}>
                                    <Table.Cell style={{ textTransform: 'capitalize' }}>
                                      {toTitleCase(t.taskType.toLowerCase().split('_').join(' '))}
                                    </Table.Cell>
                                    <Table.Cell>{t.nodeText || 'N/A'}</Table.Cell>
                                    <Table.Cell style={{ textTransform: 'capitalize' }}>{t.title}</Table.Cell>
                                    <Table.Cell>{t.description}</Table.Cell>
                                    <Table.Cell>
                                      {t.doers.map(d => users.get(d, Map({ fullName: d })).get('fullName')).join(', ')}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={cx('fg--black', {
                                        'fg--red': new Date(t.deadline) < new Date() && !t.completedAt,
                                      })}
                                    >
                                      {t.completedAt ? 'Completed' : format(new Date(t.deadline), 'MM/dd/yyyy')}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })
                          }
                        </Table.Body>
                      </Table>
                    )}
                  </ReportCard.Column>
                </ReportCard>
                <ReportCard id="logic-tree" classes={['print-pagebreak-before', 'print-pagebreak-after']}>
                  <ReportCard.Column>
                    <ReportCard.Title>Logic Tree</ReportCard.Title>
                    <ReportCard.Row className="tree-snapshot">
                      <img src={capturedTreeImage} alt={reportTitle} />
                    </ReportCard.Row>
                  </ReportCard.Column>
                </ReportCard>
                {report.tree.methodology !== AnalysisMethodology.FIVE_WHYS ? (
                  <ReportCard
                    full
                    id="root-causes-and-findings"
                    classes={['print-pagebreak-after', 'print-pagebreak-before']}
                  >
                    <ReportCard.Column style={{ maxWidth: '100%' }}>
                      <ReportCard.Row full>
                        <ReportCard.Column flex={4}>
                          <ReportCard.Title>Root Causes and Findings</ReportCard.Title>
                        </ReportCard.Column>
                        <ReportCard.Column
                          flex={1}
                          alignItems="stretch"
                          justifyContent="flex-end"
                          style={{ display: 'flex' }}
                        >
                          <button
                            data-tip="Expand Notes"
                            onClick={() => setAllNotesExpanded(true)}
                            className={cx('hide-for-print', styles['expandControlButton'], {
                              [styles['disabledButton']]: allNotesExpanded,
                            })}
                            disabled={allNotesExpanded}
                          >
                            <ExpandAll16 fill="black" />
                          </button>
                          <button
                            data-tip="Collapse Notes"
                            onClick={() => setAllNotesExpanded(false)}
                            className={cx('hide-for-print', styles['expandControlButton'], {
                              [styles['disabledButton']]: !allNotesExpanded,
                            })}
                            disabled={!allNotesExpanded}
                          >
                            <CollapseAll16 fill="black" />
                          </button>
                        </ReportCard.Column>
                      </ReportCard.Row>

                      <RootCauses
                        allNotesExpanded={allNotesExpanded}
                        /* @ts-ignore */
                        notes={report.notes.filter(n => n.noteType === 'FINDINGS')}
                        nodes={reportElements}
                        data={combinedRootCauseData}
                      />
                    </ReportCard.Column>
                  </ReportCard>
                ) : null}
                <ReportCard id="team-members">
                  <ReportCard.Column>
                    <ReportCard.Title>Team Members</ReportCard.Title>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Title</Table.HeaderCell>
                          <Table.HeaderCell>Email</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {report.members.map(m => {
                          return (
                            <Table.Row key={shortid()}>
                              <Table.Cell align="left">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Avatar height={40} fontSize={12} name={m.fullName} />
                                      </td>
                                      <td>
                                        <span className="pl3">{m.fullName}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Table.Cell>
                              <Table.Cell align="left">
                                <span>{m.jobTitle || 'N/A'}</span>
                              </Table.Cell>
                              <Table.Cell align="left">
                                <span>{m.username}</span>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </ReportCard.Column>
                </ReportCard>
                <h3 className="print-only">Analysis {report.tree.treeUuid}</h3>
              </div>
              <ReportCard id="attachments" classes={['print-only']}>
                <ReportCard.Column>
                  <ReportCard.Title>Attachments</ReportCard.Title>
                  {!attachmentsByType || (attachmentsByType.images.length < 1 && attachmentsByType.other.length < 1) ? (
                    <p>No attachments have been uploaded to this analysis.</p>
                  ) : (
                    attachmentsByType &&
                    attachmentsByType.images &&
                    attachmentsByType.images.map(i => {
                      const alt = `${i.filename} uploaded by ${i.uploaded_by}`;
                      return <img key={shortid()} className={styles['attachmentPrintImage']} src={i.url} alt={alt} />;
                    })
                  )}
                </ReportCard.Column>
              </ReportCard>
              {!attachmentsHidden ? (
                <ReportCard id="attachments" classes={['hide-for-print']}>
                  <ReportCard.Column>
                    <ReportCard.Title>Attachments</ReportCard.Title>
                    {!attachmentsByType ||
                    (attachmentsByType.images.length < 1 && attachmentsByType.other.length < 1) ? (
                      <p>No attachments have been uploaded to this analysis.</p>
                    ) : (
                      <>
                        <ReportCard.Subtitle type="h4">Images</ReportCard.Subtitle>
                        <ReportCard.Row>
                          <AttachmentGallery
                            files={
                              attachmentsByType && attachmentsByType.images
                                ? attachmentsByType.images.map(f => {
                                    const node = nodesWithAttachments.find(
                                      // @ts-ignore
                                      n => n.oid === f.node_uuid,
                                    );

                                    return {
                                      src: f.url,
                                      loading: null, // change to 'lazy' to delay rendering until in viewport
                                      alt: f.url,
                                      'data-meta': {
                                        uploadedBy: f.uploaded_by,
                                        filename: f.filename,
                                        contentType: f.content_type,
                                        node,
                                      },
                                    };
                                  })
                                : []
                            }
                          />
                        </ReportCard.Row>
                        <ReportCard.Subtitle type="h4">Other</ReportCard.Subtitle>
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Filename</Table.HeaderCell>
                              <Table.HeaderCell>Type</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {attachmentsByType &&
                              attachmentsByType.other &&
                              attachmentsByType.other.map(a => {
                                const extension = a.filename.split('.')[1];
                                const filename = a.filename.split('.')[0];

                                return (
                                  <Table.Row key={shortid()}>
                                    <Table.Cell>
                                      <a
                                        href={a.url}
                                        className="flex items-center justify-start pl1 no-underline link black"
                                      >
                                        <Attachment16 className="mr2" /> {filename}
                                      </a>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <span>{`.${extension}`}</span>
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                          </Table.Body>
                        </Table>
                      </>
                    )}
                  </ReportCard.Column>
                </ReportCard>
              ) : null}
            </section>
          </article>
        </div>

        <BackToTop />
      </Page>
    );
  }

  return null;
});

ReportContainer.displayName = 'ReportContainer';
