import React from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { Close24 } from '@carbon/icons-react';
import { debounce } from './Tree';

export const highlight = debounce((dispatch, nodeUUID) => {
  dispatch(Map({ type: 'HIGHLIGHT', nodeUUID }));
  setTimeout(() => dispatch(Map({ type: 'UNHIGHLIGHT', nodeUUID })), 250);
}, 500);

export const Accordion = ({ options, value, onChange }) => {
  return (
    <div className={classNames('bb b--dark-gray mb3 mt2 ph3')}>
      <div className={classNames('flex')}>
        {options.map(o => (
          <div
            key={o}
            className={classNames('i18n pv2 bb mr3 bw1 dim pointer b--dark-gray ph2', {
              'b--blue': value === o,
            })}
            onClick={() => {
              if (onChange) {
                onChange(o);
              }
            }}
          >
            {o}
          </div>
        ))}
      </div>
    </div>
  );
};

const Header = ({ title }) => {
  return (
    <div className={'f4 pl3 mt3 flex justify-between items-center pr3 bb pb1 b--white-10 white-70 select-none'}>
      {title}
    </div>
  );
};

export const Section = ({ className = '', title = '', children }) => {
  return (
    <div className={classNames(className, 'pb2')}>
      <Header title={title} />
      <div className={'mh3 mt2'}>{children}</div>
    </div>
  );
};

export const NodeIndicator = ({ state }) => {
  const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
  const title = state
    .getIn(['tree', 'elements'], Map())
    .getIn(path || List(), Map())
    .get('text');

  return (
    <div className={'pv2 light-gray f6'}>
      {path.size === 0
        ? 'This will be added to the top, Event node by default.'
        : title
        ? `This will be added to the node entitled "${title}."`
        : 'This will be added to the selected, untitled node.'}
    </div>
  );
};

export const Blade = ({ title, show, state, dispatch, children, onClose }) => {
  return (
    <div
      className={classNames('fixed left-0 bottom-0 z-2 bg-black-90 white bt b--white-10 w6', {
        dn: !show,
      })}
      style={{ top: '48px' }}
    >
      <div className={'h-100 overflow-y-auto'}>
        <div className={'i18n white-70 f3 pt3 pl3 pb1 ttc'}>{title}</div>
        <div
          onClick={e => {
            if (onClose) {
              onClose();
            }
            dispatch(Map({ type: 'CLEAR_CONTEXTUAL_DRAWER' }));
            e.stopPropagation();
            e.preventDefault();
          }}
          className={'absolute top-0 right-0 fill--white pa3 dim pointer'}
        >
          <Close24 />
        </div>
        {children}
      </div>
    </div>
  );
};
