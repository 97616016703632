import { User } from '@interfaces';
import React from 'react';

type OrganizationContextValue = {
  users: Record<string, User> | Record<string, never>;
  name: string | null;
  licenses: number | null;
  setOrganization: (o: any) => void;
};

const OrganizationContext = React.createContext<OrganizationContextValue | null>(
  null,
);

export const useOrganization = () => {
  const context = React.useContext(OrganizationContext);

  if (!context) {
    throw new Error(
      'useOrganization must be used within an OrganizationProvider',
    );
  }

  return context as OrganizationContextValue;
};

export const OrganizationProvider: React.FC = ({ children }) => {
  const [org, setOrg] = React.useState<{
    licenses: number;
    users: Record<string, User>;
    name: string;
  } | null>(null);
  const handleSetOrganization = React.useCallback(
    (o: Record<string, User>) => {
      try {
        const companyName = Object.values(o)[0].companyName;
        const licenses = Object.values(o)[0].licenses;
        const value = {
          licenses,
          name: companyName,
          users: o,
        };

        return setOrg(value);
      } catch (error) {
        console.warn(
          '[OrganizationContext]: failed to create org state using received users',
          o,
        );
      }
    },
    [setOrg],
  );
  const value: OrganizationContextValue = React.useMemo(
    () => ({
      users: org?.users || {},
      licenses: org?.licenses || null,
      name: org?.name || null,
      setOrganization: handleSetOrganization,
    }),
    [handleSetOrganization, org],
  );

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};
