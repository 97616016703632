import React from 'react';

export function useLocalStorage<S extends Record<string, unknown>>({
  key,
  initialValue,
}: {
  key: string;
  initialValue?: S;
}): [S, React.Dispatch<React.SetStateAction<S>>] {
  const fallback = initialValue || ({} as S);
  const [storedValue, setStoredValue] = React.useState<S>(() => {
    if (typeof window === 'undefined') {
      return fallback;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : fallback;
    } catch (error) {
      // If error also return initialValue
      console.error(
        '[useLocalStorage]: failed to parse value from storage',
        error,
      );
      return fallback;
    }
  });

  const setValue = (value: React.SetStateAction<S>) => {
    try {
      setStoredValue(value);

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      console.error('[useLocalStorage]: setValue encountered error', error);
    }
  };

  return [storedValue, setValue];
}
