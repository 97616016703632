import { Avatar } from 'components';
import React from 'react';
import shortid from 'shortid';

export const AvatarGroup: React.FC<{ usernames: string[] }> = ({
  usernames,
}) => {
  return (
    <div className="flex items-center justify-start">
      {usernames.map(username => {
        return (
          <div className="ph1" key={shortid()}>
            <Avatar fontSize={15} name={username} />
          </div>
        );
      })}
    </div>
  );
};
