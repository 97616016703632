import React, { useEffect } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import type { Core as Cytoscape } from 'cytoscape';
import Immutable from 'immutable';
import { List, Map, Repeat, Set } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

const wheelSensitivity = 0.5; // https://js.cytoscape.org/#init-opts/wheelSensitivity

const immutableGet = (object, key) =>
  Immutable.Map.isMap(object) || Immutable.List.isList(object) ? object.get(key) : object[key];

const immutableToJson = object => (Immutable.isImmutable(object) ? object.toJS() : object);

const immutableDiff = (_this, that) => _this !== that;

const defaultNode = Map({
  id: '',
  oid: null,
  children: List(),
  text: '',
  type: null,
  verified: false,
  possible: true,
  // shares structure with patch chain:
  //  - deleted
  //  - chain
});

export const Node = m => defaultNode.merge(m).set('oid', uuidv4());

export const templateToNode = root => {
  const text = root.get('label');
  const children = root.get('children', List()).map(ch => templateToNode(ch));
  const node = Node({ text, children, id: 'hypothesis', type: 'hypothesis' });

  return node;
};

const emptyMessage = 'Empty\x20';

const hasOverdue = 1; // red
const hasIncomplete = 2; // yellow
const hasComplete = 3; // green
const hasOther = 4; // gray

const getClasses = (oid, node, metadata, hasAttachment) => {
  const selected = metadata.getIn([oid, 'selected']);
  if (selected) {
    return List(['selected']);
  }

  const type = node.get('type');
  const possible = node.get('possible');
  const verified = node.get('verified');
  const invisible = metadata.getIn([oid, 'invisible']);
  const hidden = node.get('hidden');

  const classes = [];

  let clazz = type;
  if (!possible) {
    clazz = 'notTrue';
  }

  if (verified) {
    clazz = `${clazz}__V`;
  }

  if (hasAttachment === hasOverdue) {
    clazz = `${clazz}__A_Overdue`;
  } else if (hasAttachment === hasIncomplete) {
    clazz = `${clazz}__A_Incomplete`;
  } else if (hasAttachment === hasComplete) {
    clazz = `${clazz}__A_Complete`;
  } else if (hasAttachment === hasOther) {
    clazz = `${clazz}__A_Other`;
  }

  if (hidden) {
    classes.push('hidden');
  } else {
    classes.push(clazz);
  }

  if (invisible) {
    classes.push('invisible');
  }

  if (!node.get('text')) {
    classes.push('empty');
  }

  return List(classes);
};

export const CyNode = (id, oid, node, metadata, hasAttachment) => {
  const classes = getClasses(oid, node, metadata, hasAttachment);
  const isHidden = node.get('hidden');

  const label = isHidden ? '' : node.get('text') || emptyMessage;

  return Map({
    group: 'nodes',
    data: Map({ id, label }),
    classes,
  });
};

export const CyEdge = (id, srcId, dstId, classes) =>
  Map({
    group: 'edges',
    data: Map({
      id,
      source: `${srcId}`,
      target: `${dstId}`,
    }),
    classes,
  });

export const cyParentEdge = (cur, path) => {
  const parentId = path.skipLast(1).join('.');
  const childId = path.join('.');
  const edgeId = `${parentId} ${childId}`;
  return CyEdge(edgeId, parentId, childId, []);
};

export const reduceElements = (cur, parent, path, metadata, oidToIdCache, attachmentSet) => {
  const id = path.join('.');
  const oid = cur.get('oid');
  const isHidden = cur.get('hidden');

  const hasAttachment = attachmentSet.get(oid);

  oidToIdCache[oid] = id;

  const cyCur = CyNode(id, oid, cur, metadata, hasAttachment);
  const cyEdgeToParent = cyParentEdge(cur, path);
  const esList = parent ? List([cyCur, cyEdgeToParent]) : List([cyCur]);

  if (!isHidden) {
    const children = cur.get('children');

    return children.isEmpty()
      ? esList
      : esList.concat(
          children.flatMap((child, i) =>
            reduceElements(child, cur, path.push(i), metadata, oidToIdCache, attachmentSet),
          ),
        );
  } else {
    return esList;
  }
};

export const makeAdditionalEdges = (edges, oidToIdCache) =>
  edges
    .map(([a, b]) => {
      const aId = oidToIdCache[a];
      const bId = oidToIdCache[b];
      if (aId && bId) {
        const edgeId = `${aId} ${bId}`;
        return CyEdge(edgeId, aId, bId, ['additional']);
      } else {
        return null;
      }
    })
    .filter(x => x);

export const stateToCy = state => {
  const root = state.getIn(['tree', 'elements'], Map());
  const metadata = state.getIn(['tree', 'metadata'], Map());
  const edges = state.getIn(['tree', 'edges'], List());
  const treeUuid = state.getIn(['tree', 'uuid']);

  const now = Date.now();
  const byNode = {};
  state
    .getIn(['tasks', treeUuid], Map())
    .valueSeq()
    .forEach(t => {
      const nodeUuid = t.get('nodeUuid');
      const currentStatus = byNode[nodeUuid] || hasOther;
      let newStatus = currentStatus;
      const deadline = t.get('deadline');
      if (!t.get('completedAt') && deadline && deadline < now) {
        newStatus = hasOverdue;
      } else if (!t.get('completedAt') && currentStatus > hasIncomplete) {
        newStatus = hasIncomplete;
      } else if (currentStatus > hasComplete) {
        newStatus = hasComplete;
      }
      byNode[nodeUuid] = newStatus;
    });
  state
    .getIn(['notes', treeUuid], Map())
    .valueSeq()
    .forEach(t => {
      const nodeUuid = t.get('nodeUuid');
      if (!byNode[nodeUuid]) {
        byNode[nodeUuid] = hasOther;
      }
    });
  state
    .getIn(['files', treeUuid], Map())
    .valueSeq()
    .forEach(t => {
      const nodeUuid = t.get('nodeUuid');
      if (!byNode[nodeUuid]) {
        byNode[nodeUuid] = hasOther;
      }
    });
  const attachmentSet = Map(byNode);

  // NOTE:
  // This is a performance hack so we don't traverse the whole tree
  // each time to look for ids
  const oidToIdCache = {};
  const cyElements = reduceElements(root, null, List(['r']), metadata, oidToIdCache, attachmentSet);
  const additionalEdges = makeAdditionalEdges(edges, oidToIdCache);

  return cyElements.concat(additionalEdges);
};

const childrenRepeat = Repeat('children');

const idToPath = id =>
  id === 'r'
    ? List()
    : childrenRepeat
        .interleave(
          // @ts-ignore
          List(id.split('.'))
            .skip(1)
            // @ts-ignore
            .map(x => x | 0),
        )
        .toList();

export const cyToPath = cyElem => idToPath(cyElem.id());

export const throttle = (f, millis) => {
  let lastTime = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastTime >= millis) {
      lastTime = now;
      return f(...args);
    }
  };
};

export const debounce = (f, millis) => {
  let id;
  return (...args) => {
    if (id) {
      clearTimeout(id);
    }
    id = setTimeout(() => f(...args), millis);
  };
};

export const debounceFrame = f => {
  let id;
  return (...args) => {
    if (id) {
      cancelAnimationFrame(id);
    }
    id = requestAnimationFrame(() => f(...args));
  };
};

export const debounceImmediate = (f, millis) => {
  let lastExec = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastExec >= millis) {
      f(...args);
    }
    lastExec = now;
  };
};

const gridSettings = Map({
  drawGrid: true,
  gridColor: 'rgba(100,100,255,0.2)',
  gridSpacing: 50,
  snapToGridOnRelease: false,
});

const treeHandlers = (cy: Cytoscape, dispatch, hasLoaded) => {
  const scrollDelayMillis = 200;

  const dispatchStop = debounce(() => dispatch(Map({ type: 'CANVAS_VIEWPORT_STOP' })), scrollDelayMillis);

  const dispatchStart = debounceImmediate(() => dispatch(Map({ type: 'CANVAS_VIEWPORT_START' })), scrollDelayMillis);

  const onNodeAdded = ev => {
    dispatch(Map({ type: 'NODE_ADD', path: cyToPath(ev.target), event: ev }));
  };

  cy.on('select', 'node, edge', e => cy.elements().not(e.target).unselect());

  cy.on('select', 'edge', e => {
    const edge = e.target;
    const isAdditional = edge.hasClass('additional');
    const edgeId = edge.id();
    e.target.unselect();

    if (isAdditional) {
      e.target.unselect();
      const edge = List(edgeId.split(' ')).map(idToPath);
      dispatch(Map({ type: 'EDGE_DELETE', edge }));
    }
  });

  // do not want to trigger this on every node when adding at the beginning,
  // otherwise you get bad lag
  cy.on('add', 'node', hasLoaded ? onNodeAdded : () => null);

  cy.on('viewport', () => {
    dispatchStart();
    dispatchStop();
  });

  cy.on('grab', 'node', event => {
    const path = cyToPath(event.target);
    dispatch(Map({ type: 'GRAB', path, event }));
    cy.one('drag', 'node', event => {
      dispatch(Map({ type: 'DRAG', path, event }));
    });
  });

  cy.on('dragfree', 'node', event => {
    const path = cyToPath(event.target);
    dispatch(Map({ type: 'FREE', path, event }));
  });

  cy.on('tapselect', 'node', event => {
    const path = cyToPath(event.target);

    dispatch(Map({ type: 'SELECT_NODE', path, event }));
  });

  cy.dblclick();
  cy.on('dblclick', 'node', event => {
    const path = cyToPath(event.target);
    console.warn('dblclick', { event, path });
    dispatch(Map({ type: 'DOUBLECLICK', path, event }));
  });

  cy.on('tapunselect', 'node', () => {
    dispatch(Map({ type: 'UNSELECT' }));
  });
};

export const previewElementsToCy = elements => {
  const results = reduceElements(elements, null, List(['r']), Map(), {}, Set());
  console.warn('previewElementsToCy', { elements, results });
  return results;
};

export const TreePreview = ({ onCyChange, elements, stylesheet, layout }) => {
  const preparedElements = previewElementsToCy(elements);

  return (
    <CytoscapeComponent
      cy={onCyChange}
      stylesheet={stylesheet}
      layout={layout}
      elements={preparedElements}
      maxZoom={1.2}
      minZoom={0.2}
      wheelSensitivity={wheelSensitivity}
      diff={immutableDiff}
      toJson={immutableToJson}
      get={immutableGet}
      className="h-100 w-100"
    />
  );
};

export const Tree = ({ state, dispatch, isCompleted = false, isViewOnly = false }) => {
  const cyState = state.getIn(['tree', 'view', 'cy']);
  const elements = state.getIn(['tree', 'elements'], Map());
  const stylesheet = state.getIn(['tree', 'view', 'stylesheet']);
  const layout = state.getIn(['tree', 'view', 'layout']);
  const grid = state.getIn(['tree', 'view', 'grid'], true);

  const hasClipboard = state.getIn(['templateClipboard', 'elements']);

  useEffect(() => {
    if (cyState) {
      if (!isViewOnly && !isCompleted) {
        treeHandlers(cyState, dispatch, !elements.isEmpty());
      }
      cyState.gridGuide(gridSettings.set('drawGrid', grid).toJS());
      return () => {
        cyState.removeAllListeners();
      };
    }
  }, [elements, cyState, dispatch, grid, isCompleted, isViewOnly]);

  useEffect(() => {
    if (layout && cyState) {
      dispatch(Map({ type: 'RUN_LAYOUT' }));
    }
  }, [cyState, layout, dispatch]);

  useEffect(() => {
    if (elements && hasClipboard) {
      dispatch(Map({ type: 'PASTE_TEMPLATE_SUBTREE' }));
    }
  }, [elements, hasClipboard, dispatch]);

  return (
    <div className="h-100 w-100">
      <CytoscapeComponent
        cy={cy => {
          if (cy && !cyState) dispatch(Map({ type: 'SET_CY', cy }));
        }}
        stylesheet={stylesheet}
        elements={stateToCy(state)}
        layout={layout}
        maxZoom={1.2}
        minZoom={0.2}
        wheelSensitivity={wheelSensitivity}
        diff={immutableDiff}
        toJson={immutableToJson}
        get={immutableGet}
        className="h-100 w-100"
      />
    </div>
  );
};
