import React, { useEffect, useState } from 'react';
import { Set, Map, List } from 'immutable';
import { useLocation, Route } from 'react-router-dom';
import classNames from 'classnames';
import { xhr, updateOrganizations, showModal } from './Fetch';

export const userItems = Map({
  'mark-status': 'Change Status',
  'move-organization': 'Change User Organization',
  'reset-password': 'Reset Password',
  'set-expired': 'Set Expired/Unexpired',
});

export const orgItems = Map({
  'change-licenses': 'Change License Count',
  'toggle-features': 'Toggle Features',
  'add-user': 'Add User and/or Organization',
});

const Checkbox = ({ text, checked, onCheck }) => {
  return (
    <div className="mv1">
      <div className="ba pa2 dib b--gray">
        <label className="flex items-center">
          <div className="mr2">{text}</div>
          <input type="checkbox" className="sa" checked={checked} onChange={e => onCheck(e.target.checked)} />
        </label>
      </div>
    </div>
  );
};

const ChangeLicense = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());

  const organization = state.get('organization', Map());
  const organizationsId = organization.get('organizationsId');

  const onClick = async () => {
    xhr('POST', '/change-licenses', {
      licenses: parseInt(model.get('licenses')),
      organizationsId,
    })
      .then(res => showModal(res, dispatch, 'Updated license count.'))
      .then(() => updateOrganizations(dispatch));
  };

  return (
    <div>
      <div>{organization.get('companyName')}</div>
      <div className="mb2">License Count (set to -1 for unlimited)</div>
      <input
        onChange={e => setModel(model.set('licenses', e.target.value))}
        value={model.get('licenses', organization.get('licenses'))}
        type="number"
        min="-1"
        className="sa"
      />
      <div>
        <button onClick={onClick}>Go</button>
      </div>
    </div>
  );
};

const ToggleFeatures = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());

  const organization = state.get('organization', Map());
  const organizationsId = organization.get('organizationsId');
  const templates = organization.getIn(['features', 'templates']);
  const taskMetadata = organization.getIn(['features', 'taskMetadata']);
  const i18n = organization.getIn(['features', 'i18n']);
  const emailDash = organization.getIn(['features', 'emailDash'], false);
  const aiFeatures = organization.getIn(['features', 'aiFeatures'], false);

  const onClick = async () => {
    const newTemplates = model.get('templates', templates);
    const newTaskMetadata = model.get('taskMetadata', taskMetadata);
    const newI18n = model.get('i18n', i18n);
    const newEmailDash = model.get('emailDash', emailDash);
    const newAIFeatures = model.get('aiFeatures', aiFeatures);
    xhr('POST', '/toggle-features', {
      organizationsId,
      templates: newTemplates,
      taskMetadata: newTaskMetadata,
      i18n: newI18n,
      emailDash: newEmailDash,
      aiFeatures: newAIFeatures,
    })
      .then(res => showModal(res, dispatch, 'Updated org features.'))
      .then(() => updateOrganizations(dispatch))
      .then(
        dispatch(
          Map({
            type: 'SET_ORGANIZATION',
            organization: organization.set(
              'features',
              Map({
                templates: newTemplates,
                taskMetadata: newTaskMetadata,
                i18n: newI18n,
                emailDash: newEmailDash,
                aiFeatures: newAIFeatures,
              }),
            ),
          }),
        ),
      )
      .then(setModel(Map()));
  };

  return (
    <div>
      <div>{organization.get('companyName')}</div>
      <div className="mb2">Features</div>
      <Checkbox
        text="Task Metadata"
        checked={model.get('taskMetadata', taskMetadata)}
        onCheck={checked => setModel(model.set('taskMetadata', checked))}
      />
      <Checkbox
        text="Templates"
        checked={model.get('templates', templates)}
        onCheck={checked => setModel(model.set('templates', checked))}
      />
      <Checkbox
        text="I18n"
        checked={model.get('i18n', i18n)}
        onCheck={checked => setModel(model.set('i18n', checked))}
      />
      <Checkbox
        text="Monthly KPI Email"
        checked={model.get('emailDash', emailDash)}
        onCheck={checked => setModel(model.set('emailDash', checked))}
      />
      <Checkbox
        text="AI Features"
        checked={model.get('aiFeatures', aiFeatures)}
        onCheck={checked => setModel(model.set('aiFeatures', checked))}
      />
      <div>
        <button onClick={onClick}>Go</button>
      </div>
    </div>
  );
};

const ResetPassword = ({ state, dispatch }) => {
  const [password, setPassword] = useState('');
  const user = state.get('user', Map());

  const onClick = async () => {
    const res = await xhr('POST', '/reset-password', {
      password,
      usersId: user.get('id'),
    });
    showModal(res, dispatch, "Reset user's password.");
  };

  return (
    <div>
      <div>{user.get('username')}</div>
      <div className="mb2">Reset Password (at least 8 characters)</div>
      <input onChange={e => setPassword(e.target.value)} value={password} type="text" className="sa" />
      <div>
        <button onClick={onClick}>Reset</button>
      </div>
    </div>
  );
};

const ChangeStatus = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const user = state.get('user', Map());
  const roles = user.get('roles');

  useEffect(() => {
    const isPaid = Set(roles).has('PAID');
    const isAdmin = Set(roles).has('ADMIN');
    const isComplimentary = Set(roles).has('COMPLIMENTARY');
    setModel(m => Map({ isPaid, isAdmin, isComplimentary }));
  }, [roles, setModel]);

  const onClick = async () => {
    const res = await xhr('POST', '/mark-status', {
      usersId: user.get('id'),
      isAdmin: model.get('isAdmin'),
      isPaid: model.get('isPaid'),
      isComplimentary: model.get('isComplimentary'),
    });
    showModal(res, dispatch, 'Status has been changed.');
    updateOrganizations(dispatch);
  };

  return (
    <div>
      <div className="mb2">{user.get('username')}</div>
      <div className="mb2">
        <Checkbox
          text="Admin"
          checked={model.get('isAdmin', false)}
          onCheck={checked => setModel(model.set('isAdmin', checked))}
        />
        <Checkbox
          text="Paid"
          checked={model.get('isPaid', false)}
          onCheck={checked => setModel(model.set('isPaid', checked))}
        />
        <Checkbox
          text="Complimentary"
          checked={model.get('isComplimentary', false)}
          onCheck={checked => {
            if (checked) {
              setModel(model.set('isPaid', true).set('isComplimentary', true));
            } else {
              setModel(model.set('isPaid', false).set('isComplimentary', false));
            }
          }}
        />
      </div>
      <button onClick={onClick}>Change</button>
    </div>
  );
};

const OrganizationSelect = ({ state, value, onSelect }) => {
  const organizations = state.get('organizations', List()).map(o => o.first());

  return (
    <select value={value} onChange={e => onSelect(e.target.value)}>
      <option value=""></option>
      {organizations
        .sortBy(x => x.get('companyName').toLowerCase())
        .map(x => {
          const id = x.get('organizationsId');
          const name = x.get('companyName');

          return (
            <option key={id} value={id}>
              {name}
            </option>
          );
        })}
    </select>
  );
};

const MoveOrganization = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const organizations = state.get('organizations', List()).map(o => o.first());

  const user = state.get('user', Map());

  const userOrg = organizations
    .find(o => o.get('organizationsId') === user.get('organizationsId'), null, Map())
    .get('companyName', 'UNKNOWN');

  const onClick = async () => {
    const res = await xhr('POST', '/move-organization', {
      organizationsId: parseInt(model.get('select')),
      usersId: user.get('id'),
    });
    showModal(res, dispatch, 'User has been moved.');
    updateOrganizations(dispatch);
  };

  return (
    <div>
      <div className="mb2">{user.get('username')}</div>
      <div className="mb2">
        <span className="underline">{userOrg}</span> =&gt;
        <OrganizationSelect
          state={state}
          value={model.get('select', '')}
          onSelect={x => {
            setModel(model.set('select', x));
          }}
        />
      </div>
      <button onClick={onClick}>Move</button>
    </div>
  );
};

const Expired = ({ state, dispatch }) => {
  const user = state.get('user', Map());

  const onClickHandler = value => async () => {
    const res = await xhr('POST', '/set-expired', {
      usersId: user.get('id'),
      expired: value,
    });
    showModal(res, dispatch, 'User expiration status has been updated.');
    updateOrganizations(dispatch);
  };

  return (
    <div>
      <div className="mb2">{user.get('username')}</div>
      <button onClick={onClickHandler(true)}>Expire</button>
      <button onClick={onClickHandler(false)}>Re-Activate</button>
    </div>
  );
};

const AddToOrganization = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const createNew = model.get('createNew');

  const addNewOrganization = () => {
    return xhr('POST', '/add-new-organization', {
      email: model.get('email'),
      fullName: model.get('fullName'),
      companyName: model.get('companyName'),
      licenses: parseInt(model.get('licenses')),
    });
  };

  const addToOrganization = () => {
    const organizationsId = model.get('organizationsId');

    const organization = state
      .get('organizations')
      .filter(members => members.first().get('organizationsId') === (organizationsId | 0))
      .first();

    const admins = organization.filter(member => Set(member.get('roles')).has('ADMIN'));
    const adminUsername = admins.first().get('username');

    return xhr('POST', '/add-to-organization', {
      adminUsername,
      organizationsId: parseInt(organizationsId),
      email: model.get('email'),
      fullName: model.get('fullName'),
    });
  };

  const onClick = async () => {
    const res = await (createNew ? addNewOrganization() : addToOrganization());
    showModal(res, dispatch, 'User has been added to organization.');
    updateOrganizations(dispatch);
  };

  return (
    <div>
      <input
        placeholder="email"
        type="email"
        className="sa"
        value={model.get('email', '')}
        onChange={e => setModel(model.set('email', e.target.value))}
      />
      <input
        placeholder="Full Name"
        type="text"
        className="sa"
        value={model.get('fullName', '')}
        onChange={e => setModel(model.set('fullName', e.target.value))}
      />
      <div className="mv1">
        <div
          onClick={() => setModel(model.set('createNew', false))}
          className={classNames('mr2 dib dim underline', {
            fw7: !createNew,
          })}
        >
          Add to Existing
        </div>
        <div
          onClick={() => setModel(model.set('createNew', true))}
          className={classNames('dim dib underline', { fw7: createNew })}
        >
          Create New Org
        </div>
      </div>
      <div className="mv2">
        {!createNew ? (
          <div>
            <div className="mv1">Select Org</div>
            <OrganizationSelect
              state={state}
              value={model.get('organizationsId')}
              onSelect={x => setModel(model.set('organizationsId', x))}
            />
          </div>
        ) : (
          <div>
            <div className="mv1">New Org</div>
            <input
              placeholder="Org Name"
              type="text"
              className="sa"
              value={model.get('companyName', '')}
              onChange={e => setModel(model.set('companyName', e.target.value))}
            />
            <input
              placeholder="Licenses"
              type="number"
              className="sa"
              value={model.get('licenses', '')}
              onChange={e => setModel(model.set('licenses', e.target.value))}
            />
          </div>
        )}
      </div>
      <div className="mt4">
        <button onClick={onClick}>Create</button>
      </div>
    </div>
  );
};

export const Controls = ({ state, dispatch }) => {
  const location = useLocation();

  return (
    <div className="absolute bottom-0 right-0 pa3" style={{ left: '350px', height: '350px' }}>
      <div className="mv2 fw7 f4">{orgItems.merge(userItems).get(location.pathname.substring(4))}</div>
      <Route path="/sa/change-licenses">
        <ChangeLicense state={state} dispatch={dispatch} />
      </Route>
      <Route path="/sa/toggle-features">
        <ToggleFeatures state={state} dispatch={dispatch} />
      </Route>
      <Route path="/sa/reset-password">
        <ResetPassword state={state} dispatch={dispatch} />
      </Route>
      <Route path="/sa/move-organization">
        <MoveOrganization state={state} dispatch={dispatch} />
      </Route>
      <Route path="/sa/mark-status">
        <ChangeStatus state={state} dispatch={dispatch} />
      </Route>
      <Route path="/sa/set-expired">
        <Expired state={state} dispatch={dispatch} />
      </Route>
      <Route path="/sa/add-user">
        <AddToOrganization state={state} dispatch={dispatch} />
      </Route>
    </div>
  );
};
