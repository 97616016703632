import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import { Popover, IconButton } from 'components';
import { OverflowMenuHorizontal20 } from '@carbon/icons-react';
import { LegacyReducerProps } from '@interfaces';

import { Map } from 'immutable';
import styles from './styles.module.css';

const ConfirmationChoices = ({ onConfirm, onCancel }) => {
  return (
    <div className={cx(styles['menuItem'])}>
      <p className="w-70 mv0 mh0 fg--white-75 fw-medium">Are you sure?</p>
      <div className="w-30 flex items-center justify-around">
        <button className="unstyled fw-medium" onClick={onConfirm}>
          Yes
        </button>
        <span className="fg--white-75 ph1">/</span>
        <button className="unstyled fw-medium fg--white-75" onClick={onCancel}>
          No
        </button>
      </div>
    </div>
  );
};

export const MemberContextMenu: React.FC<
  {
    Trigger?: React.FC;
    username: string;
    treeUuid: string;
    disableTransfer?: boolean;
    onRemoveMember?: (member: string) => void;
    onTransferOwnership?: (newOwner: string) => void;
  } & LegacyReducerProps
> = ({ Trigger, disableTransfer = false, treeUuid, dispatch, onRemoveMember, onTransferOwnership, username }) => {
  const [currentConfirmationType, setCurrentConfirmationType] = useState<string | null>(null);
  const dismissConfirmation = useCallback(() => setCurrentConfirmationType(null), [setCurrentConfirmationType]);

  const showTransferConfirmation = useCallback(
    () => setCurrentConfirmationType('TRANSFER_OWNERSHIP'),
    [setCurrentConfirmationType],
  );
  const showRemovalConfirmation = useCallback(
    () => setCurrentConfirmationType('REMOVE_MEMBER'),
    [setCurrentConfirmationType],
  );

  // const [menuItems, setMenuItems] = useState<React.FC[]>([
  //   () => (
  //     <button
  //       onClick={showTransferConfirmation}
  //       className={cx('dim mb1', styles['menuItem'])}
  //     >
  //       Transfer Ownership
  //     </button>
  //   ),
  //   // () => <ConfirmationChoices onCancel={() => null} onConfirm={() => null} />,
  //   () => (
  //     <ConfirmationChoices
  //       onConfirm={confirmTransferOwnership}
  //       onCancel={dismissConfirmation}
  //     />
  //   ),
  // ]);

  const confirmTransferOwnership = useCallback(() => {
    if (onTransferOwnership) {
      onTransferOwnership(username);
    } else {
      dispatch(
        Map({
          type: 'SET_TREE_OWNER',
          owner: username,
          treeUuid,
        }),
      );
    }

    dismissConfirmation();
  }, [username, treeUuid, dispatch, dismissConfirmation]);

  const confirmRemoveMember = useCallback(() => {
    if (onRemoveMember) {
      onRemoveMember(username);
    } else {
      dispatch(
        Map({
          type: 'DELETE_MEMBER',
          treeUuid,
          member: username,
        }),
      );
    }

    dismissConfirmation();
  }, [dispatch, username, treeUuid, dismissConfirmation]);

  return (
    <Popover onDismissed={dismissConfirmation} onToggle={dismissConfirmation}>
      <Popover.Trigger>
        {Trigger ? (
          <Trigger />
        ) : (
          <IconButton borderColor="var(--white-35)" color="var(--white)" Icon={OverflowMenuHorizontal20} />
        )}
      </Popover.Trigger>
      <Popover.Content dark>
        {currentConfirmationType === 'REMOVE_MEMBER' ? (
          <ConfirmationChoices onConfirm={confirmRemoveMember} onCancel={dismissConfirmation} />
        ) : (
          <button
            disabled={disableTransfer}
            onClick={disableTransfer ? () => null : showTransferConfirmation}
            className={cx(styles['menuItemButton'], {
              [styles['disabledMenuItemButton']]: disableTransfer,
            })}
          >
            Transfer Ownership
          </button>
        )}

        {currentConfirmationType === 'TRANSFER_OWNERSHIP' ? (
          <ConfirmationChoices onCancel={dismissConfirmation} onConfirm={confirmTransferOwnership} />
        ) : (
          <button onClick={showRemovalConfirmation} className={styles['menuItemButton']}>
            Remove
          </button>
        )}
      </Popover.Content>
    </Popover>
  );
};
