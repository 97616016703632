import cx from 'classnames';
import { Renew32, Renew20, Renew24 } from '@carbon/icons-react';
import styles from './styles.module.css';

export const Spinner = ({ size = 20, ...props }) => (
  <div className={cx('flex items-center black', props.className)} style={{ width: `${size}px`, height: `${size}px` }}>
    <div className={cx('w-100 h-100 spin', props.color || 'blue')}>
      {size === 20 ? <Renew20 /> : null}
      {size === 32 ? <Renew32 /> : null}
      {!size || (size !== 32 && size !== 20 && <Renew24 />)}
    </div>
    {props.message ? <div className="ml3 f3">{props.message}</div> : null}
  </div>
);

export const AlternativeSpinner = props => {
  return (
    <span
      className={cx(styles.spinner, props.className)}
      style={{
        width: props.size || 15,
        height: props.size || 15,
        minWidth: props.size || 15,
        minHeight: props.size || 15,
        borderColor: props.color || '#fefefe',
        borderBottomColor: 'transparent',
        ...props.style,
      }}
    />
  );
};
