import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export const ReportSectionHeader = ({ title, id = null, right, className = null, sticky }) => {
  return (
    <header id={id} className={cx(styles.ReportSectionHeader, { [styles.sticky]: sticky }, className)}>
      <h1 className={cx('hide-for-print i18n', styles.sectionTitle)}>{title}</h1>
      <div className={styles.extraContent}>{right && right}</div>
    </header>
  );
};
