import React from 'react';
import { Area, ComposedChart, LineProps, Line, ResponsiveContainer, ResponsiveContainerProps, Tooltip } from 'recharts';

function NillDot() {
  return null;
}

type PointData = {
  amt?: number;
  uv?: number;
  pv?: number;
  name?: string;
};

type SparklineProps = Omit<LineProps, 'strokeWidth' | 'data' | 'dataKey'> & {
  weight?: number;
  tooltip?: boolean;
  data?: PointData[];
  dataKey?: string;
  height?: number;
  containerProps?: Omit<ResponsiveContainerProps, 'children'>;
};

export const Sparkline: React.FC<SparklineProps> = ({ ...props }) => {
  const chosenColor = props.color || 'var(--black-50)';
  const strokeWeight = props.weight || 2;
  const height = props.height || 43;

  return (
    <ResponsiveContainer width="100%" height={height + 1} aspect={3} {...props.containerProps}>
      <ComposedChart data={props.data}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="30%" stopColor="var(--black-60)" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        {/* @ts-ignore */}
        <Line
          height={height + 10}
          /* @ts-ignore */
          dot={NillDot}
          type="natural"
          dataKey="pv"
          stroke={chosenColor}
          strokeLinecap="round"
          strokeWidth={strokeWeight}
          {...props}
        />
        <Area type="natural" dataKey="pv" strokeWidth={0} fillOpacity={1} fill="url(#colorUv)" />
        {props.tooltip ? <Tooltip /> : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
