import React from 'react';
import cx from 'classnames';
import { CaretLeft16, CaretRight16, OverflowMenuHorizontal16 } from '@carbon/icons-react';
import shortid from 'shortid';

import styles from './styles.module.css';

/**
 * @typedef LegacyPaginationProps
 * @prop {boolean} prevPageAvailable
 * @prop {boolean} nextPageAvailable
 * @prop {number} [pages] - array of numbers, one for each page
 * @prop {number} currentPage - current page number
 * @prop {number} pageSize - total items displayed for each page
 * @prop {number} pageCount - total pages
 * @prop {string} className - optional className
 * @prop {(i: number) => void} gotoPage
 * @prop {() => void} nextPage
 * @prop {() => void} prevPage
 */

/**
 * @param {LegacyPaginationProps} props
 */
type LegacyPaginationProps = {
  prevPageAvailable?: boolean;
  nextPageAvailable?: boolean;
  pages: number[];
  currentPage: number;

  className?: string;

  pageSize?: number;
  pageCount: number;
  gotoPage: (i: number) => void;
  nextPage: () => void;
  prevPage: () => void;
};

export const LegacyPagination: React.FC<LegacyPaginationProps> = ({ pages, pageSize = 5, gotoPage, ...props }) => {
  const lastPage = pages[pages.length - 1];
  const gotoLastPage = React.useCallback(() => {
    if (gotoPage) {
      return gotoPage(pages.length - 1);
    }

    return null;
  }, [pages.length, gotoPage]);

  return (
    <div className={cx(styles['pagination'], props.className)}>
      <div className={styles['page-arrow']}>
        {props.prevPageAvailable && (
          <button className={styles['page-control']} onClick={props.prevPage}>
            <CaretLeft16 className={styles['page-control-icon']} />
          </button>
        )}
      </div>
      {pages.map((i, idx) => {
        if (idx < pageSize) {
          const handleOnClick = () => gotoPage(i);
          return (
            <button
              key={shortid()}
              onClick={handleOnClick}
              disabled={pages.length < 2}
              className={cx({
                [styles['page-indicator']]: pages.length > 1,
                [styles['active-page-indicator-colors']]: props.currentPage === i && pages.length > 1,
                [styles['active-page-indicator']]: props.currentPage === i,
              })}
            >
              {pages.length > 1 ? i + 1 : null}
            </button>
          );
        }

        return null;
      })}
      {pages.length > 5 ? (
        <OverflowMenuHorizontal16 className={styles['page-overflow-indicator']} fill="var(--black-50)" />
      ) : null}
      {props.pageCount > 5 && (
        <button className={cx(styles['page-indicator'])} onClick={gotoLastPage}>
          {lastPage}
        </button>
      )}
      <div className={styles['page-arrow']}>
        {props.nextPageAvailable && (
          <button className={styles['page-control']} onClick={props.nextPage}>
            <CaretRight16 className={styles['page-control-icon']} />
          </button>
        )}
      </div>
    </div>
  );
};
