import { FC } from 'react';
import cx from 'classnames';
import { AlternativeSpinner as Spinner } from 'components/ActivityIndicators';

type AsyncButtonProps = {
  loading?: boolean;
  children: string;
  className?: string;
  onClick: (...a: any[]) => void;
};

export const AsyncButton: FC<AsyncButtonProps> = ({ children, onClick, className, loading = false }) => {
  return (
    <button
      className={cx(
        'relative flex items-center justify-center hide-for-print trans ba bg-transparent b--black black pa2',
        className,
      )}
      onClick={onClick}
    >
      {children}
      {loading ? (
        <Spinner style={{ right: 10 }} className="absolute ml2 top-5" size={15} color="var(--black-80)" />
      ) : null}
    </button>
  );
};
