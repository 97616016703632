import React from 'react';

import { AnalysisMethodology, MethodologyDisplay } from '@interfaces';
import { CarbonIconProps, DecisionTree24, Fish24, Number_524 } from '@carbon/icons-react';
import styles from '../styles.module.css';

const FishboneIcon = (props: CarbonIconProps) => <Fish24 width={20} {...props} />;

const FiveWhysIcon = (props: CarbonIconProps) => <Number_524 style={{ position: 'relative', top: -2 }} {...props} />;

const ProactIcon = (props: CarbonIconProps) => <DecisionTree24 width={20} className="rotate-90" {...props} />;

export const MethodologyCell: React.FC<{ type: AnalysisMethodology }> = ({ type }) => {
  const MethodologyIcon = React.useMemo(() => {
    switch (type) {
      case AnalysisMethodology.PROACT:
        return ProactIcon;
      case AnalysisMethodology.FISHBONE:
        return FishboneIcon;
      default:
      case AnalysisMethodology.FIVE_WHYS:
        return FiveWhysIcon;
    }
  }, [type]);
  return (
    <div
      className={styles.bodyCell}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        padding: 0,
      }}
      data-tip={MethodologyDisplay[type]}
    >
      <MethodologyIcon type={type} />
    </div>
  );
};
