import { fire } from './Reducer';

export let auth;

export const initXhr = key => {
  auth = `Basic ${btoa(`admin:${key}`)}`;
};

export const xhr = (method, url, body) => {
  method = method.toUpperCase();

  const config = {
    method,
    headers: {
      Authorization: auth,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  if (method !== 'GET' && method !== 'OPTION' && body) {
    config.body = JSON.stringify(body);
  }

  return fetch(`/admin${url}`, config);
};

export const updateOrganizations = async dispatch => {
  const orgsXhr = await xhr('GET', '/organizations', null);
  const organizations = await orgsXhr.json();

  const treeCountXhr = await xhr('GET', '/tree-count', null);
  const counts = await treeCountXhr.json();

  fire(dispatch, 'SET_ORGANIZATIONS', { organizations });
  fire(dispatch, 'SET_COUNTS', { counts });
};

export const showModal = (res, dispatch, successMessage) => {
  fire(
    dispatch,
    'SHOW_MODAL',
    res.status === 200
      ? { message: successMessage, style: 'SUCCESS' }
      : { message: 'Failed, check your inputs', style: 'ERROR' },
  );
};
